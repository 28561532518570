/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *
 *  Special thanks goes to https://blog.bitsrc.io/improving-seo-of-react-apps-with-react-helmet-7b79fb8774f4
 *-------------------------------------------------------------*/

import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import Config from '../../constants/Config';
import { RCL_CONSENT_ANALYTICS } from '../web/CookieBanner';

const Analytics = React.memo(() => {
  /* #region Fields */
  const cookieValue = Cookies.get(RCL_CONSENT_ANALYTICS);
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (Config().facebookPixelId.length > 0 && cookieValue === 'true') {
      // using Facebook Pixel analytics
      /* eslint-disable */
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      /* eslint-enable */

      if (window.fbq) {
        window.fbq('init', Config().facebookPixelId);
        window.fbq('track', 'PageView');
      }
    }

    if (
      Config().pinterestConversionTrackingId.length > 0 &&
      cookieValue === 'true'
    ) {
      // using Pinterest analytics
      /* eslint-disable */
      !(function (e) {
        if (!window.pintrk) {
          window.pintrk = function () {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments));
          };
          var n = window.pintrk;
          n.queue = [];
          n.version = '3.0';
          var t = document.createElement('script');
          t.async = !0;
          t.src = e;
          var r = document.getElementsByTagName('script')[0];
          r.parentNode.insertBefore(t, r);
        }
      })('https://s.pinimg.com/ct/core.js');
      /* eslint-enable */
    }

    if (
      Config().googleUniversalAnalyticsTag.length > 0 &&
      cookieValue === 'true'
    ) {
      // using Google universal analytics
      /* eslint-disable */
      !(function (w, d, s, l, i) {
        if (window.gtag3) return;
        w[l] = w[l] || [];
        window.gtag3 = function () {
          w[l].push(arguments);
        };
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = !0;
        j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(
        window,
        document,
        'script',
        'dataLayer',
        Config().googleUniversalAnalyticsTag
      );
      /* eslint-enable */

      if (window.gtag3) {
        window.gtag3('js', new Date());
        window.gtag3('config', Config().googleUniversalAnalyticsTag);
      }
    }

    if (Config().googleAnalytics4Tag.length > 0 && cookieValue === 'true') {
      // using Google analytics 4
      /* eslint-disable */
      !(function (w, d, s, l, i) {
        if (window.gtag4) return;
        w[l] = w[l] || [];
        window.gtag4 = function () {
          w[l].push(arguments);
        };
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = !0;
        j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', Config().googleAnalytics4Tag);
      /* eslint-enable */

      if (window.gtag4) {
        window.gtag4('js', new Date());
        window.gtag4('config', Config().googleAnalytics4Tag);
      }
    }

    return () => {};
  }, []);
  /* #endregion */

  /* #region Renderers */
  return <></>;
  /* #endregion */
});

export default Analytics;
