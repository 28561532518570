/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dimensions, FlatList, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import Config from '../../constants/Config';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import {
  View,
  Button,
  useThemeColor,
  ThemeProps,
  Ionicons
} from '../../components/UI/Themed';
import { LabelText, MainText } from '../../components/UI/StyledText';
import Loading from '../../components/UI/Loading';
import SectionHeader from '../../components/UI/SectionHeader';
import ArticleItem from '../../components/blog/ArticleItem';
import * as blogActions from '../../store/actions/blog';
import { newsOnFeed } from '../../store/reselect';
import { NewsStackParamList } from '../../types';
import TouchableComponent from '../../components/UI/TouchableComponent';

const NewsScreen = (
  { navigation }: StackScreenProps<NewsStackParamList, 'News'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const pagination = Config().pagination;
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const { width } = Dimensions.get('window');
  const containerRef = useRef<ContainerViewProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState(1);
  const news = useSelector(newsOnFeed());
  const [newsData, setNewsData] = useState([]);
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const loadNews = useCallback(async () => {
    updateState(() => setError(''));
    updateState(() => setIsLoading(true));
    try {
      dispatch(blogActions.fetchNews());
    } catch (err) {
      updateState(() => setError(err.message));
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, setError]);

  const loadLess = () => {
    // console.log('Load less', page, page > 1);
    if (page > 1) {
      updateState(() => setPage(page - 1));
    }
  };

  const loadMore = () => {
    // console.log('Load more', page, news && page <= news.length / pagination);
    if (news && page <= news.length / pagination) {
      updateState(() => setPage(page + 1));
    }
  };

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleNewsScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleNewsScreen') });
    }

    // refetch all products when drawer navigation page is loaded (default for drawer is using a cached page)
    const unsubscribe = navigation.addListener('focus', loadNews);

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  useEffect(() => {
    if (news?.length > 0 && newsData.length === 0) {
      // console.log('Set initial page', page, news);
      updateState(() =>
        setNewsData(
          news.slice(
            (page - 1) * pagination,
            (page - 1) * pagination + pagination
          )
        )
      );
    }
  }, [news, newsData]);

  useEffect(() => {
    if (news?.length > 0) {
      // console.log('Set page', page, news);
      updateState(() =>
        news.slice(
          (page - 1) * pagination,
          (page - 1) * pagination + pagination
        )
      );
    }
  }, [page]);

  const selectItemHandler = (id: number, slug: string, title: string) => {
    navigation.push('Shop', {
      screen: 'Blog',
      params: {
        screen: 'Article',
        params: { articleSlug: slug, enableBackButton: true }
      }
    });
  };
  /* #endregion */

  /* #region Renderers */
  if (error) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleNewsScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'index,nofollow' }]}
      >
        <View style={styles('centered')}>
          <MainText>
            {t('errorOccurred')} {error}
          </MainText>
          <Button
            title={t('tryAgain')}
            color={buttonColor}
            onPress={loadNews}
          />
        </View>
      </ContainerView>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && news?.length === 0) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleNewsScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'index,nofollow' }]}
      >
        <SectionHeader screenName="NewsScreen" position="top" />
        <View style={styles('centered')}>
          <MainText>{t('noNews')}</MainText>
        </View>
        <SectionHeader screenName="NewsScreen" position="bottom" />
      </ContainerView>
    );
  }

  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      seoTitle={`${t('titleNewsScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'index,nofollow' }]}
    >
      <SectionHeader screenName="NewsScreen" position="top" />
      <FlatList
        scrollEnabled={!containerRef.current?.scrollable}
        contentContainerStyle={styles('container')}
        onRefresh={loadNews}
        refreshing={isLoading}
        data={newsData}
        extraData={{ width }} // using for responsive re-rendering of FlatList content
        keyExtractor={(item, index) => index.toString()}
        removeClippedSubviews={true}
        renderItem={(itemData) => (
          <ArticleItem
            style={styles('article')}
            date={itemData.item.date}
            category={
              itemData.item.category
                ? itemData.item.category
                : t('titleNewsScreen')
            }
            title={itemData.item.title}
            teaser={
              itemData.item.teaser ? itemData.item.teaser : itemData.item.body
            }
          >
            {itemData.item.slug && (
              <TouchableComponent
                onPress={() => {
                  selectItemHandler(
                    itemData.item.id,
                    itemData.item.slug,
                    itemData.item.title
                  );
                }}
              >
                <LabelText strong small>
                  {t('titleReadMore')}
                </LabelText>
              </TouchableComponent>
            )}
          </ArticleItem>
        )}
        ListFooterComponent={() => (
          <View style={styles('footer')}>
            <TouchableComponent
              activeOpacity={0.9}
              onPress={loadLess}
              style={styles('footerButtons')}
            >
              <Ionicons name="chevron-back" />
              {isLoading ? <Loading /> : null}
            </TouchableComponent>
            {_.range(Math.ceil(news?.length / pagination)).map(
              (element, index) => {
                const currentPage = element + 1;
                const selectedPage = currentPage === page ? true : false;
                return (
                  <MainText
                    key={index.toString()}
                    style={{
                      paddingHorizontal: 5,
                      textDecoration: selectedPage ? 'underline' : 'none'
                    }}
                    strong={selectedPage}
                    onPress={() => {
                      updateState(() => setPage(currentPage));
                    }}
                  >
                    {currentPage.toString()}
                  </MainText>
                );
              }
            )}
            <TouchableComponent
              activeOpacity={0.9}
              onPress={loadMore}
              style={styles('footerButtons')}
            >
              <Ionicons name="chevron-forward" />
              {isLoading ? <Loading /> : null}
            </TouchableComponent>
          </View>
        )}
      />
      <SectionHeader screenName="NewsScreen" position="bottom" />
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginVertical: 20
    },
    article: {
      marginBottom: 10,
      marginHorizontal: '8%'
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    footer: {
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      marginHorizontal: '8%'
    },
    footerButtons: {
      padding: 10,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      article: {
        marginHorizontal: '4%'
      },
      footer: {
        marginHorizontal: '4%'
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      container: {
        marginVertical: 0
      },
      article: {
        marginHorizontal: 0
      },
      footer: {
        marginHorizontal: 0
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      container: {
        marginVertical: 0
      },
      article: {
        marginHorizontal: 0
      },
      footer: {
        marginHorizontal: 0
      }
    }
  }
);

export default NewsScreen;
