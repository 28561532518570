/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, {
  useReducer,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';
import { StyleSheet } from 'react-native';

import {
  View,
  TextInput,
  useThemeColor,
  TextInputState,
  TextInputAction,
  TextInputProps
} from './Themed';
import { LabelText, MainText } from './StyledText';
import {
  parseLocaleNumber,
  numberToLocalString
} from '../../helpers/localized';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state: TextInputState, action: TextInputAction) => {
  switch (action.type) {
    case INPUT_CHANGE:
      // check for previous value and set touched, even if new value is empty
      const touched = state.value && !action.value ? true : !!action.value;

      return {
        ...state,
        value: action.value,
        isValid: action.isValid,
        touched: touched
      };

    case INPUT_BLUR:
      return {
        ...state,
        touched: action.required ? true : !!state.value
      };

    default:
      return state;
  }
};

const Input = forwardRef((props: TextInputProps, ref): JSX.Element => {
  /* #region Fields */
  const {
    lightColor,
    darkColor,
    id,
    initialValue,
    initiallyValid,
    controlStyle,
    onInputChange,
    label,
    placeholder,
    required,
    errorText,
    email,
    min,
    max,
    minLength,
    phonenumber,
    decimal
  } = props;
  const inputRef = useRef();
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || '',
    isValid: initiallyValid,
    touched: false
  });
  const textAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'textAccent'
  );
  const inputColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'input'
  );
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'border'
  );
  /* #endregion */

  /* #region Events */
  useImperativeHandle(ref, () => ({
    // this method is used to set the TextInput's value from outside
    value: (text: string) => {
      textChangeHandler(text);
    },
    focus: () => {
      // Todo: Not working :(
      inputRef?.current?.focus();
    }
  }));

  useEffect(() => {
    if (inputState.touched) {
      onInputChange(id, inputState.value, inputState.isValid);
    }
  }, [inputState, onInputChange, id]);

  const textChangeHandler = (text: string) => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phonenumberRegex =
      /^\+\d{1,3}[- .]?(([(]\d{1,3}[)])|(([(]\d{1}[)])?\d{1,3}))[- .]?\d{2,4}[- .]?\d{4}$/;
    let isValid = true;
    if (required && text.trim().length === 0) {
      isValid = false;
    }
    if (email && !emailRegex.test(text.toLowerCase())) {
      isValid = false;
    }
    if (min != null && +text < min) {
      isValid = false;
    }
    if (max != null && +text > max) {
      isValid = false;
    }
    if (minLength != null && text.length < minLength) {
      isValid = false;
    }
    if (phonenumber && !phonenumberRegex.test(text)) {
      isValid = false;
    }
    if (decimal && numberToLocalString(parseLocaleNumber(text)) !== text) {
      isValid = false;
    }

    dispatch({ type: INPUT_CHANGE, value: text, isValid });
  };

  const lostFocusHandler = () => {
    dispatch({ type: INPUT_BLUR, required });
  };
  /* #endregion */

  return (
    <View style={[styles.formControl, controlStyle]}>
      {label && (
        <LabelText strong>
          {label}
          {required ? '*' : ''}
        </LabelText>
      )}
      <TextInput
        ref={inputRef}
        {...props}
        style={[
          styles.input,
          {
            borderLeftColor: borderColor,
            borderBottomColor: borderColor,
            backgroundColor: inputColor
          }
        ]}
        placeholder={placeholder}
        placeholderTextColor={textAccentColor}
        value={inputState.value}
        onChangeText={textChangeHandler}
        onBlur={lostFocusHandler}
      />
      {!inputState.isValid && inputState.touched && (
        <View style={styles.errorContainer}>
          <MainText style={styles.errorText}>{errorText}</MainText>
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  formControl: {
    width: '100%',
    padding: 5
  },
  input: {
    paddingHorizontal: 2,
    paddingVertical: 5,
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  errorContainer: {
    marginVertical: 5
  },
  errorText: {
    color: 'red'
  }
});

export default Input;
