/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useCallback, useEffect, useState } from 'react';
import { Dimensions, LogBox, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';
import {
  View,
  useThemeColor,
  Button,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText, MainText, TitleText } from '../../components/UI/StyledText';
import ImageLoader from '../../components/UI/ImageLoader';
import TouchableComponent from '../../components/UI/TouchableComponent';
import Loading from '../../components/UI/Loading';
import ContainerView from '../../components/UI/ContainerView';
import SectionHeader from '../../components/UI/SectionHeader';
import HTMLComponent from '../../components/UI/HTMLComponent';
import Boop from '../../components/animated/Boop';
import * as productsActions from '../../store/actions/products';
import { RootState } from '../../store';
import { ShopStackParamList } from '../../types';

if (LogBox) {
  LogBox.ignoreLogs(['Failed prop type:']);
}

const ShopCategoriesScreen = (
  { navigation }: StackScreenProps<ShopStackParamList, 'ShopCategories'>,
  { lightColor, darkColor }: ThemeProps
) => {
  /* #region Fields */
  const { styles, deviceSize } = useStyles();
  const { width } = Dimensions.get('window');
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const isWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const shopCategories = useSelector(
    (state: RootState) => state.products.availableShopCategories
  );
  const productCategories = useSelector(
    (state: RootState) => state.products.availableProductCategories
  );
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  const detailsBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'tabIconDefault'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const loadCategories = useCallback(async () => {
    updateState(() => setError(''));
    updateState(() => setIsLoading(true));
    try {
      dispatch(productsActions.fetchShopCategories());
    } catch (err) {
      updateState(() => setError(err.message));
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, setError]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleShopCategoriesScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleShopCategoriesScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'Cart'
                }
              });
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, cartItemCount]);

  const itemHandler = (item) => {
    if (item.categoryId.length === 1) {
      const prodCategory = productCategories.find(
        (cat) => cat.id === item.categoryId[0]
      );

      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'ProductsOverview',
          params: {
            shopCategorySlug: item.slug,
            productCategorySlug: prodCategory.slug
          }
        }
      });
    } else {
      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'ProductCategories',
          params: {
            shopCategorySlug: item.slug
          }
        }
      });
    }
  };
  /* #endregion */

  /* #region Renderers */
  if (error) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleShopCategoriesScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      >
        <View style={styles('centered')}>
          <MainText>
            {t('errorOccurred')} {error}
          </MainText>
          <Button
            title={t('tryAgain')}
            color={buttonColor}
            onPress={loadCategories}
          />
        </View>
      </ContainerView>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (
    !isLoading &&
    shopCategories.length === 0 &&
    productCategories.length === 0
  ) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleShopCategoriesScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      >
        <SectionHeader screenName="ShopCategoriesScreen" position="top" />
        <View style={styles('centered')}>
          <MainText>{t('noCategories')}</MainText>
        </View>
        <SectionHeader screenName="ShopCategoriesScreen" position="bottom" />
      </ContainerView>
    );
  }

  return (
    <ContainerView
      scrollable
      seoTitle={`${t('titleShopCategoriesScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'index,follow' }]}
    >
      <SectionHeader screenName="ShopCategoriesScreen" position="top" />
      <View style={styles('container')}>
        {shopCategories.map((item, index: number) => {
          return (
            <View key={index.toString()} style={styles('infoTable')}>
              <View
                style={[
                  styles('infoTableRow'),
                  ![
                    DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                    DEVICE_SIZES.SMALL_DEVICE
                  ].includes(deviceSize) && {
                    flexDirection: index % 2 ? 'row-reverse' : 'row'
                  },
                  {
                    borderColor
                  }
                ]}
              >
                <Boop scale={0.98} timing={0}>
                  <TouchableComponent
                    style={[
                      styles('infoTableCell'),
                      {
                        alignItems: index % 2 ? 'flex-end' : 'flex-start',
                        padding: 0,
                        margin: 0,
                        width: [
                          DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                          DEVICE_SIZES.SMALL_DEVICE
                        ].includes(deviceSize)
                          ? width - 40
                          : (width / 2) * 0.8 - 20
                      }
                    ]}
                    onLayout={(event) => {
                      const { width } = event.nativeEvent.layout;
                      const aspectRatioBasedHeight = (3 / 4) * width; // Aspect ration 4 / 3
                      updateState(() =>
                        setContainerDimension({
                          width,
                          height: aspectRatioBasedHeight
                        })
                      );
                    }}
                    onPress={() => itemHandler(item)}
                  >
                    <View
                      style={[
                        styles('imageContainer'),
                        { height: containerDimension.height }
                      ]}
                    >
                      <ImageLoader
                        style={styles('image')}
                        source={rewriteBackendURL(item.imageUrl)}
                      />
                    </View>
                  </TouchableComponent>
                </Boop>
                <View
                  style={[
                    styles('infoTableCell'),
                    {
                      backgroundColor:
                        index % 2 ? backgroundColor : detailsBackgroundColor,
                      width: [
                        DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                        DEVICE_SIZES.SMALL_DEVICE
                      ].includes(deviceSize)
                        ? width - 40
                        : (width / 2) * 0.8 - 20
                    }
                  ]}
                >
                  <TitleText style={{ marginBottom: 10 }} small strong>
                    {item.title}
                  </TitleText>
                  <HTMLComponent source={{ html: item.description }} />
                  <Boop x={5} y={0}>
                    <TouchableComponent onPress={() => itemHandler(item)}>
                      <Ionicons name="return-down-forward" />
                    </TouchableComponent>
                  </Boop>
                  <View
                    style={{
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      backgroundColor: 'transparent',
                      marginTop: 10
                    }}
                  >
                    {item.categoryId.map((id: number) => {
                      const prodCategory = productCategories.find(
                        (cat) => cat.id === id
                      );

                      if (!prodCategory) {
                        return;
                      }

                      return (
                        <Boop key={id.toString()} x={5} y={0}>
                          <TouchableComponent
                            style={{ margin: 5 }}
                            onPress={() =>
                              navigation.push('Shop', {
                                screen: 'Products',
                                params: {
                                  screen: 'ProductsOverview',
                                  params: {
                                    shopCategorySlug: item.slug,
                                    productCategorySlug: prodCategory.slug
                                  }
                                }
                              })
                            }
                          >
                            <Ionicons name="pricetags" size={20}>
                              <LabelText
                                style={{ marginHorizontal: 10 }}
                                strong
                                small
                              >
                                {prodCategory.title}
                              </LabelText>
                            </Ionicons>
                          </TouchableComponent>
                        </Boop>
                      );
                    })}
                  </View>
                </View>
              </View>
            </View>
          );
        })}
      </View>
      <SectionHeader screenName="ShopCategoriesScreen" position="bottom" />
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexGrow: 1,
      marginVertical: 20,
      backgroundColor: 'transparent'
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    },
    infoTable: {
      backgroundColor: 'transparent',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    infoTableRow: {
      marginHorizontal: 20,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      overflow: 'hidden'
    },
    infoTableCell: {
      alignItems: 'flex-start',
      padding: 20
    },
    imageContainer: {
      width: '100%',
      minHeight: 200,
      overflow: 'hidden'
    },
    image: {
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      minHeight: 200
    }
  },
  {
    [DEVICE_SIZES.SMALL_DEVICE]: {
      infoTableRow: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      infoTableRow: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    }
  }
);

export default ShopCategoriesScreen;
