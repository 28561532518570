/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState } from 'react';
import { LogBox, Platform } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t, parseLocaleNumber } from '../../helpers/localized';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';
import { ThemeProps, useThemeColor, View } from '../UI/Themed';
import ImageLoader from '../UI/ImageLoader';
import { MainText } from '../UI/StyledText';
import Card from '../UI/Card';
import TouchableComponent from '../UI/TouchableComponent';
import { RootState } from '../../store';

if (LogBox) {
  LogBox.ignoreLogs(['Failed prop type:']);
}

export type ShopItemProps = ThemeProps & {
  style: StyleSheet['props'];
  title: string;
  image: string;
  price: string | number | null;
  onPress: () => void;
  actions?: () => void | JSX.Element;
};

const ShopItem = (props: ShopItemProps): JSX.Element => {
  /* #region Fields */
  const {
    style,
    title,
    image,
    price,
    onPress,
    actions,
    lightColor,
    darkColor
  } = props;
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const language = useSelector((state: RootState) => state.settings.language);
  const currency = useSelector((state: RootState) => state.settings.currency);
  const [localPrice] = useState(() => {
    let _price = parseLocaleNumber(price);
    if (_price && _price > 0) {
      return _price;
    }

    return null;
  });
  const [focusedStyle, setFocusedStyle] = useState({});
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const detailsBackground = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const textAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'textAccent'
  );
  const shadowHardColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'shadowHard'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const focusSetHandler = () => {
    updateState(() =>
      setFocusedStyle({
        ...Platform.select({
          android: {
            elevation: 8
          },
          default: {
            shadowColor: shadowHardColor,
            shadowOffset: { width: 2, height: 4 },
            shadowOpacity: 0.46,
            shadowRadius: 8
          }
        })
      })
    );
  };

  const focusUnsetHandler = () => {
    updateState(() => setFocusedStyle({}));
  };
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[
        styles('category'),
        style,
        { height: containerDimension.height + 100 }
      ]}
      onMouseEnter={focusSetHandler}
      onMouseLeave={focusUnsetHandler}
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        const aspectRatioBasedHeight = (3 / 4) * width; // Aspect ration 4 / 3
        updateState(() =>
          setContainerDimension({ width, height: aspectRatioBasedHeight })
        );
      }}
    >
      <Card style={focusedStyle}>
        <TouchableComponent style={styles('touchable')} onPress={onPress}>
          <View
            style={[
              styles('imageContainer'),
              { height: containerDimension.height }
            ]}
          >
            <ImageLoader
              style={styles('image')}
              resizeMode="contain"
              source={rewriteBackendURL(image)}
            />
          </View>
        </TouchableComponent>
      </Card>
      <View style={styles('spacer')} />
      <TouchableComponent style={focusedStyle} onPress={onPress}>
        <>
          <View
            style={[styles('title'), { backgroundColor: detailsBackground }]}
          >
            <MainText strong>{title}</MainText>
          </View>
          <View
            style={[styles('details'), { backgroundColor: detailsBackground }]}
          >
            <View></View>
            {localPrice && (
              <MainText style={{ color: textAccentColor }}>
                {t('priceFrom')}{' '}
                {localPrice.toLocaleString(language, {
                  style: 'currency',
                  currency: currency
                })}
              </MainText>
            )}
            <View style={styles('actions')}>{actions && actions()}</View>
          </View>
        </>
      </TouchableComponent>
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    category: {
      height: 320,
      margin: 20,
      backgroundColor: 'transparent'
    },
    touchable: {
      borderRadius: 10,
      overflow: 'hidden',
      backgroundColor: 'transparent'
    },
    imageContainer: {
      width: '100%',
      height: 200,
      overflow: 'hidden',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    },
    image: {
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      minHeight: 200
    },
    spacer: {
      height: 20,
      backgroundColor: 'transparent'
    },
    title: {
      alignItems: 'center',
      textAlign: 'center',
      height: 60,
      padding: 20
    },
    details: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      height: 40,
      paddingTop: 10,
      paddingHorizontal: 20,
      paddingBottom: 20
    },
    actions: {
      backgroundColor: 'transparent'
    }
  },
  {}
);

export default ShopItem;
