/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { forwardRef, useEffect, useState } from 'react';
import {
  Dimensions,
  ScrollViewProps,
  ViewProps,
  Animated,
  Platform
} from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import SafeAreaView from 'react-native-safe-area-view';
import {
  useNavigation,
  useRoute,
  useIsFocused
} from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { tAPI } from '../../helpers/api-utils';
import { ScrollView, ThemeProps, useThemeColor, View } from './Themed';
import AlertModal from './AlertModal';
import SEO, { SEOProps } from '../web/SEO';
import BigHeader, { HEADER_HEIGHT } from '../web/BigHeader';
import { useCollapsibleBigHeader } from '../../hooks/useCollapsibleBigHeader';
import Footer from '../web/Footer';
import * as notificationActions from '../../store/actions/notification';
import * as settingsActions from '../../store/actions/settings';
import { MessageParams, ScreenParamList } from '../../types';
import { RootState } from '../../store';
import CookieBanner from '../web/CookieBanner';
import Analytics from '../web/Analytics';

export type ContainerViewProps = ThemeProps &
  SEOProps & {
    ref?: React.MutableRefObject<typeof ScrollView | typeof View>;
    scrollable?: boolean;
    onScroll?: ScrollViewProps['onScroll'];
    onLayout?: ScrollViewProps['onLayout'] | ViewProps['onLayout'];
    onHeaderHeightChange?: (value: number) => void;
    scrollEventThrottle?: ScrollViewProps['scrollEventThrottle'];
    contentContainerStyle?: StyleSheet['props'];
    containerStyle?: StyleSheet['props'];
    headerComponent?: JSX.Element | Array<JSX.Element>;
    children: JSX.Element | Array<JSX.Element>;
  };

const ContainerView = forwardRef(
  (props: ContainerViewProps, ref): JSX.Element => {
    /* #region Fields */
    const {
      scrollable,
      onScroll,
      onHeaderHeightChange,
      seoTitle,
      seoDescription,
      seoMeta,
      contentContainerStyle,
      containerStyle,
      headerComponent,
      children,
      lightColor,
      darkColor,
      ...otherProps
    } = props;
    const isWeb = Platform.OS === 'web';
    const isMounted = useIsMounted();
    const { styles } = useStyles();
    const { height } = Dimensions.get('window');
    const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
    const messages: Array<MessageParams> = useSelector(
      (state: RootState) => state.notification.messages
    );
    const activeScreen: ScreenParamList = useSelector(
      (state: RootState) => state.notification.activeScreen
    );
    const animatedHeaderHeight = React.useRef(
      new Animated.Value(HEADER_HEIGHT)
    ).current;
    const animatedPaddingTop = React.useRef(new Animated.Value(0)).current;
    const collapsedHeader: boolean = useSelector(
      (state: RootState) => state.settings.collapsedHeader
    );
    const [useSEO, setUseSEO] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [keywords, setKeywords] = useState([
      { name: 'keywords', content: '' }
    ]);
    const screenBackgroundColor = useThemeColor(
      { light: lightColor, dark: darkColor },
      'screenBackground'
    );
    const shadowLightColor = useThemeColor(
      { light: lightColor, dark: darkColor },
      'shadowLight'
    );
    const navigation = useNavigation();
    const route = useRoute();
    const isFocused = useIsFocused();
    const dispatch = useDispatch();

    const {
      onScrollWithListener,
      containerPaddingTop,
      scrollIndicatorInsetTop,
      translateY,
      progress
    } = useCollapsibleBigHeader({
      navigationOptions: {
        headerStyle: {
          height: HEADER_HEIGHT
        },
        headerTitle: () => (
          <View style={{ boxShadow: `0px 2px 2px -2px ${shadowLightColor}` }}>
            <BigHeader animatedOffset={new Animated.Value(0)} />
          </View>
        )
      },
      config: {
        useNativeDriver: isWeb ? false : true,
        collapsedColor: 'white'
      }
    });
    /* #endregion */

    /* #region Methods */
    const updateState = (callback: () => void) => {
      if (isMounted.current) {
        callback();
      }
    };
    /* #endregion */

    /* #region Events */
    const clearLastMessage = () => {
      dispatch(notificationActions.clearMessage());
    };

    useEffect(() => {
      if (!isMounted.current || !isFocused || !activeScreen.name) {
        updateState(() => setUseSEO(false));
        return;
      }

      // this block is used for config SEO
      if (seoMeta) {
        updateState(() => setUseSEO(true));
      }

      if (seoMeta && !seoTitle) {
        // change page title for SEO, if not set through seoTitle
        const titleStringPattern = `title${activeScreen.name}Screen`;
        updateState(() =>
          setTitle(`${t(titleStringPattern)} | ${t('appTitle')}`)
        );
      }

      if (seoMeta && !seoDescription) {
        // only get description from server, if not set through seoDescription
        const getDescription = async () => {
          const trans = await tAPI(
            `${activeScreen.name}Screen meta description`
          );
          return trans;
        };

        getDescription().then((value) =>
          updateState(() => setDescription(value))
        );
      }

      if (seoMeta) {
        const getKeywords = async () => {
          const trans = await tAPI(`${activeScreen.name}Screen meta keywords`);
          return trans;
        };

        getKeywords().then((value) =>
          updateState(() => setKeywords([{ name: 'keywords', content: value }]))
        );
      }
      // end config SEO

      if (activeScreen.key === route.key && messages.length > 0) {
        // set up modal
        setAlertModalContent(
          <AlertModal
            title={messages[messages.length - 1].title}
            message={messages[messages.length - 1].message}
            onOkayPress={clearLastMessage}
            onDismiss={() => setAlertModalContent(<></>)}
            hideCancel
          />
        );
      }
    }, [
      isMounted,
      seoTitle,
      seoDescription,
      seoMeta,
      activeScreen,
      messages,
      navigation
    ]);

    useEffect(() => {
      if (!isMounted.current) {
        return;
      }

      const listener = progress.addListener((event) => {
        // swap collapsed header state, when at least 90% of progess state
        if (event.value >= 0.9 && isFocused) {
          if (!collapsedHeader) {
            dispatch(settingsActions.setCollapsedHeader(true));
          }
        } else {
          if (collapsedHeader) {
            dispatch(settingsActions.setCollapsedHeader(false));
          }
        }
      });

      return () => {
        progress.removeListener(listener);
      };
    }, [isMounted, progress, isFocused, collapsedHeader]);

    useEffect(() => {
      if (!isMounted.current) {
        return;
      }

      const listener = translateY.addListener((event) => {
        const currentHeaderHeight = HEADER_HEIGHT + event.value;
        animatedHeaderHeight.setValue(currentHeaderHeight);
        animatedPaddingTop.setValue(event.value * -0.35);

        // trigger outer event
        if (onHeaderHeightChange) {
          onHeaderHeightChange(currentHeaderHeight);
        }
      });

      return () => {
        translateY.removeListener(listener);
      };
    }, [isMounted, translateY]);
    /* #endregion */

    /* #region Renderers */
    const contentSEO = (
      <SEO
        seoTitle={seoTitle ? seoTitle : title}
        seoDescription={seoDescription ? seoDescription : description}
        seoMeta={
          seoMeta
            ? seoMeta.concat(
                keywords.filter((s) => !seoMeta.find((t) => t.name == s.name))
              )
            : keywords
        }
      />
    );

    if (scrollable) {
      return (
        <SafeAreaView
          style={styles('container')}
          forceInset={{ vertical: 'never', horizontal: 'always' }}
        >
          {alertModalContent}
          {headerComponent}
          <ScrollView
            scrollEventThrottle={8}
            onScroll={onScrollWithListener((event) => {
              if (onScroll) {
                onScroll(event);
              }
            })}
            style={[
              styles('container'),
              { backgroundColor: screenBackgroundColor },
              containerStyle
            ]}
            scrollIndicatorInsets={{
              top: scrollIndicatorInsetTop
            }}
            contentContainerStyle={[
              styles('scrollContainer'),
              contentContainerStyle,
              {
                paddingTop: containerPaddingTop,
                minHeight: containerPaddingTop + height
              }
            ]}
            {...otherProps}
          >
            <Animated.View
              style={{
                height: animatedPaddingTop
              }}
            />
            {useSEO && contentSEO}
            {children}
            <Footer />
          </ScrollView>
          <CookieBanner />
          <Analytics />
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView
        style={styles('container')}
        forceInset={{ vertical: 'never', horizontal: 'always' }}
      >
        {alertModalContent}
        {headerComponent}
        <View
          style={[
            styles('container'),
            { backgroundColor: screenBackgroundColor },
            containerStyle
          ]}
          {...otherProps}
        >
          {useSEO && contentSEO}
          {children}
          <Footer />
        </View>
        <CookieBanner />
        <Analytics />
      </SafeAreaView>
    );
    /* #endregion */
  }
);

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1
    },
    scrollContainer: {
      flexGrow: 1, // https://medium.com/@peterpme/taming-react-natives-scrollview-with-flex-144e6ff76c08
      justifyContent: 'space-between'
    }
  },
  {}
);

export default ContainerView;
