/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { ThemeProps, useThemeColor, View } from '../../components/UI/Themed';
import Card from '../UI/Card';
import HTMLComponent from '../UI/HTMLComponent';
import { MainText, TitleText } from '../UI/StyledText';

export type ArticleItemProps = ThemeProps & {
  style?: StyleSheet['props'];
  date: number;
  title: string;
  teaser?: string;
  body?: string;
  category?: string;
  children?: JSX.Element | Array<JSX.Element>;
};

const ArticleItem = React.memo((props: ArticleItemProps): JSX.Element => {
  /* #region Fields */
  const {
    style,
    date,
    teaser,
    body = '',
    category,
    title,
    children,
    lightColor,
    darkColor
  } = props;
  const navigation = useNavigation();
  const textAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'textAccent'
  );
  /* #endregion */

  /* #region Renderers */
  return (
    <Card style={[styles.article, style]}>
      <View style={styles.touchable}>
        <View style={styles.post}>
          <MainText
            style={{
              color: textAccentColor
            }}
            onPress={() =>
              navigation.push('Shop', {
                screen: 'Blog',
                params: {
                  screen: 'ArticleCategories',
                  params: {}
                }
              })
            }
          >
            {category ? `${date} / ${category}` : date}
          </MainText>
          <TitleText style={styles.title}>{title}</TitleText>
          <HTMLComponent source={teaser ? { html: teaser } : { html: body }} />
        </View>
        <View style={styles.actions}>{children ? children : null}</View>
      </View>
    </Card>
  );
  /* #endregion */
});

const styles = StyleSheet.create({
  article: {
    marginBottom: 1,
    borderRadius: 2
  },
  touchable: {
    borderRadius: 10,
    overflow: 'hidden'
  },
  post: {
    padding: 10
  },
  title: {
    marginVertical: 10
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 10
  }
});

export default ArticleItem;
