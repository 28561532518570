/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { StyleSheet, ImageBackground, Linking, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Colors from '../../constants/Colors';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';
import { ThemeProps, useThemeColor, View } from './Themed';
import { MainText, TitleText } from './StyledText';
import TouchableComponent from './TouchableComponent';
import ImageLoader from './ImageLoader';

export type ImageSectionProps = ThemeProps & {
  image: string;
  position?: string;
  param?: string;
  manualColorTheme?: 'light' | 'dark';
  textAlign: 'left' | 'right';
  textBackdrop?: boolean;
  subTitle?: string;
  title?: string;
  text?: string;
  linkedURL?: string;
  linkedArticle?: string;
  height?: number;
  marginBottom?: number;
};

const ImageSection = (props: ImageSectionProps) => {
  /* #region Fields */
  const {
    image,
    manualColorTheme,
    textAlign,
    textBackdrop,
    subTitle,
    title,
    text,
    linkedURL,
    linkedArticle,
    height,
    marginBottom,
    lightColor,
    darkColor
  } = props;
  const isWeb = Platform.OS === 'web';
  const textColor = manualColorTheme
    ? Colors[manualColorTheme].text
    : useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  const textBackground = textBackdrop
    ? manualColorTheme
      ? Colors[manualColorTheme].backdrop
      : useThemeColor({ light: lightColor, dark: darkColor }, 'backdrop')
    : useThemeColor({ light: lightColor, dark: darkColor }, 'backdrop');
  let TouchableView = View;
  const navigation = useNavigation();
  /* #endregion */

  if (linkedURL || linkedArticle) {
    TouchableView = TouchableComponent;
  }

  /* #region Renderers */
  return (
    <View
      style={[
        styles.imageContainer,
        height ? { height } : { height: 500 },
        marginBottom ? { marginBottom } : { marginBottom: 0 }
      ]}
    >
      <ImageLoader
        style={styles.image}
        component={ImageBackground}
        source={rewriteBackendURL(image)}
      >
        <View style={styles.textContainer}>
          {textAlign === 'right' && <View style={styles.containerColumn} />}
          <TouchableView
            style={[
              styles.containerColumn,
              textAlign === 'left'
                ? { alignItems: 'flex-start' }
                : { alignItems: 'flex-end' },
              textBackdrop
                ? {
                    backgroundColor: textBackground,
                    padding: 10,
                    borderRadius: 4
                  }
                : {}
            ]}
            onPress={() =>
              linkedURL
                ? isWeb
                  ? window.open(linkedURL, '_blank')
                  : Linking.openURL(linkedURL)
                : navigation.push('Shop', {
                    screen: 'Blog',
                    params: {
                      screen: 'Article',
                      params: {
                        articleSlug: linkedArticle,
                        enableBackButton: true
                      }
                    }
                  })
            }
          >
            <MainText style={[styles.subTitle, { color: textColor }]} strong>
              {subTitle}
            </MainText>
            <TitleText style={[styles.title, { color: textColor }]}>
              {title}
            </TitleText>
            <MainText style={[styles.text, { color: textColor }]}>
              {text}
            </MainText>
          </TouchableView>
          {textAlign === 'left' && <View style={styles.containerColumn} />}
        </View>
      </ImageLoader>
    </View>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  imageContainer: {
    width: '100%',
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 50
  },
  textContainer: {
    width: '70%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  containerColumn: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  subTitle: {
    textTransform: 'uppercase'
  },
  title: {
    marginTop: 5
  },
  text: {
    marginTop: 5
  }
});

export default ImageSection;
