/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';

import { decryptString, encryptString } from '../../helpers/crypto-utils';
import { productAttributeFilter } from '../../helpers/filter';
import { t } from '../../helpers/localized';
import logger from '../../helpers/logger';
import { ProductAttributeValueTypes } from '../../types';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SET_DID_VERIFY_ORDER = 'SET_DID_VERIFY_ORDER';
export const SET_DID_VERIFY_ADDRESS = 'SET_DID_VERIFY_ADDRESS';
export const CART_PERSISTENCE_KEY = 'CART_STATE';

export const fetchCartItemsFromLocalStore = () => {
  return async (dispatch) => {
    // get stored cart items
    const loadedData = await AsyncStorage.getItem(CART_PERSISTENCE_KEY);

    if (loadedData) {
      const { cartItems, currency, orderVerified, addressVerified } =
        JSON.parse(decryptString(loadedData));

      // check if currency are available
      if (currency) {
        cartItems.forEach((item) => {
          dispatch({
            type: ADD_TO_CART,
            language: item.language,
            currency,
            product: item.product,
            selectedProductAttributes: item.selectedProductAttributes,
            orderVerified,
            addressVerified
          });
        });
      } else {
        await AsyncStorage.removeItem(CART_PERSISTENCE_KEY);
      }
    }
  };
};

export const addToCart = (product, itemKey?: string) => {
  return async (dispatch, getState) => {
    const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
      getState().settings.productAttributeValueTypes;
    const value_type = productAttributeValueTypes?.filter(
      (valuetype) =>
        valuetype.type_name === t('productAttributeValueTypeImage') ||
        valuetype.type_name === t('productAttributeValueTypeFile')
    );
    // ignore value types 'Image' and 'File
    const selectedProductAttributes =
      getState().productattributes.selectedProductAttributes.filter(
        (attr) =>
          attr.productId === product.id &&
          !_.map(value_type, 'id').includes(attr.json.type)
      );
    const language = getState().settings.language;
    const currency = getState().settings.currency;
    const filteredAttributes = productAttributeFilter(
      productAttributeValueTypes,
      selectedProductAttributes
    );

    if (itemKey) {
      dispatch({
        type: REMOVE_FROM_CART,
        itemKey
      });
    }
    dispatch({
      type: ADD_TO_CART,
      language,
      currency,
      product,
      selectedProductAttributes: filteredAttributes,
      orderVerified: false,
      addressVerified: false
    });
    await saveDataToStorage({
      cartItem: {
        language,
        product,
        selectedProductAttributes: filteredAttributes,
        orderVerified: false,
        addressVerified: false
      },
      currency,
      itemKey
    });
  };
};

export const removeFromCart = (itemKey) => {
  return async (dispatch, getState) => {
    const currency = getState().settings.currency;

    // get stored cart items
    const loadedData = await AsyncStorage.getItem(CART_PERSISTENCE_KEY);

    if (loadedData) {
      const { cartItems } = JSON.parse(decryptString(loadedData));
      let itemIdx = cartItems.findIndex((item) => item.itemKey === itemKey);
      // delete cart item from stored array
      cartItems.splice(itemIdx, 1);
      // make cart items persistent again
      await AsyncStorage.setItem(
        CART_PERSISTENCE_KEY,
        encryptString(JSON.stringify({ cartItems, currency }))
      );
    }

    dispatch({
      type: REMOVE_FROM_CART,
      itemKey
    });
  };
};

export const setDidVerifyOrder = (value: boolean) => {
  saveDataToStorage({ didVerifyOrder: value });
  return { type: SET_DID_VERIFY_ORDER, value };
};

export const setDidVerifyAddress = (value: boolean) => {
  saveDataToStorage({ didVerifyAddress: value });
  return { type: SET_DID_VERIFY_ADDRESS, value };
};

const saveDataToStorage = async (cartData) => {
  const { cartItem, currency, itemKey, didVerifyOrder, didVerifyAddress } =
    cartData;

  // get stored cart items
  const loadedData = await AsyncStorage.getItem(CART_PERSISTENCE_KEY);

  let updatedCartItems = [];
  let updatedCurrency = currency;
  let updatedOrderVerified =
    didVerifyOrder !== undefined ? didVerifyOrder : undefined;
  let updatedAddressVerified =
    didVerifyAddress !== undefined ? didVerifyAddress : undefined;

  if (loadedData) {
    try {
      const { cartItems, currency, orderVerified, addressVerified } =
        JSON.parse(decryptString(loadedData));
      if (cartItems) {
        if (itemKey) {
          // find origin item by given itemKey
          let itemIdx = cartItems.findIndex((item) => item.itemKey === itemKey);
          // delete cart item from stored array
          cartItems.splice(itemIdx, 1);
        }
        updatedCartItems = cartItems;
        if (updatedCurrency === undefined) updatedCurrency = currency;
        if (updatedOrderVerified === undefined)
          updatedOrderVerified = orderVerified;
        if (updatedAddressVerified === undefined)
          updatedAddressVerified = addressVerified;
      }
    } catch (err) {
      logger.log(
        `store.actions.cart - saveDataToStorage: Cannot decrypt stored cart state!. Remove it.`
      );
      await AsyncStorage.removeItem(CART_PERSISTENCE_KEY);
    }
  }

  if (cartItem) {
    // generate itemKey like ADD_TO_CART reducer will do
    let cartItemKey = `${cartItem.product.id}`;
    cartItem.selectedProductAttributes.forEach((element) => {
      if (element.json.value.hasOwnProperty('finalPrice')) {
        cartItemKey += `-${element.json.value.label}`;
      }
    });
    cartItem['itemKey'] = cartItemKey;

    // append cart items
    updatedCartItems.push(cartItem);
  }

  // make cart items persistent again
  await AsyncStorage.setItem(
    CART_PERSISTENCE_KEY,
    encryptString(
      JSON.stringify({
        cartItems: updatedCartItems,
        currency: updatedCurrency,
        orderVerified: updatedOrderVerified,
        addressVerified: updatedAddressVerified
      })
    )
  );
};
