/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { Dimensions, PixelRatio, Platform } from 'react-native';
import './polyfill'; // this is the work-around for a non fixed @expo/match-media
import { useMediaQuery } from 'react-responsive';
import { DEVICE_SIZES } from 'rn-responsive-styles';
import { deviceSize } from 'rn-responsive-styles/lib/helpers';
import { RFValue } from 'react-native-responsive-fontsize';

export type LayoutProps = {
  x: number;
  y: number;
  height: number;
  width: number;
};

// Common screen sizes captured from http://screensiz.es/phone
// width > 1200: DEVICE_SIZES.EXTRA_LARGE_DEVICE
// width >  992: DEVICE_SIZES.LARGE_DEVICE
// width >  768: DEVICE_SIZES.MEDIUM_DEVICE
// width >  540: DEVICE_SIZES.SMALL_DEVICE
// otherwise:    DEVICE_SIZES.EXTRA_SMALL_DEVICE

export const PortraitMode = ({ children }): JSX.Element | null => {
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  return isPortrait ? children : null;
};

export const LandscapeMode = ({ children }): JSX.Element | null => {
  const isLandscape = useMediaQuery({ orientation: 'landscape' });
  return isLandscape ? children : null;
};

// Helper function for create different style behaviour
// Example usage:
// import React, { useState, useEffect } from 'react';
// ...
// import { matchUserAgent } from '../../helpers/screen-data';
// ...
//
// const ShopItem = (props): JSX.Element => {
//   /* #region Fields */
//   ..
//   const [enableStyleFix4Firefox, setEnableStyleFix4Firefox] = useState(false);
// ..
//   /* #endregion */
//
//   /* #region Events */
//   useEffect(() => {
//     if (matchUserAgent('Firefox')) {
//       setEnableStyleFix4Firefox(true);
//     }
//   }, []);
//   /* #endregion */
//
//  return (
//     <Card
//       style={[
//         styles.product,
//         enableStyleFix4Firefox ? { height: -1 } : {},
//         props.style
//       ]}
//     >...
//
export const matchUserAgent = (value: string): boolean => {
  if (Platform.OS === 'web') {
    const { userAgent } = window.navigator;
    return userAgent.includes(value);
  }

  return false;
};

/**
 * Recursive implementation to find the gcd (greatest common divisor) of two integers using the euclidean algorithm.
 * For more than two numbers, e.g. three, you can box it like this: gcd(a,gcd(b,greatest_common_divisor.c)) etc.
 * This runs in O(log(n)) where n is the maximum of a and b.
 *
 * Special thanks goes to https://www.algorithms-and-technologies.com/greatest_common_divisor/javascript
 *
 * @param a the first integer
 * @param b the second integer
 * @return the greatest common divisor (gcd) of the two integers.
 */
export const gcd = (a: number, b: number): number => {
  if (b === 0) return a;
  return gcd(b, a % b);
};

export const responsiveFontSize = (f: number): number => {
  const { height, width } = Dimensions.get('window');
  const size = deviceSize(width);
  const pixelBase = [
    DEVICE_SIZES.EXTRA_SMALL_DEVICE,
    DEVICE_SIZES.SMALL_DEVICE,
    DEVICE_SIZES.MEDIUM_DEVICE
  ].includes(size)
    ? 7.7
    : 5.4; // Example calculation, if height is used: 5.7 * 2.8 = 16
  const rfValue = RFValue(f * pixelBase, height); // Platform.OS === 'web' ? RFValue(f, height) : RFValue(f);
  const result = rfValue * PixelRatio.getFontScale();
  // console.log('RFValue', size,result);
  return result;
};

// Helper function for getting correct react-navigation header size, depending on platform
export const getDefaultHeaderHeight = () => {
  const { height, width } = Dimensions.get('window');
  const isLandscape = height < width;

  if (Platform.OS === 'ios') {
    if (isLandscape && !Platform.isPad) {
      return 32;
    } else {
      return 44;
    }
  } else if (Platform.OS === 'android') {
    return 56;
  }
  return 64;
};

// Parse possible string percentage input and convert it to number.
export const convertPercentageToNumber = (val: string | number) => {
  let value: number;

  if (typeof val === 'string' && val.slice(-1) === '%') {
    value = parseFloat(val);
  } else {
    value = val as number;
  }

  return PixelRatio.roundToNearestPixel(
    (Dimensions.get('window').width * value) / 100
  );
};
