/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { t } from '../../helpers/localized';
import Config from '../../constants/Config';
import logger from '../../helpers/logger';
import { recursiveCallAPI, singleCallAPI } from '../../helpers/api-utils';
import * as notificationActions from './notification';

export const SET_ARTICLES = 'SET_ARTICLES';
export const HIT_ARTICLE = 'HIT_ARTICLE';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_NEWS = 'SET_NEWS';

export const fetchArticles = () => {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }
      const loadedArticles = await recursiveCallAPI(
        'GET',
        `${Config().api_url}/articles/`,
        headers
      );

      dispatch({
        type: SET_ARTICLES,
        articles: loadedArticles
      });
    } catch (err) {
      logger.log(
        `store.actions.blog - fetchArticles: Cannot fetch articles! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch articles! Server responded with error - ${err}.`
      );
    }
  };
};

export const hitArticle = (id: number) => {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }
      const loadedArticle = await singleCallAPI(
        'GET',
        `${Config().api_url}/articles/${id}/`,
        headers
      );

      dispatch({
        type: HIT_ARTICLE,
        article: loadedArticle
      });
    } catch (err) {
      logger.log(
        `store.actions.blog - hitArticle: Cannot hit article with slug ${slug}! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot hit article with slug ${slug}! Server responded with error - ${err}.`
      );
    }
  };
};

export const fetchCategories = () => {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }
      const loadedCategories = await recursiveCallAPI(
        'GET',
        `${Config().api_url}/categories/`,
        headers
      );

      dispatch({
        type: SET_CATEGORIES,
        categories: loadedCategories
      });
    } catch (err) {
      logger.log(
        `store.actions.blog - fetchCategories: Cannot fetch categories! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch categories! Server responded with error - ${err}.`
      );
    }
  };
};

export const fetchNews = () => {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }
      const loadedNews = await recursiveCallAPI(
        'GET',
        `${Config().api_url}/news/`,
        headers
      );

      dispatch({
        type: SET_NEWS,
        news: loadedNews
      });
    } catch (err) {
      logger.log(
        `store.actions.blog - fetchNews: Cannot fetch news! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch news! Server responded with error - ${err}.`
      );
    }
  };
};
