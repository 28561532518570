/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useReducer, useCallback, useEffect } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator
} from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import Config from '../../constants/Config';
import { t } from '../../helpers/localized';
import { formReducer, getFormState } from '../../helpers/input-form';
import {
  View,
  Button,
  ScrollView,
  useThemeColor,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText, MainText } from '../../components/UI/StyledText';
import AlertModal from '../../components/UI/AlertModal';
import ContainerView from '../../components/UI/ContainerView';
import Input from '../../components/UI/Input';
import * as authActions from '../../store/actions/auth';
import { RootState } from '../../store';
import { AuthStackParamList } from '../../types';

const UserProfileScreen = (
  { route, navigation }: StackScreenProps<AuthStackParamList, 'UserProfile'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const { styles } = useStyles();
  const isWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    useState<boolean>(false);
  const userId = route.params?.userId;
  const editedUser = useSelector(
    (state: RootState) => state.auth.activeUserProfile
  );
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const dispatch = useDispatch();

  // form validation model
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      company_name: editedUser ? editedUser.company_name : null,
      title: editedUser ? editedUser.title : null,
      firstName: editedUser ? editedUser.firstName : '',
      lastName: editedUser ? editedUser.lastName : '',
      email: editedUser ? editedUser.email : '',
      mobile: editedUser ? editedUser.mobile : null,
      phone: editedUser ? editedUser.phone : null,
      address: editedUser ? editedUser.address : '',
      zipcode: editedUser ? editedUser.zipcode : '',
      location: editedUser ? editedUser.location : '',
      country: editedUser ? editedUser.country : ''
    },
    inputValidities: {
      company_name: editedUser ? true : true,
      title: editedUser ? true : true,
      firstName: editedUser ? true : false,
      lastName: editedUser ? true : false,
      email: editedUser ? true : false,
      mobile: editedUser ? true : true,
      phone: editedUser ? true : true,
      address: editedUser ? true : false,
      zipcode: editedUser ? true : false,
      location: editedUser ? true : false,
      country: editedUser ? true : false
    },
    formIsValid: false
  });
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleUserProfileScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleUserProfileScreen') });
    }
  }, [navigation]);

  const submitHandler = useCallback(async () => {
    if (!formState.formIsValid) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('wrongInput')}
            message={t('checkFormErrors')}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
      return;
    }

    updateState(() => setIsLoading(true));

    try {
      if (editedUser) {
        dispatch(
          authActions.updateUserProfile(
            userId,
            formState.inputValues.email,
            formState.inputValues.password,
            formState.inputValues.company_name,
            formState.inputValues.title,
            formState.inputValues.firstName,
            formState.inputValues.lastName,
            formState.inputValues.mobile,
            formState.inputValues.phone,
            formState.inputValues.address,
            formState.inputValues.zipcode,
            formState.inputValues.location,
            formState.inputValues.country
          )
        );
      } else {
        dispatch(
          authActions.signUp(
            formState.inputValues.email,
            formState.inputValues.password,
            formState.inputValues.company_name,
            formState.inputValues.title,
            formState.inputValues.firstName,
            formState.inputValues.lastName,
            formState.inputValues.mobile,
            formState.inputValues.phone,
            formState.inputValues.address,
            formState.inputValues.zipcode,
            formState.inputValues.location,
            formState.inputValues.country
          )
        );

        if (Config().useUserActivationLink) {
          // set up modal
          updateState(() =>
            setAlertModalContent(
              <AlertModal
                title={t('popupNotice')}
                message={t('messageSignUp')}
                onDismiss={() => updateState(() => setAlertModalContent(<></>))}
              />
            )
          );
        } else {
          // TODO: Auto login with given accessToken
          navigation.push('Auth', { screen: 'UserAuth' });
        }
      }
    } catch (err) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('errorOccurred')}
            message={err.message}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    } finally {
      updateState(() => setIsSuccessfullySubmitted(true));
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, userId, formState]);

  const inputChangeHandler = useCallback(
    (inputIdentifier: string, inputValue: unknown, inputValidity: boolean) => {
      dispatchFormState(
        getFormState(inputIdentifier, inputValue, inputValidity)
      );
    },
    [dispatchFormState]
  );
  /* #endregion */

  /* #region Renderers */
  return (
    <ContainerView
      scrollable
      seoTitle={`${t('titleUserProfileScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <KeyboardAvoidingView
        behavior="padding"
        keyboardVerticalOffset={50}
        style={styles('screen')}
      >
        {alertModalContent}
        {!isSuccessfullySubmitted ? (
          <ScrollView style={styles('container')}>
            {!userId ? (
              <View style={styles('form')}>
                <MainText style={styles('text')}>
                  {t('messageRegistration')}
                </MainText>
                <LabelText strong style={styles('caption')}>
                  {t('titleLoginData')}
                </LabelText>
                <Input
                  id="email"
                  label={t('inputEMailLabel')}
                  errorText={t('inputEMailError')}
                  keyboardType="email-address"
                  required
                  email
                  autoCapitalize="none"
                  onInputChange={inputChangeHandler}
                  initialValue={editedUser ? editedUser.email : ''}
                  initiallyValid={!!editedUser}
                  controlStyle={styles('control2')}
                />
                <Input
                  id="password"
                  label={t('inputPasswordLabel')}
                  keyboardType="default"
                  secureTextEntry
                  required
                  minLength={8}
                  autoCapitalize="none"
                  errorText={t('inputPasswordError')}
                  onInputChange={inputChangeHandler}
                  initialValue=""
                  initiallyValid={false}
                  controlStyle={styles('control2')}
                />
              </View>
            ) : (
              <View style={styles('form')}>
                <MainText style={styles('text')}>
                  {t('messageUserEdit')}
                </MainText>
                <LabelText strong style={styles('caption')}>
                  {t('titleLoginData')}
                </LabelText>
                <LabelText>
                  {t('inputEMailLabel')}{' '}
                  <MainText style={[styles('text'), { paddingLeft: 10 }]}>
                    {editedUser ? editedUser.email : ''}
                  </MainText>
                </LabelText>
                <MainText style={styles('text')}>
                  {t('titleChangePassword')}
                </MainText>
              </View>
            )}
            <View style={styles('form')}>
              <LabelText strong style={styles('caption')}>
                {t('titlePersonalData')}
              </LabelText>
              <Input
                id="company_name"
                label={t('inputCompanyNameLabel')}
                errorText={t('inputCompanyNameError')}
                keyboardType="default"
                autoCapitalize="sentences"
                autoCorrect
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedUser ? editedUser.company_name : ''}
                initiallyValid={!!editedUser}
              />
              <View style={styles('inlineFormFields')}>
                <Input
                  id="title"
                  label={t('inputContactTitleLabel')}
                  errorText={t('inputContactTitleError')}
                  keyboardType="default"
                  autoCapitalize="sentences"
                  autoCorrect
                  returnKeyType="next"
                  onInputChange={inputChangeHandler}
                  initialValue={editedUser ? editedUser.title : ''}
                  initiallyValid={!!editedUser}
                  controlStyle={{ flex: 1 }}
                />
                <Input
                  id="firstName"
                  label={t('inputContactFirstNameLabel')}
                  errorText={t('inputContactFirstNameError')}
                  keyboardType="default"
                  autoCapitalize="sentences"
                  autoCorrect
                  returnKeyType="next"
                  onInputChange={inputChangeHandler}
                  initialValue={editedUser ? editedUser.firstName : ''}
                  initiallyValid={!!editedUser}
                  required
                  controlStyle={styles('control1')}
                />
                <Input
                  id="lastName"
                  label={t('inputContactLastNameLabel')}
                  errorText={t('inputContactLastNameError')}
                  keyboardType="default"
                  autoCapitalize="sentences"
                  autoCorrect
                  returnKeyType="next"
                  onInputChange={inputChangeHandler}
                  initialValue={editedUser ? editedUser.lastName : ''}
                  initiallyValid={!!editedUser}
                  required
                  controlStyle={{ flex: 2 }}
                />
              </View>
              <Input
                id="address"
                label={t('inputAddressLabel')}
                errorText={t('inputAddressError')}
                keyboardType="default"
                autoCapitalize="sentences"
                autoCorrect
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedUser ? editedUser.address : ''}
                initiallyValid={!!editedUser}
                required
              />
              <View style={styles('inlineFormFields')}>
                <Input
                  id="zipcode"
                  label={t('inputZipcodeLabel')}
                  errorText={t('inputZipcodeError')}
                  keyboardType="default"
                  autoCapitalize="sentences"
                  autoCorrect
                  returnKeyType="next"
                  onInputChange={inputChangeHandler}
                  initialValue={editedUser ? editedUser.zipcode : ''}
                  initiallyValid={!!editedUser}
                  required
                  controlStyle={{ flex: 1 }}
                />
                <Input
                  id="location"
                  label={t('inputLocationLabel')}
                  errorText={t('inputLocationError')}
                  keyboardType="default"
                  autoCapitalize="sentences"
                  autoCorrect
                  returnKeyType="next"
                  onInputChange={inputChangeHandler}
                  initialValue={editedUser ? editedUser.location : ''}
                  initiallyValid={!!editedUser}
                  required
                  controlStyle={styles('control1')}
                />
                <Input
                  id="country"
                  label={t('inputCountryLabel')}
                  errorText={t('inputCountryError')}
                  keyboardType="default"
                  autoCapitalize="sentences"
                  autoCorrect
                  returnKeyType="next"
                  onInputChange={inputChangeHandler}
                  initialValue={editedUser ? editedUser.country : ''}
                  initiallyValid={!!editedUser}
                  required
                  controlStyle={{ flex: 2 }}
                />
              </View>
              <Input
                id="mobile"
                label={t('inputMobileLabel')}
                errorText={t('inputMobileError')}
                keyboardType="default"
                phonenumber
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedUser ? editedUser.mobile : ''}
                initiallyValid={!!editedUser}
                controlStyle={styles('control2')}
              />
              <Input
                id="phone"
                label={t('inputPhoneLabel')}
                errorText={t('inputPhoneError')}
                keyboardType="default"
                phonenumber
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedUser ? editedUser.phone : ''}
                initiallyValid={!!editedUser}
                controlStyle={styles('control2')}
              />
              <View style={styles('buttonContainer')}>
                <Button
                  color={buttonAccentColor}
                  title={t('titleBack')}
                  onPress={() => {
                    navigation.goBack();
                  }}
                />
                {isLoading ? (
                  <ActivityIndicator size="small" color={primaryColor} />
                ) : (
                  <Button
                    title={t('titleContinue')}
                    color={buttonAccentColor}
                    onPress={submitHandler}
                  />
                )}
              </View>
            </View>
          </ScrollView>
        ) : !userId ? (
          <View style={styles('container')}>
            <View style={styles('form')}>
              <MainText style={styles('text')}>
                {t('messageRegistrationSuccess')}
              </MainText>
              <View style={styles('buttonContainer')}>
                <Button
                  title={t('titleContinue')}
                  color={buttonAccentColor}
                  onPress={() => {
                    // TODO: Auto login with given accessToken
                    navigation.push('Auth', { screen: 'UserAuth' });
                  }}
                />
              </View>
            </View>
          </View>
        ) : (
          <View style={styles('container')}>
            <View style={styles('form')}>
              <MainText style={styles('text')}>
                {t('messageUserEditSuccess')}
              </MainText>
              <View style={styles('buttonContainer')}>
                <Button
                  title={t('titleContinue')}
                  color={buttonAccentColor}
                  onPress={() => {
                    updateState(() => setIsSuccessfullySubmitted(false));
                    navigation.push('Auth', { screen: 'UserProfile' });
                  }}
                />
              </View>
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    screen: {
      flex: 1
    },
    container: {
      marginVertical: 20
    },
    caption: {
      marginVertical: 20
    },
    text: {
      marginVertical: 10
    },
    form: {
      padding: 10,
      backgroundColor: 'transparent',
      marginHorizontal: '8%'
    },
    inlineFormFields: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center'
    },
    control1: {
      flex: 2,
      marginHorizontal: 20
    },
    control2: {
      width: 400
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      marginTop: 10
    },
    actions: {
      justifyContent: 'center'
    },
    button: {
      width: 80
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      form: {
        marginHorizontal: '4%'
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      form: {
        marginHorizontal: 0
      },
      inlineFormFields: {
        flexDirection: 'column'
      },
      control1: {
        marginHorizontal: 0
      },
      control2: {
        width: '100%'
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      form: {
        marginHorizontal: 0
      },
      inlineFormFields: {
        flexDirection: 'column'
      },
      control1: {
        marginHorizontal: 0
      },
      control2: {
        width: '100%'
      }
    }
  }
);

export default UserProfileScreen;
