/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Image, LogBox, Linking, Platform } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { getAllHideAttributeKeysByAttributeName } from '../../helpers/filter';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';
import { View, Ionicons, useThemeColor, ThemeProps } from '../UI/Themed';
import ImageLoader from '../UI/ImageLoader';
import { LabelText, MainText } from '../UI/StyledText';
import RadioButtonGroup from '../UI/RadioButtonGroup';
import TouchableComponent from '../UI/TouchableComponent';
import { RootState } from '../../store';
import { IProductAttribute, ProductAttributeValueTypes } from '../../types';

if (LogBox) {
  LogBox.ignoreLogs(['Failed prop type:']);
}

export type ConfigItemProps = ThemeProps & {
  name: string;
  value: unknown;
  valueType: string;
  multiSelect: boolean;
  selectedAttributes: Array<IProductAttribute> | undefined;
  configIndex: number;
  productId: number;
  selectedConfigItem: IProductAttribute;
  availableConfigItems: Array<IProductAttribute>;
  onSelectItem: () => void;
};

const ConfigItem = (props: ConfigItemProps): JSX.Element => {
  /* #region Fields */
  const {
    name,
    value,
    valueType,
    multiSelect,
    selectedAttributes,
    configIndex,
    productId,
    selectedConfigItem,
    availableConfigItems,
    onSelectItem,
    lightColor,
    darkColor
  } = props;
  const isWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorValue, setErrorValue] = useState<string>('');
  const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
    useSelector(
      (state: RootState) => state.settings.productAttributeValueTypes
    );
  let config = null;
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const attentionColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'attention'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    let _hasError = false;
    let _errorValue = '';
    if (valueType === t('productAttributeValueTypeDictionary')) {
      if (!multiSelect) {
        // if not multiSelect, selectAttribute is an object
        if (
          _.findIndex(value, {
            label: selectedAttributes?.json.value.label
          }) === -1
        ) {
          _hasError = true;
          // try to find out, which productAttribute caused the error
          const hideAttributeKeys = getAllHideAttributeKeysByAttributeName(
            productAttributeValueTypes,
            availableConfigItems
          );
          _errorValue = _.find(hideAttributeKeys, function (o) {
            return o.value.includes(
              selectedAttributes?.json.value.attributeKey
            );
          })?.key;
        }

        // set label
        const json_field = selectedAttributes?.json;
        if (
          json_field?.value.input &&
          json_field?.value.input.hasOwnProperty('replaceLabel')
        ) {
          const newLabel: string =
            json_field?.value.input.replaceLabel?.replace(
              '%n',
              json_field?.value.input.lastInput
            );
          updateState(() => setLabel(newLabel));
        } else {
          updateState(() => setLabel(json_field?.value.label));
        }
      } else {
        const newLabels: Array<string> = [];

        // if multiSelect, selectAttribute is an array of objects
        selectedAttributes?.forEach((attr) => {
          if (_.findIndex(value, { label: attr.json.value.label }) === -1) {
            _hasError = true;
            // try to find out, which productAttribute caused the error
            const hideAttributeKeys = getAllHideAttributeKeysByAttributeName(
              productAttributeValueTypes,
              availableConfigItems
            );
            _errorValue = _.find(hideAttributeKeys, function (o) {
              return o.value.includes(attr.json.value.attributeKey);
            })?.key;
          }

          // set label
          const json_field = attr.json;
          if (
            json_field.value.input &&
            json_field.value.input.hasOwnProperty('replaceLabel')
          ) {
            const newLabel: string =
              json_field?.value.input.replaceLabel?.replace(
                '%n',
                json_field.value.input.lastInput
              );
            if (!newLabels.includes(newLabel)) {
              newLabels.push(newLabel);
            }
          } else {
            if (!newLabels.includes(json_field.value.label)) {
              newLabels.push(json_field.value.label);
            }
          }
        });

        updateState(() => setLabel(newLabels.join(', ')));
      }
    }

    if (_hasError && _errorValue && errorValue.length === 0) {
      updateState(() => setErrorValue(_errorValue));
    }

    if (!_hasError && errorValue.length > 0) {
      updateState(() => setErrorValue(''));
    }

    updateState(() => setHasError(_hasError));
  }, [valueType, value, errorValue, selectedAttributes]);

  useEffect(() => {
    if (selectedConfigItem) {
      if (selectedConfigItem.name === name) {
        updateState(() => setIsVisible(true));
      } else {
        updateState(() => setIsVisible(false));
        // set checkmark when visible group is closed by selecting different group
        if (isVisible) {
          updateState(() => setIsDone(true));
        }
      }
    }
  }, [name, selectedConfigItem]);
  /* #endregion */

  /* #region Renderers */
  switch (valueType) {
    case t('productAttributeValueTypeString'):
      config = (
        <LabelText strong>
          {name}: <MainText>{value}</MainText>
        </LabelText>
      );
      break;
    case t('productAttributeValueTypeList'):
      config = (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <LabelText strong>
            {name}: <MainText>{value}</MainText>{' '}
          </LabelText>
          <TouchableComponent
            onPress={() => {
              updateState(() => setIsVisible((prevState) => !prevState));
              if (selectedConfigItem.name !== name) {
                onSelectItem();
              }
            }}
          >
            <Ionicons
              name="chevron-up-circle-outline"
              color={buttonAccentColor}
            />
          </TouchableComponent>
        </View>
      );
      break;
    case t('productAttributeValueTypeDictionary'):
      if (isVisible) {
        config = (
          <RadioButtonGroup
            onPress={() => {
              updateState(() => setIsVisible((prevState) => !prevState));
              if (selectedConfigItem.name !== name) {
                onSelectItem();
              }
              // set checkmark when visible group is closed by button
              updateState(() => setIsDone(isVisible ? true : false));
            }}
            groupStyle={styles('radioGroup')}
            buttonStyle={styles('radioButton')}
            groupText={name}
            value={value}
            multiSelect={multiSelect}
            hasError={hasError}
            errorValue={errorValue}
            productId={productId}
            selectedAttributes={selectedAttributes}
            selectedConfigItem={selectedConfigItem}
            availableConfigItems={availableConfigItems}
          />
        );
      }
      break;
    case t('productAttributeValueTypeNumber'):
      config = (
        <LabelText strong>
          {name}: <MainText>{value}</MainText>
        </LabelText>
      );
      break;
    case t('productAttributeValueTypeImage'):
      config = (
        <View>
          <MainText strong>{name}</MainText>
          <TouchableComponent
            style={styles('imageContainer')}
            onPress={() =>
              isWeb
                ? window.open(imageUrl, '_blank')
                : Linking.openURL(imageUrl)
            }
          >
            <ImageLoader
              style={styles('image')}
              resizeMode="contain"
              source={rewriteBackendURL(value)}
              onLoadEnd={(img) => updateState(() => setImageUrl(img))}
            />
          </TouchableComponent>
        </View>
      );
      break;
    case t('productAttributeValueTypeFile'):
      config = (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'flex-start'
          }}
        >
          <MainText strong>{name}</MainText>
          <TouchableComponent
            style={{ marginLeft: 10 }}
            onPress={() =>
              isWeb
                ? window.open(rewriteBackendURL(value), '_blank')
                : Linking.openURL(rewriteBackendURL(value))
            }
          >
            <Ionicons name="download-outline" />
          </TouchableComponent>
        </View>
      );
      break;
    default:
      config = null;
  }

  return (
    <View style={styles('container')}>
      {isVisible ||
      valueType === t('productAttributeValueTypeString') ||
      valueType === t('productAttributeValueTypeNumber') ||
      valueType === t('productAttributeValueTypeImage') ||
      valueType === t('productAttributeValueTypeFile') ? (
        config
      ) : (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <LabelText strong>
            {name}:{' '}
            <MainText style={hasError ? { color: attentionColor } : {}}>
              {label}{' '}
            </MainText>
          </LabelText>
          <TouchableComponent
            onPress={() => {
              updateState(() => setIsVisible((prevState) => !prevState));
              if (selectedConfigItem.name !== name) {
                onSelectItem();
              }
            }}
          >
            <Ionicons
              name="chevron-down-circle-outline"
              color={buttonAccentColor}
            />
          </TouchableComponent>
          {hasError ? (
            <>
              <Ionicons name="hand-left" color={attentionColor} />
              <MainText style={{ color: attentionColor }}>
                {t('messageActionRequired')} '{errorValue}'
              </MainText>
            </>
          ) : (
            isDone && (
              <Image
                style={styles('checkmark')}
                source={require('../../assets/images/checkmark-green.png')}
              />
            )
          )}
        </View>
      )}
    </View>
  );
  /* #endregion */
};

ConfigItem.propTypes = {
  value: PropTypes.any.isRequired
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginTop: 20
    },
    radioGroup: {
      flex: 1,
      justifyContent: 'flex-start',
      marginHorizontal: 5
    },
    radioButton: {
      marginHorizontal: 5
    },
    imageContainer: {
      width: '100%',
      height: 200,
      overflow: 'hidden'
    },
    image: {
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      minHeight: 200
    },
    checkmark: {
      position: 'absolute',
      left: -30,
      bottom: 12,
      width: 28,
      height: 28
    }
  },
  {}
);

export default ConfigItem;
