/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import SkeletonContent from 'react-native-skeleton-content';

import useIsMounted from '../../hooks/useIsMounted';
import { ThemeProps, useThemeColor, View } from '../../components/UI/Themed';
import Card from '../UI/Card';

export type ShopItemPlaceholderProps = ThemeProps & {
  style: StyleSheet['props'];
};

const ShopItemPlaceholder = (props: ShopItemPlaceholderProps): JSX.Element => {
  /* #region Fields */
  const { style, lightColor, darkColor } = props;
  const isMounted = useIsMounted();
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const detailsBackground = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const bone = useThemeColor(
    { light: lightColor, dark: darkColor },
    'skeletonBone'
  );
  const boneHighlight = useThemeColor(
    { light: lightColor, dark: darkColor },
    'skeletonBoneHighlight'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[
        styles.category,
        style,
        { height: containerDimension.height + 100 }
      ]}
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        const aspectRatioBasedHeight = (3 / 4) * width; // Aspect ration 4 / 3
        updateState(() =>
          setContainerDimension({ width, height: aspectRatioBasedHeight })
        );
      }}
    >
      <Card>
        <View style={styles.touchable}>
          <View
            style={[
              styles.imageContainer,
              { height: containerDimension.height }
            ]}
          >
            <SkeletonContent
              isLoading={true}
              animationDirection="horizontalRight"
              boneColor={bone}
              highlightColor={boneHighlight}
              layout={[
                {
                  key: 'image',
                  width: '100%',
                  height: '100%',
                  alignSelf: 'center',
                  minHeight: 200
                }
              ]}
            />
          </View>
        </View>
      </Card>
      <View style={styles.spacer} />
      <View>
        <>
          <View style={[styles.text, { backgroundColor: detailsBackground }]}>
            <SkeletonContent
              isLoading={true}
              animationDirection="horizontalRight"
              boneColor={bone}
              highlightColor={boneHighlight}
              layout={[{ key: 'title', width: 220, height: 20 }]}
            />
          </View>
          <View style={[styles.text, { backgroundColor: detailsBackground }]}>
            <View />
            <SkeletonContent
              isLoading={true}
              animationDirection="horizontalRight"
              boneColor={bone}
              highlightColor={boneHighlight}
              layout={[{ key: 'price', width: 220, height: 20 }]}
            />
          </View>
        </>
      </View>
    </View>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  category: {
    height: 320,
    margin: 20,
    backgroundColor: 'transparent'
  },
  touchable: {
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: 'transparent'
  },
  imageContainer: {
    width: '100%',
    height: 200,
    overflow: 'hidden',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  spacer: {
    height: 20,
    backgroundColor: 'transparent'
  },
  text: {
    alignItems: 'center',
    height: 60,
    padding: 20
  }
});

export default ShopItemPlaceholder;
