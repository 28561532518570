/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { createSelectorCreator, defaultMemoize } from 'reselect';
import _ from 'lodash';

import Product from '../models/Product';
import News from '../models/News';
import Article from '../models/Article';
import Category from '../models/Category';
import { RootState } from '.';
import { parseLocaleNumber } from '../helpers/localized';
import {
  calculateLastInputPrice,
  productAttributeFilter,
  productAttributePriceFilter
} from '../helpers/filter';
import {
  IProductAttribute,
  ProductAttributeJsonValueType,
  ProductAttributeValueTypes
} from '../types';

/**
 * Create a "selector creator" that uses `lodash.isEqual` instead of `===`
 *
 * Example use case: when we pass an array to the selectors,
 * they are always recalculated, because the default `reselect` memoize function
 * treats the arrays always as new instances.
 *
 * @credits https://github.com/reactjs/reselect#customize-equalitycheck-for-defaultmemoize
 */
const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  _.isEqual
);

const productAttributeValueTypes = (state: RootState) =>
  state.settings.productAttributeValueTypes;
const availableProducts = (state: RootState) =>
  state.products.availableProducts;
const availableProductAttributes = (state: RootState) =>
  state.productattributes.availableProductAttributes;
const selectedProductAttributes = (state: RootState) =>
  state.productattributes.selectedProductAttributes;
const availableNews = (state: RootState) => state.blog.availableNews;
const availableArticles = (state: RootState) => state.blog.availableArticles;
const availableCategories = (state: RootState) =>
  state.blog.availableCategories;

// Get all available product attributes
export const allProductAttributes = () =>
  createDeepEqualSelector(
    availableProductAttributes,
    (attributes: Array<IProductAttribute>) => attributes
  );

export const productAttributesByProductID = (productId: number) =>
  createDeepEqualSelector(
    allProductAttributes(),
    (attributes: Array<IProductAttribute>) =>
      _getProductAttributesByProductID(attributes, productId)
  );

export const selectedProductAttributesByProductID = (
  productId: number | undefined
) =>
  createDeepEqualSelector(
    selectedProductAttributes,
    productAttributeValueTypes,
    (
      attributes: Array<IProductAttribute>,
      types: Array<ProductAttributeValueTypes>
    ) => {
      if (productId && attributes && types) {
        const filteredAttributes = attributes.filter(
          (attr) => attr.productId === productId
        );
        const filtered = productAttributeFilter(types, filteredAttributes);
        return _.sortBy(filtered, ['sortOrder', 'json.type', 'name']);
      }

      return [];
    }
  );

const _getProductAttributesByProductID = (
  attributes: Array<IProductAttribute>,
  productId: number
) =>
  attributes &&
  _.filter(attributes, (item: IProductAttribute) =>
    item.productId.includes(productId)
  );

// Get all available products
export const allProducts = (originKey: string | null = null) =>
  createDeepEqualSelector(
    availableProducts,
    productAttributeValueTypes,
    allProductAttributes(),
    (
      products: Array<Product>,
      types: Array<ProductAttributeValueTypes>,
      attributes: Array<IProductAttribute>
    ) =>
      products &&
      types &&
      attributes &&
      products.map((_item: Product) => {
        // make deep copy, so that changes won't affect original items
        const item = _.cloneDeep(_item);
        // correct price
        if (item.is_pricedByAttribute) {
          const refAttr = attributes.find(
            (attr) => attr.id === item.pricedByAttributeId
          );
          if (refAttr) {
            const json_field_value = refAttr.json
              .value as ProductAttributeJsonValueType;
            if (Array.isArray(json_field_value)) {
              const originKeys: Array<string> = [];
              if (originKey) {
                originKeys.push(originKey);
              } else {
                // no originKey was passed as argument, so get all originKeys from refAttr
                (json_field_value as ProductAttributeJsonValueType[]).forEach(
                  (attr) => {
                    if (attr.addsAttributePrice) {
                      Object.keys(attr.addsAttributePrice).forEach((key) => {
                        if (key.includes('-') && !originKeys.includes(key)) {
                          originKeys.push(key);
                        }
                      });
                    }
                  }
                );
              }

              const filtered: Array<ProductAttributeJsonValueType> = [];
              originKeys.forEach((key) =>
                // apply price filter to every originKey
                productAttributePriceFilter(
                  types,
                  _getProductAttributesByProductID(attributes, item.id),
                  [refAttr],
                  key
                )?.forEach((filteredAttr) => {
                  let filtered_json_field_value = filteredAttr.json.value;
                  if (Array.isArray(filtered_json_field_value)) {
                    filtered_json_field_value.forEach((_attr) => {
                      filtered.push(calculateLastInputPrice(_attr));
                    });
                  } else {
                    filtered.push(
                      filtered_json_field_value as ProductAttributeJsonValueType
                    );
                  }
                })
              );

              // get min value for finalPrice and set it to product price
              const query = _.minBy(filtered, function (o) {
                return parseLocaleNumber(o.finalPrice!);
              });
              if (query) {
                item.price = query.finalPrice!;
              }
            } else {
              item.price = json_field_value.price;
            }
          }
        }
        return item;
      })
  );

export const selectedProductBySlug = (slug: string) =>
  createDeepEqualSelector(
    availableProducts,
    (products: Array<Product>) =>
      products && _.find(products, (item: Product) => item.slug === slug)
  );

// Get all available category names for further comparission.
// All internal, archived and not published categories are invisible by API design
export const getCategoryNames = createDeepEqualSelector(
  availableCategories,
  (categories: Array<Category>) => categories.map((item: Category) => item.name)
);

// All archived and not published news are invisible by API design
export const newsOnFeed = () =>
  createDeepEqualSelector(
    availableNews,
    getCategoryNames,
    (news: Array<News>, categoryNames: Array<string>) =>
      news &&
      _.filter(news, (item: News) =>
        item.category !== '' ? categoryNames.includes(item.category) : item
      )
  );

// All archived and not published articles are invisible by API design
export const articlesOnFeed = (categoryName: string) =>
  createDeepEqualSelector(
    availableArticles,
    getCategoryNames,
    (articles: Array<Article>, categoryNames: Array<string>) =>
      articles &&
      _getArticlesOnFeed(
        _.filter(articles, (item: Article) =>
          categoryNames.includes(item.category)
        ),
        categoryName
      )
  );

const _getArticlesOnFeed = (articles: Array<Article>, categoryName: string) =>
  categoryName
    ? articles &&
      _.filter(articles, (item: Article) => item.category === categoryName)
    : articles;

export const categoriesOnFeed = () =>
  createDeepEqualSelector(
    availableArticles,
    availableCategories,
    (articles: Array<Article>, categories: Array<Category>) => {
      const transformedCategories: Array<any> = [];
      categories.forEach((item: Category, index: number) => {
        const articlesCount = _getArticlesOnFeed(articles, item.name).length;
        transformedCategories.push({
          itemKey: index,
          id: categories[index].id,
          name: categories[index].name,
          slug: categories[index].slug,
          date: categories[index].date,
          articles: articlesCount
        });
      });
      return _.sortBy(transformedCategories, 'itemKey');
    }
  );

export const selectedFeedArticle = (slug: string) =>
  createDeepEqualSelector(
    availableArticles,
    (articles: Array<Article>) =>
      articles && _.find(articles, (item: Article) => item.slug === slug)
  );

export const selectedFeedCategory = (slug: string) =>
  createDeepEqualSelector(
    availableCategories,
    (categories: Array<Category>) =>
      categories && _.find(categories, (item: Category) => item.slug === slug)
  );
