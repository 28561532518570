/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import { useNavigation } from '@react-navigation/native';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { LabelText, MainText, TitleText } from '../UI/StyledText';
import {
  Button,
  Ionicons,
  Switch,
  ThemeProps,
  useThemeColor,
  View
} from '../UI/Themed';
import TabBar, { DefaultTab } from '../UI/TabBar';
import Boop from '../animated/Boop';
import TouchableComponent from '../UI/TouchableComponent';

export const RCL_CONSENT_GIVEN = 'rcl_consent_given';
export const RCL_CONSENT_ANALYTICS = 'rcl_consent_analytics';

export type CookieBannerProps = ThemeProps;

const CookieBanner = (props: CookieBannerProps): JSX.Element => {
  /* #region Fields */
  const { lightColor, darkColor } = props;
  const refCookie = useRef();
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [isMinimalEnabled, setIsMinimalEnabled] = useState<boolean>(false);
  const navigation = useNavigation();

  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  const textColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'text'
  );
  const buttonTextColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonText'
  );
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'border'
  );
  const backdropColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'backdrop'
  );
  const shadowHardColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'shadowHard'
  );
  const switchActiveColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'switchActive'
  );
  const destructiveColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'destructive'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    // remove consent, if some cookies are missing (this is the case, when new cookies are introduced)
    const allCookies = Cookies.get();
    if (!Object.keys(allCookies).includes(RCL_CONSENT_ANALYTICS)) {
      Cookies.remove(RCL_CONSENT_GIVEN);
    }

    return () => {};
  }, []);

  const onValueChangeHandler = useCallback(async () => {
    const previousState = isMinimalEnabled;
    updateState(() => setIsMinimalEnabled(!previousState));
  }, [isMinimalEnabled, isMounted]);

  const acceptAllHandler = useCallback(async () => {
    if (!isMinimalEnabled) {
      Cookies.set(RCL_CONSENT_ANALYTICS, 'true', {
        expires: 150,
        sameSite: 'Lax'
      });
    } else {
      Cookies.set(RCL_CONSENT_ANALYTICS, 'false', {
        expires: 150,
        sameSite: 'Lax'
      });
    }
  }, [isMinimalEnabled, isMounted]);

  const declineAllHandler = useCallback(async () => {
    Cookies.set(RCL_CONSENT_ANALYTICS, 'false', {
      expires: 150,
      sameSite: 'Lax'
    });
  }, [isMounted]);
  /* #endregion */

  /* #region Renderers */
  const modalInfoButton = (
    <>
      <TitleText style={styles('modalText')} strong>
        {t('titleWhatAreCookies')}
      </TitleText>
      <MainText style={styles('modalText')}>
        {t('messageWhatAreCookies1')}
      </MainText>
      <MainText style={styles('modalText')}>
        {t('messageWhatAreCookies2')}
      </MainText>
    </>
  );

  const modalMinimalButton = (
    <>
      <TitleText style={styles('modalText')} strong>
        {t('titleMinimalCookies')}
      </TitleText>
      <MainText style={styles('modalText')}>
        {t('messageMinimalCookies1')}
      </MainText>
      <View style={styles('justifyRowRegular')}>
        <Switch
          onValueChange={onValueChangeHandler}
          value={isMinimalEnabled}
          trackColor={{ true: switchActiveColor, false: destructiveColor }}
        />
      </View>
      {!isMinimalEnabled ? (
        <MainText style={styles('modalText')} strong>
          {t('messageMinimalCookies2')}
        </MainText>
      ) : (
        <View style={styles('spacer')} />
      )}
    </>
  );

  const modalMarketingButton = (
    <>
      <TitleText style={styles('modalText')} strong>
        {t('titleMarketingCookies')}
      </TitleText>
      <MainText style={styles('modalText')}>
        {t('messageMarketingCookies1')}{' '}
        <LabelText
          style={{ textDecoration: 'underline' }}
          onPress={() => {
            updateState(() => setIsModalVisible(false));
            navigation.push('Shop', {
              screen: 'Blog',
              params: {
                screen: 'Article',
                params: {
                  articleSlug: 'datenschutzerklaerung',
                  enableBackButton: true
                }
              }
            });
          }}
        >
          {t('titleDataPrivacy')}
        </LabelText>
      </MainText>
      <MainText style={styles('modalText')}>
        {t('messageMarketingCookies2')}
      </MainText>
      <LabelText style={styles('modalText')} strong small>
        {t('messageMarketingCookies3')}
      </LabelText>
    </>
  );

  const modalInfoComponent = (
    <Modal
      visible={isModalVisible}
      transparent
      onRequestClose={() => updateState(() => setIsModalVisible(false))}
    >
      <View style={[styles('container'), { backgroundColor: backdropColor }]}>
        <View style={[styles('modal'), { borderColor: borderColor }]}>
          <View
            style={[
              styles('closeContainer'),
              { backgroundColor: primaryColor }
            ]}
          >
            <Boop rotation={45}>
              <TouchableComponent
                onPress={() => updateState(() => setIsModalVisible(false))}
              >
                <Ionicons name="close-outline" color={buttonTextColor} />
              </TouchableComponent>
            </Boop>
          </View>
          <View style={styles('leftSide')}>
            <TabBar
              containerStyle={{
                marginTop: 20,
                flexDirection: 'column',
                alignSelf: 'center',
                width: '80%'
              }}
              tabStyle={{
                width: '100%',
                marginVertical: 10
              }}
              tabs={[
                <DefaultTab
                  icon="information-circle-outline"
                  title={t('titleWhatAreCookies')}
                  containerStyle={styles('tab')}
                  focusedStyle={styles('focusedTab')}
                  selectedStyle={styles('selectedTab')}
                />,
                <DefaultTab
                  icon="checkmark-circle-outline"
                  title={t('titleMinimalCookies')}
                  containerStyle={styles('tab')}
                  focusedStyle={styles('focusedTab')}
                  selectedStyle={styles('selectedTab')}
                />,
                <DefaultTab
                  icon="clipboard-outline"
                  title={t('titleMarketingCookies')}
                  containerStyle={styles('tab')}
                  focusedStyle={styles('focusedTab')}
                  selectedStyle={styles('selectedTab')}
                />
              ]}
              selectedIndex={index}
              onSelect={(idx) => updateState(() => setIndex(idx))}
            />
          </View>
          <View
            style={[
              styles('rightSide'),
              { boxShadow: `${shadowHardColor} 1px 4px 8px` }
            ]}
          >
            {index === 0 && modalInfoButton}
            {index === 1 && modalMinimalButton}
            {index === 2 && modalMarketingButton}
            <View
              style={[styles('separator'), { borderTopColor: borderColor }]}
            />
            <View
              style={[
                styles('button'),
                {
                  width: '100%',
                  alignItems: !isMinimalEnabled ? 'flex-start' : 'flex-end'
                }
              ]}
            >
              <Button
                title={
                  !isMinimalEnabled ? t('titleAccept') : t('titleSaveSettings')
                }
                onPress={() => {
                  refCookie?.current?.accept(); // accept cookie programatically
                  acceptAllHandler();
                  updateState(() => setIsModalVisible(false));
                }}
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );

  return (
    <>
      {modalInfoComponent}
      <CookieConsent
        ref={refCookie}
        location="bottom"
        buttonText={t('titleAccept')}
        cookieName={RCL_CONSENT_GIVEN}
        style={{
          backgroundColor: backgroundColor,
          boxShadow: `${shadowHardColor} 1px 4px 8px`
        }}
        contentStyle={{
          color: textColor,
          fontFamily: 'default-regular'
        }}
        buttonStyle={{
          color: buttonTextColor,
          backgroundColor: primaryColor,
          borderRadius: 2
        }}
        expires={150}
        onAccept={acceptAllHandler}
        onDecline={declineAllHandler}
      >
        {t('messageCookie')}{' '}
        <MainText
          style={{
            color: textColor,
            textDecoration: 'underline'
          }}
          onPress={() => updateState(() => setIsModalVisible(true))}
        >
          {t('titleCookieSettings')}
        </MainText>
      </CookieConsent>
    </>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },
    closeContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 30,
      height: 30,
      zIndex: 100
    },
    modal: {
      flexDirection: 'row',
      width: '60%',
      height: 400,
      // minHeight: 150,
      borderWidth: 1,
      // boxShadow: '1px 1px 1px black',
      justifyContent: 'space-between',
      alignItems: 'center',
      // boxSizing: 'border-box'
      borderRadius: 10,
      overflow: 'hidden'
    },
    leftSide: {
      width: '35%'
    },
    rightSide: {
      width: '65%',
      height: '100%',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      padding: 16
    },
    tab: {
      borderRadius: 0,
      borderWidth: 1,
      justifyContent: 'flex-start'
    },
    focusedTab: {
      borderWidth: 2
    },
    selectedTab: {
      borderWidth: 1,
      borderBottomWidth: 1
    },
    modalText: {
      marginHorizontal: 10
    },
    button: {
      width: 150
    },
    justifyRowRegular: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginHorizontal: 10
    },
    spacer: {
      width: '100%',
      marginTop: 10
    },
    separator: {
      width: '100%',
      borderTopWidth: 1,
      marginVertical: 10
    }
  },
  {
    [DEVICE_SIZES.LARGE_DEVICE]: {
      modal: {
        width: '70%',
        height: 420
      }
    },
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      modal: {
        width: '85%',
        height: 540
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      modal: {
        width: '98%',
        height: 580
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      modal: {
        width: '98%',
        height: 580
      }
    }
  }
);

export default CookieBanner;
