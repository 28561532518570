/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t, parseLocaleNumber } from '../../helpers/localized';
import {
  View,
  Text,
  Button,
  useThemeColor,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText, MainText } from '../UI/StyledText';
import CartItem from './CartItem';
import Card from '../UI/Card';
import { RootState } from '../../store';

const OrderItem = (props: ThemeProps): JSX.Element => {
  /* #region Fields */
  const { lightColor, darkColor } = props;
  const isMounted = useIsMounted();
  const language = useSelector((state: RootState) => state.settings.language);
  const currency = useSelector((state: RootState) => state.settings.currency);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const textAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'textAccent'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Renderers */
  return (
    <Card style={styles.orderItem}>
      <View style={styles.summary}>
        <LabelText strong>
          {t('totalAmount') + ' '}
          <Text style={{ color: primaryColor }}>
            {parseLocaleNumber(props.amount).toLocaleString(language, {
              style: 'currency',
              currency: currency
            })}
          </Text>
        </LabelText>
        <MainText style={{ color: textAccentColor }}>
          {t('titleOrderNr') + ' ' + props.nr}
        </MainText>
        <MainText style={{ color: textAccentColor }}>{props.date}</MainText>
      </View>
      <Button
        color={buttonColor}
        title={showDetails ? t('titleHideDetails') : t('titleDetails')}
        onPress={() => {
          updateState(() => setShowDetails((prevState) => !prevState));
        }}
      />
      {showDetails && (
        <View style={styles.detailItem}>
          {props.items.map((cartItem) => (
            <CartItem
              key={cartItem.id}
              title={cartItem.productTitle}
              attributes={cartItem.productAttributes}
              quantity={cartItem.quantity}
              amount={parseLocaleNumber(cartItem.sum)}
            />
          ))}
        </View>
      )}
    </Card>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  orderItem: {
    margin: 20,
    padding: 10,
    alignItems: 'center'
  },
  summary: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 15
  },
  detailItem: {
    width: '100%'
  }
});

export default OrderItem;
