/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { AnyAction } from 'redux';

import { MessageParams } from '../../types';
import {
  SET_ACTIVE_SCREEN,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SET_PRODUCT_CONFIGRUATOR_DETAILS,
  CLEAR_PRODUCT_CONFIGRUATOR_DETAILS
} from '../actions/notification';

interface NotificationState {
  activeScreen: { name: string; key: string };
  messages: Array<MessageParams>;
  configuratorDetails: { title: string; description: string; price: string };
}

const initialState: NotificationState = {
  activeScreen: { name: '', key: '' },
  messages: [],
  configuratorDetails: { title: '', description: '', price: '' }
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_ACTIVE_SCREEN:
      // console.log(new Date().toISOString(), 'setActiveScreen', {
      //   name: action.screen.name,
      //   key: action.screen.key
      // });
      return {
        ...state,
        activeScreen: { name: action.screen.name, key: action.screen.key }
      };

    case SET_MESSAGE: {
      const updatedMessages: Array<MessageParams> = [...state.messages];
      // console.log('SET_MESSAGE', new Date().toISOString(), action.message);
      if (
        !updatedMessages.includes({
          title: action.title,
          message: action.message
        })
      ) {
        updatedMessages.push({ title: action.title, message: action.message });
      }
      return {
        ...state,
        messages: updatedMessages
      };
    }

    case CLEAR_MESSAGE: {
      const updatedMessages = [...state.messages];
      if (updatedMessages.length > 0) {
        const lastError = updatedMessages.pop();
        // console.log('CLEAR_MESSAGE', lastError);
      }
      return {
        ...state,
        messages: updatedMessages
      };
    }

    case SET_PRODUCT_CONFIGRUATOR_DETAILS: {
      const updatedConfiguratorDetails = { ...state.configuratorDetails };
      return {
        ...state,
        configuratorDetails: {
          ...updatedConfiguratorDetails,
          ...action.details
        }
      };
    }

    case CLEAR_PRODUCT_CONFIGRUATOR_DETAILS: {
      return {
        ...state,
        configuratorDetails: initialState.configuratorDetails
      };
    }
  }
  return state;
};
