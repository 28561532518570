/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import moment from 'moment';

import { getI18Locale } from '../helpers/localized';
import { ICartItem } from '../types';

class Order {
  /* #region Properties */
  id: string | number;

  nr: string;

  items: Array<ICartItem>;

  salesTax: number;

  totalAmount: number;

  currency: string;

  date: Date | number;

  note: string;

  is_archived: boolean;
  /* #endregion */
  constructor(
    id: string | number,
    nr: string,
    items: Array<ICartItem>,
    salesTax: number,
    totalAmount: number,
    currency: string,
    date: Date | number,
    note: string,
    is_archived: boolean = false
  ) {
    this.id = id;
    this.nr = nr;
    this.items = items;
    this.salesTax = salesTax;
    this.totalAmount = totalAmount;
    this.currency = currency;
    this.date = date;
    this.note = note;
    this.is_archived = is_archived;
  }

  get readableDate() {
    moment.locale(getI18Locale());
    return moment(this.date).format('lll');
  }
}

export default Order;
