/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useCallback, useEffect } from 'react';
import { Platform, ScrollView } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';

import { t } from '../../helpers/localized';
import {
  View,
  Button,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText, MainText } from '../../components/UI/StyledText';
import TouchableComponent from '../../components/UI/TouchableComponent';
import ContainerView from '../../components/UI/ContainerView';
import Card from '../../components/UI/Card';
import OrderProgress from '../../components/shop/OrderProgress';
import AddressItem from '../../components/shop/AddressItem';
import * as cartActions from '../../store/actions/cart';
import { RootState } from '../../store';
import { ShopStackParamList } from '../../types';

const FinishOrderScreen = (
  { navigation }: StackScreenProps<ShopStackParamList, 'FinishOrder'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const { styles } = useStyles();
  const isWeb = Platform.OS === 'web';
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const cartTotalAmount = useSelector(
    (state: RootState) => state.cart.totalAmount
  );
  const activeUserProfile = useSelector(
    (state: RootState) => state.auth.activeUserProfile
  );
  const dispatch = useDispatch();
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  /* #endregion */

  /* #region Events */
  const verifyAddressHandler = useCallback(async () => {
    if (cartItemCount > 0) {
      dispatch(cartActions.setDidVerifyOrder(true));
    }
    dispatch(cartActions.setDidVerifyAddress(true));
    navigation.push('Shop', {
      screen: 'Products',
      params: {
        screen: 'OrderSummary'
      }
    });
  }, [navigation]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleFinishOrderScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleFinishOrderScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'Cart'
                }
              });
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, cartItemCount]);
  /* #endregion */

  /* #region Renderers */
  return (
    <ContainerView
      scrollable
      seoTitle={`${t('titleFinishOrderScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <View style={styles('screen')}>
        <Card style={styles('container')}>
          <OrderProgress />
          <View style={styles('summary')}>
            <View></View>
            <Button
              disabled={!(cartTotalAmount > 0 && activeUserProfile)}
              color={buttonAccentColor}
              title={t('titleContinue')}
              onPress={verifyAddressHandler}
            />
          </View>
        </Card>
        <ScrollView>
          {activeUserProfile && (
            <View style={styles('textContainer')}>
              <MainText style={styles('text')}>
                {t('messageCheckAddressData')}
              </MainText>
              <AddressItem profile={activeUserProfile} />
            </View>
          )}
          {!activeUserProfile && (
            <View style={styles('textContainer')}>
              <MainText style={styles('text')}>
                {t('messageNoAddressData')}
              </MainText>
            </View>
          )}
        </ScrollView>
      </View>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    screen: {
      flex: 1,
      margin: 20
    },
    container: {
      marginBottom: 20,
      padding: 10
    },
    summary: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center'
    },
    summaryText: {},
    textContainer: {
      marginHorizontal: 20
    },
    text: {
      marginVertical: 10
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    }
  },
  {}
);

export default FinishOrderScreen;
