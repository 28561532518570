/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *
 *  Special thanks goes to https://www.instamobile.io/mobile-development/react-native-localization/
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';
import memoize from 'lodash.memoize'; // Use for caching/memoize for better performance
import i18n from 'i18n-js';
import * as Localization from 'expo-localization';
import { I18nManager } from 'react-native';

import Config from '../constants/Config';
import store from '../store';
import { LANGUAGE_PERSISTENCE_KEY } from '../store/actions/settings';

export const { defaultLocale, defaultCurrency } = Config();

export const translationGetters = {
  en: require('../locale/en.json'),
  de: require('../locale/de.json'),
  'de-ch': require('../locale/de.json'),
  ru: require('../locale/ru.json'),
  cs: require('../locale/cs.json')
};

export const t = memoize(
  (key, config = null) =>
    i18n.t(key, config).includes('missing') ? key : i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

export const tryFindMatchFromLanguageTag = (localeTag: string): string => {
  // find similar language match if correct language tag isn't set up
  if (localeTag in translationGetters) {
    return localeTag;
  }

  const prefix = localeTag.split('-')[0]; // first two chars of local tag

  // Use main language for unconfigured sub-languages, e.g. de, en, ...
  if (prefix in translationGetters) {
    return prefix;
  }

  // switch to default, if device language not available in configuration
  return defaultLocale;
};

export const setI18nInitialConfig = async (): Promise<void> => {
  const localeLanguageTag = Localization.locale.toLowerCase();
  const { isRTL } = Localization;

  t.cache.clear();
  // update layout direction
  I18nManager.forceRTL(isRTL);
  // set i18n-js config
  i18n.translations = translationGetters;
  // do we have a saved language in AsyncStorage?
  const language = await AsyncStorage.getItem(LANGUAGE_PERSISTENCE_KEY);
  // check if data was stored
  if (!language) {
    await setI18Locale(localeLanguageTag);
  } else {
    await setI18Locale(language);
  }
  i18n.defaultLocale = defaultLocale;
  // When a value is missing from a language it'll fallback to another language with the key present.
  i18n.fallbacks = true;
};

export const getI18Locale = (): string => {
  return i18n.locale;
};

export const setI18Locale = async (localeTag: string): Promise<void> => {
  const locale = tryFindMatchFromLanguageTag(localeTag);

  i18n.locale = locale;
  await AsyncStorage.setItem(LANGUAGE_PERSISTENCE_KEY, locale);
};

export const parseLocaleNumber = (
  str: string | number,
  precision: number = 2
): number => {
  if (str === 'undefined' || str === null) {
    return -1;
  }

  if (typeof str !== 'string') {
    // Return original value
    return str;
  }

  // Detect the user's locale decimal separator:
  const decimalSeparator = (1.1).toLocaleString(i18n.locale).substring(1, 2);
  // Detect the user's locale thousand separator:
  let thousandSeparator = (1000).toLocaleString(i18n.locale).substring(1, 2);
  // In case there are locales that don't use a thousand separator
  if (thousandSeparator.match(/\d/)) thousandSeparator = '';

  const newStr = str
    .replace(new RegExp(_.escapeRegExp(thousandSeparator), 'g'), '')
    .replace(new RegExp(_.escapeRegExp(decimalSeparator)), '.');

  return _.round(parseFloat(newStr), precision);
};

export const numberToLocalString = (
  num: number,
  precision: number = 2
): string => {
  const str = num.toLocaleString(i18n.locale, {
    useGrouping: store.getState().settings.thousandSeparator,
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  });

  return str;
};
