/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dimensions, FlatList, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import Config from '../../constants/Config';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import {
  View,
  Button,
  useThemeColor,
  ThemeProps,
  Ionicons
} from '../../components/UI/Themed';
import { LabelText, MainText } from '../../components/UI/StyledText';
import Loading from '../../components/UI/Loading';
import TouchableComponent from '../../components/UI/TouchableComponent';
import ArticleItem from '../../components/blog/ArticleItem';
import * as blogActions from '../../store/actions/blog';
import { articlesOnFeed, selectedFeedCategory } from '../../store/reselect';
import { BlogStackParamList } from '../../types';

const ArticlesScreen = (
  { route, navigation }: StackScreenProps<BlogStackParamList, 'Articles'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const pagination = Config().pagination;
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const { width } = Dimensions.get('window');
  const containerRef = useRef<ContainerViewProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const categorySlug = route.params?.categorySlug;
  const selectedCategory = useSelector(selectedFeedCategory(categorySlug));
  const [page, setPage] = useState(1);
  const articles = useSelector(articlesOnFeed(selectedCategory?.name));
  const [articlesData, setArticlesData] = useState([]);
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const loadArticles = useCallback(async () => {
    updateState(() => setError(''));
    updateState(() => setIsLoading(true));
    try {
      dispatch(blogActions.fetchArticles());
    } catch (err) {
      updateState(() => setError(err.message));
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, setError]);

  const loadLess = () => {
    // console.log('Load less', page, page > 1);
    if (page > 1) {
      updateState(() => setPage(page - 1));
    }
  };

  const loadMore = () => {
    // console.log('Load more', page, articles && page <= articles.length / pagination);
    if (articles && page <= articles.length / pagination) {
      updateState(() => setPage(page + 1));
    }
  };

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleArticlesScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleArticlesScreen') });
    }

    // refetch all products when drawer navigation page is loaded (default for drawer is using a cached page)
    const unsubscribe = navigation.addListener('focus', loadArticles);

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  useEffect(() => {
    if (articles?.length > 0 && articlesData.length === 0) {
      // console.log('Set initial page', page, articles);
      updateState(() =>
        setArticlesData(
          articles.slice(
            (page - 1) * pagination,
            (page - 1) * pagination + pagination
          )
        )
      );
    }
  }, [articles, articlesData]);

  useEffect(() => {
    if (articles?.length > 0) {
      // console.log('Set page', page, articles);
      updateState(() =>
        setArticlesData(
          articles.slice(
            (page - 1) * pagination,
            (page - 1) * pagination + pagination
          )
        )
      );
    }
  }, [page]);

  const selectItemHandler = (id: number, slug: string, title: string) => {
    navigation.push('Shop', {
      screen: 'Blog',
      params: {
        screen: 'Article',
        params: {
          articleSlug: slug
        }
      }
    });
  };
  /* #endregion */

  /* #region Renderers */
  if (error) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleArticlesScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      >
        <View style={styles('centered')}>
          <MainText>
            {t('errorOccurred')} {error}
          </MainText>
          <Button
            title={t('tryAgain')}
            color={buttonColor}
            onPress={loadArticles}
          />
        </View>
      </ContainerView>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && articles?.length === 0) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleArticlesScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      >
        <View style={styles('centered')}>
          <MainText>{t('noArticles')}</MainText>
        </View>
      </ContainerView>
    );
  }

  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      seoTitle={`${t('titleArticlesScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'index,follow' }]}
    >
      <FlatList
        scrollEnabled={!containerRef.current?.scrollable}
        contentContainerStyle={styles('container')}
        onRefresh={loadArticles}
        refreshing={isLoading}
        data={articlesData}
        extraData={{ width }} // using for responsive re-rendering of FlatList content
        keyExtractor={(item, index) => index.toString()}
        removeClippedSubviews={true}
        renderItem={(itemData) => (
          <ArticleItem
            style={styles('article')}
            date={itemData.item.date}
            category={itemData.item.category}
            title={itemData.item.title}
            teaser={itemData.item.teaser}
          >
            <TouchableComponent
              onPress={() => {
                selectItemHandler(
                  itemData.item.id,
                  itemData.item.slug,
                  itemData.item.title
                );
              }}
            >
              <LabelText strong small>
                {t('titleReadMore')}
              </LabelText>
            </TouchableComponent>
          </ArticleItem>
        )}
        ListFooterComponent={() => (
          <View style={styles('footer')}>
            <TouchableComponent
              activeOpacity={0.9}
              onPress={loadLess}
              style={styles('footerButtons')}
            >
              <Ionicons name="chevron-back" />
              {isLoading ? <Loading /> : null}
            </TouchableComponent>
            {_.range(Math.ceil(articles?.length / pagination)).map(
              (element, index) => {
                const currentPage = element + 1;
                const selectedPage = currentPage === page ? true : false;
                return (
                  <MainText
                    key={index.toString()}
                    style={{
                      paddingHorizontal: 5,
                      textDecoration: selectedPage ? 'underline' : 'none'
                    }}
                    strong={selectedPage}
                    onPress={() => {
                      updateState(() => setPage(currentPage));
                    }}
                  >
                    {currentPage.toString()}
                  </MainText>
                );
              }
            )}
            <TouchableComponent
              activeOpacity={0.9}
              onPress={loadMore}
              style={styles('footerButtons')}
            >
              <Ionicons name="chevron-forward" />
              {isLoading ? <Loading /> : null}
            </TouchableComponent>
          </View>
        )}
      />
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginVertical: 20
    },
    article: {
      marginBottom: 10,
      marginHorizontal: '8%'
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    footer: {
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      marginHorizontal: '8%'
    },
    footerButtons: {
      padding: 10,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      article: {
        marginHorizontal: '4%'
      },
      footer: {
        marginHorizontal: '4%'
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      container: {
        marginVertical: 0
      },
      article: {
        marginHorizontal: 0
      },
      footer: {
        marginHorizontal: 0
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      container: {
        marginVertical: 0
      },
      article: {
        marginHorizontal: 0
      },
      footer: {
        marginHorizontal: 0
      }
    }
  }
);

export default ArticlesScreen;
