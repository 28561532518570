/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';

import Config from '../../constants/Config';
import { singleCallAPI } from '../../helpers/api-utils';
import logger from '../../helpers/logger';
import { numberToLocalString, t } from '../../helpers/localized';
import Order from '../../models/Order';
import * as notificationActions from './notification';
import { CART_PERSISTENCE_KEY } from './cart';
import { IProductAttribute, ProductAttributeJsonValueType } from '../../types';
import { calculateLastInputPrice } from '../../helpers/filter';

export const ADD_ORDER = 'ADD_ORDER';
export const SET_ORDERS = 'SET_ORDERS';

export const fetchOrders = () => {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const guestId = getState().auth.guestId;
      const language = getState().settings.language;

      let response = null;
      if (ownerId) {
        response = await singleCallAPI(
          'GET',
          `${Config().api_url}/orders/?ownerId=${ownerId}`,
          {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: `Token ${accessToken}`
          }
        );
      }
      // TODO: maybe read without token?
      // if (guestId) {
      //   response = await singleCallAPI(
      //     'GET',
      //     `${Config().api_url}/orders/?guestId=${guestId}`,
      //     {
      //       'Content-Type': 'application/json',
      //       'Accept-Language': language
      //     }
      //   );
      // }

      const loadedOrders = [];
      const resData = response;
      for (const key in resData?.results) {
        loadedOrders.push(
          new Order(
            key,
            resData.results[key].nr,
            resData.results[key].cartItems,
            resData.results[key].salesTax,
            resData.results[key].totalAmount,
            resData.results[key].currency,
            new Date(resData.results[key].created),
            resData.results[key].note
          )
        );
      }

      dispatch({
        type: SET_ORDERS,
        orders: loadedOrders
      });
    } catch (err) {
      logger.log(
        `store.actions.orders - fetchOrders: Cannot fetch orders! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch orders! Server responded with error - ${err}.`
      );
    }
  };
};

export const addOrder = (
  cartItems,
  salesTax,
  totalAmount,
  currency,
  note = ''
) => {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const guestId = getState().auth.guestId;
      const language = getState().settings.language;

      const transformedCartItems = cartItems.map((item) => {
        // transform product attributes to meet the server API
        item.productAttributes.forEach((attr: IProductAttribute) => {
          // the server don't know the finalPrice filter tags, so we have to convert all items
          attr.json.value = calculateLastInputPrice(
            attr.json.value as ProductAttributeJsonValueType,
            true
          );
          // rewrite the label
          if (
            attr.json.value.input &&
            attr.json.value.input.hasOwnProperty('replaceLabel')
          ) {
            attr.json.value.label = attr.json.value.input.replaceLabel!.replace(
              '%n',
              attr.json.value.input.lastInput
            );
          }
        });

        return {
          itemKey: item.itemKey,
          productTitle: item.productTitle,
          productPrice: numberToLocalString(item.productPrice),
          productAttributes: _.sortBy(item.productAttributes, [
            'sortOrder',
            'json.type',
            'name'
          ]),
          quantity: item.quantity,
          sum: numberToLocalString(item.sum)
        };
      });

      let response = null;
      if (ownerId) {
        response = await singleCallAPI(
          'POST',
          `${Config().api_url}/orders/`,
          {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: `Token ${accessToken}`
          },
          {
            ownerId: ownerId,
            cartItems: transformedCartItems,
            salesTax: numberToLocalString(salesTax),
            totalAmount: numberToLocalString(totalAmount),
            currency,
            note
          }
        );
      }

      if (guestId) {
        response = await singleCallAPI(
          'POST',
          `${Config().api_url}/orders/`,
          {
            'Content-Type': 'application/json',
            'Accept-Language': language
          },
          {
            guestId: guestId,
            cartItems: transformedCartItems,
            salesTax: numberToLocalString(salesTax),
            totalAmount: numberToLocalString(totalAmount),
            currency,
            note
          }
        );
      }

      const resData = response;
      if (resData) {
        await AsyncStorage.removeItem(CART_PERSISTENCE_KEY);
        dispatch({
          type: ADD_ORDER,
          orderData: {
            id: resData.id,
            nr: resData.nr,
            items: cartItems,
            salesTax: resData.salesTax,
            amount: resData.totalAmount,
            currency: resData.currency,
            date: resData.created,
            note: resData.note
          }
        });
      }
    } catch (err) {
      logger.log(
        `store.actions.orders - addOrder: Cannot add order! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot add order! Server responded with error - ${err}.`
      );
    }
  };
};
