/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { useRef, useEffect } from 'react';

const usePrevious = (value: any) => {
  /* #region Fields */
  const ref = useRef();
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    // assign the value of ref to the argument
    ref.current = value;
  }, [value]);
  /* #endregion */

  // in the end, return the current ref value.
  return ref.current;
};
export default usePrevious;
