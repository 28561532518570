/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import ImageSection, { ImageSectionProps } from './ImageSection';
import ImageSlider, { ImageSliderProps } from './ImageSlider';

type SectionHeaderProps = {
  screenName: string;
  param?: string;
  position: string;
};

type BannerSettingsProps = {
  name: string;
  array: Array<ImageSectionProps>;
};

type SliderSettingsProps = {
  name: string;
  settings: ImageSliderProps;
};

type ScreenProps = {
  name: string;
  settings: Array<BannerSettingsProps | SliderSettingsProps>;
};

const SectionHeader = (props: SectionHeaderProps) => {
  /* #region Fields */
  const { screenName, param, position } = props;
  const screenSettings = useSelector(
    (state: RootState) =>
      state.settings.screens?.find(
        (screens: ScreenProps) => screens.name === screenName
      )?.settings
  );
  /* #endregion */

  /* #region Renderers */
  const banners = screenSettings
    ?.find((config: BannerSettingsProps) => config.name === 'section banners')
    ?.array.filter((banner: ImageSectionProps) => {
      if (banner.param && param) {
        return banner.position === position && banner.param === param;
      } else {
        return banner.position === position;
      }
    })
    .map((banner: ImageSectionProps, index: number) => {
      return (
        <ImageSection
          key={index.toString()}
          image={banner.image}
          textAlign={banner.textAlign}
          manualColorTheme={banner.manualColorTheme}
          textBackdrop={banner.textBackdrop}
          subTitle={banner.subTitle}
          title={banner.title}
          text={banner.text}
          linkedURL={banner.linkedURL}
          linkedArticle={banner.linkedArticle}
          height={banner.height}
          marginBottom={banner.marginBottom}
        />
      );
    });

  const slides = screenSettings?.find(
    (config: SliderSettingsProps) => config.name === 'section slides'
  );

  let slideSettings;
  if (slides) {
    Object.keys(slides.settings).includes('param')
      ? slides.settings.param === param && slides.settings.position === position
        ? (slideSettings = slides.settings)
        : null
      : slides.settings.position === position
      ? (slideSettings = slides.settings)
      : null;
  }

  if (position === 'top') {
    return (
      <>
        {banners}
        {slideSettings && <ImageSlider {...slideSettings} />}
      </>
    );
  } else {
    return (
      <>
        {slideSettings && <ImageSlider {...slideSettings} />}
        {banners}
      </>
    );
  }
  /* #endregion */
};

export default SectionHeader;
