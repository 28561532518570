/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

class UserProfile {
  /* #region Properties */
  company_name: string;

  title: string;

  firstName: string;

  lastName: string;

  email: string;

  mobile: string;

  phone: string;

  address: string;

  zipcode: string;

  location: string;

  country: string;

  admin: boolean;
  /* #endregion */
  constructor(
    company_name: string,
    title: string,
    firstName: string,
    lastName: string,
    email: string,
    mobile: string,
    phone: string,
    address: string,
    zipcode: string,
    location: string,
    country: string,
    admin: boolean
  ) {
    this.company_name = company_name;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.mobile = mobile;
    this.phone = phone;
    this.address = address;
    this.zipcode = zipcode;
    this.location = location;
    this.country = country;
    this.admin = admin;
  }
}

export default UserProfile;
