/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useRef } from 'react';
import { LogBox } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import Swiper from 'react-native-web-swiper';

import useIsMounted from '../../hooks/useIsMounted';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';
import { View, ThemeProps, useThemeColor } from './Themed';
import ImageSection, { ImageSectionProps } from './ImageSection';
import ImageLoader from './ImageLoader';

if (LogBox) {
  LogBox.ignoreLogs(['Failed prop type:']);
}

export type ImageSliderProps = ThemeProps & {
  array: Array<string> | Array<ImageSectionProps>;
  position?: string;
  param?: string;
  height?: number;
  marginBottom?: number;
  autoplayIndex?: number;
  autoplayInterval?: number;
};

const ImageSlider = (props: ImageSliderProps): JSX.Element => {
  /* #region Fields */
  const {
    array,
    height,
    marginBottom,
    autoplayIndex,
    autoplayInterval,
    lightColor,
    darkColor
  } = props;
  const refCarousel = useRef();
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const [containerDimension, setContainerDimension] = useState({
    width: 1,
    height: 1
  });
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[
        {
          backgroundColor: 'transparent'
        },
        height ? { height } : {},
        marginBottom ? { marginBottom } : { marginBottom: 0 }
      ]}
      onLayout={(event) => {
        const { width, height } = event.nativeEvent.layout;
        updateState(() =>
          setContainerDimension({
            width: width,
            height: height
          })
        );
      }}
    >
      {(!array || array?.length === 0) && (
        <ImageLoader
          style={[
            styles('image'),
            {
              backgroundColor,
              height: containerDimension.height
            }
          ]}
          resizeMode="contain"
          source={require('../../assets/images/no-image-available.png')}
        />
      )}
      {array?.length > 0 && (
        <Swiper
          ref={refCarousel}
          from={autoplayIndex ? autoplayIndex : 0}
          loop={true}
          springConfig={{
            stiffness: 20
          }}
          timeout={autoplayInterval ? autoplayInterval / 1000 : 5}
          minDistanceForAction={0.15}
          controlsProps={{
            dotsPos: false,
            prevPos: false,
            nextPos: false
          }}
        >
          {array.map((item, index) => {
            if (typeof item === 'string') {
              // slides of image urls
              return (
                <ImageLoader
                  key={index.toString()}
                  style={[
                    styles('image'),
                    {
                      height: containerDimension.height
                    }
                  ]}
                  source={rewriteBackendURL(item)}
                />
              );
            } else {
              // slides of ImageSections
              return (
                <ImageSection
                  key={index.toString()}
                  image={item.image}
                  textAlign={item.textAlign}
                  manualColorTheme={item.manualColorTheme}
                  textBackdrop={item.textBackdrop}
                  subTitle={item.subTitle}
                  title={item.title}
                  text={item.text}
                  linkedURL={item.linkedURL}
                  linkedArticle={item.linkedArticle}
                  height={item.height}
                />
              );
            }
          })}
        </Swiper>
      )}
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    image: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  {}
);

export default ImageSlider;
