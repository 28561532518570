/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useCallback, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { Picker } from '@react-native-picker/picker';
import { StackScreenProps } from '@react-navigation/stack';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../hooks/useIsMounted';
import Config from '../constants/Config';
import { t } from '../helpers/localized';
import { fileDetailsTemplate } from '../helpers/sharing';
import {
  Button,
  Switch,
  View,
  useThemeColor,
  ThemeProps
} from '../components/UI/Themed';
import AlertModal from '../components/UI/AlertModal';
import { LabelText, MainText } from '../components/UI/StyledText';
import ContainerView from '../components/UI/ContainerView';
import { FILTER_PERSISTENCE_KEY } from '../components/UI/InputFilter';
import { CART_PERSISTENCE_KEY } from '../store/actions/cart';
import * as settingsActions from '../store/actions/settings';
import { MediaTypeProps, RootStackParamList } from '../types';
import { RootState, persistor } from '../store';

const SettingsScreen = (
  { navigation }: StackScreenProps<RootStackParamList, 'Settings'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const { styles } = useStyles();
  const logging = useSelector((state: RootState) => state.settings.logging);
  const currency = useSelector((state: RootState) => state.settings.currency);
  const availableCurrencies = useSelector(
    (state: RootState) => state.settings.availableCurrencies
  );
  const [isLoggingEnabled, setIsLoggingEnabled] = useState<boolean>(logging);
  const [selectedCurrency, setSelectedCurrency] = useState<string>(currency);
  const spacerColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'border'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const openShareDialogAsync = useCallback(
    async (mediaProp: MediaTypeProps) => {
      try {
        const fileDetails = fileDetailsTemplate(
          mediaProp.meta.fileType,
          mediaProp.meta.extension
        );
        if (!(await Sharing.isAvailableAsync())) {
          // set up modal
          updateState(() =>
            setAlertModalContent(
              <AlertModal
                title={t('titleShareAvailable')}
                message={t('messageShareNotAvailable')}
                onDismiss={() => updateState(() => setAlertModalContent(<></>))}
              />
            )
          );
          return;
        }

        // create temporary copy of file to be able to set destination filename
        if (Platform.OS !== 'web') {
          const fileIdx = mediaProp.uri.lastIndexOf('/');
          const filename = mediaProp.uri.substring(fileIdx + 1);
          const dateString = new Date()
            .toISOString()
            .slice(0, 10)
            .replaceAll('-', '');
          const tempFilename = `${FileSystem.cacheDirectory}${dateString}-${filename}`;
          await FileSystem.copyAsync({
            from: mediaProp.uri,
            to: tempFilename
          });
          // share temporary copy
          await Sharing.shareAsync(tempFilename, fileDetails.shareOptions);
          // delete temporary copy
          await FileSystem.deleteAsync(tempFilename, { idempotent: true });
        } else {
          await Sharing.shareAsync(mediaProp.uri, fileDetails.shareOptions);
        }
      } catch (error) {
        // Expo didn't build with iCloud, expo turtle fallback
        // set up modal
        updateState(() =>
          setAlertModalContent(
            <AlertModal
              title={t('errorOccurred')}
              message={t('messageSharingFileNotFound')}
              onDismiss={() => updateState(() => setAlertModalContent(<></>))}
            />
          )
        );
      }
    },
    [navigation]
  );

  const deleteLogfileHandler = useCallback(async () => {
    if (Platform.OS === 'web') {
      return;
    }

    try {
      const logFileUri: string = `${FileSystem.documentDirectory}/${
        Config().logFile
      }`;
      await FileSystem.deleteAsync(logFileUri, { idempotent: true });
    } catch (error) {
      // Expo didn't build with iCloud, expo turtle fallback
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('errorOccurred')}
            message={error}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    }
  }, [navigation]);

  const onLoggingChangeHandler = useCallback(async () => {
    const previousState = isLoggingEnabled;

    // ask if logfile should be deleted, when logging is turned on
    if (!previousState) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('titleWarning')}
            message={t('messageDeleteLogfile')}
            onOkayPress={deleteLogfileHandler}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    }

    updateState(() => setIsLoggingEnabled(!previousState));
    dispatch(settingsActions.setLogging(!previousState));
  }, [dispatch, isLoggingEnabled, navigation]);

  const onCurrencyChangeHandler = useCallback(
    async (itemValue, itemIndex) => {
      if (selectedCurrency != itemValue) {
        // set up modal
        updateState(() =>
          setAlertModalContent(
            <AlertModal
              title={t('titleWarning')}
              message={t('messageChangeCurrency')}
              onOkayPress={async () => {
                updateState(() => setSelectedCurrency(itemValue));
                // remove currency related items from AsyncStorage
                await AsyncStorage.multiRemove([
                  'cacheKeyPartnerSite',
                  'cacheKeyPartnerSiteImage',
                  'cacheKeyPartnerSiteImageRelation',
                  'cacheKeyProductCategory',
                  'cacheKeyProductCategoryRelation',
                  'cacheKeyProduct',
                  'cacheKeyProductImage',
                  'cacheKeyProductImageRelation',
                  'cacheKeyProductAttribute',
                  'cacheKeyShopCategory',
                  'cacheKeyShopCategoryRelation',
                  'cacheKeyCurrencyRate',
                  FILTER_PERSISTENCE_KEY,
                  CART_PERSISTENCE_KEY
                ]);
                await persistor.purge();
                dispatch(settingsActions.setCurrency(itemValue));
                // forceUpdate()
                isWeb && window.location.reload();
              }}
              onDismiss={() => updateState(() => setAlertModalContent(<></>))}
            />
          )
        );
      }
    },
    [dispatch, selectedCurrency, navigation]
  );

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleSettingsScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleSettingsScreen') });
    }
  }, [navigation]);
  /* #endregion */

  /* #region Renderers */
  return (
    <ContainerView
      scrollable
      seoTitle={`${t('titleSettingsScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <View style={styles('container')}>
        {alertModalContent}
        <View style={styles('settingsContainer')}>
          <MainText strong>{t('titleLogging')}</MainText>
          <View style={styles('spacer')} />
          <View style={styles('justifyRowRegular')}>
            <LabelText>{t('titleEnableLogging')}</LabelText>
            <Switch
              onValueChange={onLoggingChangeHandler}
              value={isLoggingEnabled}
            />
          </View>
          {!isWeb && (
            <View style={styles('justifyRight')}>
              <View style={Platform.OS === 'ios' ? {} : styles('button')}>
                <Button
                  disabled={!isLoggingEnabled}
                  title={t('titleShareLogfile')}
                  onPress={() => {
                    const media: MediaTypeProps = {
                      uri: `${FileSystem.documentDirectory}/${
                        Config().logFile
                      }`,
                      meta: { fileType: 'text/plain', extension: '.log' }
                    };
                    openShareDialogAsync(media, {});
                  }}
                />
              </View>
            </View>
          )}
          <View
            style={[styles('separator'), { borderTopColor: spacerColor }]}
          />

          <MainText strong>{t('titleCache')}</MainText>
          <View style={styles('spacer')} />
          <View style={styles('justifyRowRegular')}>
            <LabelText>{t('titleResetCache')}</LabelText>
            <View style={Platform.OS === 'ios' ? {} : styles('button')}>
              <Button
                title={t('titleReset')}
                onPress={async () => {
                  await AsyncStorage.multiRemove([
                    'cacheKeyPartnerSite',
                    'cacheKeyPartnerSiteImage',
                    'cacheKeyPartnerSiteImageRelation',
                    'cacheKeyProductCategory',
                    'cacheKeyProductCategoryRelation',
                    'cacheKeyProduct',
                    'cacheKeyProductImage',
                    'cacheKeyProductImageRelation',
                    'cacheKeyProductAttribute',
                    'cacheKeyShopCategory',
                    'cacheKeyShopCategoryRelation',
                    'cacheKeyCurrencyRate',
                    FILTER_PERSISTENCE_KEY,
                    CART_PERSISTENCE_KEY,
                    settingsActions.LANGUAGE_PERSISTENCE_KEY,
                    settingsActions.LOGGING_PERSISTENCE_KEY,
                    settingsActions.CURRENCY_PERSISTENCE_KEY,
                    settingsActions.APP_INITIALIZED
                  ]);
                  await persistor.purge();
                }}
              />
            </View>
          </View>
          <View
            style={[styles('separator'), { borderTopColor: spacerColor }]}
          />

          <MainText strong>{t('titleCurrency')}</MainText>
          <View style={styles('spacer')} />
          <View style={styles('justifyRowRegular')}>
            <LabelText>{t('titleSelectCurrency')}</LabelText>
            <View style={Platform.OS === 'ios' ? {} : styles('button')}>
              <Picker
                selectedValue={selectedCurrency}
                onValueChange={onCurrencyChangeHandler}
              >
                {_.sortedUniq<string>(Object.values(availableCurrencies)).map(
                  (item, index) => {
                    return (
                      <Picker.Item
                        key={index.toString()}
                        label={item}
                        value={item}
                      />
                    );
                  }
                )}
              </Picker>
            </View>
          </View>
          <View
            style={[styles('separator'), { borderTopColor: spacerColor }]}
          />
        </View>
      </View>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'stretch',
      justifyContent: 'flex-start'
    },
    settingsContainer: {
      marginTop: 10,
      marginHorizontal: 10
    },
    justifyLeft: {
      alignItems: 'flex-start'
    },
    justifyRowRegular: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginHorizontal: 10
    },
    justifyRight: {
      alignItems: 'flex-end',
      marginRight: 10
    },
    spacer: {
      width: '100%',
      marginTop: 10
    },
    separator: {
      width: '100%',
      borderTopWidth: 1,
      marginVertical: 10
    },
    button: {
      width: 180,
      minWidth: 180
    }
  },
  {
    [DEVICE_SIZES.SMALL_DEVICE]: {
      button: {
        width: 120,
        minWidth: 120
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      button: {
        width: 120,
        minWidth: 120
      }
    }
  }
);

export default SettingsScreen;
