/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { cloneElement, useState } from 'react';
import { CreateResponsiveStyle } from 'rn-responsive-styles';

import useIsMounted from '../../hooks/useIsMounted';
import { Ionicons, ThemeProps, useThemeColor, View } from './Themed';
import { MainText } from './StyledText';
import TouchableComponent from './TouchableComponent';

export type DefaultTabProps = ThemeProps & {
  icon: string;
  title: string;
  isSelected?: boolean;
  containerStyle?: StyleSheet['props'];
  iconStyle?: StyleSheet['props'];
  textStyle?: StyleSheet['props'];
  focusedStyle?: StyleSheet['props'];
  selectedStyle?: StyleSheet['props'];
};

export type TabBarProps = ThemeProps & {
  tabs: Array<JSX.Element>;
  selectedIndex: number;
  onSelect: (index: number) => void;
  containerStyle?: StyleSheet['props'];
  tabStyle?: StyleSheet['props'];
};

export const DefaultTab = (props: DefaultTabProps) => {
  /* #region Fields */
  const {
    isSelected,
    icon,
    title,
    containerStyle,
    iconStyle,
    textStyle,
    focusedStyle,
    selectedStyle,
    lightColor,
    darkColor
  } = props;
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const [useFocusedStyle, setUseFocusedStyle] = useState<boolean>(false);
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'tabIconBackground'
  );
  const activeColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'tabIconSelected'
  );
  const inactiveColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'tabIconDefault'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const focusSetHandler = () => {
    updateState(() => setUseFocusedStyle(true));
  };

  const focusUnsetHandler = () => {
    updateState(() => setUseFocusedStyle(false));
  };
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      onMouseEnter={focusSetHandler}
      onMouseLeave={focusUnsetHandler}
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent'
        },
        styles('tab'),
        useFocusedStyle
          ? focusedStyle
            ? focusedStyle
            : {
                borderColor: activeColor,
                borderTopWidth: 3,
                borderLeftWidth: 3,
                borderRightWidth: 3
              }
          : { borderColor, borderWidth: 2 },
        isSelected
          ? [
              styles('activeTab'),
              {
                backgroundColor,
                borderColor: activeColor
              },
              selectedStyle
            ]
          : [styles('inactiveTab'), { backgroundColor: inactiveColor }],
        containerStyle
      ]}
    >
      <Ionicons
        style={[iconStyle, isSelected ? { color: activeColor } : {}]}
        name={icon}
      />
      <MainText
        style={[
          { paddingLeft: 5 },
          textStyle,
          isSelected ? { color: activeColor } : {}
        ]}
      >
        {title}
      </MainText>
    </View>
  );
  /* #endregion */
};

const TabBar = (props: TabBarProps) => {
  /* #region Fields */
  const {
    tabs,
    selectedIndex,
    onSelect,
    containerStyle,
    tabStyle,
    lightColor,
    darkColor
  } = props;
  const { styles } = useStyles();
  /* #endregion */

  /* #region Renderers */
  // Hint: Passing props to dynamic children using 'cloneElement' --> https://frontarm.com/james-k-nelson/passing-data-props-children/
  return (
    <View style={[styles('container'), containerStyle]}>
      {tabs.map((tab: JSX.Element, index: number) => {
        return (
          <TouchableComponent
            key={index.toString()}
            style={tabStyle}
            onPress={() => onSelect(index)}
          >
            {cloneElement(tab, { isSelected: index === selectedIndex })}
          </TouchableComponent>
        );
      })}
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    tab: {
      padding: 10,
      borderRadius: 5,
      borderWidth: 2
    },
    activeTab: {
      borderBottomWidth: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    },
    inactiveTab: {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  {}
);

export default TabBar;
