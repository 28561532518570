/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';

import { getI18Locale, setI18nInitialConfig } from '../helpers/localized';
import logger, { setInitialLoggingConfig } from '../helpers/logger';
import { decryptString } from '../helpers/crypto-utils';
import store from '../store';
import * as blogActions from '../store/actions/blog';
import * as productsActions from '../store/actions/products';
import * as productattributesActions from '../store/actions/productattributes';
import * as authActions from '../store/actions/auth';
import * as cartActions from '../store/actions/cart';
import * as ordersActions from '../store/actions/orders';
import * as settingsActions from '../store/actions/settings';

const fetchFonts = () => {
  return Font.loadAsync({
    ...Ionicons.font,
    'default-regular': {
      uri: require('../assets/fonts/PlusJakartaSans-Regular.ttf')
    },
    'default-bold': {
      uri: require('../assets/fonts/PlusJakartaSans-Bold.ttf')
    },
    'default-title': {
      uri: require('../assets/fonts/PlusJakartaSans-Bold.ttf')
    }
  });
};

const tryFindStoredUserData = async () => {
  const loadedData = await AsyncStorage.getItem(
    authActions.AUTH_PERSISTENCE_KEY
  );

  // check if data was stored
  if (loadedData) {
    const { userId, guestData, email, expiryDate } = JSON.parse(
      decryptString(loadedData)
    );
    if (userId) {
      store.dispatch(authActions.setDidFindStoredUserData(email));
    } else if (guestData && guestData.email === email) {
      const expirationDate = new Date(expiryDate);

      // check if Now is still < expirationDate
      if (new Date() <= expirationDate) {
        store.dispatch(authActions.authenticateGuest(guestData));
      } else {
        store.dispatch(await authActions.logout());
      }
    }
  } else {
    store.dispatch(cartActions.setDidVerifyAddress(false));
  }
};

const doInitialize = async () => {
  return Promise.all([fetchFonts(), setI18nInitialConfig()]).then(() => {
    Promise.all([
      // initialize the redux store - always start with initializing the language first!
      store.dispatch(settingsActions.setLanguage(getI18Locale()))
    ]).then(() => {
      // after the language was fetched, resume with fetching the settings
      Promise.all([
        store.dispatch(settingsActions.fetchConfiguration()),
        tryFindStoredUserData()
      ]).then(() => {
        // after the settings were fetched, resume with filling the redux store
        Promise.all([
          store.dispatch(settingsActions.fetchCacheKey()),
          store.dispatch(productsActions.fetchCacheKey()),
          store.dispatch(productattributesActions.fetchCacheKey()),
          store.dispatch(blogActions.fetchCategories()),
          store.dispatch(blogActions.fetchArticles()),
          store.dispatch(blogActions.fetchNews()),
          store.dispatch(cartActions.fetchCartItemsFromLocalStore()),
          store.dispatch(ordersActions.fetchOrders())
        ]);
      });
    });
  });
};

const useCachedResources = (): boolean => {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      try {
        SplashScreen.preventAutoHideAsync();

        // Set initial logging
        await setInitialLoggingConfig();

        // Load fonts
        logger.log(
          'hooks.useCachedResources - loadResourcesAndDataAsync: Start init...'
        );
        await doInitialize();
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        logger.log(
          `hooks.useCachedResources - loadResourcesAndDataAsync: ${e}`
        );
      } finally {
        logger.log(
          'hooks.useCachedResources - loadResourcesAndDataAsync: Init done.'
        );

        await AsyncStorage.setItem(
          settingsActions.APP_INITIALIZED,
          JSON.stringify(true)
        );

        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    };

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
};

export default useCachedResources;
