/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

// Flag images can be downloaded from https://www.iconfinder.com/iconsets/flags_gosquared

import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { useThemeColor, View } from '../UI/Themed';
import TouchableComponent from '../UI/TouchableComponent';

const Flag = (props): JSX.Element => {
  /* #region Fields */
  const { style, uri, onPress, lightColor, darkColor } = props;
  const headerBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerBackground'
  );
  /* #endregion */

  /* #region Renderers */
  return onPress ? (
    <TouchableComponent
      style={[
        styles.imageContainer,
        { backgroundColor: headerBackgroundColor },
        style
      ]}
      onPress={onPress}
    >
      <Image source={uri} style={styles.image} />
    </TouchableComponent>
  ) : (
    <View style={[styles.imageContainer, style]}>
      <Image source={uri} style={styles.image} />
    </View>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  imageContainer: {
    height: 32,
    padding: 5
  },
  image: {
    width: 32,
    height: '100%',
    borderWidth: 1
  }
});

export default Flag;
