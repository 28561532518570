/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ComposeProviders from './components/ComposeProviders';
import store, { persistor } from './store';
import Navigation from './navigation';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Loading from './components/UI/Loading';

const App = (): JSX.Element => {
  /* #region Fields */
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  /* #endregion */

  if (!isLoadingComplete) {
    return <Loading bare />;
  }

  return (
    <ComposeProviders
      components={[[ReduxProvider, { store: store }], [SafeAreaProvider]]}
    >
      <PersistGate loading={<Loading bare />} persistor={persistor}>
        <Navigation colorScheme={colorScheme} />
      </PersistGate>
    </ComposeProviders>
  );
};

export default App;
