/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { ColorValue } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { useNavigationState } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { t } from '../../helpers/localized';
import {
  View,
  Text,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import { RootState } from '../../store';

export type OrderProgressProps = ThemeProps & {
  labelColor: ColorValue;
};

const OrderProgress = (props: OrderProgressProps): JSX.Element => {
  /* #region Fields */
  const { labelColor, lightColor, darkColor } = props;
  const isCartDone = useSelector(
    (state: RootState) => state.cart.didVerifyOrder
  );
  const isAddressDone = useSelector(
    (state: RootState) => state.cart.didVerifyAddress
  );
  const isFinished = useSelector(
    (state: RootState) => state.cart.didFinishOrder
  );
  const navigationState = useNavigationState((state) => state);
  const { styles } = useStyles();
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const progressCheckedColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'progressChecked'
  );
  const progressUncheckedColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'progressUnchecked'
  );
  /* #endregion */

  return (
    <View style={styles('container')}>
      <View style={styles('progressItem')}>
        <View
          style={[
            styles('circle'),
            {
              backgroundColor: labelColor,
              borderColor:
                labelColor !== ('#fff' || '#ffffff')
                  ? labelColor
                  : progressUncheckedColor
            }
          ]}
        >
          <Ionicons
            name="checkmark"
            color={isCartDone ? progressCheckedColor : progressUncheckedColor}
          />
        </View>
        <Text
          style={[
            styles('text'),
            {
              fontFamily:
                navigationState.routes[0].name === 'Cart'
                  ? 'default-bold'
                  : 'default-regular'
            }
          ]}
        >
          {t('titleCart')}
        </Text>
      </View>
      <View
        style={[styles('horizontalLine'), { borderBottomColor: borderColor }]}
      ></View>
      <View style={styles('progressItem')}>
        <View
          style={[
            styles('circle'),
            {
              backgroundColor: labelColor,
              borderColor:
                labelColor !== ('#fff' || '#ffffff')
                  ? labelColor
                  : progressUncheckedColor
            }
          ]}
        >
          <Ionicons
            name="checkmark"
            color={
              isAddressDone ? progressCheckedColor : progressUncheckedColor
            }
          />
        </View>
        <Text
          style={[
            styles('text'),
            {
              fontFamily:
                navigationState.routes[0].name === 'FinishOrder' ||
                navigationState.routes[0].name === 'GuestProfile'
                  ? 'default-bold'
                  : 'default-regular'
            }
          ]}
        >
          {t('titleAddressData')}
        </Text>
      </View>
      <View
        style={[styles('horizontalLine'), { borderBottomColor: borderColor }]}
      ></View>
      <View style={styles('progressItem')}>
        <View
          style={[
            styles('circle'),
            {
              backgroundColor: labelColor,
              borderColor:
                labelColor !== ('#fff' || '#ffffff')
                  ? labelColor
                  : progressUncheckedColor
            }
          ]}
        >
          <Ionicons
            name="checkmark"
            color={isFinished ? progressCheckedColor : progressUncheckedColor}
          />
        </View>
        <Text
          style={[
            styles('text'),
            {
              fontFamily:
                navigationState.routes[0].name === 'OrderSummary'
                  ? 'default-bold'
                  : 'default-regular'
            }
          ]}
        >
          {t('titleFinish')}
        </Text>
      </View>
    </View>
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20
    },
    circle: {
      height: 30,
      width: 30,
      marginHorizontal: 5,
      borderRadius: 30,
      borderWidth: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },
    progressItem: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    horizontalLine: {
      width: 100,
      marginHorizontal: 5,
      borderBottomWidth: 1
    },
    text: {
      marginHorizontal: 5
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      horizontalLine: {
        width: 50
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      progressItem: {
        flexDirection: 'column'
      },
      horizontalLine: {
        width: 35
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      progressItem: {
        flexDirection: 'column'
      },
      horizontalLine: {
        width: 35
      }
    }
  }
);

export default OrderProgress;
