/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import Config from '../constants/Config';

export const rewriteBackendURL = (url: any) => {
  if (!url) {
    return null;
  }

  if (url.startsWith('/static') || url.startsWith('/media')) {
    return Config().domain_prefix_backend + url;
  } else if (url.startsWith('//')) {
    return 'https:' + url;
  } else if (url.startsWith('www')) {
    return 'https://' + url;
  } else {
    return url;
  }
};
