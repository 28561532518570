/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import memoize from 'lodash.memoize';

import Config from '../constants/Config';
import { t } from './localized';
import logger from './logger';
import store from '../store';
import * as authActions from '../store/actions/auth';
import * as notificationActions from '../store/actions/notification';

const callAPI = async (url, options) => {
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      if (response.status === 400) {
        const errResData = await response.json();
        const errMessage = errResData.non_field_errors;
        let message = response.statusText;
        if (errMessage) {
          message = errMessage;
        }

        logger.log(
          `helpers.api-utils - callAPI: No active account with this credentials.`
        );

        store.dispatch(
          notificationActions.setMessage(t('errorOccurred'), message)
        );

        return [];
      } else if (response.status === 401) {
        logger.log(
          `helpers.api-utils - callAPI: Access token expired. Trying to fetch refresh token.`
        );
        await store.dispatch(authActions.refreshToken());
        return response.clone();
      } else {
        const errResData = await response.json();
        const errMessage = errResData.non_field_errors;
        let message = response.statusText;
        if (errMessage) {
          message = errMessage;
        }

        logger.log(
          `helpers.api-utils - callAPI: Cannot call API! Server responded with error code ${response.status}.`
        );

        store.dispatch(
          notificationActions.setMessage(
            t('errorOccurred'),
            `Cannot call API! Server responded with error code ${response.status} and message: ${message}.`
          )
        );

        return [];
      }
    } else {
      const resData = await response.json();
      return resData;
    }
  } catch (err) {
    logger.log(
      `helpers.api-utils - callAPI: Cannot call API! Server responded with error - ${err}.`
    );
    store.dispatch(
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot call API! Server responded with error - ${err}.`
      )
    );
    return [];
  }
};

export const singleCallAPI = async (method, url, headers, body = null) => {
  let options;
  if (body) {
    options = {
      method,
      headers,
      body: JSON.stringify(body)
    };
  } else {
    options = {
      method,
      headers
    };
  }

  const resData = await callAPI(url, options);
  return resData;
};

export const recursiveCallAPI = async (method, url, headers, body = null) => {
  let options;
  if (body) {
    options = {
      method,
      headers,
      body: JSON.stringify(body)
    };
  } else {
    options = {
      method,
      headers
    };
  }

  const resData = await callAPI(url, options);
  const results = resData.results;

  if (resData.next) {
    logger.log(
      `helpers.api-utils - recursiveCallAPI: Getting partial results from API response. Next url is ${resData.next}`
    );
    return results.concat(
      await recursiveCallAPI(method, resData.next, headers)
    );
  } else {
    logger.log(
      `helpers.api-utils - recursiveCallAPI: Getting final results. No next url in API response.`
    );
    return results;
  }
};

// Use translations from backend
export const tAPI = memoize(async (key, config = null, fallback = false) => {
  const language = store.getState().settings.language;
  const resData = await singleCallAPI(
    'GET',
    config
      ? `${Config().api_url}/translation/?msgid=${key}&config=${JSON.stringify(
          config
        )}`
      : `${Config().api_url}/translation/?msgid=${key}`,
    {
      'Content-Type': 'application/json',
      'Accept-Language': language
    }
  );

  if (resData.translation) {
    return resData.translation;
  } else if (fallback) {
    return t(key, config);
  } else {
    return key;
  }
});
