/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import CryptoES from 'crypto-es';

import Config from '../constants/Config';

export const encryptString = (input: string) => {
  return CryptoES.AES.encrypt(input, Config().cryptoSecret).toString();
};

export const decryptString = (input: string) => {
  const decrypted = CryptoES.AES.decrypt(input, Config().cryptoSecret);
  return decrypted.toString(CryptoES.enc.Utf8);
};
