/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { createTransform } from 'redux-persist';

import { encryptString, decryptString } from '../helpers/crypto-utils';

const CryptTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    return encryptString(JSON.stringify(inboundState));
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // convert mySet back to a Set.
    return JSON.parse(decryptString(outboundState));
  }
  // define which reducers this transform gets called for.
  // { whitelist: ['rootReducer'] }
);

export default CryptTransform;
