/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { forwardRef } from 'react';
import {
  Button as DefaultButton,
  Text as DefaultText,
  TextInput as DefaultTextInput,
  View as DefaultView,
  Switch as DefaultSwitch,
  ScrollView as DefaultScrollView,
  Platform,
  ColorValue
} from 'react-native';
import { Ionicons as DefaultIonicons } from '@expo/vector-icons';
import {
  Modalize as DefaultModalize,
  ModalizeProps
} from 'react-native-modalize';
import { Portal } from 'react-native-portalize';

import Colors from '../../constants/Colors';
import useColorScheme from '../../hooks/useColorScheme';
import useFocusStyle from '../../hooks/useFocusStyle';

export type ThemeProps = {
  lightColor?: ColorValue;
  darkColor?: ColorValue;
};

export type ButtonProps = ThemeProps & DefaultButton['props'];
export type IoniconsProps = ThemeProps & {
  name: string;
  color?: ColorValue;
  style?: StyleSheet['props'];
  size?: number;
  children?: JSX.Element;
};
export type TextProps = ThemeProps &
  Text['props'] & {
    hoverable?: boolean;
    small?: boolean;
    strong?: boolean;
  };
export type ViewProps = ThemeProps & DefaultView['props'];
export type SwitchProps = ThemeProps & DefaultSwitch['props'];
export type ScrollViewProps = ThemeProps & DefaultScrollView['props'];
export type ModalizeViewProps = ThemeProps & ModalizeProps;

export type TextInputProps = ThemeProps &
  DefaultTextInput['props'] & {
    id: string;
    ref?: React.MutableRefObject<any>;
    initialValue?: string;
    initiallyValid: boolean;
    controlStyle?: StyleSheet['props'];
    label?: string;
    placeholder?: string;
    required?: boolean;
    errorText: string;
    email?: boolean;
    min?: number;
    max?: number;
    minLength?: number;
    phonenumber?: boolean;
    decimal?: number;
    onInputChange: (
      inputIdentifier: string,
      inputValue: unknown,
      inputValidity: boolean
    ) => void;
  };

export type TextInputState = {
  value: string;
  isValid: boolean;
  touched: boolean;
};

export type TextInputAction =
  | {
      type: 'INPUT_CHANGE';
      value: string;
      isValid: boolean;
      touched?: boolean;
    }
  | {
      type: 'INPUT_BLUR';
      required?: boolean;
    };

export const useThemeColor = (
  props: { light?: ColorValue; dark?: ColorValue },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
): ColorValue => {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  }
  return Colors[theme][colorName] as ColorValue;
};

export const Button = (props: ButtonProps): JSX.Element => {
  /* #region Fields */
  const { lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'button');
  /* #endregion */

  return <DefaultButton color={color} {...otherProps} />;
};

// Full list of icons, see https://icons.expo.fyi
export const Ionicons = (props: IoniconsProps): JSX.Element => {
  /* #region Fields */
  const { lightColor, darkColor, name, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  /* #endregion */

  return (
    <DefaultIonicons
      color={color}
      name={Platform.select({
        ios: `ios-${name}`,
        default: `md-${name}`
      })}
      size={Platform.select({
        ios: 30,
        default: 24
      })}
      {...otherProps}
    />
  );
};

export const Text = (props: TextProps): JSX.Element => {
  /* #region Fields */
  const { style, hoverable, lightColor, darkColor, ...otherProps } = props;
  const textColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'text'
  );
  const hoverColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'hover'
  );

  const [linkStyle, startTrigger, endTrigger] = useFocusStyle({
    style: [{ color: textColor }, style],
    focusedStyle: [style, { color: hoverColor }]
  });
  /* #endregion */

  return (
    <DefaultText
      style={linkStyle}
      onMouseEnter={hoverable ? startTrigger : null}
      onMouseLeave={hoverable ? endTrigger : null}
      {...otherProps}
    />
  );
};

export const TextInput = forwardRef(
  (props: TextInputProps, ref): JSX.Element => {
    /* #region Fields */
    const { style, lightColor, darkColor, ...otherProps } = props;
    const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
    /* #endregion */

    return (
      <DefaultTextInput ref={ref} style={[{ color }, style]} {...otherProps} />
    );
  }
);

export const View = (props: ViewProps): JSX.Element => {
  /* #region Fields */
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  /* #endregion */

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
};

export const Switch = (props: SwitchProps): JSX.Element => {
  /* #region Fields */
  const { lightColor, darkColor, value, ...otherProps } = props;
  const activeTrackColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'switchActive'
  );
  const inactiveTrackColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'switchInactive'
  );
  const activeThumbColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'switchThumbActive'
  );
  const inactiveThumbColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'switchThumbInactive'
  );
  const iOSBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'switchInactive'
  );
  /* #endregion */

  return (
    <DefaultSwitch
      trackColor={{ true: activeTrackColor, false: inactiveTrackColor }}
      thumbColor={value ? activeThumbColor : inactiveThumbColor}
      ios_backgroundColor={iOSBackgroundColor}
      value={value}
      {...otherProps}
    />
  );
};

export const ScrollView = (props: ScrollViewProps): JSX.Element => {
  /* #region Fields */
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  /* #endregion */

  return (
    <DefaultScrollView style={[{ backgroundColor }, style]} {...otherProps} />
  );
};

export const Modalize = forwardRef(
  (props: ModalizeViewProps, ref): JSX.Element => {
    /* #region Fields */
    const { modalStyle, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor(
      { light: lightColor, dark: darkColor },
      'background'
    );
    /* #endregion */

    return (
      <Portal style={{ zIndex: 500 }}>
        <DefaultModalize
          ref={ref}
          modalStyle={[{ backgroundColor }, modalStyle]}
          {...otherProps}
        />
      </Portal>
    );
  }
);
