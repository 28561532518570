/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FlatList, Platform, Dimensions } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import {
  View,
  Button,
  useThemeColor,
  ThemeProps
} from '../../components/UI/Themed';
import { MainText } from '../../components/UI/StyledText';
import Loading from '../../components/UI/Loading';
import CategoryItem from '../../components/blog/CategoryItem';
import * as blogActions from '../../store/actions/blog';
import { categoriesOnFeed } from '../../store/reselect';
import { BlogStackParamList } from '../../types';

const ArticleCategoriesScreen = (
  { navigation }: StackScreenProps<BlogStackParamList, 'ArticleCategories'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const { width } = Dimensions.get('window');
  const containerRef = useRef<ContainerViewProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const categories = useSelector(categoriesOnFeed());
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const loadCategories = useCallback(async () => {
    updateState(() => setError(''));
    updateState(() => setIsLoading(true));
    try {
      dispatch(blogActions.fetchCategories());
    } catch (err) {
      updateState(() => setError(err.message));
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, setError]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleArticleCategoriesScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleArticleCategoriesScreen') });
    }

    // refetch all categories when drawer navigation page is loaded (default for drawer is using a cached page)
    const unsubscribe = navigation.addListener('focus', loadCategories);

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  const selectItemHandler = (slug: string) => {
    navigation.push('Shop', {
      screen: 'Blog',
      params: {
        screen: 'Articles',
        params: {
          categorySlug: slug
        }
      }
    });
  };
  /* #endregion */

  /* #region Renderers */
  if (error) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleArticleCategoriesScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      >
        <View style={styles('centered')}>
          <MainText>
            {t('errorOccurred')} {error}
          </MainText>
          <Button
            title={t('tryAgain')}
            color={buttonColor}
            onPress={loadCategories}
          />
        </View>
      </ContainerView>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && categories.length === 0) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleArticleCategoriesScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      >
        <View style={styles('centered')}>
          <MainText>{t('noCategories')}</MainText>
        </View>
      </ContainerView>
    );
  }

  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      seoTitle={`${t('titleArticleCategoriesScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'index,follow' }]}
    >
      <FlatList
        scrollEnabled={!containerRef.current?.scrollable}
        contentContainerStyle={styles('container')}
        onRefresh={loadCategories}
        refreshing={isLoading}
        data={categories}
        extraData={{ width }} // using for responsive re-rendering of FlatList content
        keyExtractor={(item, index) => index.toString()}
        removeClippedSubviews={true}
        renderItem={(itemData) => (
          <CategoryItem
            style={styles('category')}
            date={itemData.item.date}
            name={itemData.item.name}
            articles={itemData.item.articles}
            onPress={() => {
              selectItemHandler(itemData.item.slug);
            }}
          />
        )}
      />
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      marginVertical: 20
    },
    category: {
      marginBottom: 10,
      marginHorizontal: '8%'
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      category: {
        marginHorizontal: '4%'
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      container: {
        marginVertical: 0
      },
      category: {
        marginHorizontal: 0
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      container: {
        marginVertical: 0
      },
      category: {
        marginHorizontal: 0
      }
    }
  }
);

export default ArticleCategoriesScreen;
