/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { getStateFromPath, getPathFromState } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { translationGetters } from '../helpers/localized';

// Linking.createURL is available as of expo@40.0.1 and expo-linking@2.0.1. If
// you are using older versions, you can upgrade or use Linking.makeUrl instead,
// but note that your deep links in standalone apps will be in the format
// scheme:/// rather than scheme:// if you use makeUrl.
export const prefix = Linking.createURL('/');

export default {
  prefixes: [prefix],
  config: {
    screens: {
      Shop: {
        // ShopDrawerNavigator screens
        path: '',
        screens: {
          News: {
            // NewsStackNavigator screens
            initialRouteName: 'News',
            screens: {
              News: {
                path: 'news'
              }
            }
          },
          Blog: {
            // BlogStackNavigator screens
            initialRouteName: 'Articles',
            screens: {
              Articles: {
                path: 'blog/articles/:categorySlug?',
                parse: {
                  categorySlug: (categorySlug: string): string =>
                    decodeURIComponent(categorySlug)
                },
                stringify: {
                  categorySlug: (categorySlug: string): string => categorySlug
                }
              },
              Article: {
                path: 'blog/article/:articleSlug',
                parse: {
                  articleSlug: (articleSlug: string): string =>
                    decodeURIComponent(articleSlug)
                },
                stringify: {
                  articleSlug: (articleSlug: string): string => articleSlug
                }
              },
              ArticleCategories: {
                path: 'blog/categories'
              }
            }
          },
          Products: {
            // ProductsStackNavigator screens
            initialRouteName: 'ShopCategories',
            screens: {
              ShopAuth: {
                path: 'shop/auth'
              },
              ShopCategories: {
                path: 'shop/categories',
                exact: true
              },
              ProductCategories: {
                path: 'shop/categories/:shopCategorySlug',
                exact: true,
                parse: {
                  shopCategorySlug: (shopCategorySlug: string): string =>
                    decodeURIComponent(shopCategorySlug)
                },
                stringify: {
                  shopCategorySlug: (shopCategorySlug: string): string =>
                    shopCategorySlug
                }
              },
              ProductsOverview: {
                path: 'shop/categories/:shopCategorySlug/:productCategorySlug',
                parse: {
                  shopCategorySlug: (shopCategorySlug: string): string =>
                    decodeURIComponent(shopCategorySlug),
                  productCategorySlug: (productCategorySlug: string): string =>
                    decodeURIComponent(productCategorySlug)
                },
                stringify: {
                  shopCategorySlug: (shopCategorySlug: string): string =>
                    shopCategorySlug,
                  productCategorySlug: (productCategorySlug: string): string =>
                    productCategorySlug
                }
              },
              ProductDetail: {
                path: 'shop/products/:productSlug:itemKey?',
                parse: {
                  productSlug: (productSlug: string): string =>
                    decodeURIComponent(productSlug),
                  itemKey: (itemKey: string): string =>
                    decodeURIComponent(itemKey)
                },
                stringify: {
                  productSlug: (productSlug: string): string => productSlug,
                  itemKey: (itemKey: string): string => itemKey
                }
              },
              Cart: {
                path: 'shop/cart'
              },
              GuestProfile: {
                path: 'shop/guestprofile/:guestId?'
              },
              FinishOrder: {
                path: 'shop/finishorder'
              },
              OrderSummary: {
                path: 'shop/ordersummary'
              }
            }
          },
          Rentals: {
            // RentalsDrawerNavigator screens
            initialRouteName: 'RentalsOverview',
            screens: {
              RentalsOverview: {
                path: 'rentals',
                exact: true
              },
              RentalDetail: {
                path: 'rentals/products/:productSlug:partnerSiteSlug?:itemKey?',
                parse: {
                  productSlug: (productSlug: string): string =>
                    decodeURIComponent(productSlug),
                  partnerSiteSlug: (partnerSiteSlug: string): string =>
                    decodeURIComponent(partnerSiteSlug),
                  itemKey: (itemKey: string): string =>
                    decodeURIComponent(itemKey)
                },
                stringify: {
                  productSlug: (productSlug: string): string => productSlug,
                  partnerSiteSlug: (partnerSiteSlug: string): string =>
                    partnerSiteSlug,
                  itemKey: (itemKey: string): string => itemKey
                }
              }
            }
          },
          Orders: {
            // OrdersStackNavigator screens
            initialRouteName: 'Orders',
            screens: {
              Orders: {
                path: 'orders'
              }
            }
          },
          Admin: {
            // AdminStackNavigator screens
            initialRouteName: 'UserProduct',
            screens: {
              UserProduct: {
                path: 'user/products',
                exact: true
              },
              EditProduct: {
                path: 'user/products/:productId?'
              }
            }
          },
          Settings: {
            // SettingsStackNavigator screens
            initialRouteName: 'Settings',
            screens: {
              Settings: {
                path: 'settings'
              }
            }
          }
        }
      },
      Auth: {
        // AuthStackNavigator screens
        initialRouteName: 'UserAuth',
        screens: {
          UserAuth: {
            path: 'auth',
            exact: true
          },
          UserProfile: {
            path: 'auth/profile/:userId?'
          }
        }
      },
      NotFound: '*'
    }
  },
  getStateFromPath: (path, options) => {
    // Return a state object here
    // You can also reuse the default logic by importing `getStateFromPath` from `@react-navigation/native`
    
    // check for switching language and trim language part from url, to retrieve correct state from path
    const urlParts = path.split('/');
    if (urlParts && Object.keys(translationGetters).includes(urlParts[1])) {
      const langPart = `/${urlParts[1]}`;
      path = path.slice(langPart.length);
    }
    const newState = getStateFromPath(path, options);

    return newState;
  },
  getPathFromState(state, config) {
    // Return a path string here
    // You can also reuse the default logic by importing `getPathFromState` from `@react-navigation/native`
    const newPath = getPathFromState(state, config);

    return newPath;
  }
};
