/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { useTransition, animated } from 'react-spring';

import { t } from '../../helpers/localized';
import { View, ThemeProps, useThemeColor } from '../UI/Themed';
import { TitleText } from '../UI/StyledText';
import Logo from '../web/Logo';

type TransitionLogoProps = ThemeProps & {
  containerStyle?: StyleSheet['props'];
  toggleItem: boolean;
};

const LOGO_SIZE: Record<DEVICE_SIZES, number> = {
  extra_large: 100,
  large: 100,
  medium: 80,
  small: 60,
  extra_small: 60
};

const TransitionLogo = (props: TransitionLogoProps) => {
  /* #region Fields */
  const { styles, deviceSize } = useStyles();
  const { containerStyle, toggleItem, lightColor, darkColor } = props;
  const transitions = useTransition(toggleItem, {
    from: animatedStyles.toggleItemAnimationFrom,
    enter: animatedStyles.toggleItemAnimationEnter,
    leave: animatedStyles.toggleItemAnimationLeave
  });

  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const logoBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'logoBackground'
  );
  /* #endregion */

  /* #region Renderers */
  return (
    <>
      {transitions((props, item, key) =>
        item ? (
          <animated.div
            style={StyleSheet.flatten([
              styles('animatedContainer'),
              { ...props }
            ])}
            key={key}
          >
            <Logo
              containerStyle={[
                styles('logoContainer'),
                { borderColor: primaryColor },
                containerStyle
              ]}
              size={LOGO_SIZE[deviceSize]}
            />
            <View style={styles('textContainer')}>
              <TitleText
                style={[
                  styles('title'),
                  {
                    textShadow: `0 0 0.2em ${logoBackgroundColor}, 0 0 0.2em ${logoBackgroundColor}, 0 0 0.2em ${logoBackgroundColor}, 0 0 0.2em ${logoBackgroundColor}, 0 0 0.2em ${logoBackgroundColor}, 0 0 0.2em ${logoBackgroundColor}, 0 0 0.2em ${logoBackgroundColor}`
                  }
                ]}
                strong
              >
                {t('appTitle')}
              </TitleText>
            </View>
          </animated.div>
        ) : (
          <animated.div style={props} key={key}>
            <TitleText
              style={[styles('title'), { color: primaryColor }]}
              strong
            >
              {t('appTitle')}
            </TitleText>
          </animated.div>
        )
      )}
    </>
  );
  /* #endregion */
};

const animatedStyles = StyleSheet.flatten({
  toggleItemAnimationFrom: {
    position: 'absolute',
    opacity: 0
  },
  toggleItemAnimationEnter: {
    opacity: 1
  },
  toggleItemAnimationLeave: {
    opacity: 0
  }
});

const useStyles = CreateResponsiveStyle(
  {
    animatedContainer: {
      alignItems: 'center'
    },
    logoContainer: {
      borderWidth: 6,
      borderRadius: 50
    },
    textContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    title: {
      fontSize: 32
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      logoContainer: {
        borderWidth: 3
      },
      title: {
        fontSize: 24
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      logoContainer: {
        borderWidth: 3
      },
      title: {
        fontSize: 20
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      logoContainer: {
        borderWidth: 3
      },
      title: {
        fontSize: 20
      }
    }
  }
);

export default TransitionLogo;
