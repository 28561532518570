/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { AnyAction } from 'redux';

import News from '../../models/News';
import Article from '../../models/Article';
import Category from '../../models/Category';
import {
  SET_ARTICLES,
  HIT_ARTICLE,
  SET_CATEGORIES,
  SET_NEWS
} from '../actions/blog';

interface BlogState {
  availableArticles: Array<Article>;
  availableCategories: Array<Category>;
  availableNews: Array<News>;
}

const initialState: BlogState = {
  availableArticles: [],
  availableCategories: [],
  availableNews: []
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_ARTICLES:
      return {
        ...state,
        availableArticles: action.articles
      };

    case HIT_ARTICLE:
      const articleIndex = state.availableArticles.findIndex(
        (article: Article) => article.id === action.article.id
      );
      const updatedArticle = new Article(
        action.article.id,
        action.article.title,
        action.article.body,
        action.article.teaser,
        action.article.meta_keywords,
        action.article.tags,
        action.article.category,
        action.article.slug,
        action.article.date,
        action.article.author,
        action.article.is_archived,
        action.article.is_published,
        action.article.is_internal,
        action.article.is_onnewsfeed
      );
      const updatedAvailableArticles = [...state.availableArticles];
      updatedAvailableArticles[articleIndex] = updatedArticle;
      return {
        ...state,
        availableArticles: updatedAvailableArticles
      };

    case SET_CATEGORIES:
      return {
        ...state,
        availableCategories: action.categories
      };

    case SET_NEWS:
      return {
        ...state,
        availableNews: action.news
      };
  }
  return state;
};
