/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useMemo } from 'react';
import { LogBox } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import uuid from 'react-native-uuid';
import Swiper from 'react-native-web-swiper';

import useIsMounted from '../../hooks/useIsMounted';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';
import { View, useThemeColor, ThemeProps } from './Themed';
import ImageLoader from '../UI/ImageLoader';

if (LogBox) {
  LogBox.ignoreLogs(['Failed prop type:']);
}

export type ImageCarouselProps = ThemeProps & {
  style: StyleSheet['props'];
  images: Array<string>;
};

const ImageCarousel = (props: ImageCarouselProps): JSX.Element => {
  /* #region Fields */
  const isMounted = useIsMounted();
  const { styles } = useStyles();
  const { style, images, lightColor, darkColor } = props;
  const [containerDimension, setContainerDimension] = useState({
    width: 1,
    height: 1
  });
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  const imageCarouselAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'imageCarouselAccent'
  );
  const imageCarouselDotActiveColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'imageCarouselDotActive'
  );
  const imageCarouselDotInactiveColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'imageCarouselDotInactive'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[
        style,
        {
          backgroundColor: 'transparent'
        }
      ]}
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        const aspectRatioBasedHeight = (3 / 4) * width; // Aspect ration 4 / 3
        updateState(() =>
          setContainerDimension({
            width: width - 40,
            height: aspectRatioBasedHeight
          })
        );
      }}
    >
      {(!images || images?.length === 0) && (
        <ImageLoader
          style={[
            styles('image'),
            {
              backgroundColor,
              height: containerDimension.height
            }
          ]}
          resizeMode="contain"
          source={require('../../assets/images/no-image-available.png')}
        />
      )}
      {images?.length > 0 && (
        <View style={{ height: containerDimension.height }}>
          <Swiper
            key={useMemo(() => uuid.v4(), [images])}
            springConfig={{
              stiffness: 30
            }}
            minDistanceForAction={0.15}
            controlsProps={{
              dotsTouchable: true,
              dotsPos: 'bottom',
              prevPos: false,
              nextPos: false,
              cellsStyle: {
                bottom: {
                  width: containerDimension.width + 40
                }
              },
              dotsWrapperStyle: {
                position: 'absolute',
                left: 0,
                bottom: -20,
                width: containerDimension.width + 40,
                backgroundColor: imageCarouselAccentColor
              },
              dotActiveStyle: { backgroundColor: imageCarouselDotActiveColor }
            }}
          >
            {images.map((item, index) => {
              return (
                <ImageLoader
                  key={index.toString()}
                  style={[
                    styles('image'),
                    {
                      height: containerDimension.height
                    }
                  ]}
                  resizeMode="contain"
                  source={rewriteBackendURL(item)}
                />
              );
            })}
          </Swiper>
        </View>
      )}
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    image: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  {}
);

export default ImageCarousel;
