/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { InputFormAction, InputFormState } from '../types';

const FORM_INPUT_UPDATE = 'FORM_INPUT_UPDATE';

export const formReducer = (
  state: InputFormState,
  action: InputFormAction
): InputFormState => {
  if (action.type === FORM_INPUT_UPDATE) {
    const updatedValues: Array<unknown> = {
      ...state.inputValues,
      [action.input]: action.value?.trim()
    };
    const updatedValidities: Array<boolean> = {
      ...state.inputValidities,
      [action.input]: action.isValid
    };
    let updatedFormIsValid = true;
    for (const key in updatedValidities) {
      // false overrides true values
      updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
    }
    return {
      inputValues: updatedValues,
      inputValidities: updatedValidities,
      formIsValid: updatedFormIsValid
    };
  }
  return state;
};

export const getFormState = (
  inputIdentifier: string,
  inputValue: unknown,
  inputValidity: boolean
): InputFormAction => {
  return {
    type: FORM_INPUT_UPDATE,
    value: inputValue,
    isValid: inputValidity,
    input: inputIdentifier
  };
};
