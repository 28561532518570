/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { AnyAction } from 'redux';

import Config from '../../constants/Config';
import { defaultLocale, defaultCurrency } from '../../helpers/localized';
import {
  SET_LOCALIZATION,
  SET_CURRENCY,
  SET_CURRENCY_RATES,
  SET_CONFIGURATION,
  SET_LOGGING,
  SET_COLLAPSED_HEADER
} from '../actions/settings';
import { ProductAttributeValueTypes } from '../../types';

interface SettingsState {
  language: string | null;
  currency: string | null;
  availableCurrencies: Array<string>;
  currencyRates: Array<string>;
  logging: boolean;
  collapsedHeader: boolean;
  productAttributeValueTypes: Array<ProductAttributeValueTypes>;
  screens: Array<string>;
  thousandSeparator: boolean;
  salesTax: number | null;
}

const initialState: SettingsState = {
  language: defaultLocale,
  currency: defaultCurrency,
  availableCurrencies: [],
  currencyRates: [],
  logging: Config().isLoggingEnabled,
  collapsedHeader: false,
  productAttributeValueTypes: [],
  screens: [],
  thousandSeparator: Config().useThousandSeparator,
  salesTax: null
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_LOCALIZATION:
      return {
        ...state,
        language: action.locale
      };

    case SET_CURRENCY:
      return {
        ...state,
        currency: action.currency
      };

    case SET_CURRENCY_RATES:
      return {
        ...state,
        currencyRates: action.currencies
      };

    case SET_CONFIGURATION:
      return {
        ...state,
        logging: action.logging,
        availableCurrencies: action.currencies,
        productAttributeValueTypes: action.valuetypes,
        screens: action.screens,
        thousandSeparator: action.thousandSeparator,
        salesTax: action.salesTax
      };

    case SET_LOGGING:
      return {
        ...state,
        logging: action.logging
      };

    case SET_COLLAPSED_HEADER:
      return {
        ...state,
        collapsedHeader: action.isCollapsed
      };
  }
  return state;
};
