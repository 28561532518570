/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import Config from '../constants/Config';
import { LOGGING_PERSISTENCE_KEY } from '../store/actions/settings';
import { GroupLogFn } from '../types';

let isLoggingEnabled = Config().isLoggingEnabled;
const noop = () => {};
const displayLogs = (
  name: string,
  title: string,
  logs: Array<Array<unknown>>,
  write: boolean
) => {
  if (isLoggingEnabled || !write) {
    return;
  }

  if (console.groupCollapsed) {
    console.groupCollapsed(`${name}: ${title}`);
  }

  for (let i = 0; i < logs.length; i += 1) {
    console.log(...logs[i]);
  }

  if (console.groupEnd) {
    console.groupEnd();
  }
};

let filters: Array<string> = [];

export const setInitialLoggingConfig = async (): Promise<void> => {
  const loadedIsLoggingEnabled = (await AsyncStorage.getAllKeys()).includes(
    LOGGING_PERSISTENCE_KEY
  );

  // override isLoggingEnabled from AsyncStorage
  if (loadedIsLoggingEnabled) {
    isLoggingEnabled =
      (await AsyncStorage.getItem(LOGGING_PERSISTENCE_KEY)) === 'true'
        ? true
        : false;
  }
  await AsyncStorage.setItem(
    LOGGING_PERSISTENCE_KEY,
    JSON.stringify(isLoggingEnabled)
  );
};

const logger = {
  setFilters: (...names: Array<string>): void => {
    filters = names;
  },
  log: (...args: Array<unknown>): void => {
    if (isLoggingEnabled) {
      console.log(...args);
    }
  },
  group: (
    name: string,
    title: string,
    logFn: (log: GroupLogFn) => void | Promise<unknown> = noop
  ): void => {
    const write = filters.length === 0 || filters.indexOf(name) !== -1;

    const logs: Array<Array<unknown>> = [];
    const done = logFn((...args: Array<unknown>): void => {
      if (isLoggingEnabled && write) {
        logs.push(args);
      }
    });

    if (done instanceof Promise) {
      done
        .then(() => {
          displayLogs(name, title, logs, isLoggingEnabled && write);
        })
        .catch(() => {
          displayLogs(name, title, logs, isLoggingEnabled && write);
        });
    } else {
      displayLogs(name, title, logs, isLoggingEnabled && write);
    }
  }
};

export default logger;
