/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dimensions, FlatList, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import Category from '../../models/Category';
import { parseLocaleNumber, t } from '../../helpers/localized';
import { truncateString } from '../../helpers/filter';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import {
  View,
  Button,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText, MainText } from '../../components/UI/StyledText';
import TouchableComponent from '../../components/UI/TouchableComponent';
import SectionHeader from '../../components/UI/SectionHeader';
import ShopItem from '../../components/shop/ShopItem';
import ShopItemPlaceholder from '../../components/animated/ShopItemPlaceholder';
import { allProducts } from '../../store/reselect';
import * as productsActions from '../../store/actions/products';
import { RootState } from '../../store';
import { ShopStackParamList } from '../../types';

const ProductCategoriesScreen = (
  {
    route,
    navigation
  }: StackScreenProps<ShopStackParamList, 'ProductCategories'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const { styles, deviceSize } = useStyles();
  const { width, height } = Dimensions.get('window');
  const isLandscape = width > height;
  const [containerDimension, setContainerDimension] = useState({
    width: 1,
    height: 1
  });
  const containerRef = useRef<ContainerViewProps>();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const shopCategorySlug = route.params?.shopCategorySlug;
  const shopCategory = useSelector((state: RootState) => {
    if (shopCategorySlug) {
      return state.products.availableShopCategories.find(
        (cat: Category) => cat.slug === shopCategorySlug
      );
    }

    return null;
  });
  const categories = useSelector((state: RootState) => {
    if (shopCategory) {
      const availableProductCategories =
        state.products.availableProductCategories.filter((cat: Category) =>
          shopCategory.categoryId.includes(cat.id)
        );

      const result: Array<Category> = [];
      shopCategory.categoryId.forEach((id: number) => {
        result.push(
          availableProductCategories.find((cat: Category) => cat.id === id)
        );
      });

      // filter possible null values
      return result.filter((obj) => obj);
    }

    return state.products.availableProductCategories;
  });
  const fetchedProductsFromAPI = useSelector(
    (state: RootState) => state.products.fetchedFromAPI
  );
  const products = useSelector(allProducts());
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const [columns, setColumns] = useState<number>(3);
  const [skeletons, setSkeletons] = useState([{}, {}, {}]);
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    updateState(() => setIsLoading(!fetchedProductsFromAPI));
  }, [categories, fetchedProductsFromAPI]);

  const loadCategories = useCallback(async () => {
    updateState(() => setError(''));
    updateState(() => setIsLoading(true));
    try {
      dispatch(productsActions.fetchCategories());
    } catch (err) {
      updateState(() => setError(err.message));
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, setError]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    // dynamically set FlatList columns for different device sizes
    if (deviceSize === DEVICE_SIZES.EXTRA_LARGE_DEVICE) {
      setColumns(4);
      setSkeletons([{}, {}, {}, {}]);
    } else if (deviceSize === DEVICE_SIZES.LARGE_DEVICE) {
      setColumns(3);
      setSkeletons([{}, {}, {}]);
    } else if (
      [DEVICE_SIZES.SMALL_DEVICE, DEVICE_SIZES.MEDIUM_DEVICE].includes(
        deviceSize
      )
    ) {
      setColumns(2);
      setSkeletons([{}, {}]);
    } else {
      setColumns(1);
      setSkeletons([{}]);
    }
  }, [isMounted, width, isLandscape]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${shopCategory?.title} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: truncateString(shopCategory?.title, 22) });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'Cart'
                }
              });
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, route, shopCategory, cartItemCount]);

  const selectItemHandler = useCallback(
    (shopCategorySlug: string, productCategorySlug: string) => {
      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'ProductsOverview',
          params: {
            shopCategorySlug,
            productCategorySlug
          }
        }
      });
    },
    [navigation]
  );
  /* #endregion */

  /* #region Renderers */
  if (error) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${shopCategory?.title} | ${t('appTitle')}`}
        seoDescription={shopCategory?.description?.replace(/(<([^>]+)>)/gi, '')}
        seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      >
        <View style={styles('centered')}>
          <MainText>
            {t('errorOccurred')} {error}
          </MainText>
          <Button
            title={t('tryAgain')}
            color={buttonColor}
            onPress={loadCategories}
          />
        </View>
      </ContainerView>
    );
  }

  if (isLoading) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${shopCategory?.title} | ${t('appTitle')}`}
        seoDescription={shopCategory?.description?.replace(/(<([^>]+)>)/gi, '')}
        seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      >
        <SectionHeader
          screenName="ProductCategoriesScreen"
          param={shopCategorySlug}
          position="top"
        />
        <FlatList
          scrollEnabled={!containerRef.current?.scrollable}
          contentContainerStyle={styles('container')}
          data={skeletons}
          extraData={{ columns, width }} // using for responsive re-rendering of FlatList content
          numColumns={columns}
          keyExtractor={(item, index) => index.toString()}
          key={columns.toString()} // changing key is needed, for dynamically changing numColumns attribute
          removeClippedSubviews={true}
          renderItem={() => (
            <ShopItemPlaceholder
              style={
                isWeb
                  ? {
                      // substract fixed margin of product item
                      width: width / columns - 40
                    }
                  : {
                      // Styling has to be done here, to use dynamic column constant.
                      // Substract fixed margin of product item, when in landscape mode
                      width: isLandscape
                        ? containerDimension.width / columns - 20
                        : containerDimension.width / columns
                    }
              }
            />
          )}
        />
        <SectionHeader
          screenName="ProductCategoriesScreen"
          param={shopCategorySlug}
          position="bottom"
        />
      </ContainerView>
    );
  }

  // if (!isLoading && categories.length === 0) {
  //   return (
  //     <ContainerView
  //       scrollable
  //       seoTitle={`${shopCategory?.title} | ${t('appTitle')}`}
  //       seoDescription={shopCategory?.description?.replace(/(<([^>]+)>)/gi, '')}
  //       seoMeta={[{ name: 'robots', content: 'index,follow' }]}
  //     >
  //       <SectionHeader
  //         screenName="ProductCategoriesScreen"
  //         param={shopCategorySlug}
  //         position="top"
  //       />
  //       <View style={styles('centered')}>
  //         <MainText>{t('noCategories')}</MainText>
  //       </View>
  //       <SectionHeader
  //         screenName="ProductCategoriesScreen"
  //         param={shopCategorySlug}
  //         position="bottom"
  //       />
  //     </ContainerView>
  //   );
  // }

  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      seoTitle={`${shopCategory?.title} | ${t('appTitle')}`}
      seoDescription={shopCategory?.description?.replace(/(<([^>]+)>)/gi, '')}
      seoMeta={[{ name: 'robots', content: 'index,follow' }]}
      onLayout={(event) => {
        const { width, height } = event.nativeEvent.layout;
        updateState(() =>
          setContainerDimension({
            width: width - 40,
            height
          })
        );
      }}
    >
      <SectionHeader
        screenName="ProductCategoriesScreen"
        param={shopCategorySlug}
        position="top"
      />
      <FlatList
        scrollEnabled={!containerRef.current?.scrollable}
        contentContainerStyle={styles('container')}
        onRefresh={loadCategories}
        refreshing={isLoading}
        data={categories}
        extraData={{ columns, width }} // using for responsive re-rendering of FlatList content
        numColumns={columns}
        keyExtractor={(item, index) => index.toString()}
        key={columns.toString()} // changing key is needed, for dynamically changing numColumns attribute
        removeClippedSubviews={true}
        renderItem={(itemData) => {
          // calculate the minimum price of the category
          let minPrice = null;
          if (products.length > 0) {
            const query = _.minBy(
              products.filter((prod) => prod.categoryId === itemData.item.id),
              function (o) {
                return parseLocaleNumber(o.price);
              }
            );
            if (query) {
              minPrice = query.price;
            }
          }

          // render category item
          return (
            <ShopItem
              style={
                isWeb
                  ? {
                      // substract fixed margin of product item
                      width: width / columns - 40
                    }
                  : {
                      // Styling has to be done here, to use dynamic column constant.
                      // Substract fixed margin of product item, when in landscape mode
                      width: isLandscape
                        ? containerDimension.width / columns - 20
                        : containerDimension.width / columns
                    }
              }
              image={itemData.item.imageUrl}
              title={itemData.item.title}
              price={minPrice}
              onPress={() => {
                selectItemHandler(shopCategorySlug, itemData.item.slug);
              }}
            />
          );
        }}
      />
      <SectionHeader
        screenName="ProductCategoriesScreen"
        param={shopCategorySlug}
        position="bottom"
      />
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      alignItems: 'stretch',
      marginBottom: 20
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    }
  },
  {}
);

export default ProductCategoriesScreen;
