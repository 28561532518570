/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { AnyAction } from 'redux';
import _ from 'lodash';

import { productAttributePriceFilter } from '../../helpers/filter';
import { IProductAttribute, ProductAttributeJsonValueType } from '../../types';
import {
  SET_PRODUCTATTRIBUTES,
  UPDATE_PRODUCTATTRIBUTES
} from '../actions/productattributes';

interface ProductAttributesState {
  availableProductAttributes: Array<IProductAttribute>;
  selectedProductAttributes: Array<IProductAttribute>;
}

const initialState: ProductAttributesState = {
  availableProductAttributes: [],
  selectedProductAttributes: []
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_PRODUCTATTRIBUTES:
      return {
        availableProductAttributes: action.availableProductAttributes,
        selectedProductAttributes: action.selectedProductAttributes
      };
    case UPDATE_PRODUCTATTRIBUTES: {
      // reference original items
      let updatedSelectedProductAttributes: Array<IProductAttribute> = [
        ...state.selectedProductAttributes
      ];

      if (!action.multiSelect) {
        // get original product attributes for object to modify
        const availableProductAttribute: IProductAttribute =
          action.availableConfigItems.find(
            (attr) => attr.id === action.selectedConfigItem.id
          );

        // make deep copy, so that changes won't affect original items
        const updatedProductAttribute: IProductAttribute = _.cloneDeep(
          action.selectedAttributes
        );

        if (availableProductAttribute && updatedProductAttribute) {
          const updateIndex = updatedSelectedProductAttributes.findIndex(
            (attr) =>
              attr.attributeId === updatedProductAttribute.attributeId &&
              attr.productId === action.productId
          );
          const json_field = availableProductAttribute.json;
          // set json of object to modify with json from original object with searched label
          updatedProductAttribute.json = {
            value: json_field.value[action.selectedIndex],
            type: json_field.type,
            selectedIndex: action.selectedIndex
          };

          // update possible new attributeKey from json_field
          updatedProductAttribute.attributeKey = json_field.attributeKey
            ? json_field.attributeKey
            : '';

          // update possible input field values
          const original_input_field = updatedProductAttribute.json.value.input;
          const request_input_field =
            action.selectedAttributes.json.value.input;
          if (original_input_field && request_input_field) {
            if (
              original_input_field.lastInput !== request_input_field.lastInput
            ) {
              // update lastInput
              updatedProductAttribute.json.value.input.lastInput =
                request_input_field.lastInput;
            }
          }

          updatedSelectedProductAttributes[updateIndex] =
            updatedProductAttribute;
        }
      } else {
        // get original product attributes for object to modify
        const availableProductAttribute: IProductAttribute =
          action.availableConfigItems.find(
            (attr) => attr.id === action.selectedConfigItem.id
          );

        let updatedProductAttribute: IProductAttribute | undefined;
        if (action.selectedAttributes) {
          updatedProductAttribute = updatedSelectedProductAttributes.find(
            (attr: IProductAttribute) =>
              _.map(action.selectedAttributes, 'id').includes(attr.id) &&
              attr.json.value.label === action.new_name
          );
        }

        if (updatedProductAttribute) {
          if (
            !action.selectedIndex.includes(
              updatedProductAttribute.json.selectedIndex
            )
          ) {
            // remove updatedProductAttribute from array, if it isn't in the selectedIndex anymore
            updatedSelectedProductAttributes =
              updatedSelectedProductAttributes.filter(
                (attr) => attr.id !== updatedProductAttribute.id
              );
          } else {
            // update possible input field values
            const original_input_field =
              updatedProductAttribute.json.value.input;
            const request_input_field = action.selectedAttributes.find(
              (attr) => attr.id === updatedProductAttribute.id
            )?.json.value.input;
            if (original_input_field && request_input_field) {
              if (
                original_input_field.lastInput !== request_input_field.lastInput
              ) {
                // update lastInput
                updatedProductAttribute.json.value.input.lastInput =
                  request_input_field.lastInput;
              }
            }
          }
        } else {
          // make deep copy, so that changes won't affect original items
          const updatedProductAttribute = _.cloneDeep(
            availableProductAttribute
          );
          const json_field = updatedProductAttribute.json;

          // get last id from array
          const lastId = _.get(
            _.maxBy(updatedSelectedProductAttributes, 'id'),
            'id',
            updatedSelectedProductAttributes.length
          );

          // rewrite the id, productId and add attributeId
          updatedProductAttribute.attributeId = updatedProductAttribute.id;
          updatedProductAttribute.id = lastId + 1;
          updatedProductAttribute.productId = action.productId;
          // add possible attributeKey from json_field
          updatedProductAttribute.attributeKey = json_field.attributeKey
            ? json_field.attributeKey
            : '';

          // set json of object to modify with json from original object with searched label
          updatedProductAttribute.json = {
            value: json_field.value.find(
              (item: ProductAttributeJsonValueType) =>
                item.label === action.new_name
            ),
            type: json_field.type,
            selectedIndex: json_field.value.findIndex(
              (item: ProductAttributeJsonValueType) =>
                item.label === action.new_name
            )
          };

          // Add possible multiSelect from json_field
          // Defaults to "false", because we don't have pre-selected values when using "multiSelect"
          updatedProductAttribute.multiSelect = !!json_field.multiSelect;
          updatedSelectedProductAttributes.push(updatedProductAttribute);
        }
      }

      // update price tags
      updatedSelectedProductAttributes = productAttributePriceFilter(
        action.productAttributeValueTypes,
        updatedSelectedProductAttributes
      );

      return {
        ...state,
        selectedProductAttributes: updatedSelectedProductAttributes
      };
    }
  }
  return state;
};
