/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

class CartItem {
  /* #region Properties */
  quantity: number;

  productTitle: string;

  productAttributes: string;

  productPrice: number;

  sum: number;
  /* #endregion */
  constructor(
    quantity: number,
    productTitle: string,
    productAttributes: string,
    productPrice: number,
    sum: number
  ) {
    this.quantity = quantity;
    this.productTitle = productTitle;
    this.productAttributes = productAttributes;
    this.productPrice = productPrice;
    this.sum = sum;
  }
}

export default CartItem;
