/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';

import { View, useThemeColor } from './Themed';
import ContainerView from './ContainerView';

const Loading = (props): JSX.Element => {
  /* #region Fields */
  const { bare, lightColor, darkColor } = props;
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  /* #endregion */

  /* #region Renderers */
  return bare ? (
    <View style={styles.centered}>
      <ActivityIndicator size="large" color={primaryColor} />
    </View>
  ) : (
    <ContainerView>
      <View style={styles.centered}>
        <ActivityIndicator size="large" color={primaryColor} />
      </View>
    </ContainerView>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default Loading;
