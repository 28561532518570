/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { Animated } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

import { getDefaultHeaderHeight } from '../../helpers/screen-data';
import { ThemeProps, View } from '../UI/Themed';
import BigHeaderTile from '../animated/BigHeaderTile';
import { RootState } from '../../store';
import Toolbar from '../UI/Toolbar';

export const HEADER_HEIGHT = 250;

export type BigHeaderProps = ThemeProps & {
  animatedOffset?: typeof Animated.Value;
};

const BigHeader = React.memo((props: BigHeaderProps) => {
  /* #region Fields */
  const { animatedOffset } = props;
  const { styles } = useStyles();
  const collapsedHeader: boolean = useSelector(
    (state: RootState) => state.settings.collapsedHeader
  );
  const insets = useSafeAreaInsets();

  const headerHeight = animatedOffset?.interpolate({
    inputRange: [0, HEADER_HEIGHT + insets.top],
    outputRange: [
      HEADER_HEIGHT + insets.top,
      insets.top + getDefaultHeaderHeight()
    ],
    extrapolate: 'clamp'
  });
  /* #endregion */

  /* #region Renderers */
  const innerHeaderComponent = (
    <View
      style={[
        styles('header'),
        collapsedHeader ? { paddingTop: 0 } : styles('headerPadding')
      ]}
    >
      <View style={styles('headerLeft')}>
        <BigHeaderTile position="left" />
      </View>
      <View style={styles('headerCenter')}>
        <BigHeaderTile position="center" />
      </View>
      <View style={styles('headerRight')}>
        <BigHeaderTile position="right" />
      </View>
    </View>
  );

  return (
    <Animated.View style={[styles('container'), { height: headerHeight }]}>
      {innerHeaderComponent}
      <View
        style={[
          styles('toolbar'),
          {
            height: getDefaultHeaderHeight()
          }
        ]}
      >
        <Toolbar {...props} />
      </View>
    </Animated.View>
  );
  /* #endregion */
});

const useStyles = CreateResponsiveStyle(
  {
    container: {},
    header: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    headerPadding: {
      paddingTop: 15
    },
    headerLeft: {
      flex: 1,
      height: 140,
      overflow: 'hidden',
      marginTop: 0
    },
    headerCenter: {
      flex: 2,
      flexWrap: 'wrap',
      height: 140,
      overflow: 'hidden',
      marginTop: 0
    },
    headerRight: {
      flex: 1,
      height: 140,
      overflow: 'hidden',
      marginTop: 0
    },
    toolbar: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      header: {
        flexDirection: 'column'
      },
      headerPadding: {
        paddingTop: 0
      },
      headerLeft: {
        height: 70,
        marginTop: 4
      },
      headerCenter: {
        flex: 1,
        height: 70,
        marginTop: 4
      },
      headerRight: {
        height: 70,
        marginTop: 4
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      header: {
        flexDirection: 'column'
      },
      headerPadding: {
        paddingTop: 0
      },
      headerLeft: {
        height: 70,
        marginTop: 2
      },
      headerCenter: {
        flex: 1,
        height: 70,
        marginTop: 2
      },
      headerRight: {
        height: 70,
        marginTop: 2
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      header: {
        flexDirection: 'column'
      },
      headerPadding: {
        paddingTop: 0
      },
      headerLeft: {
        height: 70,
        marginTop: 2
      },
      headerCenter: {
        flex: 1,
        height: 70,
        marginTop: 2
      },
      headerRight: {
        height: 70,
        marginTop: 2
      }
    }
  }
);

export default BigHeader;
