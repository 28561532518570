/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import { LogBox } from 'react-native';

import { t } from '../../helpers/localized';
import Config from '../../constants/Config';
import { singleCallAPI } from '../../helpers/api-utils';
import logger from '../../helpers/logger';
import { encryptString, decryptString } from '../../helpers/crypto-utils';
import * as notificationActions from './notification';

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const LOGOUT = 'LOGOUT';
export const UPDATE_LOGOUT_TIMER = 'UPDATE_LOGOUT_TIMER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_DID_TRY_AL = 'SET_DID_TRY_AL';
export const SET_DID_FIND_STORED_USERDATA = 'SET_DID_FIND_STORED_USERDATA';
export const CREATE_GUEST = 'CREATE_GUEST';
export const UPDATE_GUEST = 'UPDATE_GUEST';
export const AUTHENTICATE_GUEST = 'AUTHENTICATE_GUEST';
export const AUTH_PERSISTENCE_KEY = 'AUTH_STATE';

if (LogBox) {
  LogBox.ignoreLogs(['Require cycle:']);
}

let timer: ReturnType<typeof setTimeout>;

export const setDidTryAL = (value: boolean) => {
  return async (dispatch) => {
    dispatch({ type: SET_DID_TRY_AL, value });
  };
};

export const setDidFindStoredUserData = (email: string) => {
  return async (dispatch) => {
    dispatch({ type: SET_DID_FIND_STORED_USERDATA, email });
  };
};

export const authenticateUser = (
  userId,
  accessToken,
  refreshToken,
  expiryTime,
  userData
) => {
  return async (dispatch, getState) => {
    try {
      // userData should be null, if login() is used
      if (!userData) {
        const language = getState().settings.language;
        const resData = await singleCallAPI(
          'GET',
          `${Config().api_url}/auth/users/${userId}/`,
          {
            'Content-Type': 'application/json',
            'Accept-Language': language,
            Authorization: `Token ${accessToken}`
          }
        );
        // console.log(resData);

        userData = {
          company_name: resData.company_name,
          title: resData.title,
          firstName: resData.firstName,
          lastName: resData.lastName,
          email: resData.email,
          mobile: resData.mobile,
          phone: resData.phone,
          address: resData.address,
          zipcode: resData.zipcode,
          location: resData.location,
          country: resData.country,
          admin: resData.admin
        };
      }

      dispatch(setLogoutTimer(expiryTime));
      dispatch({
        type: AUTHENTICATE_USER,
        userId,
        accessToken,
        refreshToken,
        userData
      });

      const expirationDate = new Date(new Date().getTime() + expiryTime);
      await saveDataToStorage({
        accessToken,
        refreshToken,
        userId,
        email: userData.email,
        expiryTime,
        expiryDate: expirationDate
      });
    } catch (err) {
      logger.log(
        `store.actions.auth - authenticate: Cannot authenticate! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot authenticate! Server responded with error - ${err}.`
      );
    }
  };
};

export const authenticateGuest = (profileData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTHENTICATE_GUEST, profileData });

      const expiryTime = 15 * 60 * 1000; // default: 15min
      const expirationDate = new Date(new Date().getTime() + expiryTime);
      await saveDataToStorage({
        guestData: profileData,
        email: profileData.email,
        expiryTime,
        expiryDate: expirationDate
      });
    } catch (err) {
      logger.log(
        `store.actions.auth - authenticateGuest: Cannot authenticate! Error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot authenticate! Error - ${err}.`
      );
    }
  };
};

export const signUp = (
  email,
  password,
  company_name = null,
  title = null,
  firstName = null,
  lastName = null,
  mobile = null,
  phone = null,
  address = null,
  zipcode = null,
  location = null,
  country = null
) => {
  return async (dispatch, getState) => {
    try {
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'POST',
        `${Config().api_url}/auth/users/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        },
        {
          email,
          password,
          company_name,
          title,
          firstName,
          lastName,
          mobile,
          phone,
          address,
          zipcode,
          location,
          country,
          admin: false
        }
      );
      // console.log(resData);

      if (resData.active) {
        dispatch(
          authenticateUser(
            resData.id,
            resData.access,
            resData.refresh,
            parseInt(resData.access_expires_in) * 1000,
            {
              company_name: resData.company_name,
              title: resData.title,
              firstName: resData.firstName,
              lastName: resData.lastName,
              email: resData.email,
              mobile: resData.mobile,
              phone: resData.phone,
              address: resData.address,
              zipcode: resData.zipcode,
              location: resData.location,
              country: resData.country,
              admin: false
            }
          )
        );
      }
    } catch (err) {
      logger.log(
        `store.actions.auth - signUp: Cannot sign up! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot sign up! Server responded with error - ${err}.`
      );
    }
  };
};

export const updateUserProfile = (
  userId,
  email,
  password,
  company_name,
  title,
  firstName,
  lastName,
  mobile,
  phone,
  address,
  zipcode,
  location,
  country
) => {
  return async (dispatch, getState) => {
    try {
      const accessToken = getState().auth.accessToken;
      const language = getState().settings.language;
      let body;
      if (password) {
        body = {
          email,
          password,
          company_name,
          title,
          firstName,
          lastName,
          mobile,
          phone,
          address,
          zipcode,
          location,
          country
        };
      } else {
        body = {
          company_name,
          title,
          firstName,
          lastName,
          mobile,
          phone,
          address,
          zipcode,
          location,
          country
        };
      }

      const resData = await singleCallAPI(
        'PATCH',
        `${Config().api_url}/auth/users/${userId}/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        },
        body
      );

      if (resData?.id) {
        dispatch({
          type: UPDATE_USER,
          userData: {
            userId: resData.id,
            company_name: resData.company_name,
            title: resData.title,
            firstName: resData.firstName,
            lastName: resData.lastName,
            email: resData.email,
            mobile: resData.mobile,
            phone: resData.phone,
            address: resData.address,
            zipcode: resData.zipcode,
            location: resData.location,
            country: resData.country
          }
        });
      }
    } catch (err) {
      logger.log(
        `store.actions.auth - updateUserProfile: Cannot update user profile! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot update user profile! Server responded with error - ${err}.`
      );
    }
  };
};

export const createGuestProfile = (
  company_name,
  title,
  firstName,
  lastName,
  email,
  mobile,
  phone,
  address,
  zipcode,
  location,
  country
) => {
  return async (dispatch, getState) => {
    try {
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'POST',
        `${Config().api_url}/auth/guests/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        },
        {
          company_name,
          title,
          firstName,
          lastName,
          email,
          mobile,
          phone,
          address,
          zipcode,
          location,
          country
        }
      );
      // console.log(resData);

      if (resData?.id) {
        dispatch({
          type: CREATE_GUEST,
          guestData: {
            guestId: resData.id,
            company_name,
            title,
            firstName,
            lastName,
            email,
            mobile,
            phone,
            address,
            zipcode,
            location,
            country
          }
        });
        dispatch(
          authenticateGuest({
            guestId: resData.id,
            company_name,
            title,
            firstName,
            lastName,
            email,
            mobile,
            phone,
            address,
            zipcode,
            location,
            country
          })
        );
      }
    } catch (err) {
      logger.log(
        `store.actions.auth - createGuestProfile: Cannot create guest profile! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot create guest profile! Server responded with error - ${err}.`
      );
    }
  };
};

export const updateGuestProfile = (
  guestId,
  company_name,
  title,
  firstName,
  lastName,
  email,
  mobile,
  phone,
  address,
  zipcode,
  location,
  country
) => {
  return async (dispatch, getState) => {
    try {
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'PATCH',
        `${Config().api_url}/auth/guests/${guestId}/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        },
        {
          company_name: company_name,
          title,
          firstName,
          lastName,
          email,
          mobile,
          phone,
          address,
          zipcode,
          location,
          country
        }
      );
      // console.log(resData);

      if (resData?.id) {
        dispatch({
          type: UPDATE_GUEST,
          guestData: {
            guestId: resData.id,
            company_name: resData.company_name,
            title: resData.title,
            firstName: resData.firstName,
            lastName: resData.lastName,
            email: resData.email,
            mobile: resData.mobile,
            phone: resData.phone,
            address: resData.address,
            zipcode: resData.zipcode,
            location: resData.location,
            country: resData.country
          }
        });
        dispatch(
          authenticateGuest({
            guestId: resData.id,
            company_name: resData.company_name,
            title: resData.title,
            firstName: resData.firstName,
            lastName: resData.lastName,
            email: resData.email,
            mobile: resData.mobile,
            phone: resData.phone,
            address: resData.address,
            zipcode: resData.zipcode,
            location: resData.location,
            country: resData.country
          })
        );
      }
    } catch (err) {
      logger.log(
        `store.actions.auth - updateGuestProfile: Cannot update guest profile! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot update guest profile! Server responded with error - ${err}.`
      );
    }
  };
};

export const login = (email, password) => {
  return async (dispatch, getState) => {
    try {
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'POST',
        `${Config().api_url}/auth/obtain-token/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        },
        {
          email,
          password
        }
      );
      // console.log(resData);

      if (resData?.user_id) {
        dispatch(
          authenticateUser(
            resData.user_id,
            resData.access,
            resData.refresh,
            parseInt(resData.access_expires_in) * 1000,
            null
          )
        );
      }
    } catch (err) {
      logger.log(
        `store.actions.auth - login: Cannot log in! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot log in! Server responded with error - ${err}.`
      );
    }
  };
};

export const refreshToken = () => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().auth.userId;
      const refreshToken = getState().auth.refreshToken;
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'POST',
        `${Config().api_url}/auth/refresh-token/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        },
        {
          refresh: refreshToken
        }
      );
      // console.log(resData);

      dispatch(
        authenticateUser(
          userId,
          resData.access,
          refreshToken,
          parseInt(resData.access_expires_in) * 1000,
          null
        )
      );
    } catch (err) {
      logger.log(
        `store.actions.auth - refreshToken: Cannot refresh token! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot refresh token! Server responded with error - ${err}.`
      );
    }
  };
};

export const logout = async () => {
  clearLogoutTimer();
  await AsyncStorage.removeItem(AUTH_PERSISTENCE_KEY);

  // don't wait for promise of AsyncStorage.removeItem and logout
  return { type: LOGOUT };
};

export const updateLogoutTimer = () => {
  return async (dispatch) => {
    const loadedData = await AsyncStorage.getItem(AUTH_PERSISTENCE_KEY);

    // check if data was stored
    if (loadedData) {
      const {
        accessToken,
        refreshToken,
        userId,
        guestData,
        email,
        expiryTime
      } = JSON.parse(decryptString(loadedData));

      if (expiryTime) {
        const expirationDate = new Date(new Date().getTime() + expiryTime);

        await saveDataToStorage({
          accessToken,
          refreshToken,
          userId,
          guestData,
          email,
          expiryTime,
          expiryDate: expirationDate
        });
        dispatch(setLogoutTimer(expiryTime));
      }
    }
  };
};

const clearLogoutTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

const setLogoutTimer = (expirationTime) => {
  clearLogoutTimer();
  return (dispatch) => {
    timer = setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  };
};

const saveDataToStorage = async (authData) => {
  const {
    accessToken,
    refreshToken,
    userId,
    guestData,
    email,
    expiryTime,
    expiryDate
  } = authData;

  // get stored auth data
  const loadedData = await AsyncStorage.getItem(AUTH_PERSISTENCE_KEY);

  let updatedAccessToken = accessToken !== undefined ? accessToken : undefined;
  let updatedRefreshToken =
    refreshToken !== undefined ? refreshToken : undefined;
  let updatedUserId = userId !== undefined ? userId : undefined;
  let updatedGuestData = guestData !== undefined ? guestData : undefined;
  let updatedEmail = email !== undefined ? email : undefined;
  let updatedExpiryTime = expiryTime !== undefined ? expiryTime : undefined;
  let updatedExpiryDate = expiryDate !== undefined ? expiryDate : undefined;

  if (loadedData) {
    const {
      accessToken,
      refreshToken,
      userId,
      guestData,
      email,
      expiryTime,
      expiryDate
    } = JSON.parse(decryptString(loadedData));

    if (updatedAccessToken === undefined) updatedAccessToken = accessToken;
    if (updatedRefreshToken === undefined) updatedRefreshToken = refreshToken;
    if (updatedUserId === undefined) updatedUserId = userId;
    if (updatedGuestData === undefined) updatedGuestData = guestData;
    if (updatedEmail === undefined) updatedEmail = email;
    if (updatedExpiryTime === undefined) updatedExpiryTime = expiryTime;
    if (updatedExpiryDate === undefined) updatedExpiryDate = expiryDate;
  }

  await AsyncStorage.setItem(
    AUTH_PERSISTENCE_KEY,
    encryptString(
      JSON.stringify({
        accessToken: updatedAccessToken,
        refreshToken: updatedRefreshToken,
        userId: updatedUserId,
        guestData: updatedGuestData,
        email: updatedEmail,
        expiryTime: updatedExpiryTime,
        expiryDate: updatedExpiryDate
          ? updatedExpiryDate.toISOString()
          : undefined
      })
    )
  );
};
