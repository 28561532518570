/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *
 *  Credits goes to https://www.joshwcomeau.com/react/boop/
 *-------------------------------------------------------------*/

import React from 'react';
import { Platform } from 'react-native';
import { animated } from 'react-spring';

import useBoop, { BoopHookProps } from '../../hooks/useBoop';

export type BoopProps = BoopHookProps & {
  children: JSX.Element;
};

const Boop = (props: BoopProps) => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const { children, ...boopConfig } = props;
  /* #endregion */

  /* #region Renderers */
  if (isWeb) {
    const [style, startTrigger, endTrigger] = useBoop(boopConfig);

    return (
      <animated.div
        onMouseEnter={startTrigger}
        onMouseLeave={endTrigger}
        style={style}
      >
        {children}
      </animated.div>
    );
  }

  return <>{children}</>;
  /* #endregion */
};

export default Boop;
