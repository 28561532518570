/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { AnyAction } from 'redux';

import Order from '../../models/Order';
import { SET_ORDERS, ADD_ORDER } from '../actions/orders';

interface OrderState {
  orders: Array<Order>;
}

const initialState: OrderState = {
  orders: []
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        orders: action.orders
      };

    case ADD_ORDER:
      const newOrder = new Order(
        action.orderData.id,
        action.orderData.nr,
        action.orderData.items,
        action.orderData.salesTax,
        action.orderData.amount,
        action.orderData.currency,
        action.orderData.date,
        action.orderData.note
      );
      return {
        ...state,
        orders: state.orders.concat(newOrder)
      };
  }

  return state;
};
