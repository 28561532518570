/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { AnyAction } from 'redux';

import UserProfile from '../../models/UserProfile';
import {
  AUTHENTICATE_USER,
  AUTHENTICATE_GUEST,
  LOGOUT,
  UPDATE_USER,
  SET_DID_TRY_AL,
  SET_DID_FIND_STORED_USERDATA,
  CREATE_GUEST,
  UPDATE_GUEST
} from '../actions/auth';

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  userId: number | null;
  guestId: number | null;
  activeUserProfile: UserProfile | null;
  email: string | null;
  didTryAutoLogin: boolean;
  didFindStoredUserData: boolean;
  isAdmin: boolean;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  userId: null,
  guestId: null,
  activeUserProfile: null,
  email: null,
  didTryAutoLogin: false,
  didFindStoredUserData: false,
  isAdmin: false
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      const newUserProfile = new UserProfile(
        action.userData.company_name,
        action.userData.title,
        action.userData.firstName,
        action.userData.lastName,
        action.userData.email,
        action.userData.mobile,
        action.userData.phone,
        action.userData.address,
        action.userData.zipcode,
        action.userData.location,
        action.userData.country,
        action.userData.admin
      );
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        userId: action.userId,
        email: action.userData.email,
        activeUserProfile: newUserProfile,
        didTryAutoLogin: true,
        didFindStoredUserData: true,
        isAdmin: action.userData.admin
      };

    case AUTHENTICATE_GUEST:
      const activeUserProfile = new UserProfile(
        action.profileData.company_name,
        action.profileData.title,
        action.profileData.firstName,
        action.profileData.lastName,
        action.profileData.email,
        action.profileData.mobile,
        action.profileData.phone,
        action.profileData.address,
        action.profileData.zipcode,
        action.profileData.location,
        action.profileData.country,
        false
      );
      return {
        ...state,
        guestId: action.profileData.guestId,
        email: action.profileData.email,
        activeUserProfile
      };

    case LOGOUT:
      return initialState;

    case UPDATE_USER:
      const editedUserProfile = new UserProfile(
        action.userData.company_name,
        action.userData.title,
        action.userData.firstName,
        action.userData.lastName,
        action.userData.email,
        action.userData.mobile,
        action.userData.phone,
        action.userData.address,
        action.userData.zipcode,
        action.userData.location,
        action.userData.country,
        action.userData.admin
      );
      return {
        ...state,
        email: action.userData.email,
        activeUserProfile: editedUserProfile
      };

    case SET_DID_TRY_AL:
      return {
        ...state,
        didTryAutoLogin: action.value
      };

    case SET_DID_FIND_STORED_USERDATA:
      return {
        ...state,
        email: action.email,
        didFindStoredUserData: true
      };

    case CREATE_GUEST:
      const newGuestProfile = new UserProfile(
        action.guestData.company_name,
        action.guestData.title,
        action.guestData.firstName,
        action.guestData.lastName,
        action.guestData.email,
        action.guestData.mobile,
        action.guestData.phone,
        action.guestData.address,
        action.guestData.zipcode,
        action.guestData.location,
        action.guestData.country,
        false
      );
      return {
        ...state,
        guestId: action.guestData.guestId,
        email: action.guestData.email,
        activeUserProfile: newGuestProfile
      };

    case UPDATE_GUEST:
      const editedGestProfile = new UserProfile(
        action.guestData.company_name,
        action.guestData.title,
        action.guestData.firstName,
        action.guestData.lastName,
        action.guestData.email,
        action.guestData.mobile,
        action.guestData.phone,
        action.guestData.address,
        action.guestData.zipcode,
        action.guestData.location,
        action.guestData.country,
        false
      );
      return {
        ...state,
        email: action.guestData.email,
        activeUserProfile: editedGestProfile
      };
  }
  return state;
};
