/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';

export type FocusStyleHookProps = {
  style: StyleSheet['props'];
  focusedStyle: StyleSheet['props'];
};

const useFocusStyle = (props: FocusStyleHookProps) => {
  /* #region Fields */
  const { style, focusedStyle } = props;
  const [isFocused, setIsFocused] = React.useState(false);
  /* #endregion */

  /* #region Methods */
  const startTrigger = () => {
    setIsFocused(true);
  };

  const endTrigger = () => {
    setIsFocused(false);
  };
  /* #endregion */

  const applicableStyle = isFocused ? focusedStyle : style;

  return [applicableStyle, startTrigger, endTrigger];
};

export default useFocusStyle;
