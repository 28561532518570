/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dimensions, FlatList, Platform } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import {
  View,
  Button,
  useThemeColor,
  ThemeProps
} from '../../components/UI/Themed';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import { MainText } from '../../components/UI/StyledText';
import Loading from '../../components/UI/Loading';
import OrderItem from '../../components/shop/OrderItem';
import * as ordersActions from '../../store/actions/orders';
import { RootState } from '../../store';
import { OrdersStackParamList } from '../../types';

const OrdersScreen = (
  { navigation }: StackScreenProps<OrdersStackParamList, 'Orders'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const { styles } = useStyles();
  const { width } = Dimensions.get('window');
  const containerRef = useRef<ContainerViewProps>();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const ownerId = useSelector((state: RootState) => state.auth.userId);
  const orders = useSelector((state: RootState) => state.orders.orders);
  const dispatch = useDispatch();
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const loadOrders = useCallback(async () => {
    updateState(() => setError(''));
    updateState(() => setIsLoading(true));

    try {
      dispatch(ordersActions.fetchOrders());
    } catch (err) {
      updateState(() => setError(err.message));
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, setError, ownerId]);
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    // refetch all orders when drawer navigation page is loaded (default for drawer is using a cached page)
    const unsubscribe = navigation.addListener('focus', loadOrders);

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleOrdersScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleOrdersScreen') });
    }
  }, [navigation]);
  /* #endregion */

  /* #region Renderers */
  if (error) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleOrdersScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
      >
        <View style={styles('centered')}>
          <MainText>
            {t('errorOccurred')} {error}
          </MainText>
          <Button
            title={t('tryAgain')}
            color={buttonColor}
            onPress={loadOrders}
          />
        </View>
      </ContainerView>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && orders.length === 0) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleOrdersScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
      >
        <View style={styles('centered')}>
          <MainText>{t('noOrders')}</MainText>
        </View>
      </ContainerView>
    );
  }

  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      seoTitle={`${t('titleOrdersScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <FlatList
        scrollEnabled={!containerRef.current?.scrollable}
        onRefresh={loadOrders}
        refreshing={isLoading}
        data={orders}
        extraData={{ width }} // using for responsive re-rendering of FlatList content
        keyExtractor={(item) => item.id.toString()}
        removeClippedSubviews={true}
        renderItem={(itemData) => (
          <OrderItem
            nr={itemData.item.nr}
            amount={itemData.item.totalAmount}
            date={itemData.item.readableDate}
            items={itemData.item.items}
          />
        )}
      />
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  {}
);

export default OrdersScreen;
