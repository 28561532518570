/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import EnvVars from '../env.json';
import CryptTransform from './transform';
import blogReducer from './reducers/blog';
import productsReducer from './reducers/products';
import productattributesReducer from './reducers/productattributes';
import cartReducer from './reducers/cart';
import ordersReducer from './reducers/orders';
import authReducer from './reducers/auth';
import settingsReducer from './reducers/settings';
import notificationReducer from './reducers/notification';

const rootReducer = {
  blog: blogReducer,
  products: productsReducer,
  productattributes: productattributesReducer,
  cart: cartReducer,
  orders: ordersReducer,
  auth: authReducer,
  settings: settingsReducer,
  notification: notificationReducer
};

// Infer the `RootState` type from the store itself
const combinedRootReducers = combineReducers(rootReducer);
export type RootState = ReturnType<typeof combinedRootReducers>;

const composeEnhancers =
  EnvVars.ENV === 'development'
    ? composeWithDevTools({
        // Specify name here, actionsBlacklist, actionsCreators and other options if needed
      })
    : compose();

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  // stateReconciler: autoMergeLevel2,
  transforms: [CryptTransform],
  blacklist: ['navigation', 'notification'] // navigation will not be persisted
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(ReduxThunk)
    // other store enhancers if any
  )
);

export const persistor = persistStore(store);
export default store;
