/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useRef } from 'react';
import { ColorSchemeName, Linking, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  NavigationContainerRef,
  CommonActions
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Host } from 'react-native-portalize';
import { useSelector, useDispatch } from 'react-redux';

import { decryptString } from '../helpers/crypto-utils';
import { translationGetters } from '../helpers/localized';
import NotFoundScreen from '../screens/NotFoundScreen';
import Loading from '../components/UI/Loading';
import LinkingConfiguration, { prefix } from './LinkingConfiguration';
import { RootStackParamList } from '../types';
import { AuthNavigator, ShopNavigator } from './ShopNavigator';
import * as authActions from '../store/actions/auth';
import * as settingsActions from '../store/actions/settings';
import * as notificationActions from '../store/actions/notification';
import { RootState } from '../store';

export const NAVIGATION_PERSISTENCE_KEY = 'NAVIGATION_STATE';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
const Navigation = ({
  colorScheme
}: {
  colorScheme: ColorSchemeName;
}): JSX.Element => {
  /* #region Fields */
  const language = useSelector((state: RootState) => state.settings.language);
  const isAnonymous = useSelector(
    (state: RootState) => state.auth.userId === null
  );
  const isAuth = useSelector((state: RootState) => !!state.auth.accessToken);
  const didTryAutoLogin = useSelector(
    (state: RootState) => state.auth.didTryAutoLogin
  );
  const didFindStoredUserData = useSelector(
    (state: RootState) => state.auth.didFindStoredUserData
  );
  const isCartDone = useSelector(
    (state: RootState) => state.cart.didVerifyOrder
  );
  const availableCurrencies = useSelector(
    (state: RootState) => state.settings.availableCurrencies
  );
  const navRef = useRef<NavigationContainerRef>(null);
  const routeNameRef = useRef<string>();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [initialState, setInitialState] = useState();
  const [triggerTime, setTriggerTime] = useState<number>();
  const dispatch = useDispatch();
  /* #rendegion */

  /* #region Events */
  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();
        const urlParts = initialUrl?.replace(prefix, '').split('/');

        // check for switching language
        if (urlParts && Object.keys(translationGetters).includes(urlParts[0])) {
          if (language !== urlParts[0]) {
            dispatch(
              settingsActions.setCurrency(availableCurrencies[urlParts[0]])
            );
            dispatch(settingsActions.setLanguage(urlParts[0]));
          }

          if (urlParts[1] === 'auth') {
            // set for correct handling of auto routing
            dispatch(authActions.setDidTryAL(true));
          }
        } else {
          if (initialUrl === `${prefix}auth`) {
            // set for correct handling of auto routing
            dispatch(authActions.setDidTryAL(true));
          }
        }

        if (Platform.OS !== 'web' && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await AsyncStorage.getItem(
            NAVIGATION_PERSISTENCE_KEY
          );
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  useEffect(() => {
    // console.log(
    //   `Navigation, isAnonymous: ${isAnonymous}, isAuth: ${isAuth}, didTryAutoLogin: ${didTryAutoLogin}, didFindStoreUserData: ${didFindStoredUserData}, isCartDone: ${isCartDone}`
    // );

    const tryLogin = async () => {
      const loadedData = await AsyncStorage.getItem(
        authActions.AUTH_PERSISTENCE_KEY
      );

      // check if data was stored
      if (!loadedData) {
        // TODO: setDidTryAL() ?
        // dispatch(authActions.setDidTryAL(true));
        return;
      }

      const { accessToken, refreshToken, userId, expiryTime, expiryDate } =
        JSON.parse(decryptString(loadedData));
      const expirationDate = new Date(expiryDate);

      // console.log(
      //   `AccessToken: ${accessToken}, RefreshToken: ${refreshToken}, UserId: ${userId}, expiryDate: ${expiryDate}`
      // );

      // check if accessToken or userId is still valid
      if (
        !expiryDate ||
        expirationDate <= new Date() ||
        !accessToken ||
        !userId
      ) {
        dispatch(authActions.setDidTryAL(true));
        return;
      }

      // OLD BEHAVIOR: calculate the expiration time
      // const expirationTime = expirationDate.getTime() - new Date().getTime();

      // NEW BEHAVIOR: use stored expirationTimeout from server, get during login/signup
      // automatically log in and forward to shop
      dispatch(
        authActions.authenticateUser(
          userId,
          accessToken,
          refreshToken,
          expiryTime,
          null
        )
      );
    };

    // Basic handling for auto routing of anonymous and authenticated users
    if (isReady && isAnonymous && !didTryAutoLogin) {
      // console.log('Goto Shop');
      navRef.current?.dispatch(CommonActions.navigate({ name: 'Shop' }));
    }
    if (isReady && isAnonymous && !isAuth && didTryAutoLogin) {
      // console.log('Goto Auth');
      navRef.current?.dispatch(CommonActions.navigate({ name: 'Auth' }));
    }
    if (isReady && !isAuth && !didTryAutoLogin && didFindStoredUserData) {
      // console.log('Trying to auto login, because stored UserData was found!');
      tryLogin();
    }
    // if (!isAnonymous && isCartDone) {
    //   console.log('Goto FinishOrder');
    //   navRef.current?.dispatch(CommonActions.navigate({ name: 'FinishOrder' }));
    // }
    // if (isAuth && !isCartDone) {
    //   console.log('Goto Orders');
    //   navRef.current?.dispatch(CommonActions.navigate({ name: 'Orders' }));
    // }
  }, [
    dispatch,
    isReady,
    isAuth,
    isAnonymous,
    isCartDone,
    didTryAutoLogin,
    didFindStoredUserData
  ]);
  /* #endregion */

  /* #region Renderers */
  if (!isReady) {
    return <Loading bare />;
  }

  return (
    <NavigationContainer
      ref={navRef}
      linking={LinkingConfiguration}
      initialState={initialState}
      onReady={() => {
        // set initial values after finished initialization of Navigation Container
        const currentRoute = navRef.current?.getCurrentRoute();

        if (currentRoute) {
          routeNameRef.current = currentRoute.name;
          dispatch(
            notificationActions.setActiveScreen({
              name: currentRoute.name,
              key: currentRoute.key
            })
          );
        }
      }}
      onStateChange={async (state) => {
        if (Platform.OS !== 'web') {
          await AsyncStorage.setItem(
            NAVIGATION_PERSISTENCE_KEY,
            JSON.stringify(state)
          );
        }

        const previousRouteName = routeNameRef.current;
        const currentRoute = navRef.current?.getCurrentRoute();

        if (currentRoute) {
          if (previousRouteName !== currentRoute.name) {
            // Here could also be done some analytics with expo-firebase-analytics tracker
            // see https://docs.expo.io/versions/latest/sdk/firebase-analytics/

            dispatch(
              notificationActions.setActiveScreen({
                name: currentRoute.name,
                key: currentRoute.key
              })
            );
          }
        }

        // Save the current route name for later comparision
        routeNameRef.current = currentRoute?.name;

        // update logout timeout between an event window of 5s, if user navigates
        const compareTime = new Date().getTime();
        if (
          !triggerTime ||
          (triggerTime && (Math.abs(compareTime - triggerTime) / 1000) % 60 > 5)
        ) {
          setTriggerTime(compareTime);
          dispatch(authActions.updateLogoutTimer());
        }
      }}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      <Host>
        <RootNavigator />
      </Host>
    </NavigationContainer>
  );
  /* #endregion */
};

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

const RootNavigator = () => {
  /* #region Renderers */
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: false,
        headerBackTitleVisible: false,
        presentation: 'modal'
      }}
    >
      <Stack.Screen name="Shop" component={ShopNavigator} />
      <Stack.Screen name="Auth" component={AuthNavigator} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} />
    </Stack.Navigator>
  );
  /* #endregion */
};

export default Navigation;
