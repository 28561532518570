/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { Platform, KeyboardAvoidingView } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import Config from '../../constants/Config';
import { t } from '../../helpers/localized';
import { formReducer, getFormState } from '../../helpers/input-form';
import {
  View,
  ScrollView,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import AlertModal from '../../components/UI/AlertModal';
import ContainerView from '../../components/UI/ContainerView';
import TouchableComponent from '../../components/UI/TouchableComponent';
import Loading from '../../components/UI/Loading';
import Input from '../../components/UI/Input';
import * as productsActions from '../../store/actions/products';
import { RootState } from '../../store';
import { AdminStackParamList } from '../../types';

const EditProductScreen = (
  { route, navigation }: StackScreenProps<AdminStackParamList, 'EditProduct'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const { styles } = useStyles();
  const isWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const prodId = route.params?.productId;
  const categoryId = route.params?.categoryId;
  const editedProduct = useSelector((state: RootState) =>
    state.products.userProducts.find((prod) => prod.id === prodId)
  );
  // const selectedCategory = useSelector((state: RootState) =>
  //   state.products.availableProductCategories.find((cat) => cat.id === categoryId)
  // );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const dispatch = useDispatch();

  if (prodId && !editedProduct) {
    return <Loading />;
  }

  // form validation model
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      title: editedProduct ? editedProduct.title : '',
      'imageURL-1': editedProduct ? editedProduct.images[0] : '',
      'imageURL-2': editedProduct ? editedProduct.images[1] : '',
      'imageURL-3': editedProduct ? editedProduct.images[2] : '',
      'imageURL-4': editedProduct ? editedProduct.images[3] : '',
      'imageURL-5': editedProduct ? editedProduct.images[4] : '',
      'imageURL-6': editedProduct ? editedProduct.images[5] : '',
      'imageURL-7': editedProduct ? editedProduct.images[6] : '',
      'imageURL-8': editedProduct ? editedProduct.images[7] : '',
      'imageURL-9': editedProduct ? editedProduct.images[8] : '',
      'imageURL-10': editedProduct ? editedProduct.images[9] : '',
      description: editedProduct ? editedProduct.description : '',
      price: editedProduct ? editedProduct.price : 1
      // category: selectedCategory ? selectedCategory.title : ''
    },
    inputValidities: {
      title: editedProduct ? true : false,
      'imageURL-1': editedProduct ? true : false, // in the create form we only see the first imageURL field, hence mark all others as 'valid'
      'imageURL-2': true,
      'imageURL-3': true,
      'imageURL-4': true,
      'imageURL-5': true,
      'imageURL-6': true,
      'imageURL-7': true,
      'imageURL-8': true,
      'imageURL-9': true,
      'imageURL-10': true,
      description: editedProduct ? true : false,
      price: editedProduct ? true : false
      // category: selectedCategory ? true : false
    },
    formIsValid: editedProduct ? true : false
  });
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const submitHandler = useCallback(async () => {
    // merge all image Inputs to array
    const formImages = [];
    Object.keys(formState.inputValues).forEach((key) => {
      if (key.includes('imageURL')) {
        if (
          formState.inputValues[key] !== undefined &&
          formState.inputValues[key] !== ''
        ) {
          formImages.push(formState.inputValues[key]);
        }
      }
    });

    if (!formState.formIsValid) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('wrongInput')}
            message={t('checkFormErrors')}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
      return;
    }

    updateState(() => setIsLoading(true));

    try {
      if (editedProduct) {
        dispatch(
          productsActions.updateProduct(
            prodId,
            categoryId,
            formState.inputValues.title,
            formState.inputValues.description,
            formImages,
            formState.inputValues.price
          )
        );
      } else {
        dispatch(
          productsActions.createProduct(
            categoryId,
            formState.inputValues.title,
            formState.inputValues.description,
            formImages,
            formState.inputValues.price
          )
        );
      }

      // go back to the previous screen
      navigation.goBack();
    } catch (err) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('errorOccurred')}
            message={err.message}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, prodId, formState]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${prodId ? t('titleEditProduct') : t('titleAddProduct')} | ${t(
          'appTitle'
        )}`
      });
    } else {
      navigation.setOptions({
        title: prodId ? t('titleEditProduct') : t('titleAddProduct')
      });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={submitHandler}
            style={styles('headerButton')}
          >
            <Ionicons
              name="checkmark"
              color={headerButtonColor}
              style={styles('touchable')}
            />
          </TouchableComponent>
        );
      }
    });
  }, [navigation, prodId, submitHandler]);

  const inputChangeHandler = useCallback(
    (inputIdentifier: string, inputValue: unknown, inputValidity: boolean) => {
      dispatchFormState(
        getFormState(inputIdentifier, inputValue, inputValidity)
      );
    },
    [dispatchFormState]
  );
  /* #endregion */

  /* #region Renderers */
  if (isLoading) {
    return <Loading />;
  }

  return (
    <ContainerView
      scrollable
      seoTitle={`${prodId ? t('titleEditProduct') : t('titleAddProduct')} | ${t(
        'appTitle'
      )}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior="padding"
        keyboardVerticalOffset={100}
      >
        {alertModalContent}
        <ScrollView>
          <View style={styles('form')}>
            <Input
              id="title"
              label={t('inputTitleLabel')}
              errorText={t('inputTitleError')}
              keyboardType="default"
              autoCapitalize="sentences"
              autoCorrect
              returnKeyType="next"
              onInputChange={inputChangeHandler}
              initialValue={editedProduct ? editedProduct.title : ''}
              initiallyValid={!!editedProduct}
              required
            />
            {editedProduct?.length > 0 ? (
              editedProduct.images.map((image, index: number) => {
                return (
                  <Input
                    key={index.toString()}
                    id={`imageURL-${index + 1}`}
                    label={`${t('inputImageUrlLabel')} ${index + 1}`}
                    errorText={t('inputImageUrlError')}
                    keyboardType="default"
                    returnKeyType="next"
                    onInputChange={inputChangeHandler}
                    initialValue={editedProduct ? image : ''}
                    initiallyValid={!!editedProduct}
                  />
                );
              })
            ) : (
              <Input
                id="imageURL-1"
                label={`${t('inputImageUrlLabel')} 1`}
                errorText={t('inputImageUrlError')}
                keyboardType="default"
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={`${
                  Config().domain_prefix_backend
                }/media/saunaexpert/...`}
                initiallyValid={!!editedProduct}
              />
            )}
            <Input
              id="price"
              label={t('inputPriceLabel')}
              errorText={t('inputPriceError')}
              keyboardType="decimal-pad"
              returnKeyType="next"
              onInputChange={inputChangeHandler}
              initialValue={editedProduct ? editedProduct.price : 1}
              initiallyValid={!!editedProduct}
              required
              decimal
              min={0.1}
            />
            <Input
              id="description"
              label={t('inputDescriptionLabel')}
              errorText={t('inputDescriptionError')}
              keyboardType="default"
              autoCapitalize="sentences"
              autoCorrect
              multiline
              numberOfLines={3}
              onInputChange={inputChangeHandler}
              initialValue={editedProduct ? editedProduct.description : ''}
              initiallyValid={!!editedProduct}
              required
              minLength={5}
            />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    form: {
      margin: 20
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    actions: {
      justifyContent: 'center'
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    touchable: {
      marginHorizontal: 8
    }
  },
  {}
);

export default EditProductScreen;
