/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import _ from 'lodash';

import Config from '../../constants/Config';
import logger from '../../helpers/logger';
import { parseLocaleNumber, t } from '../../helpers/localized';
import { recursiveCallAPI, singleCallAPI } from '../../helpers/api-utils';
import Product from '../../models/Product';
import * as notificationActions from './notification';
import * as settingsActions from './settings';
import { IProductAttribute, ProductAttributeValueTypes } from '../../types';
import { RootState } from '..';

export const SET_PRODUCTATTRIBUTES = 'SET_PRODUCTATTRIBUTES';
export const UPDATE_PRODUCTATTRIBUTES = 'UPDATE_PRODUCTATTRIBUTES';

let timer: ReturnType<typeof setTimeout>;

export const fetchCacheKey = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    const delayInitialize = await AsyncStorage.getItem(
      settingsActions.APP_INITIALIZED
    );
    if (delayInitialize) {
      setTimeout(() => {
        // wait
        logger.log(
          `store.actions.productattributes - fetchCacheKey: Timer delayInitialize done.`
        );
      }, 3000);
    }

    try {
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'GET',
        `${Config().api_url}/cachekeys/?name=ProductAttribute`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        }
      );
      const dbCacheKey =
        resData.results.length > 0 ? resData.results[0].cacheKey : '';

      // check dbCacheKey with cacheKey stored in AsyncStorage
      const localCacheKey = await AsyncStorage.getItem(
        'cacheKeyProductAttribute'
      );

      if (
        dbCacheKey &&
        (!localCacheKey ||
          localCacheKey.trim() === '' ||
          (localCacheKey && localCacheKey !== dbCacheKey))
      ) {
        // update cacheKey in AsyncStorage
        await AsyncStorage.setItem('cacheKeyProductAttribute', dbCacheKey);

        // re-fetch from database
        dispatch(fetchAvailableProductAttributes());
      }

      dispatch(setCacheKeyTimer(Config().cacheRefetchTimeout));
    } catch (err) {
      logger.log(
        `store.actions.productattributes - fetchCacheKey: Cannot fetch cache key! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch cache key! Server responded with error - ${err}.`
      );
    }
  };
};

export const fetchAvailableProductAttributes = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      const currency = getState().settings.currency;
      const currencyRates = getState().settings.currencyRates;
      const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
        getState().settings.productAttributeValueTypes;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }
      const loadedProductAttributes = await recursiveCallAPI(
        'GET',
        `${Config().api_url}/productattributes/`,
        headers
      );

      try {
        loadedProductAttributes.forEach((item: IProductAttribute) => {
          let json_field = item.json;
          const value_type = productAttributeValueTypes?.find(
            (valuetype) => valuetype.id === json_field.type
          );

          // transform attributes of type 'Product'
          if (value_type?.type_name === t('productAttributeValueTypeProduct')) {
            const updatedJson = {
              value: [],
              multiSelect: !!json_field.multiSelect,
              type: productAttributeValueTypes?.find(
                (valuetype) =>
                  valuetype.type_name ===
                  t('productAttributeValueTypeDictionary')
              )?.id
            };

            // add possible attributeKey
            if (item.json.attributeKey) {
              updatedJson.attributeKey = item.json.attributeKey;
            }

            if (Array.isArray(json_field.value)) {
              json_field.value.forEach((attr) => {
                const { productSlug, attributeName, ...otherProps } = attr;
                const product = getState().products.availableProducts.find(
                  (prod: Product) =>
                    prod.slug === productSlug && prod.is_archived === false
                );

                const productAttribute = loadedProductAttributes.find(
                  (attr: IProductAttribute) => {
                    if (product?.id) {
                      return (
                        attr.productId.includes(product.id) &&
                        attr.name === attributeName
                      );
                    } else {
                      return null;
                    }
                  }
                );

                if (productSlug && product && !productAttribute) {
                  // add product as attribute value
                  const jsonValue = {
                    label: product.title,
                    image: product.images ? product.images[0] : '',
                    price: product.price,
                    currency: product.currency,
                    description: product.description
                  };
                  // fill updateJson with other props from configuration
                  updatedJson.value.push({ ...jsonValue, ...otherProps });
                } else if (productSlug && product && productAttribute) {
                  // copy original json
                  const newJson = productAttribute.json;
                  // fill newJson with other props from configuration
                  if (Array.isArray(newJson.value)) {
                    const jsonValues = [];
                    newJson.value.forEach((attr) => {
                      jsonValues.push({ ...attr, ...otherProps });
                    });
                    newJson.value = jsonValues;
                  }
                  // override items json
                  updatedJson.value = [...updatedJson.value, ...newJson.value];
                  if (!updatedJson.attributeKey && newJson.attributeKey) {
                    updatedJson.attributeKey = newJson.attributeKey;
                  }
                } else if (!productSlug && !product) {
                  // add normal attribute to values
                  updatedJson.value.push(attr);
                }
              });

              // Rewrite the item
              json_field = item.json = updatedJson;
            } else {
              const { productSlug, attributeName, ...otherProps } =
                json_field.value;
              const product = getState().products.availableProducts.find(
                (prod: Product) =>
                  prod.slug === productSlug && prod.is_archived === false
              );

              const productAttribute = loadedProductAttributes.find(
                (attr: IProductAttribute) => {
                  if (product?.id) {
                    return (
                      attr.productId.includes(product.id) &&
                      attr.name === attributeName
                    );
                  } else {
                    return null;
                  }
                }
              );

              // Rewrite the item
              if (productAttribute) {
                // copy original json
                const newJson = productAttribute.json;
                // add attributeKey
                if (item.json.attributeKey) {
                  newJson.attributeKey = item.json.attributeKey;
                }
                // fill newJson with other props from configuration
                if (Array.isArray(newJson.value)) {
                  const jsonValues = [];
                  newJson.value.forEach((attr) => {
                    jsonValues.push({ ...attr, ...otherProps });
                  });
                  newJson.value = jsonValues;
                }
                // override items json
                item.json = newJson;
              } else {
                const { productSlug, ...otherProps } = json_field.value;

                // add product as attribute value
                const jsonValue = {
                  label: product.title,
                  image: product.images ? product.images[0] : '',
                  price: product.price,
                  currency: product.currency,
                  description: product.description
                };

                // fill updatedJson with other props from configuration
                updatedJson.value.push({ ...jsonValue, ...otherProps });
                // We have to set multiSelect to true, because we have only one item! Otherwise the configuration will fail!
                updatedJson.multiSelect = true;
                json_field = item.json = updatedJson;
              }
            }
          }

          // add or update other attribute values, if ProductAttribute value is an array
          if (Array.isArray(json_field.value)) {
            json_field.value.forEach((attr) => {
              // it's possible to change the price for special attributes, so we create extra price tags
              if (attr.hasOwnProperty('price')) {
                let price_field = parseLocaleNumber(attr.price);
                let currency_field = attr.currency;

                // get currency and divide by rate from settings
                let rate_factor = currencyRates[currency_field];
                if (currency_field !== currency) {
                  attr.price = _.round(price_field / rate_factor, 2);
                  attr.currency = currency;
                }

                // possibly set stepPrice
                if (attr.input && !attr.input.hasOwnProperty('stepPrice')) {
                  attr.input.stepPrice = parseLocaleNumber(attr.price);
                  attr.price = 0;
                }

                // possibly set finalStepPrice
                if (attr.input && attr.input.hasOwnProperty('stepPrice')) {
                  attr.input.finalStepPrice = attr.input.stepPrice;
                }

                if (
                  attr.addsAttributePrice &&
                  Object.keys(attr.addsAttributePrice).includes('self')
                ) {
                  let selfPrice = attr.addsAttributePrice['self'];

                  if (currency_field !== currency) {
                    selfPrice = parseLocaleNumber(selfPrice) / rate_factor;
                  }
                  // ... and possibly set new price by key 'self'
                  attr.price =
                    parseLocaleNumber(attr.price) +
                    parseLocaleNumber(selfPrice);
                }
                attr.finalPrice = attr.price;
              }
              // possibly set lastInput
              if (attr.input && !attr.input.lastInput) {
                attr.input.lastInput = attr.input.min;
              }
            });
          }
        });
      } catch (err) {
        logger.log(
          `store.actions.productattributes - fetchAvailableProductAttributes: Cannot loaded product attributes! Server responded with error - ${err}.`
        );
      }

      let transformedProductAttributes: Array<IProductAttribute> = [];

      let idx = 0;
      loadedProductAttributes.forEach(
        (item: IProductAttribute, index: number) => {
          const json_field = item.json;
          const value_type = productAttributeValueTypes?.find(
            (valuetype) => valuetype.id === json_field.type
          );

          // handle "Dictionary" and extract first element as default
          item.productId.forEach((prodId: number) => {
            // reference original item
            const updatedItem = { ...item };

            // rewrite the id, productId and add attributeId
            updatedItem.id = ++idx;
            updatedItem.productId = prodId;
            updatedItem.attributeId = item.id;
            // add possible attributeKey from json_field
            updatedItem.attributeKey = json_field.attributeKey
              ? json_field.attributeKey
              : '';

            // Add possible multiSelect from json_field
            // Defaults to "false", because we don't have pre-selected values when using "multiSelect"
            updatedItem.multiSelect = !!json_field.multiSelect;

            if (
              value_type?.type_name === t('productAttributeValueTypeDictionary')
            ) {
              // Rewrite json field with the first element, when attributeType is "Dictionary" and "multiSelect" is false
              // This will select a default value for the RadioButtonGroup, where only one single selection at a time is allowed!
              if (!json_field.multiSelect) {
                updatedItem.json = {
                  value: json_field.value[0],
                  type: value_type?.id,
                  selectedIndex: 0
                };
              }
            } else {
              // transform possible json string to json object
              updatedItem.json = json_field;
            }

            // Don't push item, when "multiSelect" is true, because the default behaviour is, that no value is selected
            if (!json_field.multiSelect) {
              // prevent double entries
              const doublesArray = _.find(
                transformedProductAttributes,
                function (o) {
                  return (
                    o.productId === updatedItem.productId &&
                    o.attributeId === updatedItem.attributeId
                  );
                }
              );
              if (!doublesArray) {
                transformedProductAttributes.push(updatedItem);
              }
            }
          });
        }
      );

      dispatch({
        type: SET_PRODUCTATTRIBUTES,
        availableProductAttributes: loadedProductAttributes,
        selectedProductAttributes: transformedProductAttributes
      });
    } catch (err) {
      logger.log(
        `store.actions.productattributes - fetchAvailableProductAttributes: Cannot fetch productattributes! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch productattributes! Server responded with error - ${err}.`
      );
    }
  };
};

export const updateSelectedProductAttributes = (
  name: string,
  selectedIndex: Array<number> | number,
  multiSelect: boolean,
  productId: number,
  selectedAttributes: Array<IProductAttribute> | IProductAttribute,
  selectedConfigItem: IProductAttribute,
  availableConfigItems: Array<IProductAttribute>
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
      getState().settings.productAttributeValueTypes;
    dispatch({
      type: UPDATE_PRODUCTATTRIBUTES,
      new_name: name,
      selectedIndex,
      multiSelect,
      productId,
      selectedAttributes,
      selectedConfigItem,
      availableConfigItems,
      productAttributeValueTypes
    });
  };
};

const clearCacheKeyTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

const setCacheKeyTimer = (
  expirationTime: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  clearCacheKeyTimer();
  return (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    timer = setTimeout(() => {
      dispatch(fetchCacheKey());
    }, expirationTime);
  };
};
