/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useCallback, useEffect, useRef } from 'react';
import { Dimensions, FlatList, Platform } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { t } from '../../helpers/localized';
import {
  View,
  Button,
  Text,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText } from '../../components/UI/StyledText';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import TouchableComponent from '../../components/UI/TouchableComponent';
import CartItem from '../../components/shop/CartItem';
import Card from '../../components/UI/Card';
import OrderProgress from '../../components/shop/OrderProgress';
import { allProducts } from '../../store/reselect';
import * as cartActions from '../../store/actions/cart';
import { RootState } from '../../store';
import { ShopStackParamList } from '../../types';

const CartScreen = (
  { navigation }: StackScreenProps<ShopStackParamList, 'Cart'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const { styles } = useStyles();
  const { width } = Dimensions.get('window');
  const containerRef = useRef<ContainerViewProps>();
  const language = useSelector((state: RootState) => state.settings.language);
  const currency = useSelector((state: RootState) => state.settings.currency);
  const isAnonymous = useSelector(
    (state: RootState) => state.auth.userId === null
  );
  const activeUserProfile = useSelector(
    (state: RootState) => state.auth.activeUserProfile
  );
  const products = useSelector(allProducts());
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const cartTotalAmount = useSelector(
    (state: RootState) => state.cart.totalAmount
  );
  const cartItems = useSelector((state: RootState) => {
    const transformedCartItems = [];
    Object.keys(state.cart.items).forEach((key) => {
      transformedCartItems.push({
        itemKey: key,
        productTitle: state.cart.items[key].productTitle,
        productPrice: state.cart.items[key].productPrice,
        productAttributes: state.cart.items[key].productAttributes,
        quantity: state.cart.items[key].quantity,
        sum: state.cart.items[key].sum
      });
    });

    return _.sortBy(transformedCartItems, 'itemKey');
  });
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Events */
  const verifyOrderHandler = useCallback(async () => {
    dispatch(cartActions.setDidVerifyOrder(true));
    if (isAnonymous && !activeUserProfile) {
      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'ShopAuth'
        }
      });
    } else {
      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'FinishOrder'
        }
      });
    }
  }, [navigation, isAnonymous, activeUserProfile]);

  const selectItemHandler = useCallback(
    (slug: string, key: string) => {
      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'ProductDetail',
          params: {
            productSlug: slug,
            itemKey: key
          }
        }
      });
    },
    [navigation]
  );

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleCartScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleCartScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'Cart'
                }
              });
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, cartItemCount]);
  /* #endregion */

  /* #region Renderers */
  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      seoTitle={`${t('titleCartScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <View style={styles('screen')}>
        <Card style={styles('container')}>
          <OrderProgress />
          <View style={styles('summary')}>
            <LabelText strong>
              {t('totalAmount') + ' '}
              <Text style={{ color: primaryColor }}>
                {cartTotalAmount.toLocaleString(language, {
                  style: 'currency',
                  currency: currency
                })}
              </Text>
            </LabelText>
            <Button
              color={buttonAccentColor}
              title={t('titleContinue')}
              disabled={cartItems.length === 0}
              onPress={verifyOrderHandler}
            />
          </View>
        </Card>
        <FlatList
          scrollEnabled={!containerRef.current?.scrollable}
          data={cartItems}
          extraData={{ width }} // using for responsive re-rendering of FlatList content
          keyExtractor={(item) => item.itemKey}
          removeClippedSubviews={true}
          renderItem={(itemData) => (
            <CartItem
              title={itemData.item.productTitle}
              attributes={itemData.item.productAttributes}
              quantity={itemData.item.quantity}
              amount={itemData.item.sum}
              onEdit={() => {
                // find the product from the cart
                const product = products.find(
                  (prod) => prod.title === itemData.item.productTitle
                );
                selectItemHandler(product.slug, itemData.item.itemKey);
              }}
              onAdd={() => {
                // find the product from the cart
                const product = products.find(
                  (prod) => prod.title === itemData.item.productTitle
                );
                product.attributes = itemData.item.productAttributes;
                dispatch(cartActions.addToCart(product));
              }}
              onRemove={() => {
                dispatch(cartActions.removeFromCart(itemData.item.itemKey));
              }}
            />
          )}
        />
      </View>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    screen: {
      flex: 1,
      margin: 20
    },
    container: {
      marginBottom: 20,
      padding: 10
    },
    summary: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    }
  },
  {}
);

export default CartScreen;
