/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import { ImageBackground, Image, LogBox, ImageResizeMode } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import InputSpinner from 'react-native-input-spinner';
import { useDispatch } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';
import { View, useThemeColor, ThemeProps } from './Themed';
import { MainText } from './StyledText';
import TouchableComponent from './TouchableComponent';
import ImageLoader from './ImageLoader';
import * as notificationActions from '../../store/actions/notification';

if (LogBox) {
  LogBox.ignoreLogs(['Failed prop type:']);
}

export type RadioButtonProps = ThemeProps & {
  id: number;
  selectedRadioId: Array<number> | number;
  finalPrice: string;
  description: string;
  label: string;
  image: string;
  imageResizeMode: ImageResizeMode;
  color: string;
  style: StyleSheet['props'];
  hasError: boolean;
  input?: {
    min: number;
    max: number;
    step: number;
    lastInput: number;
    replaceLabel?: string;
  };
  inputNumber?: number;
  onInputChange: (id: number, value: number) => void;
  onPress: () => void;
};

const RadioButton = (props: RadioButtonProps): JSX.Element => {
  /* #region Fields */
  const {
    id,
    selectedRadioId,
    finalPrice,
    description,
    label,
    image,
    imageResizeMode,
    color,
    style,
    hasError,
    input,
    inputNumber,
    onInputChange,
    onPress,
    lightColor,
    darkColor
  } = props;
  const { styles } = useStyles();
  const isMounted = useIsMounted();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [focusedStyle, setFocusedStyle] = useState({});
  const [replacedLabel, setReplacedLabel] = useState<string>('');
  const radioButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'radioButton'
  );
  const radioButtonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'radioButtonAccent'
  );
  const inactiveColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const attentionColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'attention'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    updateState(() =>
      setReplacedLabel(
        input?.replaceLabel && inputNumber
          ? input.replaceLabel.replace('%n', inputNumber.toString())
          : label
      )
    );
  }, [label, input, inputNumber]);

  useEffect(() => {
    // If selectedRadioId is an Array, the parent RadioButtonGroup has multiSelect values
    const selected = Array.isArray(selectedRadioId)
      ? selectedRadioId.includes(id)
      : selectedRadioId === id;

    updateState(() => setIsSelected(selected));

    if (selected) {
      // If multiSelect value, we only want to set the ProductConfiguratorDetails for onPress events
      if (!Array.isArray(selectedRadioId)) {
        dispatch(
          notificationActions.setProductConfiguratorDetails({
            title: replacedLabel,
            price: finalPrice,
            description
          })
        );
      }
    }
  }, [id, selectedRadioId, replacedLabel, finalPrice]);

  const focusSetHandler = () => {
    updateState(() =>
      setFocusedStyle({ borderColor: radioButtonColor, borderWidth: 3 })
    );
  };

  const focusUnsetHandler = () => {
    updateState(() => setFocusedStyle({}));
  };
  /* #endregion */

  /* #region Renderers */
  const priceInfoComponent = (
    <View style={styles('priceInfoContainer')}>
      <MainText
        style={[
          styles('text'),
          {
            fontWeight: isSelected ? 'bold' : 'normal'
          }
        ]}
      >
        {finalPrice}
      </MainText>
    </View>
  );

  let radioButtonComponent;
  if (image) {
    radioButtonComponent = (
      <ImageLoader
        style={[styles('radioButton'), { backgroundColor: color }]}
        resizeMode={imageResizeMode}
        component={ImageBackground}
        source={rewriteBackendURL(image)}
      >
        <View />
        <>
          {finalPrice.length > 0 && (
            <View
              style={[
                styles('priceInfo'),
                {
                  backgroundColor: radioButtonAccentColor
                }
              ]}
            >
              {priceInfoComponent}
            </View>
          )}
        </>
      </ImageLoader>
    );
  } else {
    radioButtonComponent = (
      <View style={styles('radioButton')}>
        <View
          style={[
            styles('radioStyle'),
            {
              backgroundColor: color,
              borderColor: inactiveColor
            }
          ]}
        />
        <View />
        <View
          style={[
            styles('priceInfo'),
            {
              backgroundColor: radioButtonAccentColor
            }
          ]}
        >
          {priceInfoComponent}
        </View>
      </View>
    );
  }

  return (
    <View onMouseEnter={focusSetHandler} onMouseLeave={focusUnsetHandler}>
      <TouchableComponent
        key={id}
        style={[
          styles('container'),
          {
            borderColor: hasError
              ? attentionColor
              : isSelected
              ? radioButtonColor
              : inactiveColor,
            borderWidth: isSelected ? 3 : 2
          },
          focusedStyle,
          style
        ]}
        onPress={() => {
          onPress();
          if (!isSelected) {
            dispatch(
              notificationActions.setProductConfiguratorDetails({
                title: replacedLabel,
                price: finalPrice,
                description
              })
            );
          } else {
            // If selectedRadioId is an Array, the parent RadioButtonGroup has multiSelect values.
            // In that case reset ProductConfiguratorDetails
            if (Array.isArray(selectedRadioId)) {
              dispatch(
                notificationActions.setProductConfiguratorDetails({
                  title: '',
                  price: 0,
                  description: ''
                })
              );
            }
          }
        }}
      >
        <>
          {radioButtonComponent}
          {isSelected && (
            <Image
              style={styles('checkmark')}
              source={
                hasError
                  ? require('../../assets/images/checkmark-red.png')
                  : require('../../assets/images/checkmark-green.png')
              }
            />
          )}
        </>
      </TouchableComponent>
      <View style={{ marginVertical: 5, alignItems: 'center' }}>
        <MainText small strong>
          {replacedLabel}
        </MainText>
        {input && inputNumber && (
          <InputSpinner
            style={{ marginTop: 5 }}
            disabled={!isSelected}
            editable={isSelected}
            max={input.max}
            min={input.min}
            step={input.step}
            width={100}
            height={26}
            rounded={false}
            showBorder={true}
            skin={'round'}
            color={radioButtonColor}
            colorMax={attentionColor}
            colorMin={inactiveColor}
            value={inputNumber}
            onChange={(num: number) => onInputChange(id, num)}
          />
        )}
      </View>
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      borderStyle: 'solid',
      borderRadius: 5,
      width: 146,
      height: 130,
      alignItems: 'center',
      overflow: 'hidden'
    },
    radioButton: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    radioStyle: {
      marginTop: 10,
      height: 75,
      width: 75,
      borderRadius: 75,
      borderWidth: 1
    },
    text: {
      marginTop: 0,
      alignSelf: 'center',
      textAlign: 'center'
    },
    priceInfoContainer: {
      height: 23,
      justifyContent: 'space-between',
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4
    },
    priceInfo: {
      paddingHorizontal: 5,
      paddingBottom: 5,
      width: '100%'
    },
    checkmark: {
      position: 'absolute',
      right: 2,
      bottom: 12,
      width: 28,
      height: 28
    }
  },
  {}
);

export default RadioButton;
