/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { Linking, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { useNavigation } from '@react-navigation/native';

import { t } from '../../helpers/localized';
import { View, useThemeColor, ThemeProps } from '../../components/UI/Themed';
import { LabelText, MainText } from '../UI/StyledText';

export const FOOTER_HEIGHT: Record<DEVICE_SIZES, number> = {
  extra_large: 220,
  large: 220,
  medium: 220,
  small: 450,
  extra_small: 450
};

const Footer = React.memo((props: ThemeProps): JSX.Element => {
  /* #region Fields */
  const { lightColor, darkColor } = props;
  const isWeb = Platform.OS === 'web';
  const { styles, deviceSize } = useStyles();
  const headerColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'header'
  );
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const textAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'textAccent'
  );
  const shadowLightColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'shadowLight'
  );
  const navigation = useNavigation();
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[
        styles('container'),
        {
          backgroundColor: headerColor,
          shadowColor: shadowLightColor
        }
      ]}
    >
      <View style={styles('linkContainer')}>
        <View style={styles('linkGroup')}>
          <MainText strong style={styles('caption')}>
            {t('titleDiscoverShop')}
          </MainText>
          <LabelText
            small
            hoverable
            onPress={() =>
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'ShopCategories',
                  params: { enableBackButton: true }
                }
              })
            }
          >
            {t('titleProductCategoriesScreen')}
          </LabelText>
        </View>
        <View style={styles('linkGroup')}>
          <MainText strong style={styles('caption')}>
            {t('titleAboutUs')}
          </MainText>
          <LabelText
            small
            hoverable
            onPress={() =>
              navigation.push('Shop', {
                screen: 'Blog',
                params: {
                  screen: 'Article',
                  params: {
                    articleSlug: 'wir-uber-uns',
                    enableBackButton: true
                  }
                }
              })
            }
          >
            {t('titleCompanyOverview')}
          </LabelText>
          <LabelText
            small
            hoverable
            onPress={() =>
              navigation.push('Shop', {
                screen: 'Blog',
                params: {
                  screen: 'Article',
                  params: { articleSlug: 'impressum', enableBackButton: true }
                }
              })
            }
          >
            {t('titleDisclosure')}
          </LabelText>
          <LabelText
            small
            hoverable
            onPress={() =>
              navigation.push('Shop', {
                screen: 'Blog',
                params: {
                  screen: 'Article',
                  params: {
                    articleSlug: 'datenschutzerklaerung',
                    enableBackButton: true
                  }
                }
              })
            }
          >
            {t('titleDataPrivacy')}
          </LabelText>
          <LabelText
            small
            hoverable
            onPress={() =>
              navigation.push('Shop', {
                screen: 'Blog',
                params: {
                  screen: 'Article',
                  params: { articleSlug: 'agb', enableBackButton: true }
                }
              })
            }
          >
            {t('titleTermsAndConditions')}
          </LabelText>
        </View>
        <View style={styles('linkGroup')}>
          <MainText strong style={styles('caption')}>
            {t('titleCustomerService')}
          </MainText>
          <LabelText
            small
            hoverable
            onPress={() =>
              navigation.push('Shop', {
                screen: 'Blog',
                params: {
                  screen: 'Article',
                  params: { articleSlug: 'faq', enableBackButton: true }
                }
              })
            }
          >
            {t('titleFAQ')}
          </LabelText>
        </View>
        <View style={styles('linkGroup')}>
          <MainText strong style={styles('caption')}>
            {t('titleFollowUs')}
          </MainText>
          <LabelText
            small
            hoverable
            onPress={() =>
              navigation.push('Shop', {
                screen: 'Blog',
                params: {
                  screen: 'Article',
                  params: {
                    articleSlug: 'social-media',
                    enableBackButton: true
                  }
                }
              })
            }
          >
            {t('titleSocialMedia')}
          </LabelText>
        </View>
      </View>
      <LabelText
        small={
          [DEVICE_SIZES.EXTRA_SMALL_DEVICE, DEVICE_SIZES.SMALL_DEVICE].includes(
            deviceSize
          )
            ? true
            : false
        }
        style={[styles('copyright'), { color: primaryColor }]}
      >
        {t('appCopyright')}
      </LabelText>
      <LabelText
        small
        style={[styles('copyrightDev'), { color: textAccentColor }]}
        onPress={() =>
          isWeb
            ? window.open('https://dsoft-app-dev.de', '_blank')
            : Linking.openURL('https://dsoft-app-dev.de')
        }
      >
        {t('appCopyrightDev')}
      </LabelText>
    </View>
  );
  /* #endregion */
});

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 220,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'stretch',
      marginTop: 2,
      ...Platform.select({
        android: {
          elevation: 5
        },
        default: {
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.26,
          shadowRadius: 8
        }
      })
    },
    linkContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: 10,
      marginHorizontal: '16%',
      backgroundColor: 'transparent'
    },
    linkGroup: {
      flexGrow: 1,
      paddingHorizontal: 10,
      backgroundColor: 'transparent'
    },
    copyright: {
      marginVertical: 0,
      marginTop: 20,
      marginBottom: 8,
      alignSelf: 'center'
    },
    copyrightDev: {
      marginVertical: 0,
      marginBottom: 8,
      alignSelf: 'center'
    },
    caption: {
      marginTop: 10
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      linkContainer: {
        marginHorizontal: '8%'
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      container: {
        height: 'auto'
      },
      linkContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        marginHorizontal: 0
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      container: {
        height: 'auto'
      },
      linkContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        marginHorizontal: 0
      }
    }
  }
);

export default Footer;
