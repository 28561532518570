/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { Dimensions } from 'react-native';
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import RenderHtml, {
  HTMLContentModel,
  HTMLSource,
  defaultHTMLElementModels,
  defaultSystemFonts,
  MixedStyleDeclaration
} from 'react-native-render-html';
import WebView from 'react-native-webview';
import transform, { StyleTuple } from 'css-to-react-native';

import { rewriteBackendURL } from '../../helpers/staticfiles-storage';

type HTMLComponentProps = {
  baseStyle?: MixedStyleDeclaration;
  source: HTMLSource;
};

const HTMLComponent = (props: HTMLComponentProps) => {
  /* #region Fields */
  const { baseStyle, source } = props;
  const renderers = {
    iframe: IframeRenderer
  };
  const customHTMLElementModels = {
    iframe: iframeModel.extend({
      getMixedUAStyles(tnode, element) {
        // Rewrite relative backend urls
        element.attribs.src = rewriteBackendURL(element.attribs.src);
      }
    }),
    img: defaultHTMLElementModels.img.extend({
      contentModel: HTMLContentModel.mixed,
      getMixedUAStyles(tnode, element) {
        let parser = new DOMParser();
        let doc = parser.parseFromString(source.html, 'text/html');
        let CSSstyle: Array<StyleTuple> = [];

        // find raw img node for elements src and extract CSS style
        [...doc.getElementsByTagName('img')].every((img) => {
          if (img.getAttribute('src') === element.attribs.src) {
            let parent = element.parent;

            // extract CSS style from element
            for (var i = img.style.length; i--; ) {
              let property = img.style[i];
              let value = img.style.getPropertyValue(property);

              if (property === 'float') {
                if (value === 'left') {
                  CSSstyle.push(
                    ['display', 'block'],
                    ['margin-left', 'unset'],
                    ['margin-right', 'auto']
                  );
                } else if (value === 'right') {
                  CSSstyle.push(
                    ['display', 'block'],
                    ['margin-left', 'auto'],
                    ['margin-right', 'unset']
                  );
                }
              } else {
                CSSstyle.push([property, value]);
              }
            }

            // extract CSS style from parent and append
            if (parent && parent.name === 'p') {
              if (parent.attribs?.style?.includes('text-align')) {
                const idx = parent.attribs.style.indexOf('text-align');
                const midIdx = parent.attribs.style.indexOf(':', idx);
                const lastIdx = parent.attribs.style.indexOf(';', idx);
                const alignment = parent.attribs.style
                  .substr(midIdx + 1, lastIdx)
                  .trim();

                if (alignment.includes('left')) {
                  CSSstyle.push(['align-self', 'flex-start']);
                } else if (alignment.includes('center')) {
                  CSSstyle.push(['align-self', 'center']);
                } else if (alignment.includes('right')) {
                  CSSstyle.push(['align-self', 'flex-end']);
                }
              }
            }

            // Break out after finding the first matching node
            return false;
          }

          // Make sure you return true. If you don't return a value, `every()` will stop.
          return true;
        });

        // Rewrite relative backend urls
        element.attribs.src = rewriteBackendURL(element.attribs.src);

        // This is equivalent to targetting a "p > img" CSS selector.
        // if (isDomElement(element.parent) && element.parent.tagName === 'p') {
        //   return {
        //     marginTop: 0,
        //     marginBottom: 0
        //   };
        // }

        // Default left alignment
        if (CSSstyle.length === 0) {
          CSSstyle.push(['align-self', 'flex-start']);
        }
        return transform(CSSstyle);
      }
    }),
    a: defaultHTMLElementModels.a.extend({
      getMixedUAStyles(tnode, element) {
        // Rewrite relative backend urls
        element.attribs.href = rewriteBackendURL(element.attribs.href);
      }
    })
  };
  /* #endregion */

  /* #region Renderers */
  return (
    <RenderHtml
      contentWidth={Dimensions.get('window').width}
      renderers={renderers}
      customHTMLElementModels={customHTMLElementModels}
      WebView={WebView}
      defaultWebViewProps={
        {
          /* Any prop you want to pass to all WebViews */
        }
      }
      renderersProps={{
        iframe: {
          scalesPageToFit: true,
          webViewProps: {
            /* Any prop you want to pass to iframe WebViews */
          }
        },
        img: {
          enableExperimentalPercentWidth: true
        }
      }}
      source={
        source
          ? source.hasOwnProperty('html')
            ? source.html
              ? source
              : { html: '' }
            : source
          : { html: '' }
      }
      allowedStyles={['display']}
      systemFonts={[...defaultSystemFonts, 'default-regular', 'default-title']}
      baseStyle={
        baseStyle
          ? {
              fontFamily: 'default-regular',
              fontSize: 16,
              ...baseStyle
            }
          : {
              fontFamily: 'default-regular',
              fontSize: 16
            }
      }
      tagsStyles={{
        h1: {
          fontFamily: 'default-title',
          fontSize: 32,
          marginBottom: 10
        },
        h2: {
          fontFamily: 'default-title',
          fontSize: 28,
          marginBottom: 10
        },
        h3: {
          fontFamily: 'default-title',
          fontSize: 24,
          marginBottom: 10
        },
        h4: {
          fontFamily: 'default-title',
          fontSize: 20,
          marginBottom: 10
        },
        h5: {
          fontFamily: 'default-title',
          fontSize: 18,
          marginBottom: 10
        },
        h6: {
          fontFamily: 'default-title',
          fontSize: 16,
          marginBottom: 10
        },
        p: {
          marginVertical: 5
        },
        ul: {
          marginTop: 15
        },
        iframe: {
          marginTop: 15
        }
      }}
    />
  );
  /* #endregion */
};

export default HTMLComponent;
export { HTMLSource };
