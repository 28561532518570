/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState } from 'react';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { calculateLastInputPrice } from '../../helpers/filter';
import {
  View,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import { MainText } from '../../components/UI/StyledText';
import TouchableComponent from '../UI/TouchableComponent';
import { RootState } from '../../store';
import {
  IProductAttribute,
  ProductAttributeJsonValueType,
  ProductAttributeValueTypes
} from '../../types';

export type CartItemProps = ThemeProps & {
  attributes: Array<IProductAttribute>;
  quantity: number;
  title: string;
  amount: number;
  onEdit?: () => void;
  onAdd?: () => void;
  onRemove?: () => void;
};

const CartItem = (props: CartItemProps): JSX.Element => {
  /* #region Fields */
  const {
    attributes,
    quantity,
    title,
    amount,
    onEdit,
    onAdd,
    onRemove,
    lightColor,
    darkColor
  } = props;
  const isMounted = useIsMounted();
  const language = useSelector((state: RootState) => state.settings.language);
  const currency = useSelector((state: RootState) => state.settings.currency);
  const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
    useSelector(
      (state: RootState) => state.settings.productAttributeValueTypes
    );
  const { styles, deviceSize } = useStyles();
  const [isVisible, setIsVisible] = useState<boolean>(
    attributes.length > 0 ? true : false
  );
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const destructiveColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'destructive'
  );
  const textAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'textAccent'
  );
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'navlinkBackground'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Methods */
  const details = attributes ? (
    <View style={[styles('cartItemInfo'), { backgroundColor }]}>
      {_.sortBy(attributes, ['json.type', 'sortOrder', 'name']).map(
        (item, index) => {
          const json_field = item.json;
          const value_type = productAttributeValueTypes?.find(
            (valuetype) => valuetype.id === json_field.type
          );

          let label = '';
          if (
            json_field.value.input &&
            json_field.value.input.hasOwnProperty('replaceLabel')
          ) {
            const newLabel: string =
              json_field.value.input.replaceLabel?.replace(
                '%n',
                json_field.value.input.lastInput
              );
            label = newLabel;
          } else {
            label = json_field.value.label;
          }

          // calculate price * lastInput
          let finalPrice = 0;
          if (
            value_type?.type_name === t('productAttributeValueTypeDictionary')
          ) {
            finalPrice = calculateLastInputPrice(
              json_field.value as ProductAttributeJsonValueType
            ).finalPrice!;
          }

          return (
            <View key={index} style={styles('infoItem')}>
              <MainText
                style={{ flex: 1, color: textAccentColor }}
                small={
                  [
                    DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                    DEVICE_SIZES.SMALL_DEVICE,
                    DEVICE_SIZES.MEDIUM_DEVICE
                  ].includes(deviceSize)
                    ? true
                    : false
                }
              >
                {item.name}
              </MainText>
              {value_type?.type_name === t('productAttributeValueTypeString') ||
              value_type?.type_name === t('productAttributeValueTypeNumber') ? (
                <MainText
                  style={{ flex: 1, color: textAccentColor }}
                  small={
                    [
                      DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                      DEVICE_SIZES.SMALL_DEVICE,
                      DEVICE_SIZES.MEDIUM_DEVICE
                    ].includes(deviceSize)
                      ? true
                      : false
                  }
                >
                  {json_field.value}
                </MainText>
              ) : (
                <MainText
                  style={{ flex: 1, color: textAccentColor }}
                  small={
                    [
                      DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                      DEVICE_SIZES.SMALL_DEVICE,
                      DEVICE_SIZES.MEDIUM_DEVICE
                    ].includes(deviceSize)
                      ? true
                      : false
                  }
                >
                  {label}
                </MainText>
              )}
              {json_field.value.hasOwnProperty('finalPrice') ? (
                <MainText
                  style={{ flex: 1, color: textAccentColor }}
                  small={
                    [
                      DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                      DEVICE_SIZES.SMALL_DEVICE,
                      DEVICE_SIZES.MEDIUM_DEVICE
                    ].includes(deviceSize)
                      ? true
                      : false
                  }
                >
                  {finalPrice.toLocaleString(language, {
                    style: 'currency',
                    currency: currency
                  })}
                </MainText>
              ) : (
                <View style={{ flex: 1, backgroundColor: 'transparent' }} />
              )}
            </View>
          );
        }
      )}
    </View>
  ) : null;
  /* #endregion */

  /* #region Renderers */
  return (
    <View>
      <View style={styles('cartItem')}>
        <View style={styles('itemData')}>
          <MainText
            style={{ color: textAccentColor }}
            small={
              [
                DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                DEVICE_SIZES.SMALL_DEVICE,
                DEVICE_SIZES.MEDIUM_DEVICE
              ].includes(deviceSize)
                ? true
                : false
            }
          >
            {quantity} {t('piece', { count: quantity })}{' '}
          </MainText>
          <MainText
            small={
              [
                DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                DEVICE_SIZES.SMALL_DEVICE,
                DEVICE_SIZES.MEDIUM_DEVICE
              ].includes(deviceSize)
                ? true
                : false
            }
          >
            {title}
          </MainText>
          {attributes.length > 0 && (
            <TouchableComponent
              onPress={() => updateState(() => setIsVisible(!isVisible))}
              style={styles('infoButton')}
            >
              <Ionicons
                name={
                  isVisible
                    ? 'chevron-up-circle-outline'
                    : 'chevron-down-circle-outline'
                }
                color={buttonAccentColor}
              />
            </TouchableComponent>
          )}
        </View>
        <View style={styles('itemData')}>
          <MainText
            small={
              [
                DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                DEVICE_SIZES.SMALL_DEVICE,
                DEVICE_SIZES.MEDIUM_DEVICE
              ].includes(deviceSize)
                ? true
                : false
            }
          >
            {amount.toLocaleString(language, {
              style: 'currency',
              currency: currency
            })}
          </MainText>
          {onEdit && (
            <TouchableComponent onPress={onEdit} style={styles('editButton')}>
              <Ionicons name="create-outline" color={textAccentColor} />
            </TouchableComponent>
          )}
          {onAdd && (
            <TouchableComponent onPress={onAdd} style={styles('addButton')}>
              <Ionicons name="add-circle" color={buttonAccentColor} />
            </TouchableComponent>
          )}
          {onRemove && (
            <TouchableComponent
              onPress={onRemove}
              style={styles('deleteButton')}
            >
              <Ionicons name="trash-bin" color={destructiveColor} />
            </TouchableComponent>
          )}
        </View>
      </View>
      {isVisible && details}
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    cartItem: {
      padding: 10,
      backgroundColor: 'transparent',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginHorizontal: 20
    },
    itemData: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    infoButton: {
      marginLeft: 10
    },
    editButton: {
      marginLeft: 20
    },
    addButton: {
      marginLeft: 5
    },
    deleteButton: {
      marginLeft: 5
    },
    cartItemInfo: {
      padding: 10,
      marginHorizontal: 20,
      marginBottom: 20
    },
    infoItem: {
      flex: 1,
      backgroundColor: 'transparent',
      alignSelf: 'auto',
      flexDirection: 'row'
    }
  },
  {}
);

export default CartItem;
