/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';

import useIsMounted from '../../hooks/useIsMounted';
import Config from '../../constants/Config';
import { View, ThemeProps, useThemeColor } from './Themed';

export type MapPreviewProps = ThemeProps & {
  latitude: number;
  longitude: number;
  style?: StyleSheet['props'];
  onPress?: () => void;
};

const MapPreview = (props: MapPreviewProps): JSX.Element => {
  //#region Fields
  const { longitude, latitude, style, onPress, lightColor, darkColor } = props;
  const isMounted = useIsMounted();
  const pinColor = useThemeColor({ light: lightColor, dark: darkColor }, 'pin');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  //#endregion

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (longitude && latitude) {
      // const geoJSON = {
      //   type: 'Feature',
      //   properties: {
      //     'marker-size': 'l',
      //     'marker-color': pinColor
      //   },
      //   geometry: {
      //     type: 'Point',
      //     coordinates: [longitude, latitude]
      //   }
      // };
      const geoJSON = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              'marker-size': 'medium',
              'marker-symbol': 'marker',
              'marker-color': pinColor
            },
            geometry: {
              type: 'Point',
              coordinates: [longitude, latitude]
            }
          }
        ]
      };
      const layer = {
        id: 'circle-fill',
        source: 'composite',
        paint: {
          'circle-radius': {
            stops: [
              [5, 1],
              [15, 1024]
            ],
            base: 2
          },
          'circle-color': 'red',
          'circle-opacity': 0.6
        }
      };
      updateState(() =>
        setImagePreviewUrl(
          `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(${encodeURIComponent(
            JSON.stringify(geoJSON)
          )})/${longitude},${latitude},14/500x500?access_token=${
            Config().mapboxApiKey
          }`
        )
      ); // &addlayer={${encodeURIComponent(JSON.stringify(layer).slice(1, -1))}}
    }
  }, [longitude, latitude]);
  //#endregion

  if (onPress) {
    return (
      <TouchableOpacity style={[styles.mapPreview, style]} onPress={onPress}>
        {imagePreviewUrl && (
          <Image style={styles.mapImage} source={{ uri: imagePreviewUrl }} />
        )}
      </TouchableOpacity>
    );
  }

  return (
    <View style={[styles.mapPreview, style]}>
      {imagePreviewUrl && (
        <Image style={styles.mapImage} source={{ uri: imagePreviewUrl }} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mapPreview: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  mapImage: {
    width: '100%',
    height: '100%'
  }
});

export default MapPreview;
