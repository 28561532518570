/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { Dimensions } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackActions, useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { t } from '../../helpers/localized';
import { getDefaultHeaderHeight } from '../../helpers/screen-data';
import { ThemeProps, useThemeColor, View } from './Themed';
import { MainText } from './StyledText';
import SearchModal from '../shop/SearchModal';
import TransitionLogo from '../animated/TransitionLogo';
import { RootState } from '../../store';

export type ToolbarProps = ThemeProps & {
  style?: StyleSheet['props'];
  children?: string;
};

const Toolbar = (props: ToolbarProps): JSX.Element => {
  /* #region Fields */
  const { styles, deviceSize } = useStyles();
  const { width } = Dimensions.get('window');
  const { style, lightColor, darkColor } = props;
  const collapsedHeader: boolean = useSelector(
    (state: RootState) => state.settings.collapsedHeader
  );
  const headerBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerBackground'
  );
  const navigation = useNavigation();
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[
        styles('container'),
        {
          backgroundColor: headerBackgroundColor,
          height: getDefaultHeaderHeight(),
          width: width - 150
        },
        style
      ]}
    >
      <View style={styles('linkDirection')}>
        {[DEVICE_SIZES.EXTRA_SMALL_DEVICE, DEVICE_SIZES.SMALL_DEVICE].includes(
          deviceSize
        ) ? (
          <></>
        ) : (
          <>
            <View style={styles('link')}>
              <MainText
                style={styles('linkText')}
                hoverable
                strong
                onPress={() => {
                  navigation.dispatch({
                    ...StackActions.replace('Shop', {
                      screen: 'Products',
                      params: {
                        screen: 'ShopCategories'
                      }
                    })
                  });
                }}
              >
                {t('titleProductsNavigation')}
              </MainText>
            </View>
            <View style={styles('link')}>
              <SearchModal
                style={{ flex: 1 }}
                labelStyle={[styles('linkText'), styles('linkMargin')]}
                showLabel
              />
            </View>
          </>
        )}
      </View>

      <View style={styles('logo')}>
        <TransitionLogo toggleItem={!collapsedHeader} />
      </View>
      <View
        style={[
          styles('linkDirection'),
          {
            backgroundColor: 'transparent'
          }
        ]}
      >
        {[DEVICE_SIZES.EXTRA_SMALL_DEVICE, DEVICE_SIZES.SMALL_DEVICE].includes(
          deviceSize
        ) ? (
          <View style={styles('search')}>
            <SearchModal style={{ flex: 1 }} />
          </View>
        ) : (
          <>
            <View
              style={[
                styles('link'),
                {
                  alignItems: 'flex-end'
                }
              ]}
            >
              <MainText
                style={styles('linkText')}
                hoverable
                strong
                onPress={() => {
                  navigation.dispatch({
                    ...StackActions.replace('Shop', { screen: 'News' })
                  });
                }}
              >
                {t('titleNewsNavigation')}
              </MainText>
            </View>
            <View
              style={[
                styles('link'),
                {
                  alignItems: 'flex-end'
                }
              ]}
            >
              <MainText
                style={[styles('linkText'), styles('linkMargin')]}
                hoverable
                strong
                onPress={() => {
                  navigation.dispatch({
                    ...StackActions.replace('Shop', {
                      screen: 'Blog',
                      params: {
                        screen: 'Articles',
                        params: {}
                      }
                    })
                  });
                }}
              >
                {t('titleBlogNavigation')}
              </MainText>
            </View>
          </>
        )}
      </View>
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    title: {
      alignSelf: 'center',
      fontWeight: 'bold',
      paddingHorizontal: 20
    },
    logo: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },
    linkDirection: {
      flex: 1,
      flexDirection: 'row'
    },
    link: {
      flex: 1,
      marginLeft: 10,
      marginRight: 10
    },
    linkText: {
      fontSize: 20
    },
    linkMargin: {
      marginTop: 0
    },
    search: {
      flex: 1,
      alignItems: 'flex-end',
      backgroundColor: 'transparent'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      linkDirection: {
        flexDirection: 'column'
      },
      link: {
        marginLeft: 5,
        marginRight: 5
      },
      linkText: {
        fontSize: 18
      },
      linkMargin: {
        marginTop: 5
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      linkDirection: {
        flexDirection: 'column'
      },
      link: {
        marginLeft: 5,
        marginRight: 5
      },
      linkText: {
        fontSize: 18
      },
      linkMargin: {
        marginTop: 5
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      linkDirection: {
        flexDirection: 'column'
      },
      link: {
        marginLeft: 0,
        marginRight: 0
      },
      linkText: {
        fontSize: 14
      },
      linkMargin: {
        marginTop: 5
      }
    }
  }
);

export default Toolbar;
