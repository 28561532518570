/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, FlatList, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { productAttributeFilter } from '../../helpers/filter';
import { t, parseLocaleNumber } from '../../helpers/localized';
import { getDefaultHeaderHeight, LayoutProps } from '../../helpers/screen-data';
import PartnerSite from '../../models/PartnerSite';
import Product from '../../models/Product';
import {
  View,
  Button,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText, MainText, TitleText } from '../../components/UI/StyledText';
import ContainerView from '../../components/UI/ContainerView';
import AlertModal from '../../components/UI/AlertModal';
import TouchableComponent from '../../components/UI/TouchableComponent';
import { HEADER_HEIGHT } from '../../components/web/BigHeader';
import Loading from '../../components/UI/Loading';
import ImageCarousel from '../../components/UI/ImageCarousel';
import HTMLComponent from '../../components/UI/HTMLComponent';
import MapPreview from '../../components/UI/MapPreview';
import ShopItem from '../../components/shop/ShopItem';
import Boop from '../../components/animated/Boop';
import TabBar, { DefaultTab } from '../../components/UI/TabBar';
import Configurator from '../../components/product/Configurator';
import { allProducts, selectedProductBySlug } from '../../store/reselect';
import * as cartActions from '../../store/actions/cart';
import { RootState } from '../../store';
import { ProductAttributeValueTypes, RentalsStackParamList } from '../../types';

const RentalDetailScreen = (
  {
    route,
    navigation
  }: StackScreenProps<RentalsStackParamList, 'RentalDetail'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const { styles, deviceSize } = useStyles();
  const { width, height } = Dimensions.get('window');
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const animatedWidthOffset = useRef(new Animated.Value(0)).current;
  const animatedHeightOffset = useRef(
    new Animated.Value(HEADER_HEIGHT)
  ).current;
  const [stickyPriceInfoLayout, setStickyPriceInfoLayout] =
    useState<LayoutProps>();
  const [stickyPriceInfoEnabled, setStickyPriceInfoEnabled] =
    useState<boolean>(false);
  const isMounted = useIsMounted();
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const language = useSelector((state: RootState) => state.settings.language);
  const currency = useSelector((state: RootState) => state.settings.currency);
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const [productPrice, setProductPrice] = useState<number>(0);
  const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
    useSelector(
      (state: RootState) => state.settings.productAttributeValueTypes
    );
  const productSlug = route.params?.productSlug;
  const partnerSiteSlug = route.params?.partnerSiteSlug;
  const itemKey = route.params?.itemKey;
  const selectedProduct = useSelector(selectedProductBySlug(productSlug));
  const filteredPartnerSites = useSelector((state: RootState) => {
    if (selectedProduct) {
      return state.products.availablePartnerSites.filter(
        (partner: PartnerSite) => partner.productId.includes(selectedProduct.id)
      );
    } else {
      return [];
    }
  });
  const selectedPartnerSite = filteredPartnerSites?.find(
    (item: PartnerSite) => item.slug === partnerSiteSlug
  );
  const availableProducts = useSelector(allProducts(partnerSiteSlug));
  const partnerSiteProducts = useSelector((state: RootState) => {
    if (partnerSiteSlug) {
      const partnerSite = state.products.availablePartnerSites.find(
        (partner: PartnerSite) => partner.slug === partnerSiteSlug
      );
      return availableProducts.filter(
        (prod: Product) =>
          partnerSite.productId?.includes(prod.id) &&
          prod.id !== selectedProduct?.id
      );
    } else {
      return [];
    }
  });
  let additionalPricing = 0;
  const selectedProductAttributes = useSelector((state: RootState) => {
    if (selectedProduct) {
      const filteredAttributes =
        state.productattributes.selectedProductAttributes.filter(
          (attr) => attr.productId === selectedProduct.id
        );

      const filtered = productAttributeFilter(
        productAttributeValueTypes,
        filteredAttributes
      );

      // console.log('RentalDetailScreen selectedProductAttributes', filtered);
      return _.sortBy(filtered, ['sortOrder', 'json.type', 'name']);
    }
    return null;
  });
  const [index, setIndex] = useState<number>(0);
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const textAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'textAccent'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const detailsBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'tabIconDefault'
  );
  const shadowLightColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'shadowLight'
  );
  const shadowHardColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'shadowHard'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (selectedProductAttributes) {
      // calculate additional pricing
      selectedProductAttributes.forEach((element) => {
        if (element.json.value.hasOwnProperty('finalPrice')) {
          additionalPricing += parseLocaleNumber(element.json.value.finalPrice);
        }

        // calculate additional price for lastInput
        if (element.json.value.input) {
          let finalStepPrice = parseLocaleNumber(
            element.json.value.input.finalStepPrice
          );
          finalStepPrice = finalStepPrice * element.json.value.input.lastInput;
          additionalPricing += finalStepPrice;
        }
      });

      if (selectedProduct) {
        setProductPrice(
          parseLocaleNumber(selectedProduct.price) + additionalPricing
        );
      }
    }
  }, [selectedProductAttributes, selectedProduct]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${selectedProduct?.title} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleRentalDetailScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              // ToDo: no shopping cart screen, instead show rental cart screen
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, selectedProduct, cartItemCount]);

  const addToCartHandler = useCallback(() => {
    dispatch(cartActions.addToCart(selectedProduct, itemKey));
    // set up modal
    updateState(() =>
      setAlertModalContent(
        <AlertModal
          message={itemKey ? t('messageUpdateCart') : t('messageAddToCart')}
          icon={'checkmark-circle'}
          iconColor={'green'}
          onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          useAutoClosing
        />
      )
    );
  }, [selectedProduct, itemKey]);

  const selectItemHandler = useCallback(
    (slug: string) => {
      navigation.push('Shop', {
        screen: 'Rentals',
        params: {
          screen: 'RentalDetail',
          params: {
            productSlug: slug,
            partnerSiteSlug: partnerSiteSlug,
            enableBackButton: true
          }
        }
      });
    },
    [navigation]
  );
  /* #endregion */

  /* #region Renderers */
  if (!selectedProduct) {
    return <Loading />;
  }

  const productSummary = (
    <>
      <TitleText style={styles('title')}>{selectedProduct.title}</TitleText>
      <View style={styles('description')}>
        <View style={{ marginBottom: 20, backgroundColor: 'transparent' }}>
          <HTMLComponent source={{ html: selectedProduct.description }} />
        </View>
        {selectedProductAttributes?.length > 0 && (
          <>
            <LabelText strong>{t('titleCurrentConfiguration')}</LabelText>
            <View style={{ marginTop: 10 }}>
              {selectedProductAttributes
                ?.filter((attr) => {
                  const json_field = attr.json;
                  const value_type = productAttributeValueTypes?.find(
                    (valuetype) => valuetype.id === json_field.type
                  );

                  if (
                    value_type?.type_name ===
                    t('productAttributeValueTypeDictionary')
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((item, index) => {
                  let label = '';
                  if (
                    item.json.value.input &&
                    item.json.value.input.hasOwnProperty('replaceLabel')
                  ) {
                    const newLabel: string =
                      item.json.value.input.replaceLabel?.replace(
                        '%n',
                        item.json.value.input.lastInput
                      );
                    label = newLabel;
                  } else {
                    label = item.json.value.label;
                  }

                  return (
                    <View key={index.toString()} style={styles('infoItem')}>
                      <MainText strong style={{ flex: 1 }}>
                        {item.name}:
                      </MainText>
                      <MainText style={{ flex: 1 }}>{label}</MainText>
                    </View>
                  );
                })}
            </View>
          </>
        )}
      </View>
      <View style={styles('actions')}>
        <Button
          disabled={selectedPartnerSite ? false : true}
          color={buttonColor}
          title={itemKey ? t('titleUpdateCart') : t('titleAddToCart')}
          onPress={addToCartHandler}
        />
      </View>
      <MainText
        onLayout={(event) => {
          const layout: LayoutProps = event.nativeEvent.layout;
          // TODO: After browser page reload, the layout.top isn't set correctly. It seems that the HEADER_HEIGHT is missing
          // set offset, where productPrice text begins (add height of dynamic header - height of standard navigation header)
          updateState(() => setStickyPriceInfoLayout(layout));
        }}
        strong
        style={[styles('price'), { color: textAccentColor }]}
      >
        {productPrice.toLocaleString(language, {
          style: 'currency',
          currency: currency
        })}
      </MainText>
    </>
  );

  const firstTab = partnerSiteSlug ? (
    <View style={styles('scene')}>
      <MainText>{t('messageConfigurator')}</MainText>
      <View style={styles('configurator')}>
        <Configurator
          originKey={partnerSiteSlug}
          prodId={selectedProduct.id}
          filterProductAttributeValueTypes={[
            t('productAttributeValueTypeDictionary'),
            t('productAttributeValueTypeList')
          ]}
          selectedProductAttributes={selectedProductAttributes}
        />
      </View>
    </View>
  ) : (
    <View style={styles('scene')}>
      <MainText>{t('messageChooseLocation')}</MainText>
    </View>
  );

  const secondTab = (
    <View style={styles('scene')}>
      <MainText>{t('messageAdditionalInformation')}</MainText>
      {_.sortBy(
        selectedProductAttributes?.filter((attr) => {
          const json_field = attr.json;
          const value_type = productAttributeValueTypes?.find(
            (valuetype) => valuetype.id === json_field.type
          );

          return (
            value_type?.type_name === t('productAttributeValueTypeString') ||
            value_type?.type_name === t('productAttributeValueTypeNumber')
          );
        }),
        ['sortOrder', 'json.type', 'name']
      ).map((item, index) => {
        return (
          <View style={styles('infoTable')} key={index.toString()}>
            <View
              style={[
                styles('infoTableRow'),
                {
                  borderColor,
                  backgroundColor:
                    index % 2 ? backgroundColor : detailsBackgroundColor
                }
              ]}
            >
              <View
                style={[
                  styles('infoTableCell'),
                  {
                    backgroundColor:
                      index % 2 ? backgroundColor : detailsBackgroundColor,
                    width: [
                      DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                      DEVICE_SIZES.SMALL_DEVICE
                    ].includes(deviceSize)
                      ? containerDimension.width
                      : containerDimension.width / 2
                  }
                ]}
              >
                <MainText strong>{item.name}</MainText>
              </View>
              <View
                style={[
                  styles('infoTableCell'),
                  {
                    backgroundColor:
                      index % 2 ? backgroundColor : detailsBackgroundColor
                  }
                ]}
              >
                <MainText>{item.json.value}</MainText>
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );

  const thirdTab = (
    <View style={styles('scene')}>
      <MainText>{t('messageConstructionDrawing')}</MainText>
      <View style={styles('configurator')}>
        <Configurator
          originKey={partnerSiteSlug}
          prodId={selectedProduct.id}
          filterProductAttributeValueTypes={[
            t('productAttributeValueTypeImage')
          ]}
          selectedProductAttributes={selectedProductAttributes}
        />
      </View>
    </View>
  );

  const fourthTab = (
    <View style={styles('scene')}>
      <MainText>{t('messageInstructionsForUse')}</MainText>
      <View style={styles('configurator')}>
        <Configurator
          originKey={partnerSiteSlug}
          prodId={selectedProduct.id}
          filterProductAttributeValueTypes={[
            t('productAttributeValueTypeFile')
          ]}
          selectedProductAttributes={selectedProductAttributes}
        />
      </View>
    </View>
  );

  const stickyHeaderComponent = stickyPriceInfoEnabled ? (
    <View
      style={{
        flexDirection: 'row',
        ...Platform.select({
          android: {
            elevation: 5
          },
          default: {
            shadowColor: shadowLightColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.26,
            shadowRadius: 8
          }
        }),
        position: 'absolute',
        top: isWeb
          ? getDefaultHeaderHeight()
          : 0 /* Height of standard navigation header */,
        left: 0,
        right: 0,
        zIndex: 10,
        height: stickyPriceInfoLayout?.height + 10
      }}
    >
      <Animated.View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 20,
          height: stickyPriceInfoLayout?.height + 10,
          width: animatedWidthOffset
        }}
      >
        <MainText
          style={{ textAlign: 'center', color: textAccentColor }}
          strong
        >
          {productPrice.toLocaleString(language, {
            style: 'currency',
            currency: currency
          })}
        </MainText>
        <View style={{ paddingHorizontal: 10 }}>
          <Ionicons name="arrow-forward" color={textAccentColor} />
        </View>
        <TouchableComponent onPress={addToCartHandler}>
          <Ionicons name="cart" color={buttonColor} />
        </TouchableComponent>
      </Animated.View>
    </View>
  ) : (
    <></>
  );

  return (
    <ContainerView
      scrollable
      scrollEventThrottle={8}
      onHeaderHeightChange={(value) => animatedHeightOffset.setValue(value)}
      onScroll={(event) => {
        const layout = event.nativeEvent.contentOffset;
        const diffOffset = stickyPriceInfoLayout
          ? stickyPriceInfoLayout.top -
            animatedHeightOffset.__getValue() -
            layout.y
          : 0;
        if (diffOffset <= 0) {
          // calculate offset for X-transform animation
          const offsetModifier =
            width > stickyPriceInfoLayout?.width
              ? stickyPriceInfoLayout?.width + diffOffset * -2
              : stickyPriceInfoLayout?.width;
          if (offsetModifier <= width) {
            animatedWidthOffset.setValue(offsetModifier);
          }
          if (partnerSiteSlug && !stickyPriceInfoEnabled) {
            updateState(() => setStickyPriceInfoEnabled(true));
          }
        } else {
          if (stickyPriceInfoEnabled) {
            updateState(() => setStickyPriceInfoEnabled(false));
          }
        }
      }}
      seoTitle={`${selectedProduct?.title} | ${t('appTitle')}`}
      seoDescription={selectedProduct?.description?.replace(
        /(<([^>]+)>)/gi,
        ''
      )}
      seoMeta={[{ name: 'robots', content: 'index,nofollow' }]}
      headerComponent={stickyHeaderComponent}
    >
      <View style={styles('container')}>
        {alertModalContent}
        <View
          onLayout={(event) => {
            const { width, height } = event.nativeEvent.layout;
            updateState(() => setContainerDimension({ width, height }));
          }}
          style={styles('leftSide')}
        >
          <View style={styles('summary')}>
            <View
              style={{
                width: [
                  DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                  DEVICE_SIZES.SMALL_DEVICE
                ].includes(deviceSize)
                  ? containerDimension.width
                  : containerDimension.width / 2
              }}
            >
              {productSummary}
            </View>
            <View style={styles('imageContainer')}>
              <ImageCarousel
                style={{
                  width: [
                    DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                    DEVICE_SIZES.SMALL_DEVICE
                  ].includes(deviceSize)
                    ? containerDimension.width
                    : containerDimension.width / 2
                }}
                images={selectedProduct.images}
              />
            </View>
          </View>
          {selectedProductAttributes?.length > 0 && (
            <>
              <TabBar
                containerStyle={styles('tabContainer')}
                tabStyle={styles('tab')}
                tabs={[
                  <DefaultTab
                    icon="calculator"
                    title={t('titleConfigurator')}
                  />,
                  <DefaultTab
                    icon="clipboard"
                    title={t('titleAdditionalInformation')}
                  />,
                  <DefaultTab
                    icon="map"
                    title={t('titleConstructionDrawing')}
                  />,
                  <DefaultTab
                    icon="book"
                    title={t('titleInstructionsForUSe')}
                  />
                ]}
                selectedIndex={index}
                onSelect={(idx) => updateState(() => setIndex(idx))}
              />
              <View style={{ padding: 20 }}>
                {index === 0 && firstTab}
                {index === 1 && secondTab}
                {index === 2 && thirdTab}
                {index === 3 && fourthTab}
              </View>
            </>
          )}
        </View>
        <View
          style={[
            styles('rightSide'),
            [
              DEVICE_SIZES.EXTRA_SMALL_DEVICE,
              DEVICE_SIZES.SMALL_DEVICE,
              DEVICE_SIZES.MEDIUM_DEVICE
            ].includes(deviceSize)
              ? { width: '100%' }
              : {
                  width: '35%',
                  minHeight:
                    height > containerDimension.height
                      ? containerDimension.height
                      : height,
                  maxHeight:
                    height > containerDimension.height
                      ? height
                      : containerDimension.height
                }
          ]}
        >
          <View
            style={[
              styles('detailsContainer'),
              {
                backgroundColor: detailsBackgroundColor,
                shadowColor: shadowHardColor
              }
            ]}
          >
            <TitleText>{t('titleLocationDetails')}</TitleText>
            {!partnerSiteSlug ? (
              <View style={styles('details')}>
                <MainText style={{ marginVertical: 10 }}>
                  {t('messageChooseLocation')}
                </MainText>
                {filteredPartnerSites?.map((item: PartnerSite) => {
                  return (
                    <Boop key={item.id.toString()} x={5} y={0}>
                      <TouchableComponent
                        style={{ margin: 5 }}
                        onPress={() =>
                          navigation.push('Shop', {
                            screen: 'Rentals',
                            params: {
                              screen: 'RentalDetail',
                              params: {
                                productSlug: selectedProduct.slug,
                                partnerSiteSlug: item.slug,
                                enableBackButton: true
                              }
                            }
                          })
                        }
                      >
                        <Ionicons name="pricetags" size={20}>
                          <LabelText
                            style={{ marginHorizontal: 10 }}
                            strong
                            small
                          >
                            {item.name}
                          </LabelText>
                        </Ionicons>
                      </TouchableComponent>
                    </Boop>
                  );
                })}
              </View>
            ) : (
              <View style={styles('details')}>
                <TitleText small>{selectedPartnerSite.name}</TitleText>
                <MapPreview
                  style={styles('mapPreview')}
                  latitude={selectedPartnerSite.latitude}
                  longitude={selectedPartnerSite.longitude}
                />
                <LabelText strong>
                  {t('titleGPSCoordinates')}:{' '}
                  <MainText>
                    {selectedPartnerSite.latitude},{' '}
                    {selectedPartnerSite.longitude}
                  </MainText>
                </LabelText>
                {selectedPartnerSite.website && (
                  <LabelText strong>
                    {t('titleHomepage')}:{' '}
                    <MainText>{selectedPartnerSite.website}</MainText>
                  </LabelText>
                )}
                {selectedPartnerSite.email && (
                  <LabelText strong>
                    {t('inputEMailLabel')}:{' '}
                    <MainText>{selectedPartnerSite.email}</MainText>
                  </LabelText>
                )}
                {selectedPartnerSite.phone && (
                  <LabelText strong>
                    {t('inputPhoneLabel')}:{' '}
                    <MainText>{selectedPartnerSite.phone}</MainText>
                  </LabelText>
                )}
                {selectedPartnerSite.mobile && (
                  <LabelText strong>
                    {t('inputMobileLabel')}:{' '}
                    <MainText>{selectedPartnerSite.mobile}</MainText>
                  </LabelText>
                )}
              </View>
            )}
          </View>
          <View
            style={[
              styles('detailsContainer'),
              { flex: 1, shadowColor: shadowHardColor }
            ]}
          >
            <TitleText>{t('titleOtherModels')}</TitleText>
            {partnerSiteProducts.length > 0 ? (
              <FlatList
                contentContainerStyle={{
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
                data={partnerSiteProducts}
                keyExtractor={(item, index) => index.toString()}
                removeClippedSubviews={true}
                renderItem={(itemData) => (
                  <ShopItem
                    style={{
                      width: [
                        DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                        DEVICE_SIZES.SMALL_DEVICE,
                        DEVICE_SIZES.MEDIUM_DEVICE
                      ].includes(deviceSize)
                        ? containerDimension.width * 0.6
                        : (width - containerDimension.width) * 0.6
                    }}
                    image={itemData.item.images[0]}
                    title={itemData.item.title}
                    price={itemData.item.price}
                    onPress={() => {
                      updateState(() => selectItemHandler(itemData.item.slug));
                    }}
                  />
                )}
              />
            ) : (
              <MainText style={{ marginTop: 10 }}>{t('noProducts')}</MainText>
            )}
          </View>
        </View>
      </View>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    tabContainer: {
      marginTop: 40,
      flexDirection: 'row'
    },
    tab: {},
    scene: {
      flexGrow: 1
    },
    infoTable: {
      justifyContent: 'center',
      alignItems: 'stretch'
    },
    infoTableRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      borderTopWidth: 1,
      borderBottomWidth: 1
    },
    infoTableCell: {
      marginHorizontal: 20
    },
    leftSide: {
      flexGrow: 1,
      width: '65%'
    },
    rightSide: {
      flexGrow: 1,
      alignContent: 'stretch'
    },
    summary: {
      flexDirection: 'row'
    },
    imageContainer: {
      flex: 1,
      alignItems: 'center',
      marginTop: 50
    },
    configurator: {
      paddingVertical: 10,
      paddingHorizontal: 20
    },
    actions: {
      marginTop: 20,
      marginHorizontal: 20,
      alignItems: 'stretch'
    },
    title: {
      textAlign: 'left',
      margin: 20
    },
    description: {
      textAlign: 'left',
      marginHorizontal: 20
    },
    price: {
      textAlign: 'center',
      marginVertical: 10
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    },
    headerModalize: {
      marginTop: 15,
      marginBottom: 10,
      overflow: 'hidden',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    infoItem: {
      flex: 1,
      backgroundColor: 'transparent',
      alignSelf: 'auto',
      flexDirection: 'row'
    },
    detailsContainer: {
      ...Platform.select({
        android: {
          elevation: 8
        },
        default: {
          shadowOffset: { width: 2, height: 4 },
          shadowOpacity: 0.46,
          shadowRadius: 8
        }
      }),
      borderRadius: 5,
      padding: 20,
      margin: 20
    },
    details: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      backgroundColor: 'transparent',
      marginTop: 10
    },
    mapPreview: {
      marginVertical: 10,
      width: '100%',
      minWidth: 250,
      height: 300,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch'
      },
      leftSide: {
        width: '100%'
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch'
      },
      tabContainer: {
        flexDirection: 'column',
        alignSelf: 'center',
        width: '80%'
      },
      tab: {
        width: '100%'
      },
      infoTableRow: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      },
      leftSide: {
        width: '100%'
      },
      summary: {
        flexDirection: 'column'
      },
      imageContainer: {
        marginTop: 0
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch'
      },
      tabContainer: {
        flexDirection: 'column',
        alignSelf: 'center',
        width: '80%'
      },
      tab: {
        width: '100%'
      },
      infoTableRow: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      },
      leftSide: {
        width: '100%'
      },
      summary: {
        flexDirection: 'column'
      },
      imageContainer: {
        marginTop: 0
      }
    }
  }
);

export default RentalDetailScreen;
