/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import _ from 'lodash';

import Config from '../../constants/Config';
import { parseLocaleNumber, t } from '../../helpers/localized';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';
import logger from '../../helpers/logger';
import { recursiveCallAPI, singleCallAPI } from '../../helpers/api-utils';
import Product from '../../models/Product';
import * as notificationActions from './notification';
import * as settingsActions from './settings';
import { RootState } from '..';

export const SET_FETCHED_FROM_API = 'SET_FETCHED_FROM_API';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT_SHOP_CATEGORIES = 'SET_PRODUCT_SHOP_CATEGORIES';
export const SET_PARTNER_SITES = 'SET_PARTNER_SITES';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

let timer: ReturnType<typeof setTimeout>;

export const fetchCacheKey = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    const delayInitialize = await AsyncStorage.getItem(
      settingsActions.APP_INITIALIZED
    );
    if (delayInitialize) {
      setTimeout(() => {
        // wait
        logger.log(
          `store.actions.products - fetchCacheKey: Timer delayInitialize done.`
        );
      }, 3000);
    }

    try {
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'GET',
        `${Config().api_url}/cachekeys/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        }
      );

      resData.results?.forEach(async (res) => {
        if (
          [
            'PartnerSite',
            'PartnerSiteImage',
            'PartnerSiteImageRelation',
            'ProductCategory',
            'ProductCategoryRelation',
            'Product',
            'ProductImage',
            'ProductImageRelation',
            'ShopCategory',
            'ShopCategoryRelation',
            'CurrencyRate'
          ].includes(res.tableName)
        ) {
          const dbCacheKey = res.cacheKey;

          // check dbCacheKey with cacheKey stored in AsyncStorage
          const localCacheKey = await AsyncStorage.getItem(
            `cacheKey${res.tableName}`
          );

          if (
            dbCacheKey &&
            (!localCacheKey ||
              localCacheKey.trim() === '' ||
              (localCacheKey && localCacheKey !== dbCacheKey))
          ) {
            // update cacheKey in AsyncStorage
            await AsyncStorage.setItem(`cacheKey${res.tableName}`, dbCacheKey);

            // delete stored ProductCategory and re-fetch from database
            if (
              ['ProductCategory', 'ProductCategoryRelation'].includes(
                res.tableName
              )
            ) {
              dispatch(fetchCategories());
            }

            // delete stored Product and re-fetch from database
            if (
              ['Product', 'ProductImage', 'ProductImageRelation'].includes(
                res.tableName
              )
            ) {
              dispatch(fetchProducts());
            }

            // delete stored PartnerSite and re-fetch from database
            if (
              [
                'PartnerSite',
                'PartnerSiteImage',
                'PartnerSiteImageRelation'
              ].includes(res.tableName)
            ) {
              dispatch(fetchPartnerSites());
            }

            // delete stored ShopCategory and re-fetch from database
            if (
              ['ShopCategory', 'ShopCategoryRelation'].includes(res.tableName)
            ) {
              dispatch(fetchShopCategories());
            }
          }
        }
      });

      dispatch(setCacheKeyTimer(Config().cacheRefetchTimeout));
    } catch (err) {
      logger.log(
        `store.actions.products - fetchCacheKey: Cannot fetch cache key! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch cache key! Server responded with error - ${err}.`
      );
    }
  };
};

export const setFetchedFromAPI = (value: boolean) => {
  return { type: SET_FETCHED_FROM_API, value };
};

export const fetchShopCategories = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }

      const loadedCategories = await recursiveCallAPI(
        'GET',
        `${Config().api_url}/shopcategories/`,
        headers
      );

      dispatch({
        type: SET_PRODUCT_SHOP_CATEGORIES,
        categories: loadedCategories
      });
    } catch (err) {
      logger.log(
        `store.actions.products - fetchShopCategories: Cannot fetch shop categories! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch shop categories! Server responded with error - ${err}.`
      );
    }
  };
};

export const fetchCategories = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }

      const loadedCategories = await recursiveCallAPI(
        'GET',
        `${Config().api_url}/productcategories/`,
        headers
      );

      dispatch({
        type: SET_PRODUCT_CATEGORIES,
        categories: loadedCategories
      });
    } catch (err) {
      logger.log(
        `store.actions.products - fetchCategories: Cannot fetch categories! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch categories! Server responded with error - ${err}.`
      );
    }
  };
};

export const fetchProducts = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      const currency = getState().settings.currency;
      const currencyRates = getState().settings.currencyRates;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }

      dispatch(setFetchedFromAPI(false));

      const loadedProducts = await recursiveCallAPI(
        'GET',
        `${Config().api_url}/products/`,
        headers
      );

      try {
        loadedProducts.forEach((item: Product) => {
          let price_field = parseLocaleNumber(item.price);
          let currency_field = item.currency;

          // get currency and divide by rate from settings
          let rate_factor = currencyRates[currency_field];
          if (currency_field !== currency) {
            item.price = _.round(price_field / rate_factor, 2);
            item.currency = currency;
          }

          // correct linkedURL
          if (item.linkedURL) {
            item.linkedURL = rewriteBackendURL(item.linkedURL);
          }
        });
      } catch (err) {
        logger.log(
          `store.actions.products - fetchProducts: Cannot loaded products! Error - ${err}.`
        );
      }

      dispatch({
        type: SET_PRODUCTS,
        products: loadedProducts.filter((prod) => prod.is_archived === false),
        userProducts: ownerId
          ? loadedProducts.filter(
              (prod) => prod.ownerId === ownerId && prod.is_archived === false
            )
          : loadedProducts
      });
    } catch (err) {
      logger.log(
        `store.actions.products - fetchProducts: Cannot fetch products! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch products! Server responded with error - ${err}.`
      );
    }
  };
};

export const createProduct = (
  categoryId: number,
  title: string,
  description: string,
  images: Array<string>,
  price: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'POST',
        `${Config().api_url}/products/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        },
        {
          title,
          images,
          description,
          price,
          ownerId,
          categoryId
        }
      );

      dispatch({
        type: CREATE_PRODUCT,
        productData: {
          id: resData.id,
          slug: resData.slug,
          title,
          description,
          linkedURL: null,
          is_pricedByAttribute: false,
          pricedByAttributeId: null,
          images,
          price,
          ownerId,
          categoryId
        }
      });
    } catch (err) {
      logger.log(
        `store.actions.products - createProduct: Cannot create product! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot create product! Server responded with error - ${err}.`
      );
    }
  };
};

export const updateProduct = (
  id: number,
  categoryId: number,
  title: string,
  description: string,
  images: Array<string>,
  price: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'PATCH',
        `${Config().api_url}/products/${id}/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        },
        {
          title,
          images,
          description,
          price,
          ownerId,
          categoryId
        }
      );

      dispatch({
        type: UPDATE_PRODUCT,
        pid: id,
        productData: {
          title: resData.title,
          images: resData.images,
          linkedURL: resData.linkedURL,
          is_pricedByAttribute: resData.is_pricedByAttribute,
          pricedByAttributeId: resData.pricedByAttributeId,
          description: resData.description,
          price: resData.price,
          ownerId: resData.ownerId,
          categoryId: resData.categoryId
        }
      });
    } catch (err) {
      logger.log(
        `store.actions.products - updateProduct: Cannot update product! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot update product! Server responded with error - ${err}.`
      );
    }
  };
};

export const deleteProduct = (
  productId: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const accessToken = getState().auth.accessToken;
      const language = getState().settings.language;
      await singleCallAPI(
        'DELETE',
        `${Config().api_url}/products/${productId}/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        }
      );

      dispatch({
        type: DELETE_PRODUCT,
        pid: productId
      });
    } catch (err) {
      logger.log(
        `store.actions.products - deleteProduct: Cannot delete product! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot delete product! Server responded with error - ${err}.`
      );
    }
  };
};

export const fetchPartnerSites = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const accessToken = getState().auth.accessToken;
      const ownerId = getState().auth.userId;
      const language = getState().settings.language;
      let headers;
      if (ownerId) {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language,
          Authorization: `Token ${accessToken}`
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
          'Accept-Language': language
        };
      }

      const loadedPartnerSites = await recursiveCallAPI(
        'GET',
        `${Config().api_url}/partnersites/`,
        headers
      );

      dispatch({
        type: SET_PARTNER_SITES,
        partnersites: loadedPartnerSites
      });
    } catch (err) {
      logger.log(
        `store.actions.products - fetchPartnerSites: Cannot fetch partner sites! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch partner sites! Server responded with error - ${err}.`
      );
    }
  };
};

const clearCacheKeyTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

const setCacheKeyTimer = (
  expirationTime: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  clearCacheKeyTimer();
  return (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    timer = setTimeout(() => {
      dispatch(fetchCacheKey());
    }, expirationTime);
  };
};
