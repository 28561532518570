/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *
 *  Special thanks goes to https://blog.logrocket.com/caching-images-react-native-tutorial-with-examples/
 *-------------------------------------------------------------*/

import React, { useEffect, useRef, useState } from 'react';
import {
  Image,
  ActivityIndicator,
  ImageResizeMode,
  ImageBackground,
  Platform
} from 'react-native';
import * as FileSystem from 'expo-file-system';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import usePrevious from '../../hooks/usePrevious';
import { ThemeProps, useThemeColor, View } from './Themed';

export type ImageLoaderProps = ThemeProps & {
  component?: typeof Image | typeof ImageBackground;
  source: string;
  fallback?: string;
  cacheKey?: string;
  style?: StyleSheet['props'];
  resizeMode?: ImageResizeMode;
  resizeMethod?: 'auto' | 'resize' | 'scale';
  children?: Array<JSX.Element> | JSX.Element;
  onError?: (uri: string) => void;
  onLoadEnd?: (uri: string) => void;
  onLoadStart?: (uri: string) => void;
  onSuccess?: (uri: string) => void;
};

const ImageLoader = (props: ImageLoaderProps): JSX.Element => {
  /* #region Fields */
  const {
    component,
    source,
    fallback,
    cacheKey,
    style,
    resizeMode,
    resizeMethod,
    onError,
    onLoadEnd,
    onLoadStart,
    onSuccess,
    lightColor,
    darkColor,
    ...rest
  } = props;
  const isMounted = useIsMounted();
  const ImageComponent = component || Image;
  const [imgUri, setUri] = useState('');
  const [imageSources, setImageSources] = useState<Array<string>>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const cacheKeyRef = useRef(cacheKey);
  const prevSource = usePrevious(source);
  const prevFallback = usePrevious(fallback);
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };

  const getImgXtension = (uri: string) => {
    let basename = uri.split(/[\\/]/).pop();

    if (!cacheKeyRef.current) {
      // set default cacheKey
      cacheKeyRef.current = /[.]/.exec(basename!)
        ? basename?.split('.')[0]
        : undefined;
    }
    return /[.]/.exec(basename!) ? /[^.]+$/.exec(basename!) : undefined;
  };

  const findImageInCache = async (uri: string) => {
    try {
      let info = await FileSystem.getInfoAsync(uri);
      return { ...info, err: false };
    } catch (error) {
      return {
        exists: false,
        err: true,
        msg: error
      };
    }
  };

  const cacheImage = async (
    uri: string,
    cacheUri: string,
    callback: () => void
  ) => {
    try {
      const downloadImage = FileSystem.createDownloadResumable(
        uri,
        cacheUri,
        {},
        callback
      );
      const downloaded = await downloadImage.downloadAsync();
      return {
        cached: true,
        err: false,
        path: downloaded?.uri
      };
    } catch (error) {
      return {
        cached: false,
        err: true,
        msg: error
      };
    }
  };

  const getAllImageSources = (props: {
    source: string;
    fallback: string;
  }): Array<string> => {
    const { source, fallback } = props;

    // Get all the image sources from the props and create new array
    let imgSources: Array<string> = [];

    // Concat the source if available
    if (source) {
      imgSources = imgSources.concat(source);
    }

    // Concat the fallback(s) if they are given
    if (fallback) {
      imgSources = imgSources.concat(fallback);
    } else {
      // Add default fallback
      imgSources = imgSources.concat(
        require('../../assets/images/no-image-available.png')
      );
    }

    // Return the filtered out image sources
    // No null should be present here
    // Also, please no duplicates
    return [...new Set(imgSources.filter((imageSource) => imageSource))];
  };

  /* #endregion */

  /* #region Events */
  useEffect(() => {
    const sourceChanged = source !== prevSource;
    const fallbackChanged = fallback !== prevFallback;

    if (imageSources.length === 0 || sourceChanged || fallbackChanged) {
      // Get the imagesources into an array
      const imgSources = getAllImageSources({ source, fallback });

      // skip unnecessary re-renders
      if (!_.isEqual(imageSources, imgSources)) {
        // console.log('Rendered');
        updateState(() => setImageSources(imgSources));
      }
    }
  }, [source, imageSources, fallback, prevSource, prevFallback]);

  useEffect(() => {
    const loadImg = async (uri: string) => {
      // don't use for Web platform or local app assets
      if (Platform.OS === 'web' || uri.startsWith('/static/media')) {
        // console.log('Load from local app assets', uri);
        updateState(() => setUri(uri));
        return;
      }

      let imgXt = getImgXtension(uri);
      if (!imgXt || !imgXt.length) {
        // console.log(`Couldn't load image!`);
        return;
      }

      const cacheFileUri = `${FileSystem.cacheDirectory}${cacheKeyRef.current}.${imgXt[0]}`;
      let imgXistsInCache = await findImageInCache(cacheFileUri);
      if (imgXistsInCache.exists) {
        // console.log('re-cached with key', cacheKeyRef.current);
        updateState(() => setUri(cacheFileUri));
      } else {
        let cached = await cacheImage(uri, cacheFileUri, () => {});
        if (cached.cached) {
          // console.log('cached with new key', cacheKeyRef.current);
          updateState(() => setUri(cached.path!));
        } else {
          // console.log(`Couldn't load image from cache!`);

          // load from given uri
          updateState(() => setUri(uri));
        }
      }
    };

    const imageSource = imageSources[currentImageIndex];
    if (imageSource) {
      loadImg(imageSource);
    }
  }, [imageSources, currentImageIndex]);

  /**
   * Handle image load start
   */
  const handleImageLoadStart = () => {
    // Notify the user what image we are trying to load
    if (onLoadStart) {
      // console.log('Fire onLoadStart', imageSources[currentImageIndex]);
      onLoadStart(imageSources[currentImageIndex]);
    }
  };

  /**
   * Handle image load error
   */
  const handleImageLoadSuccess = () => {
    // Notify the user what image is loaded
    if (onSuccess) {
      // console.log('Fire onSuccess', imageSources[currentImageIndex]);
      onSuccess(imageSources[currentImageIndex]);
    }
  };

  /**
   * Handle image load error
   */
  const handleImageLoadError = () => {
    // Check if we have run out of sources
    if (currentImageIndex >= imageSources.length) {
      // console.log('Fire onError');

      // That's it, we have done everything we can
      onError;
    } else {
      // We still have options
      // Update the state to switch to fallback image
      updateState(() => setCurrentImageIndex(currentImageIndex + 1));
    }
  };

  /**
   * Handle image load end
   */
  const handleImageLoadEnd = () => {
    // Notify the user what image is loaded
    if (onLoadEnd) {
      // console.log('Fire onLoadEnd', imageSources[currentImageIndex]);
      onLoadEnd(imageSources[currentImageIndex]);
    }
  };

  /* #endregion */

  /* #region Renderers */
  return (
    <>
      {imgUri && isMounted.current ? (
        <ImageComponent
          {...rest}
          source={{ uri: imgUri }}
          style={style}
          resizeMode={resizeMode ? resizeMode : 'cover'}
          resizeMethod={resizeMethod ? resizeMethod : 'auto'}
          onError={handleImageLoadError}
          onLoad={handleImageLoadSuccess}
          onLoadEnd={handleImageLoadEnd}
          onLoadStart={handleImageLoadStart}
        />
      ) : (
        <View
          style={[
            style,
            {
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }
          ]}
        >
          <ActivityIndicator color={primaryColor} size="large" />
        </View>
      )}
    </>
  );
  /* #endregion */
};

export default ImageLoader;
