/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';

import { Text, TextProps } from './Themed';

export const MonoText = (props: TextProps): JSX.Element => {
  /* #region Fields */
  const { style } = props;
  /* #endregion */

  return (
    <Text
      {...props}
      style={[{ fontFamily: 'space-mono', fontSize: 14 }, style]}
    />
  );
};

export const TitleText = (props: TextProps): JSX.Element => {
  /* #region Fields */
  const { style, small, strong } = props;
  /* #endregion */

  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: 'default-title',
          fontSize: small ? 24 : 32,
          fontWeight: strong ? 'bold' : 'normal'
        },
        style
      ]}
    />
  );
};

export const LabelText = (props: TextProps): JSX.Element => {
  /* #region Fields */
  const { style, small, strong } = props;
  /* #endregion */

  return (
    <Text
      {...props}
      style={[
        {
          fontSize: small ? 13 : 16,
          marginVertical: 4
        },
        strong
          ? { fontFamily: 'default-bold' }
          : { fontFamily: 'default-regular' },
        style
      ]}
    />
  );
};

export const MainText = (props: TextProps): JSX.Element => {
  /* #region Fields */
  const { style, small, strong } = props;
  /* #endregion */

  return (
    <Text
      {...props}
      style={[
        {
          fontFamily: strong ? 'default-bold' : 'default-regular',
          fontSize: small ? 13 : 16
        },
        style
      ]}
    />
  );
};
