/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { Image } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { View, useThemeColor, ThemeProps } from '../../components/UI/Themed';

export type LogoProps = ThemeProps & {
  size?: number;
  containerStyle?: StyleSheet['props'];
};

const Logo = (props: LogoProps): JSX.Element => {
  /* #region Fields */
  const { size, containerStyle, lightColor, darkColor } = props;
  const { styles } = useStyles();
  const logoBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'logoBackground'
  );
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[
        styles('imageContainer'),
        {
          backgroundColor: logoBackgroundColor
        },
        size ? { width: size, height: size } : styles('imageDimension'),
        containerStyle
      ]}
    >
      <Image
        source={require('../../assets/images/logo.jpg')}
        style={styles('image')}
      />
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    imageContainer: {
      borderRadius: 30,
      overflow: 'hidden'
    },
    imageDimension: {
      width: 56,
      height: 56
    },
    image: {
      height: '100%'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      imageDimension: {
        width: 46,
        height: 46
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      imageDimension: {
        width: 46,
        height: 46
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      imageDimension: {
        width: 46,
        height: 46
      }
    }
  }
);

export default Logo;
