/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { Platform } from 'react-native';

// project main colors
// more info for rgb to hex and vice versa, see https://css-tricks.com/converting-color-spaces-in-javascript/
// adding alpha to hex colors, see https://alligator.io/css/hex-code-colors-alpha-values/
const primaryColor = '#d08340';
const accentColor = '#5e3c32';

const tintColorLight = '#d08340';
const tintColorDark = '#fff';

export default {
  primary: primaryColor,
  accent: accentColor,

  // new definitions for selected Theme
  light: {
    primary: primaryColor,
    accent: accentColor,
    screenBackground: '#f2f2f2',
    headerBackground: Platform.select({
      android: primaryColor,
      ios: '#fff',
      web: '#fff'
    }),
    headerBottom: '#fff',
    header: Platform.select({
      android: '#fff',
      ios: primaryColor,
      web: '#fff'
    }),
    headerButtons: Platform.select({
      android: '#fff',
      ios: primaryColor,
      web: '#000'
    }),
    logoBackground: '#fff',
    navlink: 'rgba(28, 28, 30, 0.68)', // #1c1c1e, opacity: 0.68
    navlinkBackground: 'rgba(249, 211, 66, 0.12)', // of active router link, primaryColor, opacity: 0.12
    favoriteEdgeIcon: '#fff',
    button: primaryColor,
    buttonText: '#fff',
    buttonAccent: accentColor,
    radioButton: primaryColor,
    radioButtonAccent: 'rgba(255, 255, 255, 0.6)',
    attention: 'red',
    destructive: 'red',
    inactive: '#dbdbdb',
    imageCarouselDotActive: '#000',
    imageCarouselDotInactive: '#000',
    imageCarouselAccent: '#fff',
    switchActive: Platform.select({
      android: 'rgb(131, 196, 189)',
      default: 'rgb(101, 195, 102)'
    }),
    switchInactive: Platform.select({
      android: 'rgb(187, 186, 186)',
      default: 'rgb(233, 233, 235)'
    }),
    switchThumbActive: Platform.select({
      android: 'rgb(21, 152, 138)',
      default: 'rgb(250, 250, 250)'
    }),
    switchThumbInactive: 'rgb(250, 250, 250)',
    text: '#000',
    textAccent: accentColor,
    input: '#f9f9f9',
    hover: primaryColor,
    background: '#fff',
    backdrop: 'rgba(0, 0, 0, 0.7)',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    tabIconBackground: '#fff',
    border: '#eee',
    shadowLight: 'rgba(0,0,0, 0.2)',
    shadowHard: 'rgba(0,0,0, 0.46)',
    progressChecked: 'green',
    progressUnchecked: '#dbdbdb',
    skeletonBone: '#e1e9ee',
    skeletonBoneHighlight: '#f2f8fc',
    pin: '#fd1c3a'
  },
  dark: {
    primary: primaryColor,
    accent: accentColor,
    screenBackground: '#000',
    headerBackground: Platform.select({
      android: primaryColor,
      ios: '#000',
      web: primaryColor
    }),
    headerBottom: '#fff',
    header: Platform.select({
      android: '#fff',
      ios: primaryColor,
      web: '#fff'
    }),
    headerButtons: Platform.select({
      android: '#fff',
      ios: primaryColor,
      web: '#fff'
    }),
    logoBackground: '#fff',
    navlink: 'rgba(28, 28, 30, 0.68)', // #1c1c1e, opacity: 0.68
    navlinkBackground: 'rgba(249, 211, 66, 0.12)', // of active router link, primaryColor, opacity: 0.12
    favoriteEdgeIcon: '#fff',
    button: 'rgb(33, 50, 255)',
    buttonText: '#fff',
    buttonAccent: accentColor,
    radioButton: primaryColor,
    radioButtonAccent: 'rgba(255, 255, 255, 0.6)',
    attention: 'red',
    destructive: 'red',
    inactive: '#dbdbdb',
    imageCarouselDotActive: '#000',
    imageCarouselDotInactive: '#000',
    imageCarouselAccent: '#fff',
    switchActive: Platform.select({
      android: 'rgb(83, 127, 127)',
      default: 'rgb(104, 205, 103)'
    }),
    switchInactive: Platform.select({
      android: 'rgb(104, 112, 116)',
      default: 'rgb(57, 57, 61)'
    }),
    switchThumbActive: Platform.select({
      android: 'rgb(127, 204, 197)',
      default: 'rgb(250, 250, 250)'
    }),
    switchThumbInactive: Platform.select({
      android: 'rgb(189, 189, 189)',
      default: 'rgb(250, 250, 250)'
    }),
    text: '#fff',
    textAccent: '#888',
    input: '#f9f9f9',
    hover: primaryColor,
    background: '#000',
    backdrop: 'rgba(20, 20, 20, 0.7)',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    tabIconBackground: '#fff',
    border: 'rgba(255,255,255,0.1)',
    shadowLight: '#fff',
    shadowHard: '#fff',
    progressChecked: 'green',
    progressUnchecked: '#dbdbdb',
    skeletonBone: '#121212',
    skeletonBoneHighlight: '#333333',
    pin: '#fd1c3a'
  }
};
