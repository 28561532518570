/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import usePrevious from '../../hooks/usePrevious';
import {
  productAttributeFilter,
  productAttributeInputFilter,
  productAttributePriceFilter,
  productAttributeValueFilter
} from '../../helpers/filter';
import Product from './Product';
import { View } from '../UI/Themed';
import { RootState } from '../../store';
import { IProductAttribute, ProductAttributeValueTypes } from '../../types';

export type ConfiguratorProps = {
  originKey?: string;
  selectedProductAttributes: Array<IProductAttribute> | null;
  filterProductAttributeValueTypes?: Array<string>;
  prodId: number;
};

const Configurator = (props: ConfiguratorProps): JSX.Element => {
  /* #region  Fields */
  const {
    originKey,
    selectedProductAttributes,
    filterProductAttributeValueTypes,
    prodId
  } = props;
  const isMounted = useIsMounted();
  const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
    useSelector(
      (state: RootState) => state.settings.productAttributeValueTypes
    );
  const [
    filteredSelectedProductAttributes,
    setFilteredSelectedProductAttributes
  ] = useState([]);
  const prevFilteredSelectedProductAttributes = usePrevious(
    _.cloneDeep(filteredSelectedProductAttributes)
  );
  const configItems = useSelector((state: RootState) =>
    state.productattributes.availableProductAttributes?.filter(
      (attr: IProductAttribute) => {
        const json_field = attr.json;
        const value_type = productAttributeValueTypes?.find(
          (valuetype) => valuetype.id === json_field.type
        );

        if (filterProductAttributeValueTypes) {
          return (
            attr.productId.includes(prodId) &&
            filterProductAttributeValueTypes.includes(value_type?.type_name)
          );
        }

        return attr.productId.includes(prodId);
      }
    )
  );
  const [filteredConfigItems, setFilteredConfigItems] = useState(configItems);
  const prevFilteredConfigItems = usePrevious(_.cloneDeep(filteredConfigItems));
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (
      configItems.length > 0 &&
      filteredSelectedProductAttributes.length > 0
    ) {
      // filter attribute groups with matching attributeKey and make deep copy of new array,
      // so that changes won't affect original items
      let filtered = _.cloneDeep(
        productAttributeFilter(
          productAttributeValueTypes,
          filteredSelectedProductAttributes,
          configItems
        )
      );

      filtered?.forEach((item) => {
        const values = productAttributeValueFilter(
          productAttributeValueTypes,
          filteredSelectedProductAttributes,
          item.json.value
        );

        item.json.value = values;
      });

      filtered = productAttributeInputFilter(
        productAttributeValueTypes,
        filteredSelectedProductAttributes,
        filtered
      );

      filtered = productAttributePriceFilter(
        productAttributeValueTypes,
        filteredSelectedProductAttributes,
        filtered,
        originKey
      );

      // skip unnecessary re-renders
      // console.log('Detect possible re-rendering throuh prevFilteredConfigItems');
      if (!_.isEqual(filtered, prevFilteredConfigItems)) {
        // console.log('Filter selected config', filtered);
        updateState(() => setFilteredConfigItems(filtered));
      }
    }
  }, [
    configItems,
    productAttributeValueTypes,
    filteredSelectedProductAttributes,
    originKey
  ]);

  useEffect(() => {
    if (selectedProductAttributes?.length > 0) {
      let filtered = selectedProductAttributes?.filter((attr) => {
        const json_field = attr.json;
        const value_type = productAttributeValueTypes?.find(
          (valuetype) => valuetype.id === json_field.type
        );

        if (filterProductAttributeValueTypes) {
          return filterProductAttributeValueTypes.includes(
            value_type?.type_name
          );
        }

        return true;
      });

      // update price tags
      filtered = productAttributePriceFilter(
        productAttributeValueTypes,
        filtered,
        undefined,
        originKey
      );

      // skip unnecessary re-renders
      // console.log('Detect possible re-rendering throuh prevFilteredSelectedProductAttributes');
      if (!_.isEqual(filtered, prevFilteredSelectedProductAttributes)) {
        // console.log('Filter selected attributes', filtered);
        updateState(() => setFilteredSelectedProductAttributes(filtered));
      }
    }
  }, [
    selectedProductAttributes,
    productAttributeValueTypes,
    filterProductAttributeValueTypes,
    originKey
  ]);
  /* #endregion */

  /* #region Renderers */
  return (
    <View style={styles.container}>
      <Product
        prodId={prodId}
        configItems={filteredConfigItems}
        selectedAttributes={filteredSelectedProductAttributes}
      />
    </View>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  container: {}
});

export default Configurator;
