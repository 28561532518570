/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *
 *  Special thanks goes to https://github.com/benevbright/react-navigation-collapsible
 *-------------------------------------------------------------*/

import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Animated,
  NativeSyntheticEvent,
  NativeScrollEvent,
  useWindowDimensions
} from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import shallowequal from 'shallowequal';
import {
  getSafeBounceHeight,
  getDefaultHeaderHeight,
  getNavigationHeight,
  getStatusBarHeight
} from 'react-navigation-collapsible/lib/src/utils';
import { createHeaderBackground as createDefaultHeaderBackground } from 'react-navigation-collapsible/lib/src/createHeaderBackground';
import { Params as createHeaderBackgroundParams } from 'react-navigation-collapsible/lib/src/createHeaderBackground';
import { createCollapsibleCustomHeaderAnimator } from 'react-navigation-collapsible/lib/src/createCollapsibleCustomHeaderAnimator';

enum CollapsibleHeaderType {
  Default,
  BigHeader,
  SubHeader
}

export type Collapsible = {
  onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  onScrollWithListener: (
    listener: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
  ) => (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  containerPaddingTop: number;
  scrollIndicatorInsetTop: number;
  positionY: Animated.AnimatedValue;
  offsetY: Animated.AnimatedValue;
  translateY: Animated.AnimatedInterpolation;
  progress: Animated.AnimatedInterpolation;
  opacity: Animated.AnimatedInterpolation;
  showHeader: () => void;
};

export type UseCollapsibleOptions = {
  navigationOptions?: { [key: string]: any };
  config?: {
    useNativeDriver?: boolean;
    elevation?: number;
    collapsedColor?: string;
    disableOpacity?: boolean;
    createHeaderBackground?: (
      params: createHeaderBackgroundParams
    ) => ReactNode;
  };
};

const useCollapsibleHeader = (
  options?: UseCollapsibleOptions,
  collapsibleHeaderType: CollapsibleHeaderType = CollapsibleHeaderType.Default
): Collapsible => {
  /* #region Fields */
  const { navigationOptions = {}, config = {} } = options || {};
  const {
    useNativeDriver = true,
    elevation,
    collapsedColor,
    disableOpacity = false,
    createHeaderBackground = createDefaultHeaderBackground
  } = config;

  const { headerStyle: userHeaderStyle = {} } = navigationOptions;
  const [headerStyle, setHeaderStyle] = useState(userHeaderStyle);
  const [collapsible, setCollapsible] = useState<Collapsible>();
  const { width, height } = useWindowDimensions();
  const isLandscape = height < width;

  const route = useRoute();
  const navigation = useNavigation();

  const positionY = useRef(new Animated.Value(0)).current;
  const onScroll = Animated.event(
    [{ nativeEvent: { contentOffset: { y: positionY } } }],
    { useNativeDriver }
  );
  const onScrollWithListener = (
    listener: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
  ) =>
    Animated.event([{ nativeEvent: { contentOffset: { y: positionY } } }], {
      useNativeDriver,
      listener
    });

  const offsetY = useRef(new Animated.Value(0)).current;
  const offsetYValue = useRef(0);

  const {
    // @ts-ignore
    collapsibleSubHeaderHeight: subHeaderHeight,
    // @ts-ignore
    collapsibleCustomHeaderHeight: customHeaderHeight
  } = route.params || {};
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    const listener = offsetY.addListener(({ value }) => {
      offsetYValue.current = value;
    });

    return () => {
      offsetY.removeListener(listener);
    };
  }, []);

  useEffect(() => {
    if (!shallowequal(headerStyle, userHeaderStyle))
      setHeaderStyle(userHeaderStyle);
  }, [userHeaderStyle]);

  useLayoutEffect(() => {
    let headerHeight = 0;
    if (customHeaderHeight) {
      headerHeight = customHeaderHeight - getStatusBarHeight(isLandscape);
    } else {
      if (collapsibleHeaderType === CollapsibleHeaderType.SubHeader) {
        headerHeight = subHeaderHeight || 0;
      } else {
        headerHeight =
          headerStyle.height != null
            ? headerStyle.height - getStatusBarHeight(isLandscape)
            : getDefaultHeaderHeight(isLandscape);
      }
    }
    const safeBounceHeight = getSafeBounceHeight();
    const minHeaderVisibleHeight =
      collapsibleHeaderType === CollapsibleHeaderType.BigHeader
        ? getDefaultHeaderHeight(isLandscape)
        : 0;

    const progress = positionY.interpolate({
      inputRange: [safeBounceHeight, safeBounceHeight + headerHeight],
      outputRange: [0, 1],
      extrapolate: 'clamp'
    });
    const heightMoveTo = -(headerHeight - minHeaderVisibleHeight);
    const translateY = Animated.multiply(progress, heightMoveTo);
    const opacity = Animated.subtract(1, disableOpacity ? 0 : progress);
    const reversedOpacity = Animated.add(0, progress);

    if (
      collapsibleHeaderType === CollapsibleHeaderType.Default ||
      collapsibleHeaderType === CollapsibleHeaderType.BigHeader
    ) {
      const options = {
        ...navigationOptions,
        headerStyle: {
          ...headerStyle,
          transform: [{ translateY }],
          opacity: navigationOptions.headerBackground ? opacity : 1
        },
        headerTitleContainerStyle: {
          alignSelf: 'flex-end',
          opacity: navigationOptions.headerBackground ? reversedOpacity : 1
        },
        headerLeftContainerStyle: {
          height: getDefaultHeaderHeight(isLandscape),
          marginTop: 'auto'
        },
        headerRightContainerStyle: {
          height: getDefaultHeaderHeight(isLandscape),
          marginTop: 'auto'
        },
        headerBackground: createHeaderBackground({
          translateY,
          opacity,
          backgroundColor: headerStyle?.backgroundColor,
          collapsedColor: collapsedColor || headerStyle?.backgroundColor,
          elevation,
          headerBackground: navigationOptions.headerBackground
        }),
        headerTransparent: true
      };

      if (navigationOptions.header) {
        Object.assign(options, {
          header: createCollapsibleCustomHeaderAnimator(
            navigationOptions.header
          )
        });
      }

      if (
        navigationOptions.headerBackground &&
        collapsibleHeaderType === CollapsibleHeaderType.BigHeader
      ) {
        const startToVisible = 0.5;
        const defaultHeaderTranslateY = progress.interpolate({
          inputRange: [0, startToVisible, 1],
          outputRange: [-1000, -heightMoveTo * 0.25, -heightMoveTo * 0.5]
        });
        const defaultHeaderOpacity = progress.interpolate({
          inputRange: [0, startToVisible, 1],
          outputRange: [0, 0, 1]
        });
        options.headerStyle = {
          ...options.headerStyle,
          opacity: defaultHeaderOpacity
        };
        Object.assign(options, {
          headerTitleStyle: {
            transform: [{ translateY: defaultHeaderTranslateY }]
          },
          headerLeftContainerStyle: {
            transform: [{ translateY: defaultHeaderTranslateY }]
          },
          headerRightContainerStyle: {
            transform: [{ translateY: defaultHeaderTranslateY }]
          }
        });
      }

      navigation.setOptions(options);
    }

    const collapsible: Collapsible = {
      onScroll,
      onScrollWithListener,
      containerPaddingTop:
        collapsibleHeaderType === CollapsibleHeaderType.SubHeader
          ? headerHeight
          : getNavigationHeight(isLandscape, headerHeight),
      scrollIndicatorInsetTop: headerHeight,
      positionY,
      offsetY,
      translateY,
      progress,
      opacity,
      showHeader: () => {
        offsetY.setValue(offsetYValue.current - headerHeight);
      }
    };
    setCollapsible(collapsible);
  }, [isLandscape, headerStyle, subHeaderHeight, customHeaderHeight]);
  /* #endregion */

  /* #region Renderers */
  return (
    collapsible || {
      onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>) => null,
      onScrollWithListener:
        (listener: (event: NativeSyntheticEvent<NativeScrollEvent>) => void) =>
        (event: NativeSyntheticEvent<NativeScrollEvent>) =>
          null,
      containerPaddingTop: 0,
      scrollIndicatorInsetTop: 0,
      positionY: new Animated.Value(0),
      offsetY: new Animated.Value(0),
      translateY: new Animated.Value(0),
      progress: new Animated.Value(0),
      opacity: new Animated.Value(1),
      showHeader: () => {
        // do nothing
      }
    }
  );
  /* #endregion */
};

const useCollapsibleBigHeader = (options?: UseCollapsibleOptions) =>
  useCollapsibleHeader(options, CollapsibleHeaderType.BigHeader);

const useCollapsibleSubHeader = (options?: UseCollapsibleOptions) =>
  useCollapsibleHeader(options, CollapsibleHeaderType.SubHeader);

export {
  useCollapsibleHeader,
  useCollapsibleBigHeader,
  useCollapsibleSubHeader
};
