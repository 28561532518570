/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

class Article {
  /* #region Properties */
  [key: string]: any;

  id: number;

  title: string;

  body: string;

  teaser: string;

  meta_keywords: string;

  tags: string;

  category: string;

  slug: string;

  date: number;

  author: string;

  is_archived: boolean;

  is_published: boolean;

  is_internal: boolean;

  is_onnewsfeed: boolean;
  /* #endregion */

  constructor(
    id: number,
    title: string,
    body: string,
    teaser: string,
    meta_keywords: string,
    tags: string,
    category: string,
    slug: string,
    date: number,
    author: string,
    is_archived: boolean = false,
    is_published: boolean = false,
    is_internal: boolean = false,
    is_onnewsfeed: boolean = false
  ) {
    this.id = id;
    this.title = title;
    this.body = body;
    this.teaser = teaser;
    this.meta_keywords = meta_keywords;
    this.tags = tags;
    this.category = category;
    this.slug = slug;
    this.date = date;
    this.author = author;
    this.is_archived = is_archived;
    this.is_published = is_published;
    this.is_internal = is_internal;
    this.is_onnewsfeed = is_onnewsfeed;
  }
}

export default Article;
