/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

class Product {
  /* #region Properties */
  id: number;

  ownerId: number;

  categoryId: number;

  title: string;

  slug: string;

  images: Array<string>;

  linkedURL: string | null;

  is_pricedByAttribute: boolean;

  pricedByAttributeId: number;

  description: string;

  price: number;

  currency: string;

  is_archived: boolean;
  /* #endregion */
  constructor(
    id: number,
    ownerId: number,
    categoryId: number,
    title: string,
    slug: string,
    images: Array<string>,
    linkedURL: string | null = null,
    is_pricedByAttribute: boolean,
    pricedByAttributeId: number,
    description: string,
    price: number,
    currency: string,
    is_archived: boolean = false
  ) {
    this.id = id;
    this.ownerId = ownerId;
    this.categoryId = categoryId;
    this.title = title;
    this.slug = slug;
    this.images = images;
    this.linkedURL = linkedURL;
    this.is_pricedByAttribute = is_pricedByAttribute;
    this.pricedByAttributeId = pricedByAttributeId;
    this.description = description;
    this.price = price;
    this.currency = currency;
    this.is_archived = is_archived;
  }
}

export default Product;
