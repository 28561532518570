/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { ScrollView, ActivityIndicator, Platform } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { parseLocaleNumber, t } from '../../helpers/localized';
import { formReducer, getFormState } from '../../helpers/input-form';
import Order from '../../models/Order';
import {
  View,
  Text,
  Button,
  Ionicons,
  useThemeColor,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText } from '../../components/UI/StyledText';
import AlertModal from '../../components/UI/AlertModal';
import ContainerView from '../../components/UI/ContainerView';
import TouchableComponent from '../../components/UI/TouchableComponent';
import Input from '../../components/UI/Input';
import Loading from '../../components/UI/Loading';
import Card from '../../components/UI/Card';
import OrderProgress from '../../components/shop/OrderProgress';
import AddressItem from '../../components/shop/AddressItem';
import CartItem from '../../components/shop/CartItem';
import * as orderActions from '../../store/actions/orders';
import { RootState } from '../../store';
import { ICartItem, ShopStackParamList } from '../../types';

const OrderSummaryScreen = (
  { navigation }: StackScreenProps<ShopStackParamList, 'OrderSummary'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const { styles } = useStyles();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const language = useSelector((state: RootState) => state.settings.language);
  const currency = useSelector((state: RootState) => state.settings.currency);
  const salesTax = useSelector((state: RootState) => state.settings.salesTax);
  const isOrderFinished = useSelector(
    (state: RootState) => state.cart.didFinishOrder
  );
  const activeUserProfile = useSelector(
    (state: RootState) => state.auth.activeUserProfile
  );
  const [currentOrder, setCurrentOrder] = useState();
  const cartItems: Array<ICartItem> = useSelector((state: RootState) => {
    const transformedCartItems: Array<ICartItem> = [];
    Object.keys(state.cart.items).forEach((key) => {
      transformedCartItems.push({
        itemKey: key,
        productTitle: state.cart.items[key].productTitle,
        productPrice: state.cart.items[key].productPrice,
        productAttributes: state.cart.items[key].productAttributes,
        quantity: state.cart.items[key].quantity,
        sum: state.cart.items[key].sum
      });
    });

    return _.sortBy(transformedCartItems, 'itemKey');
  });
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const cartTotalAmount = useSelector(
    (state: RootState) => state.cart.totalAmount
  );
  const lastOrder = useSelector((state: RootState) =>
    _.last(state.orders.orders)
  );
  const dispatch = useDispatch();
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );

  // form validation model
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      note: ''
    },
    inputValidities: {
      note: true
    },
    formIsValid: true
  });
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (!isOrderFinished) {
      const newOrder = new Order(
        -1,
        '',
        cartItems,
        salesTax,
        cartTotalAmount,
        currency,
        new Date().getDate(),
        ''
      );
      updateState(() => setCurrentOrder(newOrder));
    } else {
      updateState(() => setCurrentOrder(lastOrder));
    }
  }, [isOrderFinished, lastOrder]);

  const sendOrderHandler = useCallback(async () => {
    updateState(() => setIsLoading(true));
    try {
      dispatch(
        orderActions.addOrder(
          cartItems,
          salesTax,
          cartTotalAmount,
          currency,
          formState.inputValues.note
        )
      );

      // if set up, use Analytics functions from Pinterest
      if (window.pintrk) {
        cartItems?.forEach((cartItem: ICartItem) => {
          window.pintrk('track', 'addtocart', {
            product_id: cartItem.productTitle,
            value: cartItem.sum,
            order_quantity: cartItem.quantity,
            currency: 'EUR'
          });
        });
      }
    } catch (err) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('errorOccurred')}
            message={err.message}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    } finally {
      updateState(() => setIsLoading(false));

      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('popupNotice')}
            message={t('messageOrderConfirmed')}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    }
  }, [cartItems, cartTotalAmount, setIsLoading]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleOrderSummaryScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleOrderSummaryScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'Cart'
                }
              });
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, cartItemCount]);

  const inputChangeHandler = useCallback(
    (inputIdentifier: string, inputValue: unknown, inputValidity: boolean) => {
      dispatchFormState(
        getFormState(inputIdentifier, inputValue, inputValidity)
      );
    },
    [dispatchFormState]
  );
  /* #endregion */

  /* #region Renderers */
  if (!currentOrder) {
    return <Loading />;
  }

  return (
    <ContainerView
      scrollable
      seoTitle={`${t('titleOrderSummaryScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <View style={styles('screen')}>
        {alertModalContent}
        <Card style={styles('container')}>
          <OrderProgress />
          <View style={styles('summary')}>
            <LabelText strong>
              {t('totalAmount') + ' '}
              <Text style={{ color: primaryColor }}>
                {parseLocaleNumber(currentOrder?.totalAmount)?.toLocaleString(
                  language,
                  {
                    style: 'currency',
                    currency: currency
                  }
                )}
              </Text>
            </LabelText>
            {!isOrderFinished ? (
              <View>
                {isLoading ? (
                  <ActivityIndicator size="small" color={primaryColor} />
                ) : (
                  <Button
                    disabled={!activeUserProfile}
                    color={buttonAccentColor}
                    title={t('titleOrderNow')}
                    onPress={sendOrderHandler}
                  />
                )}
              </View>
            ) : (
              <View>
                <Button
                  color={buttonAccentColor}
                  title={t('titleBackToProducts')}
                  onPress={() =>
                    navigation.navigate('Shop', {
                      screen: 'Products',
                      params: {
                        screen: 'ShopCategories'
                      }
                    })
                  }
                />
              </View>
            )}
          </View>
        </Card>
        <ScrollView>
          <View style={styles('textContainer')}>
            {activeUserProfile && (
              <View style={styles('text')}>
                <LabelText strong style={{ paddingBottom: 10 }}>
                  {t('titleAddressData')}
                </LabelText>
                <AddressItem profile={activeUserProfile} />
              </View>
            )}
            <LabelText strong style={{ paddingVertical: 10 }}>
              {t('titleNote')}
            </LabelText>
            <Input
              id="note"
              label=""
              keyboardType="default"
              autoCapitalize="none"
              multiline
              numberOfLines={6}
              errorText={t('inputNoteError')}
              onInputChange={inputChangeHandler}
              initialValue=""
              initiallyValid={true}
              editable={!isOrderFinished}
              selectTextOnFocus={!isOrderFinished}
            />
            <LabelText strong style={{ paddingVertical: 10 }}>
              {t('titleCart')}
            </LabelText>
            <View style={styles('detailItem')}>
              {currentOrder?.items.map((cartItem: ICartItem, index: number) => (
                <CartItem
                  key={index.toString()}
                  title={cartItem.productTitle}
                  attributes={cartItem.productAttributes}
                  quantity={cartItem.quantity}
                  amount={cartItem.sum}
                />
              ))}
            </View>
          </View>
        </ScrollView>
      </View>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    screen: {
      flex: 1,
      margin: 20
    },
    container: {
      marginBottom: 20,
      padding: 10
    },
    summary: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center'
    },
    textContainer: {
      marginHorizontal: 20
    },
    text: {
      marginVertical: 10
    },
    detailItem: {
      width: '100%'
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    }
  },
  {}
);

export default OrderSummaryScreen;
