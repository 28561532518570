/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import ConfigItem from './ConfigItem';
import { View } from '../UI/Themed';
import * as notificationActions from '../../store/actions/notification';
import { IProductAttribute, ProductAttributeValueTypes } from '../../types';
import { RootState } from '../../store';

export type ProductProps = {
  prodId: number;
  configItems: Array<IProductAttribute>;
  selectedAttributes: Array<IProductAttribute>;
};

const Product = (props: ProductProps): JSX.Element => {
  /* #region Fields */
  const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
    useSelector(
      (state: RootState) => state.settings.productAttributeValueTypes
    );
  const { prodId, configItems, selectedAttributes } = props;
  const isMounted = useIsMounted();
  const [transformedConfigItems, setTransformedConfigItems] = useState<
    Array<IProductAttribute>
  >([]);
  const [selectedConfigItem, setSelectedConfigItem] =
    useState<IProductAttribute>();
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (!selectedConfigItem) {
      // find first of config item, which is of type dictionary or list
      const initConfigItem = configItems.find((item) => {
        const value_type_ids = productAttributeValueTypes?.map((valuetype) => {
          if (
            valuetype.type_name === t('productAttributeValueTypeDictionary') ||
            valuetype.type_name === t('productAttributeValueTypeList')
          ) {
            return valuetype.id;
          }
        });

        if (value_type_ids?.includes(item.json.type)) {
          return item;
        }
      });

      updateState(() => setSelectedConfigItem(initConfigItem));
    }
  }, [configItems, selectedConfigItem, productAttributeValueTypes]);

  useEffect(() => {
    if (configItems) {
      const updatedConfigItems = configItems.map(
        (item: IProductAttribute, index: number) => {
          const json_field = item.json;
          const value_type = productAttributeValueTypes?.find(
            (valuetype) => valuetype.id === json_field.type
          );
          const selectedAttribute = json_field.multiSelect
            ? selectedAttributes.filter(
                (attr: IProductAttribute) => attr.name === item.name
              )
            : selectedAttributes.find(
                (attr: IProductAttribute) => attr.name === item.name
              );

          return (
            <ConfigItem
              key={item.productId + '-' + item.id}
              name={item.name}
              value={json_field.value}
              valueType={value_type?.type_name}
              multiSelect={json_field.multiSelect}
              selectedAttributes={selectedAttribute}
              configIndex={index}
              productId={prodId}
              selectedConfigItem={selectedConfigItem}
              availableConfigItems={configItems}
              onSelectItem={() => {
                updateState(() => setSelectedConfigItem(item));
                if (json_field.multiSelect) {
                  // reset ProductConfiguratorDetails
                  dispatch(
                    notificationActions.setProductConfiguratorDetails({
                      title: '',
                      price: 0,
                      description: ''
                    })
                  );
                }
              }}
            />
          );
        }
      );

      updateState(() => setTransformedConfigItems(updatedConfigItems));
    }
  }, [
    configItems,
    selectedAttributes,
    selectedConfigItem,
    productAttributeValueTypes
  ]);
  /* #endregion */

  /* #region Renderers */
  return <View style={styles.container}>{transformedConfigItems}</View>;
  /* #endregion */
};

const styles = StyleSheet.create({
  container: {}
});

export default Product;
