/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import { Dimensions, Image, LogBox, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import Config from '../../constants/Config';
import { t } from '../../helpers/localized';
import PartnerSite from '../../models/PartnerSite';
import {
  View,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import { LabelText, TitleText } from '../../components/UI/StyledText';
import TouchableComponent from '../../components/UI/TouchableComponent';
import ImageLoader from '../../components/UI/ImageLoader';
import Loading from '../../components/UI/Loading';
import ContainerView from '../../components/UI/ContainerView';
import SectionHeader from '../../components/UI/SectionHeader';
import HTMLComponent from '../../components/UI/HTMLComponent';
import Boop from '../../components/animated/Boop';
import { allProducts } from '../../store/reselect';
import { RootState } from '../../store';
import { RentalsStackParamList } from '../../types';
import Product from '../../models/Product';
import { rewriteBackendURL } from '../../helpers/staticfiles-storage';

if (LogBox) {
  LogBox.ignoreLogs(['Failed prop type:']);
}

const RentalsOverviewScreen = (
  { navigation }: StackScreenProps<RentalsStackParamList, 'RentalsOverview'>,
  { lightColor, darkColor }: ThemeProps
) => {
  /* #region Fields */
  const { styles, deviceSize } = useStyles();
  const { width } = Dimensions.get('window');
  const [mapContainerDimension, setMapContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const [imageContainerDimension, setImageContainerDimension] = useState({
    width: 0,
    height: 0
  });
  const isWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const pinColor = useThemeColor({ light: lightColor, dark: darkColor }, 'pin');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const availablePartnerSites = useSelector(
    (state: RootState) => state.products.availablePartnerSites
  );
  const availableProducts = useSelector(allProducts());
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  const detailsBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'tabIconDefault'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleRentalsOverviewScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleRentalsOverviewScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'Cart'
                }
              });
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, cartItemCount]);

  useEffect(() => {
    if (availablePartnerSites.length > 0) {
      // MapBox playground https://docs.mapbox.com/playground/static/
      const pins = availablePartnerSites.map(
        (item: PartnerSite, index: number) => {
          return `pin-s-${index + 1}+${pinColor.replace('#', '')}(${
            item.longitude
          },${item.latitude})`;
        }
      );
      updateState(() =>
        setImagePreviewUrl(
          `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${pins.join(
            ','
          )}/${availablePartnerSites[0].longitude},${
            availablePartnerSites[0].latitude
          },4/400x500?access_token=${Config().mapboxApiKey}`
        )
      );
    }
  }, [availablePartnerSites]);

  const itemHandler = (item: PartnerSite) => {
    const partnerSiteProduct = availableProducts.find(
      (prod: Product) => prod.id === item.productId[0]
    );

    navigation.navigate('Shop', {
      screen: 'Rentals',
      params: {
        screen: 'RentalDetail',
        params: {
          partnerSiteSlug: item.slug,
          productSlug: partnerSiteProduct.slug,
          enableBackButton: true
        }
      }
    });
  };
  /* #endregion */

  /* #region Renderers */
  if (isLoading) {
    return <Loading />;
  }

  return (
    <ContainerView
      scrollable
      seoTitle={`${t('titleRentalsOverviewScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'index,follow' }]}
    >
      <SectionHeader screenName="RentalsOverviewScreen" position="top" />
      <View style={styles('container')}>
        <View style={styles('infoTable')}>
          <View
            style={[
              styles('infoTableRow'),
              {
                flex: 1,
                width: [
                  DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                  DEVICE_SIZES.SMALL_DEVICE
                ].includes(deviceSize)
                  ? width - 40
                  : width * 0.8 - 40
              }
            ]}
            onLayout={(event) => {
              const { width } = event.nativeEvent.layout;
              const aspectRatioBasedHeight = (3 / 4) * width; // Aspect ration 4 / 3
              updateState(() =>
                setMapContainerDimension({
                  width,
                  height: aspectRatioBasedHeight
                })
              );
            }}
          >
            <View
              style={[
                styles('mapPreview'),
                { height: mapContainerDimension.height }
              ]}
            >
              {imagePreviewUrl && (
                <Image
                  style={styles('mapImage')}
                  source={{ uri: imagePreviewUrl }}
                />
              )}
            </View>
          </View>
        </View>
        {availablePartnerSites.map((item: PartnerSite, index: number) => {
          return (
            <View key={index.toString()} style={styles('infoTable')}>
              <View
                style={[
                  styles('infoTableRow'),
                  ![
                    DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                    DEVICE_SIZES.SMALL_DEVICE
                  ].includes(deviceSize) && {
                    flexDirection: index % 2 ? 'row-reverse' : 'row'
                  },
                  {
                    borderColor
                  }
                ]}
              >
                <Boop scale={0.98} timing={0}>
                  <TouchableComponent
                    style={[
                      styles('infoTableCell'),
                      {
                        alignItems: index % 2 ? 'flex-end' : 'flex-start',
                        padding: 0,
                        margin: 0,
                        width: [
                          DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                          DEVICE_SIZES.SMALL_DEVICE
                        ].includes(deviceSize)
                          ? width - 40
                          : (width / 2) * 0.8 - 20
                      }
                    ]}
                    onLayout={(event) => {
                      const { width } = event.nativeEvent.layout;
                      const aspectRatioBasedHeight = (3 / 4) * width; // Aspect ration 4 / 3
                      updateState(() =>
                        setImageContainerDimension({
                          width,
                          height: aspectRatioBasedHeight
                        })
                      );
                    }}
                    onPress={() => itemHandler(item)}
                  >
                    <View
                      style={[
                        styles('imageContainer'),
                        { height: imageContainerDimension.height }
                      ]}
                    >
                      <ImageLoader
                        style={styles('image')}
                        source={
                          item.images.length > 0
                            ? rewriteBackendURL(item.images[0])
                            : require('../../assets/images/no-image-available.png')
                        }
                      />
                    </View>
                  </TouchableComponent>
                </Boop>
                <View
                  style={[
                    styles('infoTableCell'),
                    {
                      backgroundColor:
                        index % 2 ? backgroundColor : detailsBackgroundColor,
                      width: [
                        DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                        DEVICE_SIZES.SMALL_DEVICE
                      ].includes(deviceSize)
                        ? width - 40
                        : (width / 2) * 0.8 - 20
                    }
                  ]}
                >
                  <TitleText style={{ marginBottom: 10 }} small strong>
                    {item.name}
                  </TitleText>
                  <HTMLComponent source={{ html: item.description }} />
                  <Boop x={5} y={0}>
                    <TouchableComponent onPress={() => itemHandler(item)}>
                      <Ionicons name="return-down-forward" />
                    </TouchableComponent>
                  </Boop>
                  <View
                    style={{
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      backgroundColor: 'transparent',
                      marginTop: 10
                    }}
                  >
                    {item.productId.map((id: number) => {
                      const partnerSiteProduct = availableProducts.find(
                        (prod: Product) => prod.id === id
                      );

                      if (!partnerSiteProduct) {
                        return;
                      }

                      return (
                        <Boop key={id.toString()} x={5} y={0}>
                          <TouchableComponent
                            style={{ margin: 5 }}
                            onPress={() =>
                              navigation.navigate('Shop', {
                                screen: 'Rentals',
                                params: {
                                  screen: 'RentalDetail',
                                  params: {
                                    partnerSiteSlug: item.slug,
                                    productSlug: partnerSiteProduct.slug,
                                    enableBackButton: true
                                  }
                                }
                              })
                            }
                          >
                            <Ionicons name="pricetags" size={20}>
                              <LabelText
                                style={{ marginHorizontal: 10 }}
                                strong
                                small
                              >
                                {partnerSiteProduct.title}
                              </LabelText>
                            </Ionicons>
                          </TouchableComponent>
                        </Boop>
                      );
                    })}
                  </View>
                </View>
              </View>
            </View>
          );
        })}
      </View>
      <SectionHeader screenName="RentalsOverviewScreen" position="bottom" />
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexGrow: 1,
      marginVertical: 20,
      backgroundColor: 'transparent'
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    },
    infoTable: {
      backgroundColor: 'transparent',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    infoTableRow: {
      marginHorizontal: 20,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      overflow: 'hidden'
    },
    infoTableCell: {
      alignItems: 'flex-start',
      padding: 20
    },
    imageContainer: {
      width: '100%',
      minHeight: 200,
      overflow: 'hidden'
    },
    image: {
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      minHeight: 200
    },
    mapPreview: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    mapImage: {
      width: '100%',
      height: '100%'
    }
  },
  {
    [DEVICE_SIZES.SMALL_DEVICE]: {
      infoTableRow: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      infoTableRow: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    }
  }
);

export default RentalsOverviewScreen;
