/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { StyleSheet } from 'react-native';

import { ThemeProps, View } from '../../components/UI/Themed';
import { LabelText } from '../UI/StyledText';
import Card from '../UI/Card';
import TouchableComponent from '../UI/TouchableComponent';

export type CategoryItemProps = ThemeProps & {
  style?: StyleSheet['props'];
  onPress: () => void;
  name: string;
  articles: number;
};

const CategoryItem = (props: CategoryItemProps): JSX.Element => {
  /* #region Fields */
  const { style, onPress, name, articles } = props;
  /* #endregion */

  /* #region Renderers */
  return (
    <Card style={[styles.category, style]}>
      <TouchableComponent onPress={onPress} style={styles.touchable}>
        <View style={styles.labelContainer}>
          <LabelText strong>{name}</LabelText>
          <View style={styles.badge}>
            <LabelText strong style={styles.badgeText}>
              {articles}
            </LabelText>
          </View>
        </View>
      </TouchableComponent>
    </Card>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  category: {
    marginBottom: 1,
    borderRadius: 2,
    height: 60,
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 10
  },
  touchable: {
    borderRadius: 10,
    overflow: 'hidden'
  },
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  badge: {
    borderRadius: 50,
    padding: 13,
    marginHorizontal: 10,
    height: 20,
    width: 20,
    borderWidth: 2,
    borderStyle: 'solid'
  },
  badgeText: {
    position: 'absolute',
    top: -1,
    left: 8
  }
});

export default CategoryItem;
