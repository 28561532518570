/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { Dimensions, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { IHandles } from 'react-native-modalize/lib/options';
import { useNavigation } from '@react-navigation/native';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import Product from '../../models/Product';
import PartnerSite from '../../models/PartnerSite';
import { t } from '../../helpers/localized';
import { convertPercentageToNumber } from '../../helpers/screen-data';
import {
  View,
  Modalize,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../UI/Themed';
import InputFilter from '../UI/InputFilter';
import { RootState } from '../../store';
import TouchableComponent from '../UI/TouchableComponent';
import { LabelText, MainText } from '../UI/StyledText';
import ShopItem from './ShopItem';
import { allProducts } from '../../store/reselect';
import { ProductAttributeValueTypes } from '../../types';

export type SearchModalProps = ThemeProps & {
  style?: StyleSheet['props'];
  labelStyle?: StyleSheet['props'];
  showLabel?: boolean;
};

const SearchModal = forwardRef((props: SearchModalProps, ref) => {
  /* #region Fields */
  const { style, labelStyle, showLabel, lightColor, darkColor } = props;
  const modalizeRef = useRef<IHandles>(null);
  const isMounted = useIsMounted();
  const { styles, deviceSize } = useStyles();
  const { width, height } = Dimensions.get('window');
  const isLandscape = width > height;
  const [columns, setColumns] = useState<number>(3);
  const availablePartnerSites = useSelector(
    (state: RootState) => state.products.availablePartnerSites
  );
  const products = useSelector(allProducts());
  const productCount = products?.length;
  const productAttributeValueTypes: Array<ProductAttributeValueTypes> =
    useSelector(
      (state: RootState) => state.settings.productAttributeValueTypes
    );
  const availableProductAttributes = useSelector(
    (state: RootState) => state.productattributes.availableProductAttributes
  );
  const [filterItems, setFilterItems] = useState<Array<string>>([]);
  const filteredProducts = useSelector((state: RootState) => {
    if (filterItems.length === 0) {
      return [];
    }

    // generate map of all searchable values and productIds for available product attributes
    const productAttributeMap = {};
    availableProductAttributes.forEach((attr) => {
      const json_field = attr.json;
      const value_type = productAttributeValueTypes?.find(
        (valuetype) => valuetype.id === json_field.type
      );
      if (value_type?.type_name === t('productAttributeValueTypeDictionary')) {
        // get column values for property 'labels'
        const labels = _.compact(_.map(json_field.value, 'label'));
        // get column values for property 'description'
        const description = _.compact(_.map(json_field.value, 'description'));
        attr.productId.forEach((id: number) => {
          productAttributeMap[id] = _.union(
            labels,
            description,
            productAttributeMap[id]
          );
        });
      } else {
        const name = attr.name;
        const value = json_field.value;
        attr.productId.forEach((id: number) => {
          productAttributeMap[id] = _.union(
            [name],
            [value],
            productAttributeMap[id]
          );
        });
      }
    });

    // combine product keywords with productAttributeMap
    products.forEach((prod: Product) => {
      productAttributeMap[prod.id] = _.union(
        [prod.title],
        [prod.description],
        productAttributeMap[prod.id]
      );
    });

    const filtered: Array<number> = [];
    if (filterItems.length > 0) {
      let attribs = Object.keys(productAttributeMap);
      filterItems.forEach((filter) => {
        // use inverse search, if filter word starts with -
        if (filter.startsWith('-')) {
          attribs = _.reject(attribs, function (key) {
            return productAttributeMap[key]
              .join(',')
              .toLowerCase()
              .includes(filter.slice(1).toLowerCase());
          });
        } else {
          // use normal search
          attribs = _.filter(attribs, function (key) {
            return productAttributeMap[key]
              .join(',')
              .toLowerCase()
              .includes(filter.toLowerCase());
          });
        }
      });

      // push all products with matching title first
      attribs.forEach((productId) => {
        filterItems.forEach((filter) => {
          let filterTerm = '';
          if (filter.startsWith('-')) {
            filterTerm = filter.slice(1);
          } else {
            filterTerm = filter;
          }

          const match = products.find(
            (prod: Product) =>
              prod.id === Number(productId) &&
              prod.title.toLowerCase().indexOf(filterTerm) > -1
          );

          if (match && !filtered.includes(match)) {
            filtered.push(match);
          }
        });
      });

      // push the rest
      attribs.forEach((productId) => {
        const match = products.find(
          (prod: Product) => prod.id === Number(productId)
        );

        if (match && !filtered.includes(match)) {
          filtered.push(match);
        }
      });
    } else {
      return [];
    }

    return filtered;
  });
  const headerColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'header'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const textAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'textAccent'
  );
  const shadowLightColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'shadowLight'
  );
  const navigation = useNavigation();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useImperativeHandle(ref, () => ({
    // this method is used to open the modal from outside
    open: () => modalizeRef.current?.open()
  }));

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    // dynamically set FlatList columns for different device sizes
    if (deviceSize === DEVICE_SIZES.EXTRA_LARGE_DEVICE) {
      setColumns(4);
    } else if (deviceSize === DEVICE_SIZES.LARGE_DEVICE) {
      setColumns(3);
    } else if (
      [DEVICE_SIZES.SMALL_DEVICE, DEVICE_SIZES.MEDIUM_DEVICE].includes(
        deviceSize
      )
    ) {
      setColumns(2);
    } else {
      setColumns(1);
    }
  }, [isMounted, width, isLandscape]);

  const selectItemHandler = useCallback(
    (slug: string, prodId: number) => {
      const partnersite = availablePartnerSites.find((partner: PartnerSite) =>
        partner.productId.includes(prodId)
      );

      if (!partnersite) {
        navigation.navigate('Shop', {
          screen: 'Products',
          params: {
            screen: 'ProductDetail',
            params: {
              productSlug: slug,
              enableBackButton: true
            }
          }
        });
      } else {
        navigation.navigate('Shop', {
          screen: 'Rentals',
          params: {
            screen: 'RentalDetail',
            params: {
              productSlug: slug,
              enableBackButton: true
            }
          }
        });
      }

      // close modal
      modalizeRef.current?.close();
    },
    [navigation, availablePartnerSites]
  );
  /* #endregion */

  /* #region Renderers */
  const modalizedHeader = (
    <View style={styles('headerModalize')}>
      <View
        style={[styles('modalizeBanner'), { backgroundColor: primaryColor }]}
      >
        <LabelText strong style={{ color: headerColor }}>
          {t('messageProductSearch')}{' '}
          <LabelText
            strong
            style={[styles('products'), { color: textAccentColor }]}
          >
            {filteredProducts.length} {t('messageOutOf')} {productCount}{' '}
            {t('titleSearchHits', { count: filteredProducts.length })}
          </LabelText>
        </LabelText>
      </View>
      <InputFilter
        containerStyle={[
          styles('filterContainer'),
          { shadowColor: shadowLightColor }
        ]}
        useStorage={true}
        onChangeFilter={(filters) => updateState(() => setFilterItems(filters))}
      />
    </View>
  );

  return (
    <View style={[styles('container'), { width: showLabel ? '100%' : 40 }]}>
      <TouchableComponent
        onPress={() => modalizeRef.current?.open()}
        style={[
          style,
          {
            marginHorizontal: Platform.select({
              web: showLabel ? 0 : 11,
              default: 0
            })
          }
        ]}
      >
        {!showLabel ? (
          <Ionicons
            name="search"
            color={headerButtonColor}
            style={styles('touchable')}
            size={24}
          />
        ) : (
          <MainText style={labelStyle} hoverable strong>
            {t('titleSearch')}
          </MainText>
        )}
      </TouchableComponent>
      <Modalize
        ref={modalizeRef}
        modalTopOffset={convertPercentageToNumber('5%')}
        keyboardAvoidingBehavior="padding"
        keyboardAvoidingOffset={100}
        // adjustToContentHeight={screenSize({ m: false }, true)}
        withOverlay={true}
        handleStyle={{ backgroundColor: textAccentColor }}
        // snapPoint={120}
        HeaderComponent={modalizedHeader}
        // panGestureEnabled={screenSize({ m: false }, true)}
        flatListProps={{
          style: { marginBottom: 20 },
          data: filteredProducts,
          extraData: columns,
          numColumns: columns,
          keyExtractor: (item, index) => index.toString(),
          key: columns.toString(), // changing key is needed, for dynamically changing numColumns attribute
          removeClippedSubviews: true,
          renderItem: (itemData) => (
            <ShopItem
              style={{
                // styling has to be done here, to use dynamic column constant
                width: width / columns - 40 // substract fixed margin of product item
              }}
              image={itemData.item.images[0]}
              title={itemData.item.title}
              price={itemData.item.price}
              onPress={() => {
                selectItemHandler(itemData.item.slug, itemData.item.id);
              }}
            />
          )
        }}
      />
    </View>
  );
  /* #endregion */
});

const useStyles = CreateResponsiveStyle(
  {
    container: {
      backgroundColor: 'transparent'
    },
    filterContainer: {
      ...Platform.select({
        android: {
          elevation: 5
        },
        default: {
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.26,
          shadowRadius: 8
        }
      })
    },
    headerModalize: {
      marginTop: 15,
      marginBottom: 10,
      paddingBottom: 10,
      overflow: 'hidden',
      justifyContent: 'space-around'
    },
    modalizeBanner: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    products: {
      textAlign: 'center',
      marginVertical: 10
    },
    touchable: {
      alignSelf: 'center',
      marginHorizontal: 8
    }
  },
  {}
);

export default SearchModal;
