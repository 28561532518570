/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';

import Config from '../../constants/Config';
import { singleCallAPI } from '../../helpers/api-utils';
import {
  getI18Locale,
  setI18Locale,
  t,
  tryFindMatchFromLanguageTag
} from '../../helpers/localized';
import logger from '../../helpers/logger';
import * as notificationActions from './notification';
import { FILTER_PERSISTENCE_KEY } from '../../components/UI/InputFilter';
import { CART_PERSISTENCE_KEY } from './cart';
import { RootState, persistor } from '..';

export const APP_INITIALIZED = 'APP_INITIALIZED';
export const SET_LOCALIZATION = 'SET_LOCALIZATION';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_CURRENCY_RATES = 'SET_CURRENCY_RATES';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_LOGGING = 'SET_LOGGING';
export const SET_COLLAPSED_HEADER = 'SET_COLLAPSED_HEADER';
export const LANGUAGE_PERSISTENCE_KEY = 'language';
export const CURRENCY_PERSISTENCE_KEY = 'currency';
export const LOGGING_PERSISTENCE_KEY = 'isLoggingEnabled';

let timer: ReturnType<typeof setTimeout>;

export const fetchCacheKey = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    const delayInitialize = await AsyncStorage.getItem(APP_INITIALIZED);
    if (delayInitialize) {
      setTimeout(() => {
        // wait
        logger.log(
          `store.actions.settings - fetchCacheKey: Timer delayInitialize done.`
        );
      }, 3000);
    }

    try {
      const language = getState().settings.language;
      const resData = await singleCallAPI(
        'GET',
        `${Config().api_url}/cachekeys/?name=CurrencyRate`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        }
      );
      const dbCacheKey =
        resData.results.length > 0 ? resData.results[0].cacheKey : '';

      // check dbCacheKey with cacheKey stored in AsyncStorage
      const localCacheKey = await AsyncStorage.getItem('cacheKeyCurrencyRate');

      if (
        dbCacheKey &&
        (!localCacheKey ||
          localCacheKey.trim() === '' ||
          (localCacheKey && localCacheKey !== dbCacheKey))
      ) {
        // update cacheKey in AsyncStorage
        await AsyncStorage.setItem('cacheKeyCurrencyRate', dbCacheKey);

        // delete stored ProductAttributes and re-fetch from database
        dispatch(fetchCurrencyRates());
      }

      dispatch(setCacheKeyTimer(Config().cacheRefetchTimeout));
    } catch (err) {
      logger.log(
        `store.actions.settings - fetchCacheKey: Cannot fetch cache key! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch cache key! Server responded with error - ${err}.`
      );
    }
  };
};

export const setLanguage = (
  languageTag: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    const language = tryFindMatchFromLanguageTag(languageTag);
    // try {
    //   const response = await fetch(`${Config().api_url}/set_language/`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Accept-Language': language
    //     },
    //     body: JSON.stringify({
    //       language: language
    //     })
    //   });

    //   if (!response.ok) {
    //     throw new Error('Something went wrong!');
    //   }

    //   const resData = await response.json();
    // } catch (ex) {}

    const storedLanguage = getI18Locale();
    if (storedLanguage !== language) {
      await setI18Locale(language);
      // remove language related items from AsyncStorage
      await AsyncStorage.multiRemove([
        'cacheKeyPartnerSite',
        'cacheKeyPartnerSiteImage',
        'cacheKeyPartnerSiteImageRelation',
        'cacheKeyProductCategory',
        'cacheKeyProductCategoryRelation',
        'cacheKeyProduct',
        'cacheKeyProductImage',
        'cacheKeyProductImageRelation',
        'cacheKeyProductAttribute',
        'cacheKeyShopCategory',
        'cacheKeyShopCategoryRelation',
        'cacheKeyCurrencyRate',
        FILTER_PERSISTENCE_KEY,
        CART_PERSISTENCE_KEY
      ]);
      await persistor.purge();
      persistor.persist();
    }
    dispatch({ type: SET_LOCALIZATION, locale: language });
  };
};

export const setCurrency = (
  currency: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    const storedCurrency = await AsyncStorage.getItem(CURRENCY_PERSISTENCE_KEY);
    if (storedCurrency !== currency) {
      await AsyncStorage.setItem(CURRENCY_PERSISTENCE_KEY, currency);
    }
    dispatch({ type: SET_CURRENCY, currency });
  };
};

export const fetchCurrencyRates = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    try {
      const exchangeCurrency = getState().settings.currency;
      const resData = await singleCallAPI(
        'GET',
        `${Config().api_url}/currencyrates/?currency=${exchangeCurrency}`,
        {
          'Content-Type': 'application/json'
        }
      );

      dispatch({
        type: SET_CURRENCY_RATES,
        currencies: resData.results.length > 0 ? resData.results[0].rates : {}
      });
    } catch (err) {
      logger.log(
        `store.actions.settings - fetchCurrencyRates: Cannot fetch configuration! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch configuration! Server responded with error - ${err}.`
      );
    }
  };
};

export const fetchConfiguration = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState
  ) => {
    const delayInitialize = await AsyncStorage.getItem(APP_INITIALIZED);
    if (delayInitialize) {
      setTimeout(() => {
        // wait
        logger.log(
          `store.actions.settings - fetchConfiguration: Timer delayInitialize done.`
        );
      }, 1500);
    }
    try {
      const language = getState().settings.language;
      const logging = getState().settings.logging;
      const resData = await singleCallAPI(
        'GET',
        `${Config().api_url}/configuration/`,
        {
          'Content-Type': 'application/json',
          'Accept-Language': language
        }
      );

      // maybe override isLoggingEnabled from AsyncStorage
      const loadedIsLoggingEnabled = (await AsyncStorage.getAllKeys()).includes(
        LOGGING_PERSISTENCE_KEY
      );
      let isLoggingEnabled = logging;
      if (loadedIsLoggingEnabled) {
        isLoggingEnabled =
          (await AsyncStorage.getItem(LOGGING_PERSISTENCE_KEY)) === 'true'
            ? true
            : false;
      }

      // maybe override currency setting from AsyncStorage
      const loadedStoredCurrency = (await AsyncStorage.getAllKeys()).includes(
        CURRENCY_PERSISTENCE_KEY
      );
      let storedCurrency = resData.language_to_currency[language];
      if (loadedStoredCurrency) {
        storedCurrency = await AsyncStorage.getItem(CURRENCY_PERSISTENCE_KEY);
      }

      logger.log(
        'store.actions.settings - fetchConfiguration: Using server config',
        resData
      );
      dispatch({
        type: SET_CONFIGURATION,
        logging: isLoggingEnabled,
        currencies: resData.language_to_currency,
        valuetypes: resData.valuetypes,
        screens: resData.screens,
        thousandSeparator: resData.use_thousand_separator,
        salesTax: resData.sales_tax
      });
      dispatch(setCurrency(storedCurrency));
    } catch (err) {
      logger.log(
        `store.actions.settings - fetchConfiguration: Cannot fetch configuration! Server responded with error - ${err}.`
      );
      notificationActions.setMessage(
        t('errorOccurred'),
        `Cannot fetch configuration! Server responded with error - ${err}.`
      );
    }
  };
};

export const setLogging = (
  isLoggingEnabled: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    dispatch({
      type: SET_LOGGING,
      logging: isLoggingEnabled
    });
    await saveDataToStorage(isLoggingEnabled);
  };
};

export const setCollapsedHeader = (
  isCollapsed: boolean
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    dispatch({
      type: SET_COLLAPSED_HEADER,
      isCollapsed
    });
  };
};

const clearCacheKeyTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

const setCacheKeyTimer = (
  expirationTime: number
): ThunkAction<void, RootState, unknown, AnyAction> => {
  clearCacheKeyTimer();
  return (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    timer = setTimeout(() => {
      dispatch(fetchCacheKey());
    }, expirationTime);
  };
};

const saveDataToStorage = async (logging: boolean) => {
  await AsyncStorage.setItem(LOGGING_PERSISTENCE_KEY, JSON.stringify(logging));
};
