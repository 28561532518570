/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { Platform } from 'react-native';
import EnvVars from '../env.json';

const variables = {
  development: {
    api_url: Platform.select({
      android: 'http://10.0.2.2:8000/api/v1',
      default: 'http://127.0.0.1:8000/api/v1'
    }),
    domain_prefix_backend: 'http://localhost:8000',
    domain_prefix_frontend: 'http://localhost:19006',
    defaultLocale: 'de',
    defaultCurrency: 'EUR',
    logFile: 'Logging/saunaexpert.log',
    isLoggingEnabled: true,
    cacheRefetchTimeout: 1000 * 60,
    useThousandSeparator: false,
    useUserActivationLink: true,
    cryptoSecret: '21qHs00000000000QEqZXMxQ9zgHy+bu0=',
    pagination: 25,
    facebookPixelId: '',
    pinterestConversionTrackingId: '',
    googleUniversalAnalyticsTag: '',
    googleAnalytics4Tag: '',
    rentalsUrl: '',
    mapboxApiKey:
      'pk.eyJ1IjoiZHNvZnRhcHBkZXYiLCJhIjoiY2t5MG9tZDJ5MDNuajJ1bzZ5Ymo0bHNkYiJ9.pFtdGb3Ei1IhwYjaNH32zw'
  },
  staging: {
    api_url: 'https://saunaexpert.dsoft-app-dev.de:8080/api/v1',
    domain_prefix_backend: 'https://saunaexpert.dsoft-app-dev.de:8080',
    domain_prefix_frontend: 'https://saunaexpert.dsoft-app-dev.de',
    defaultLocale: 'de',
    defaultCurrency: 'EUR',
    logFile: 'Logging/saunaexpert.log',
    isLoggingEnabled: true,
    cacheRefetchTimeout: 1000 * 60,
    useThousandSeparator: false,
    useUserActivationLink: true,
    cryptoSecret: '21qHs00000000000QEqZXMxQ9zgHy+bu0=',
    pagination: 25,
    facebookPixelId: '',
    pinterestConversionTrackingId: '',
    googleUniversalAnalyticsTag: '',
    googleAnalytics4Tag: '',
    rentalsUrl: '',
    mapboxApiKey: ''
  },
  production: {
    api_url: 'https://shop.saunaexpert.de:8443/api/v1',
    domain_prefix_backend: 'https://shop.saunaexpert.de:8443',
    domain_prefix_frontend: 'https://shop.saunaexpert.de',
    defaultLocale: 'de',
    defaultCurrency: 'EUR',
    logFile: 'Logging/saunaexpert.log',
    isLoggingEnabled: false,
    cacheRefetchTimeout: 1000 * 60 * 15,
    useThousandSeparator: false,
    useUserActivationLink: true,
    cryptoSecret: 'U2FsdGVkX18ZUVvShFSES21q9zgHy+bu0=',
    pagination: 25,
    facebookPixelId: '1383981668643471',
    pinterestConversionTrackingId: '2612752796663',
    googleUniversalAnalyticsTag: 'UA-207178647-1',
    googleAnalytics4Tag: 'G-KK9P75VS3D',
    rentalsUrl: 'https://saunaexpert.de/catalog.html',
    mapboxApiKey: ''
  }
};

const getConfigVariables = () => {
  let vars;
  if (EnvVars.ENV === 'development') {
    // return this if in development mode
    vars = variables.development;
  } else if (EnvVars.ENV === 'staging') {
    // return this if in staging mode
    vars = variables.staging;
  } else {
    // otherwise, return this
    vars = variables.production;
  }

  // overwrite vars with .env variables
  Object.keys(EnvVars).forEach((key) => {
    if (key in vars) {
      vars[key] = EnvVars[key];
    }
  });

  return vars;
};

export default getConfigVariables;
