/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import useIsMounted from '../../hooks/useIsMounted';
import { parseLocaleNumber, t } from '../../helpers/localized';
import { View, Ionicons, useThemeColor, ThemeProps } from './Themed';
import { LabelText, MainText } from './StyledText';
import RadioButton from './RadioButton';
import TouchableComponent from './TouchableComponent';
import HTMLComponent from './HTMLComponent';
import * as productattributesActions from '../../store/actions/productattributes';
import * as notificationActions from '../../store/actions/notification';
import { RootState } from '../../store';
import { IProductAttribute, ProductAttributeJsonValueType } from '../../types';

export type RadioButtonGroupProps = ThemeProps & {
  value: Array<ProductAttributeJsonValueType> | ProductAttributeJsonValueType;
  multiSelect: boolean;
  groupStyle: StyleSheet['props'];
  buttonStyle: StyleSheet['props'];
  groupText: string;
  productId: number;
  selectedAttributes: Array<IProductAttribute> | IProductAttribute;
  selectedConfigItem: IProductAttribute;
  availableConfigItems: Array<IProductAttribute>;
  hasError: boolean;
  errorValue: string;
  onPress: () => void;
};

const RadioButtonGroup = (props: RadioButtonGroupProps): JSX.Element => {
  /* #region Fields */
  const {
    value,
    multiSelect,
    groupStyle,
    buttonStyle,
    groupText,
    productId,
    selectedAttributes,
    selectedConfigItem,
    availableConfigItems,
    hasError,
    errorValue,
    onPress,
    lightColor,
    darkColor
  } = props;
  const language = useSelector((state: RootState) => state.settings.language);
  const currency = useSelector((state: RootState) => state.settings.currency);
  const { styles } = useStyles();
  const { width, height } = Dimensions.get('window');
  const isLandscape = width > height;
  const isMounted = useIsMounted();
  const [label, setLabel] = useState<string>('');
  const [currentValue, setCurrentValue] = useState(value);
  const configuratorDetails = useSelector(
    (state: RootState) => state.notification.configuratorDetails
  );
  const [radioSelected, setRadioSelected] = useState<Array<number> | number>(
    multiSelect ? [] : -1
  );
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const attentionColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'attention'
  );
  const detailsBackground = useThemeColor(
    { light: lightColor, dark: darkColor },
    'inactive'
  );
  const shadowHardColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'shadowHard'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (multiSelect) {
      // filter selectedConfigItem selectedIndex, if attribute is hidden by attributeKey
      const newSelectedIndex: Array<number> = [];
      const newLabels: Array<string> = [];
      _.map(selectedAttributes, 'json.value.label').forEach((item) => {
        if (_.map(selectedConfigItem.json.value, 'label').includes(item)) {
          // add index
          newSelectedIndex.push(
            selectedConfigItem.json.value.findIndex((val) => val.label === item)
          );

          // set label
          selectedAttributes.forEach((attr) => {
            const json_field = attr.json;
            if (
              json_field.value.input &&
              json_field.value.input.hasOwnProperty('replaceLabel')
            ) {
              const newLabel: string =
                json_field?.value.input.replaceLabel?.replace(
                  '%n',
                  json_field.value.input.lastInput
                );
              if (!newLabels.includes(newLabel)) {
                newLabels.push(newLabel);
              }
            } else {
              if (!newLabels.includes(json_field.value.label)) {
                newLabels.push(json_field.value.label);
              }
            }
          });
        }
      });
      updateState(() => setRadioSelected([...new Set(newSelectedIndex)]));
      updateState(() => setLabel(newLabels.join(', ')));
    } else {
      const newSelectedIndex = selectedAttributes?.json.selectedIndex;
      updateState(() => setRadioSelected(newSelectedIndex));

      // set label
      const json_field = selectedAttributes?.json;
      if (
        json_field?.value.input &&
        json_field?.value.input.hasOwnProperty('replaceLabel')
      ) {
        const newLabel: string = json_field?.value.input.replaceLabel?.replace(
          '%n',
          json_field?.value.input.lastInput
        );
        updateState(() => setLabel(newLabel));
      } else {
        updateState(() => setLabel(json_field?.value.label));
      }
    }
  }, [multiSelect, selectedAttributes, selectedConfigItem]);

  useEffect(() => {
    // update currentValue with stored values from selectedAttributes
    if (multiSelect) {
      const updatedValue = value;
      selectedAttributes.forEach((attr) => {
        const json_field = attr.json;
        const configItemIdx = value.findIndex(
          (val) => val.label === json_field.value.label
        );
        updatedValue[configItemIdx] = json_field.value;
      });

      updateState(() => setCurrentValue(updatedValue));
    } else {
      updateState(() => setCurrentValue(value));
    }
  }, [multiSelect, selectedAttributes, value]);
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[styles('container'), { width: isLandscape ? '90%' : '100%' }]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <LabelText strong>
          {groupText}:{' '}
          <MainText style={hasError ? { color: attentionColor } : {}}>
            {label}{' '}
          </MainText>
        </LabelText>
        <TouchableComponent testID="closeButtonGroupTrigger" onPress={onPress}>
          <Ionicons
            name="chevron-up-circle-outline"
            color={buttonAccentColor}
          />
        </TouchableComponent>
        {hasError && (
          <>
            <Ionicons name="hand-left" color={attentionColor} />
            <MainText style={{ color: attentionColor }}>
              {t('messageActionRequired')} '{errorValue}'
            </MainText>
          </>
        )}
      </View>
      <View style={[styles('group'), groupStyle]}>
        {currentValue.map((val, id: number) => {
          // set dynamic fields
          let lastInput = val.input ? val.input.lastInput : 1;
          let finalPrice =
            parseLocaleNumber(val.finalPrice) !== 0
              ? parseLocaleNumber(val.finalPrice)
              : '';

          // price correction for finalStepPrice
          if (val.input?.hasOwnProperty('finalStepPrice')) {
            let finalStepPrice = parseLocaleNumber(val.input.finalStepPrice);
            finalStepPrice = finalStepPrice * lastInput;
            finalPrice = parseLocaleNumber(val.finalPrice) + finalStepPrice;
          }

          // render with dynamic fields
          return (
            <RadioButton
              key={id.toString()}
              style={[styles('radioStyle'), buttonStyle]}
              id={id}
              onPress={() => {
                let newSelectedValue;
                if (multiSelect) {
                  // toggle state, return type is number[]
                  if (!radioSelected.includes(id)) {
                    newSelectedValue = [...radioSelected, id];
                  } else {
                    newSelectedValue = radioSelected.filter(
                      (obj) => obj !== id
                    );
                  }
                } else {
                  // if not multiSelect, return type is number
                  newSelectedValue = id;
                }
                updateState(() => setRadioSelected(newSelectedValue));

                // update group label
                if (val.input && val.input.hasOwnProperty('replaceLabel')) {
                  const newLabel = val.input.replaceLabel?.replace(
                    '%n',
                    lastInput.toString()
                  );
                  if (newLabel !== label) {
                    updateState(() => setLabel(newLabel));
                  }
                }

                dispatch(
                  productattributesActions.updateSelectedProductAttributes(
                    val.label,
                    newSelectedValue,
                    multiSelect,
                    productId,
                    selectedAttributes,
                    selectedConfigItem,
                    availableConfigItems
                  )
                );
              }}
              onInputChange={(idx, num) => {
                // update lastInput
                lastInput = num;

                // .. for selectedAttributes
                if (multiSelect) {
                  selectedAttributes.forEach((attr: IProductAttribute) => {
                    if (attr.json.selectedIndex === idx) {
                      attr.json.value.input.lastInput = lastInput;
                    }
                  });
                } else {
                  selectedAttributes.json.value.input.lastInput = lastInput;
                }

                // .. for currentValue
                currentValue[idx].input.lastInput = lastInput;

                // update redux store
                dispatch(
                  productattributesActions.updateSelectedProductAttributes(
                    val.label,
                    radioSelected,
                    multiSelect,
                    productId,
                    selectedAttributes,
                    selectedConfigItem,
                    availableConfigItems
                  )
                );

                // set ProductConfiguratorDetails for event
                const tmpLabel =
                  val.input?.replaceLabel && lastInput
                    ? val.input.replaceLabel.replace('%n', lastInput.toString())
                    : val.label;

                let tmpStepPrice = parseLocaleNumber(val.input.finalStepPrice);
                tmpStepPrice = tmpStepPrice * lastInput;
                const tmpFinalPrice = (
                  parseLocaleNumber(val.finalPrice) + tmpStepPrice
                ).toLocaleString(language, {
                  style: 'currency',
                  currency: currency
                });

                dispatch(
                  notificationActions.setProductConfiguratorDetails({
                    title: tmpLabel,
                    price: tmpFinalPrice,
                    description: val.description
                  })
                );
              }}
              selectedRadioId={radioSelected}
              hasError={hasError}
              label={val.label}
              image={val.image}
              imageResizeMode={
                val.imageResizeMode ? val.imageResizeMode : 'cover'
              }
              color={val.color}
              input={val.input}
              inputNumber={lastInput}
              finalPrice={finalPrice.toLocaleString(language, {
                style: 'currency',
                currency: currency
              })}
              description={val.description}
            />
          );
        })}
      </View>
      <View style={{ justifyContent: 'center', marginHorizontal: 10 }}>
        <View
          style={[
            styles('detailsContainer'),
            {
              backgroundColor: detailsBackground,
              shadowColor: shadowHardColor
            }
          ]}
        >
          <MainText small strong>
            {configuratorDetails.title?.length > 0
              ? `${t('titleSelection')} "${configuratorDetails.title}"`
              : t('titleNothingSelected')}
          </MainText>
          {configuratorDetails.title?.length > 0 && (
            <>
              <View
                style={{ marginVertical: 10, backgroundColor: 'transparent' }}
              >
                <HTMLComponent
                  baseStyle={{ fontSize: 13 }}
                  source={{ html: configuratorDetails.description }}
                />
              </View>
              <MainText small strong>
                {t('titleAdditionalPrice')}{' '}
                <MainText small>
                  {configuratorDetails.price
                    ? configuratorDetails.price
                    : t('none')}
                </MainText>
              </MainText>
            </>
          )}
        </View>
      </View>
    </View>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {},
    group: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    radioStyle: {
      margin: 5
    },
    detailsContainer: {
      ...Platform.select({
        android: {
          elevation: 8
        },
        default: {
          shadowOffset: { width: 2, height: 4 },
          shadowOpacity: 0.46,
          shadowRadius: 8
        }
      }),
      borderRadius: 5,
      padding: 20,
      marginTop: 20
    }
  },
  {}
);

export default RadioButtonGroup;
