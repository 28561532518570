/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import {
  TouchableOpacity,
  TouchableNativeFeedback,
  Platform
} from 'react-native';

export type TouchableComponentProps = { noFeedback?: boolean } & (
  | TouchableNativeFeedback['props']
  | TouchableOpacity['props']
);

const TouchableComponent = (props: TouchableComponentProps): JSX.Element => {
  /* #region Fields */
  const { children, noFeedback } = props;
  /* #endregion */

  const TouchableCmp: React.ElementType =
    Platform.OS === 'android' && Platform.Version >= 21
      ? TouchableNativeFeedback
      : TouchableOpacity;

  return (
    <TouchableCmp activeOpacity={noFeedback ? 1.0 : 0.5} {...props}>
      {children}
    </TouchableCmp>
  );
};

export default TouchableComponent;
