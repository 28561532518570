/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { Platform, StyleSheet } from 'react-native';

import { useThemeColor, View } from './Themed';

const Card = (props): JSX.Element => {
  /* #region Fields */
  const { style, children, lightColor, darkColor } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'background'
  );
  const shadowLightColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'shadowLight'
  );
  /* #endregion */

  /* #region Renderers */
  return (
    <View
      style={[
        styles.card,
        { backgroundColor, shadowColor: shadowLightColor },
        style
      ]}
    >
      {children}
    </View>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  card: {
    ...Platform.select({
      android: {
        elevation: 5
      },
      default: {
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.26,
        shadowRadius: 8
      }
    }),
    borderRadius: 10
  }
});

export default Card;
