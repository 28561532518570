/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *
 *  Credits goes to https://www.joshwcomeau.com/react/boop/
 *-------------------------------------------------------------*/

import React from 'react';
import { useSpring } from 'react-spring';

import usePrefersReducedMotion from './usePrefersReducedMotion';

export type BoopHookProps = {
  x?: number;
  y?: number;
  rotation?: number;
  scale?: number;
  timing?: number;
  springConfig?: {
    tension: number;
    friction: number;
  };
};

const useBoop = ({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  timing = 150,
  springConfig = {
    tension: 300,
    friction: 10
  }
}: BoopHookProps) => {
  /* #region Fields */
  const prefersReducedMotion = usePrefersReducedMotion();

  const [isBooped, setIsBooped] = React.useState(false);

  const style = useSpring({
    display: 'inline-block',
    backfaceVisibility: 'hidden',
    transform: isBooped
      ? `translate(${x}px, ${y}px)
         rotate(${rotation}deg)
         scale(${scale})`
      : `translate(0px, 0px)
         rotate(0deg)
         scale(1)`,
    config: springConfig
  });
  /* #endregion */

  /* #region Events */
  React.useEffect(() => {
    // We only want to act when we're going from
    // not-booped to booped.
    if (!isBooped) {
      return;
    }

    if (timing > 0) {
      const timeoutId = window.setTimeout(() => {
        setIsBooped(false);
      }, timing);

      // Just in case our component happens to
      // unmount while we're booped, cancel
      // the timeout to avoid a memory leak.
      return () => {
        window.clearTimeout(timeoutId);
      };
    }

    // Trigger this effect whenever `isBooped`
    // changes. We also listen for `timing` changes,
    // in case the length of the boop delay is
    // variable.
  }, [isBooped, timing]);

  const startTrigger = () => {
    setIsBooped(true);
  };

  const endTrigger = () => {
    // Trigger is only used, if timing is set to 0
    if (timing === 0) {
      setIsBooped(false);
    }
  };
  /* #endregion */

  const applicableStyle = prefersReducedMotion ? {} : style;

  return [applicableStyle, startTrigger, endTrigger];
};

export default useBoop;
