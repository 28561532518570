/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { Linking, Platform, StyleSheet } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import { CommonActions, DrawerActions } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList
} from '@react-navigation/drawer'; // Full list of icons, see https://icons.expo.fyi
import { useDispatch, useSelector } from 'react-redux';
import pkg from '../package.json';

import Config from '../constants/Config';
import { t } from '../helpers/localized';
import {
  View,
  Button,
  Ionicons,
  useThemeColor,
  ThemeProps
} from '../components/UI/Themed';
import { LabelText } from '../components/UI/StyledText';
import TouchableComponent from '../components/UI/TouchableComponent';
import Toolbar from '../components/UI/Toolbar';
import Flag from '../components/web/Flag';
import NewsScreen from '../screens/content/NewsScreen';
import ArticlesScreen from '../screens/content/ArticlesScreen';
import ArticleScreen from '../screens/content/ArticleScreen';
import ArticleCategoriesScreen from '../screens/content/ArticleCategoriesScreen';
import ShopCategoriesScreen from '../screens/shop/ShopCategoriesScreen';
import ProductCategoriesScreen from '../screens/shop/ProductCategoriesScreen';
import ProductsOverviewScreen from '../screens/shop/ProductsOverviewScreen';
import ProductDetailScreen from '../screens/shop/ProductDetailScreen';
import RentalsOverviewScreen from '../screens/rentals/RentalsOverviewScreen';
import RentalDetailScreen from '../screens/rentals/RentalDetailScreen';
import CartScreen from '../screens/shop/CartScreen';
import OrdersScreen from '../screens/shop/OrdersScreen';
import UserProductsScreen from '../screens/user/UserProductsScreen';
import EditProductScreen from '../screens/user/EditProductScreen';
import UserAuthScreen from '../screens/user/AuthScreen';
import UserProfileScreen from '../screens/user/UserProfileScreen';
import ShopAuthScreen from '../screens/shop/AuthScreen';
import GuestProfileScreen from '../screens/shop/GuestProfileScreen';
import FinishOrderScreen from '../screens/shop/FinishOrderScreen';
import OrderSummaryScreen from '../screens/shop/OrderSummaryScreen';
import SettingsScreen from '../screens/SettingsScreen';
import * as authActions from '../store/actions/auth';
import * as settingsActions from '../store/actions/settings';
import * as notificationActions from '../store/actions/notification';
import { RootState } from '../store';

const defaultNavOptions = (props: ThemeProps) => {
  /* #region Fields */
  const { lightColor, darkColor } = props;

  const headerBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerBackground'
  );
  const headerBottomColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerBottom'
  );
  const headerButtonsColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const headerColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'header'
  );
  /* #endregion */

  return {
    headerStyle: {
      backgroundColor: headerBackgroundColor,
      borderBottomColor: headerBottomColor,
      borderBottomWidth: 2
    },
    headerTintColor: headerButtonsColor,
    headerTitleStyle: {
      fontFamily: 'default-bold',
      color: headerColor
    },
    headerBackTitleStyle: {
      fontFamily: 'default-regular',
      color: headerColor
    },
    headerBackTitleVisible: false,
    // Bugfix for backImage theme tintColor on web platform
    headerBackImage: (props: { tintColor: string }) => {
      const { tintColor } = props;
      const marginHorizontal = 8;
      return (
        <Ionicons
          style={{ marginHorizontal }}
          color={tintColor}
          name="arrow-back"
        />
      );
    },
    animationEnabled: true,
    gestureEnabled: true,
    // Bugfix for scrollability on web platform
    cardStyle: { flex: 1 }
  };
};

const defaultScreenOptions = () => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  /* #endregion */

  return {
    headerTitle: (props) => <Toolbar {...props} />, // title string is injected by navigation.setOptions({ title: '...' })
    headerRight: () => <View /> // Dummy for right buttons of header, to correct size of Toolbar
  };
};

const mainScreenOptions = ({ navigation, route, lightColor, darkColor }) => {
  /* #region Fields */
  const stackIdx = navigation.getState().index;
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  /* #endregion */

  return {
    // Show HeaderButtons for Menu, when
    // 1. Screen was opened via drawer
    // 2. Screen was directly called via deep linking with route params
    headerLeft: () => {
      if (
        !route.params?.enableBackButton &&
        (!route.params || stackIdx === 0)
      ) {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.dispatch(DrawerActions.toggleDrawer());
            }}
            style={styles.headerButton}
          >
            <Ionicons
              name="menu"
              color={headerButtonColor}
              style={styles.touchable}
            />
          </TouchableComponent>
        );
      }
    }
  };
};

const NewsStackNavigator = createStackNavigator();

const NewsNavigator = (props: ThemeProps) => {
  return (
    <NewsStackNavigator.Navigator screenOptions={defaultNavOptions(props)}>
      <NewsStackNavigator.Screen
        name="News"
        component={NewsScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props),
            ...mainScreenOptions(props)
          };
        }}
      />
    </NewsStackNavigator.Navigator>
  );
};

const BlogStackNavigator = createStackNavigator();

const BlogNavigator = (props: ThemeProps) => {
  return (
    <BlogStackNavigator.Navigator screenOptions={defaultNavOptions(props)}>
      <BlogStackNavigator.Screen
        name="Articles"
        component={ArticlesScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props),
            ...mainScreenOptions(props)
          };
        }}
      />
      <BlogStackNavigator.Screen
        name="Article"
        component={ArticleScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <BlogStackNavigator.Screen
        name="ArticleCategories"
        component={ArticleCategoriesScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
    </BlogStackNavigator.Navigator>
  );
};

const ProductsStackNavigator = createStackNavigator();

const ProductsNavigator = (props: ThemeProps) => {
  return (
    <ProductsStackNavigator.Navigator screenOptions={defaultNavOptions(props)}>
      <ProductsStackNavigator.Screen
        name="ShopCategories"
        component={ShopCategoriesScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props),
            ...mainScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="ProductCategories"
        component={ProductCategoriesScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="ProductsOverview"
        component={ProductsOverviewScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="ProductDetail"
        component={ProductDetailScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="Cart"
        component={CartScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="ShopAuth"
        component={ShopAuthScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="GuestProfile"
        component={GuestProfileScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="FinishOrder"
        component={FinishOrderScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="OrderSummary"
        component={OrderSummaryScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
    </ProductsStackNavigator.Navigator>
  );
};

const RentalsStackNavigator = createStackNavigator();

const RentalsNavigator = (props: ThemeProps) => {
  return (
    <RentalsStackNavigator.Navigator screenOptions={defaultNavOptions(props)}>
      <ProductsStackNavigator.Screen
        name="RentalsOverview"
        component={RentalsOverviewScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props),
            ...mainScreenOptions(props)
          };
        }}
      />
      <RentalsStackNavigator.Screen
        name="RentalDetail"
        component={RentalDetailScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
    </RentalsStackNavigator.Navigator>
  );
};

const OrdersStackNavigator = createStackNavigator();

const OrdersNavigator = (props: ThemeProps) => {
  return (
    <OrdersStackNavigator.Navigator screenOptions={defaultNavOptions(props)}>
      <OrdersStackNavigator.Screen
        name="Orders"
        component={OrdersScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props),
            ...mainScreenOptions(props)
          };
        }}
      />
    </OrdersStackNavigator.Navigator>
  );
};

const AdminStackNavigator = createStackNavigator();

const AdminNavigator = (props: ThemeProps) => {
  return (
    <AdminStackNavigator.Navigator screenOptions={defaultNavOptions(props)}>
      <AdminStackNavigator.Screen
        name="UserProduct"
        component={UserProductsScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props),
            ...mainScreenOptions(props)
          };
        }}
      />
      <AdminStackNavigator.Screen
        name="EditProduct"
        component={EditProductScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
    </AdminStackNavigator.Navigator>
  );
};

const AuthStackNavigator = createStackNavigator();

export const AuthNavigator = (props: ThemeProps) => {
  return (
    <AuthStackNavigator.Navigator screenOptions={defaultNavOptions(props)}>
      <AuthStackNavigator.Screen
        name="UserAuth"
        component={UserAuthScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
      <ProductsStackNavigator.Screen
        name="UserProfile"
        component={UserProfileScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props)
          };
        }}
      />
    </AuthStackNavigator.Navigator>
  );
};

const SettingsStackNavigator = createStackNavigator();

export const SettingsNavigator = (props: ThemeProps) => {
  return (
    <SettingsStackNavigator.Navigator screenOptions={defaultNavOptions(props)}>
      <SettingsStackNavigator.Screen
        name="Settings"
        component={SettingsScreen}
        options={(props) => {
          return {
            ...defaultScreenOptions(props),
            ...mainScreenOptions(props)
          };
        }}
      />
    </SettingsStackNavigator.Navigator>
  );
};

const ShopDrawerNavigator = createDrawerNavigator();

export const ShopNavigator = (props: ThemeProps) => {
  /* #region Fields */
  const { lightColor, darkColor } = props;
  const availableCurrencies = useSelector(
    (state: RootState) => state.settings.availableCurrencies
  );
  const isWeb = Platform.OS === 'web';
  const isAnonymous = useSelector(
    (state: RootState) => state.auth.userId === null
  );
  const isAuth = useSelector((state: RootState) => !!state.auth.accessToken);
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);

  const tintColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'tint'
  );
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );

  const dispatch = useDispatch();
  /* #endregion */

  /* #region Events */
  const reloadHandler = (lang: string) => {
    try {
      dispatch(settingsActions.setCurrency(availableCurrencies[lang]));
      dispatch(settingsActions.setLanguage(lang));
      // forceUpdate()
      isWeb && window.location.reload();
    } catch (err) {
      dispatch(notificationActions.setMessage(t('errorOccurred'), err.message));
    }
  };
  /* #endregion */

  /* #region Renderers */
  return (
    <ShopDrawerNavigator.Navigator
      initialRouteName="Products"
      screenOptions={{
        headerShown: false,
        drawerActiveTintColor: tintColor
      }}
      drawerContent={(props) => {
        // Done: Hiding DrawerItem "Blog" for now, but keep it configured to navigate to blog articles
        const filteredProps = {
          ...props,
          state: {
            ...props.state,
            routeNames: props.state.routeNames, // .filter((routeName) => routeName !== 'Blog'),
            routes: props.state.routes // .filter((route) => route.name !== 'Blog')
          }
        };
        return (
          <View style={{ flex: 1, paddingTop: 20 }}>
            <SafeAreaView
              forceInset={{ vertical: 'always', horizontal: 'always' }}
            >
              <DrawerContentScrollView {...props}>
                <DrawerItemList {...filteredProps} />
                {/* <DrawerItem
                label={t('titleSearch')}
                icon={(props) => (
                  <Ionicons
                    name="search"
                    color={props.color}
                    size={props.size}
                  />
                )}
                onPress={() => {
                  props.navigation.emit({
                    type: 'drawerItemPress',
                    target: route.key,
                    canPreventDefault: true,
                  });
                  props.navigation.dispatch(DrawerActions.closeDrawer());
                  searchRef.current?.open();
                }}
              /> */}
                <View
                  style={{
                    alignContent: 'flex-start',
                    backgroundColor: 'transparent'
                  }}
                >
                  {isAnonymous && (
                    <Button
                      title={t('titleLogin')}
                      color={buttonColor}
                      onPress={() => {
                        props.navigation.dispatch(DrawerActions.closeDrawer());
                        props.navigation.dispatch(
                          CommonActions.navigate({ name: 'Auth' })
                        );
                      }}
                    />
                  )}
                  {isAuth && (
                    <Button
                      title={t('titleLogout')}
                      color={buttonColor}
                      onPress={() => {
                        props.navigation.dispatch(DrawerActions.closeDrawer());
                        dispatch(authActions.logout());
                      }}
                    />
                  )}
                </View>
                {isWeb && (
                  <View
                    style={{
                      marginTop: 20,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      height: 43
                    }}
                  >
                    <Flag
                      uri={require('../assets/images/de_flag.png')}
                      style={{
                        marginHorizontal: 10,
                        backgroundColor: 'transparent'
                      }}
                      onPress={() => reloadHandler('de')}
                    />
                    <Flag
                      uri={require('../assets/images/ch_flag.png')}
                      style={{
                        marginHorizontal: 10,
                        backgroundColor: 'transparent'
                      }}
                      onPress={() => reloadHandler('de-ch')}
                    />
                    <Flag
                      uri={require('../assets/images/uk_flag.png')}
                      style={{
                        marginHorizontal: 10,
                        backgroundColor: 'transparent'
                      }}
                      onPress={() => reloadHandler('en')}
                    />
                    <Flag
                      uri={require('../assets/images/ru_flag.png')}
                      style={{
                        marginHorizontal: 10,
                        backgroundColor: 'transparent'
                      }}
                      onPress={() => reloadHandler('ru')}
                    />
                    <Flag
                      uri={require('../assets/images/cs_flag.png')}
                      style={{
                        marginHorizontal: 10,
                        backgroundColor: 'transparent'
                      }}
                      onPress={() => reloadHandler('cs')}
                    />
                  </View>
                )}
                <View
                  style={{
                    marginTop: 20,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    backgroundColor: 'transparent'
                  }}
                >
                  <LabelText small>
                    {t('titleVersion')} {pkg.version}
                  </LabelText>
                </View>
              </DrawerContentScrollView>
            </SafeAreaView>
          </View>
        );
      }}
      screenListeners={({ navigation, route }) => ({
        drawerItemPress: (e) => {
          // Prevent default behavior and do something with the event data
          // console.log('drawerItemPress on screen', e);

          const rentalsUrl = Config().rentalsUrl;

          if (rentalsUrl.trim().length > 0 && route.name === 'Rentals') {
            e.preventDefault();
            navigation.dispatch(DrawerActions.closeDrawer());
            isWeb
              ? window.open(rentalsUrl, '_blank')
              : Linking.openURL(rentalsUrl);
          }
        },
        beforeRemove: (e) => {
          // Do something with the event data
          // console.log('beforeRemove on news', e.data);
          // Bugfix for Firefox - Prevent default behavior of leaving the screen
          if (e.data?.action.type === 'RESET') {
            e.preventDefault();
          }
        },
        blur: (e) => {
          // Do something with the event logic
          // console.log('blur on screen', e);
          navigation.setParams({ screen: undefined, params: undefined });
        }
      })}
    >
      <ShopDrawerNavigator.Screen
        name="News"
        component={NewsNavigator}
        options={{
          title: t('titleNewsNavigation'),
          drawerIcon: (props) => (
            <Ionicons name="newspaper" size={23} color={props.color} />
          )
        }}
      />
      <ShopDrawerNavigator.Screen
        name="Blog"
        component={BlogNavigator}
        options={{
          title: t('titleBlogNavigation'),
          drawerIcon: (props) => (
            <Ionicons name="book" size={23} color={props.color} />
          )
        }}
      />
      <ShopDrawerNavigator.Screen
        name="Products"
        component={ProductsNavigator}
        options={{
          title: t('titleProductsNavigation'),
          drawerIcon: (props) => (
            <Ionicons name="cart" size={23} color={props.color} />
          )
        }}
      />
      <ShopDrawerNavigator.Screen
        name="Rentals"
        component={RentalsNavigator}
        options={{
          title: t('titleRentalsNavigation'),
          drawerIcon: (props) => (
            <Ionicons name="location-sharp" size={23} color={props.color} />
          )
        }}
      />
      {isAuth && (
        <ShopDrawerNavigator.Screen
          name="Orders"
          component={OrdersNavigator}
          options={{
            title: t('titleOrdersNavigation'),
            drawerIcon: (props) => (
              <Ionicons name="receipt" size={23} color={props.color} />
            ),
            unmountOnBlur: isWeb ? true : false
          }}
        />
      )}
      {isAuth && isAdmin && (
        <ShopDrawerNavigator.Screen
          name="Admin"
          component={AdminNavigator}
          options={{
            title: t('titleAdminNavigation'),
            drawerIcon: (props) => (
              <Ionicons name="create" size={23} color={props.color} />
            ),
            unmountOnBlur: isWeb ? true : false
          }}
        />
      )}
      <ShopDrawerNavigator.Screen
        name="Settings"
        component={SettingsNavigator}
        options={{
          title: t('titleSettings'),
          drawerIcon: (props) => (
            <Ionicons name="cog" size={23} color={props.color} />
          )
        }}
      />
    </ShopDrawerNavigator.Navigator>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  headerButton: {
    marginHorizontal: Platform.select({ web: 11, default: 0 })
  },
  touchable: {
    marginHorizontal: 8
  }
});
