/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useReducer, useCallback, useEffect } from 'react';
import {
  ScrollView,
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform
} from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { formReducer, getFormState } from '../../helpers/input-form';
import {
  View,
  Button,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import AlertModal from '../../components/UI/AlertModal';
import { LabelText } from '../../components/UI/StyledText';
import TouchableComponent from '../../components/UI/TouchableComponent';
import ContainerView from '../../components/UI/ContainerView';
import Input from '../../components/UI/Input';
import OrderProgress from '../../components/shop/OrderProgress';
import * as authActions from '../../store/actions/auth';
import { RootState } from '../../store';
import { ShopStackParamList } from '../../types';

const GuestProfileScreen = (
  { route, navigation }: StackScreenProps<ShopStackParamList, 'GuestProfile'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const { styles } = useStyles();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const guestId = route.params?.guestId;
  const editedGuest = useSelector(
    (state: RootState) => state.auth.activeUserProfile
  );
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const dispatch = useDispatch();
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );

  // form validation model
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      company_name: editedGuest ? editedGuest.company_name : null,
      title: editedGuest ? editedGuest.title : null,
      firstName: editedGuest ? editedGuest.firstName : '',
      lastName: editedGuest ? editedGuest.lastName : '',
      email: editedGuest ? editedGuest.email : '',
      mobile: editedGuest ? editedGuest.mobile : null,
      phone: editedGuest ? editedGuest.phone : null,
      address: editedGuest ? editedGuest.address : '',
      zipcode: editedGuest ? editedGuest.zipcode : '',
      location: editedGuest ? editedGuest.location : '',
      country: editedGuest ? editedGuest.country : ''
    },
    inputValidities: {
      company_name: true,
      title: true,
      firstName: editedGuest ? true : false,
      lastName: editedGuest ? true : false,
      email: editedGuest ? true : false,
      mobile: true,
      phone: true,
      address: editedGuest ? true : false,
      zipcode: editedGuest ? true : false,
      location: editedGuest ? true : false,
      country: editedGuest ? true : false
    },
    formIsValid: false
  });
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const submitHandler = useCallback(async () => {
    if (!formState.formIsValid) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('wrongInput')}
            message={t('checkFormErrors')}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
      return;
    }

    updateState(() => setIsLoading(true));

    try {
      if (editedGuest && guestId) {
        dispatch(
          authActions.updateGuestProfile(
            guestId,
            formState.inputValues.company_name,
            formState.inputValues.title,
            formState.inputValues.firstName,
            formState.inputValues.lastName,
            formState.inputValues.email,
            formState.inputValues.mobile,
            formState.inputValues.phone,
            formState.inputValues.address,
            formState.inputValues.zipcode,
            formState.inputValues.location,
            formState.inputValues.country
          )
        );
      } else {
        dispatch(
          authActions.createGuestProfile(
            formState.inputValues.company_name,
            formState.inputValues.title,
            formState.inputValues.firstName,
            formState.inputValues.lastName,
            formState.inputValues.email,
            formState.inputValues.mobile,
            formState.inputValues.phone,
            formState.inputValues.address,
            formState.inputValues.zipcode,
            formState.inputValues.location,
            formState.inputValues.country
          )
        );
      }
    } catch (err) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('errorOccurred')}
            message={err.message}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    } finally {
      updateState(() => setIsLoading(false));
      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'FinishOrder'
        }
      });
    }
  }, [dispatch, guestId, formState]);

  const inputChangeHandler = useCallback(
    (inputIdentifier: string, inputValue: unknown, inputValidity: boolean) => {
      dispatchFormState(
        getFormState(inputIdentifier, inputValue, inputValidity)
      );
    },
    [dispatchFormState]
  );

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleGuestProfileScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleGuestProfileScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'Cart'
                }
              });
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, cartItemCount]);
  /* #endregion */

  return (
    <ContainerView
      scrollable
      seoTitle={`${t('titleGuestProfileScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <KeyboardAvoidingView
        behavior="padding"
        keyboardVerticalOffset={50}
        style={styles('screen')}
      >
        {alertModalContent}
        <ScrollView style={styles('container')}>
          <View style={styles('form')}>
            <OrderProgress />
            <Input
              id="company_name"
              label={t('inputCompanyNameLabel')}
              errorText={t('inputCompanyNameError')}
              keyboardType="default"
              autoCapitalize="sentences"
              autoCorrect
              returnKeyType="next"
              onInputChange={inputChangeHandler}
              initialValue={editedGuest ? editedGuest.company_name : ''}
              initiallyValid={!!editedGuest}
            />
            <View style={styles('inlineFormFields')}>
              <Input
                id="title"
                label={t('inputContactTitleLabel')}
                errorText={t('inputContactTitleError')}
                keyboardType="default"
                autoCapitalize="sentences"
                autoCorrect
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedGuest ? editedGuest.title : ''}
                initiallyValid={!!editedGuest}
                controlStyle={{ flex: 1 }}
              />
              <Input
                id="firstName"
                label={t('inputContactFirstNameLabel')}
                errorText={t('inputContactFirstNameError')}
                keyboardType="default"
                autoCapitalize="sentences"
                autoCorrect
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedGuest ? editedGuest.firstName : ''}
                initiallyValid={!!editedGuest}
                required
                controlStyle={styles('control1')}
              />
              <Input
                id="lastName"
                label={t('inputContactLastNameLabel')}
                errorText={t('inputContactLastNameError')}
                keyboardType="default"
                autoCapitalize="sentences"
                autoCorrect
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedGuest ? editedGuest.lastName : ''}
                initiallyValid={!!editedGuest}
                required
                controlStyle={{ flex: 2 }}
              />
            </View>
            <Input
              id="address"
              label={t('inputAddressLabel')}
              errorText={t('inputAddressError')}
              keyboardType="default"
              autoCapitalize="sentences"
              autoCorrect
              returnKeyType="next"
              onInputChange={inputChangeHandler}
              initialValue={editedGuest ? editedGuest.address : ''}
              initiallyValid={!!editedGuest}
              required
            />
            <View style={styles('inlineFormFields')}>
              <Input
                id="zipcode"
                label={t('inputZipcodeLabel')}
                errorText={t('inputZipcodeError')}
                keyboardType="default"
                autoCapitalize="sentences"
                autoCorrect
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedGuest ? editedGuest.zipcode : ''}
                initiallyValid={!!editedGuest}
                required
                controlStyle={{ flex: 1 }}
              />
              <Input
                id="location"
                label={t('inputLocationLabel')}
                errorText={t('inputLocationError')}
                keyboardType="default"
                autoCapitalize="sentences"
                autoCorrect
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedGuest ? editedGuest.location : ''}
                initiallyValid={!!editedGuest}
                required
                controlStyle={styles('control1')}
              />
              <Input
                id="country"
                label={t('inputCountryLabel')}
                errorText={t('inputCountryError')}
                keyboardType="default"
                autoCapitalize="sentences"
                autoCorrect
                returnKeyType="next"
                onInputChange={inputChangeHandler}
                initialValue={editedGuest ? editedGuest.country : ''}
                initiallyValid={!!editedGuest}
                required
                controlStyle={{ flex: 2 }}
              />
            </View>
            <Input
              id="email"
              label={t('inputEMailLabel')}
              errorText={t('inputEMailError')}
              keyboardType="email-address"
              email
              autoCapitalize="none"
              onInputChange={inputChangeHandler}
              initialValue={editedGuest ? editedGuest.email : ''}
              initiallyValid={!!editedGuest}
              required
              controlStyle={styles('control2')}
            />
            <Input
              id="mobile"
              label={t('inputMobileLabel')}
              errorText={t('inputMobileError')}
              keyboardType="default"
              phonenumber
              returnKeyType="next"
              onInputChange={inputChangeHandler}
              initialValue={editedGuest ? editedGuest.mobile : ''}
              initiallyValid={!!editedGuest}
              controlStyle={styles('control2')}
            />
            <Input
              id="phone"
              label={t('inputPhoneLabel')}
              errorText={t('inputPhoneError')}
              keyboardType="default"
              phonenumber
              returnKeyType="next"
              onInputChange={inputChangeHandler}
              initialValue={editedGuest ? editedGuest.phone : ''}
              initiallyValid={!!editedGuest}
              controlStyle={styles('control2')}
            />
            <View style={styles('buttonContainer')}>
              <Button
                color={buttonAccentColor}
                title={t('titleBack')}
                onPress={() => {
                  navigation.goBack();
                }}
              />
              {isLoading ? (
                <ActivityIndicator size="small" color={primaryColor} />
              ) : (
                <Button
                  title={t('titleContinue')}
                  color={buttonAccentColor}
                  onPress={submitHandler}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </ContainerView>
  );
};

const useStyles = CreateResponsiveStyle(
  {
    screen: {
      flex: 1
    },
    container: {
      marginVertical: 20
    },
    form: {
      padding: 10,
      marginHorizontal: '8%'
    },
    inlineFormFields: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center'
    },
    control1: {
      flex: 2,
      marginHorizontal: 20
    },
    control2: {
      width: 400
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      marginTop: 10
    },
    actions: {
      justifyContent: 'center'
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      form: {
        marginHorizontal: '4%'
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      form: {
        marginHorizontal: 0
      },
      inlineFormFields: {
        flexDirection: 'column'
      },
      control1: {
        marginHorizontal: 0
      },
      control2: {
        width: '100%'
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      form: {
        marginHorizontal: 0
      },
      inlineFormFields: {
        flexDirection: 'column'
      },
      control1: {
        marginHorizontal: 0
      },
      control2: {
        width: '100%'
      }
    }
  }
);

export default GuestProfileScreen;
