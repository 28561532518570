/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2020 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { StyleSheet } from 'react-native';

import { t } from '../../helpers/localized';
import { View } from '../../components/UI/Themed';
import { LabelText } from '../../components/UI/StyledText';

const AddressItem = (props): JSX.Element => {
  return (
    <View>
      {props.profile.company_name && (
        <LabelText strong>
          {t('inputCompanyNameLabel')}{' '}
          <LabelText>{props.profile.company_name}</LabelText>
        </LabelText>
      )}
      <LabelText strong>
        {t('titleName')}{' '}
        {props.profile.title && <LabelText>{props.profile.title} </LabelText>}
        <LabelText>{props.profile.firstName}</LabelText>{' '}
        <LabelText>{props.profile.lastName}</LabelText>
      </LabelText>
      {props.profile.address && (
        <LabelText strong>
          {t('inputAddressLabel')}{' '}
          <LabelText>{props.profile.address}</LabelText>
        </LabelText>
      )}
      <LabelText strong>
        {t('inputZipcodeLabel')} <LabelText>{props.profile.zipcode}</LabelText>
      </LabelText>
      <LabelText strong>
        {t('inputLocationLabel')}{' '}
        <LabelText>{props.profile.location}</LabelText>
      </LabelText>
      {props.profile.country && (
        <LabelText strong>
          {t('inputCountryLabel')}{' '}
          <LabelText>{props.profile.country}</LabelText>
        </LabelText>
      )}
      {props.profile.email && (
        <LabelText strong>
          {t('inputEMailLabel')} <LabelText>{props.profile.email}</LabelText>
        </LabelText>
      )}
      {props.profile.mobile && (
        <LabelText strong>
          {t('inputMobileLabel')} <LabelText>{props.profile.mobile}</LabelText>
        </LabelText>
      )}
      {props.profile.phone && (
        <LabelText strong>
          {t('inputPhoneLabel')} <LabelText>{props.profile.phone}</LabelText>
        </LabelText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({});

export default AddressItem;
