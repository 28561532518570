/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dimensions, FlatList, Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import {
  View,
  useThemeColor,
  Ionicons,
  Button,
  ThemeProps
} from '../../components/UI/Themed';
import { MainText } from '../../components/UI/StyledText';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import AlertModal from '../../components/UI/AlertModal';
import TouchableComponent from '../../components/UI/TouchableComponent';
import Loading from '../../components/UI/Loading';
import ShopItem from '../../components/shop/ShopItem';
import * as productsActions from '../../store/actions/products';
import * as notificationActions from '../../store/actions/notification';
import { RootState } from '../../store';
import { AdminStackParamList } from '../../types';

const UserProductsScreen = (
  { navigation }: StackScreenProps<AdminStackParamList, 'UserProduct'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const { styles, deviceSize } = useStyles();
  const { width, height } = Dimensions.get('window');
  const isLandscape = width > height;
  const [containerDimension, setContainerDimension] = useState({
    width: 1,
    height: 1
  });
  const containerRef = useRef<ContainerViewProps>();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>(
    <></>
  );
  const [error, setError] = useState<string>('');
  const userProducts = useSelector(
    (state: RootState) => state.products.userProducts
  );
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<number>(3);
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const loadUserProducts = useCallback(async () => {
    updateState(() => setError(''));
    updateState(() => setIsLoading(true));
    try {
      dispatch(productsActions.fetchProducts());
    } catch (err) {
      updateState(() => setError(err.message));
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, setError]);

  useEffect(() => {
    // refetch all products when drawer navigation page is loaded (default for drawer is using a cached page)
    const unsubscribe = navigation.addListener('focus', loadUserProducts);

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  useEffect(() => {
    // dynamically set FlatList columns for different device sizes
    if (deviceSize === DEVICE_SIZES.EXTRA_LARGE_DEVICE) {
      setColumns(4);
    } else if (deviceSize === DEVICE_SIZES.LARGE_DEVICE) {
      setColumns(3);
    } else if (
      [DEVICE_SIZES.SMALL_DEVICE, DEVICE_SIZES.MEDIUM_DEVICE].includes(
        deviceSize
      )
    ) {
      setColumns(2);
    } else {
      setColumns(1);
    }
  }, [width, isLandscape]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleUserProductsScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleUserProductsScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'EditProduct'
                }
              });
            }}
            style={styles('headerButton')}
          >
            <Ionicons
              name="create"
              color={headerButtonColor}
              style={styles('touchable')}
            />
          </TouchableComponent>
        );
      }
    });
  }, [navigation]);

  const deleteHandler = useCallback(
    (id) => {
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('areYouSure')}
            message={t('deleteItem')}
            onOkayPress={async () => {
              updateState(() => setIsLoading(true));
              try {
                dispatch(productsActions.deleteProduct(id));
              } catch (err) {
                dispatch(
                  notificationActions.setMessage(
                    t('errorOccurred'),
                    err.message
                  )
                );
              } finally {
                updateState(() => setIsLoading(false));
              }
            }}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    },
    [dispatch, setIsLoading]
  );

  const editProductHandler = useCallback(
    (id) => {
      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'EditProduct',
          params: { productId: id }
        }
      });
    },
    [navigation]
  );
  /* #endregion */

  /* #region Renderers */
  if (error) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleUserProductsScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
      >
        <View style={styles('centered')}>
          <MainText>
            {t('errorOccurred')} {error}
          </MainText>
          <Button
            title={t('tryAgain')}
            color={buttonColor}
            onPress={loadUserProducts}
          />
        </View>
      </ContainerView>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (userProducts.length === 0) {
    return (
      <ContainerView
        scrollable
        seoTitle={`${t('titleUserProductsScreen')} | ${t('appTitle')}`}
        seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
      >
        <View style={styles('centered')}>
          <MainText>{t('noProducts')}</MainText>
        </View>
      </ContainerView>
    );
  }

  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      seoTitle={`${t('titleUserProductsScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
      onLayout={(event) => {
        const { width, height } = event.nativeEvent.layout;
        updateState(() =>
          setContainerDimension({
            width: width - 40,
            height
          })
        );
      }}
    >
      {alertModalContent}
      <FlatList
        scrollEnabled={!containerRef.current?.scrollable}
        contentContainerStyle={styles('container')}
        onRefresh={loadUserProducts}
        refreshing={isLoading}
        data={userProducts}
        extraData={{ columns, width }} // using for responsive re-rendering of FlatList content
        numColumns={columns}
        keyExtractor={(item, index) => index.toString()}
        key={columns.toString()} // changing key is needed, for dynamically changing numColumns attribute
        removeClippedSubviews={true}
        renderItem={(itemData) => (
          <ShopItem
            style={
              isWeb
                ? {
                    // substract fixed margin of product item
                    width: width / columns - 40
                  }
                : {
                    // Styling has to be done here, to use dynamic column constant.
                    // Substract fixed margin of product item, when in landscape mode
                    width: isLandscape
                      ? containerDimension.width / columns - 20
                      : containerDimension.width / columns
                  }
            }
            image={itemData.item.images[0]}
            title={itemData.item.title}
            price={itemData.item.price}
            onSelect={() => {
              editProductHandler(itemData.item.id);
            }}
            actions={() => (
              <Ionicons
                name="trash-bin"
                onPress={() => {
                  deleteHandler(itemData.item.id);
                }}
              />
            )}
          />
        )}
      />
    </ContainerView>
  );
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      alignItems: 'stretch',
      marginBottom: 20
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    touchable: {
      marginHorizontal: 8
    }
  },
  {}
);

export default UserProductsScreen;
