/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { t } from '../helpers/localized';

export const fileDetailsTemplate = (mimeType: string, extension?: string) => {
  switch (mimeType) {
    case 'video/mp4':
      return {
        extension: extension || '.mp4',
        shareOptions: {
          mimeType: 'video/mp4',
          dialogTitle: t('titleShareDialogVideo'),
          UTI: 'video/mp4'
        }
      };

    case 'image/jpeg':
      return {
        extension: extension || '.jpg',
        shareOptions: {
          mimeType: 'image/jpeg',
          dialogTitle: t('titleShareDialogImage'),
          UTI: 'image/jpeg'
        }
      };

    default:
      return {
        extension: extension || '.txt',
        shareOptions: {
          mimeType: 'text/plain',
          dialogTitle: t('titleShareDialogTextFile'),
          UTI: 'text/plain'
        }
      };
  }
};
