/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';

interface Props {
  components: Array<
    [React.JSXElementConstructor<React.PropsWithChildren<any>>, {}?]
  >;
  children: React.ReactNode;
}

const ComposeProviders = (props: Props) => {
  const { components = [], children } = props;

  return (
    <React.Fragment>
      {components.reduceRight((acc, [Comp, compProps = {}]) => {
        return <Comp {...compProps}>{acc}</Comp>;
      }, children)}
    </React.Fragment>
  );
};

export default ComposeProviders;
