/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import { Modal } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { MainText } from './StyledText';
import { useThemeColor, View, Ionicons, ThemeProps, Button } from './Themed';

export type AlertModalProps = ThemeProps &
  (
    | {
        title?: string;
        message: string;
        icon: string;
        iconColor: string;
        isVisible?: boolean;
        hideCancel?: boolean;
        useAutoClosing?: boolean;
        autoCloseTiming?: number;
        onOkayPress?: () => void;
        onDismiss?: () => void;
        actionsStyle?: StyleSheet['props'];
        children?: JSX.Element | Array<JSX.Element>;
      }
    | {
        title?: string;
        message: string;
        icon?: string;
        iconColor?: string;
        isVisible?: boolean;
        hideCancel?: boolean;
        useAutoClosing?: boolean;
        autoCloseTiming?: number;
        onOkayPress?: () => void;
        onDismiss?: () => void;
        actionsStyle?: StyleSheet['props'];
        children?: JSX.Element | Array<JSX.Element>;
      }
  );

const AlertModal = (props: AlertModalProps): JSX.Element => {
  /* #region Fields */
  const {
    title,
    message,
    icon,
    iconColor,
    isVisible,
    hideCancel,
    useAutoClosing,
    autoCloseTiming,
    onOkayPress,
    onDismiss,
    actionsStyle,
    children,
    lightColor,
    darkColor
  } = props;
  const isMounted = useIsMounted();
  const [visible, setVisible] = useState<boolean>(
    isVisible === undefined ? true : isVisible
  );
  const [useIconsOnly, setUseIconsOnly] = useState<boolean>(false);
  const [useInternalButtons, setUseInternalButtons] = useState<boolean>(false);
  const { styles } = useStyles();
  const [timing, setTiming] = useState<number>(
    autoCloseTiming === undefined ? 800 : autoCloseTiming
  );
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'border'
  );
  const backdropColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'backdrop'
  );
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (useAutoClosing) {
      const timeoutId = setTimeout(() => {
        updateState(() => setVisible(false));
      }, timing);

      // Just in case our component happens to
      // unmount, cancel the timeout to avoid a memory leak.
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [useAutoClosing, timing]);

  useEffect(() => {
    if (isVisible) {
      updateState(() => setVisible(isVisible));
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    if (icon) {
      if (!children && !onOkayPress) {
        // show Icons only
        setUseIconsOnly(true);
        setUseInternalButtons(false);
      } else if (!children && onOkayPress) {
        // show Icons and Internal Buttons
        setUseInternalButtons(true);
        setUseIconsOnly(false);
      } else {
        // show Icons and External Buttons
        setUseInternalButtons(false);
        setUseIconsOnly(false);
      }
    } else {
      setUseIconsOnly(false);

      if (!children) {
        // show no Icons and Internal Buttons
        setUseInternalButtons(true);
      } else {
        // show no Icons and External Buttons
        setUseInternalButtons(false);
      }
    }
  }, [isMounted, children, onOkayPress, icon]);
  /* #endregion */

  /* #region Renderers */
  return (
    <Modal
      testID="modalView"
      visible={visible}
      transparent
      onRequestClose={() => updateState(() => setVisible(false))}
      onDismiss={onDismiss}
    >
      <View style={[styles('container'), { backgroundColor: backdropColor }]}>
        <View
          style={[
            styles('modal'),
            { borderColor },
            children || onOkayPress ? styles('children') : {}
          ]}
        >
          {title && <MainText strong>{title}</MainText>}
          {icon && iconColor && (
            <Ionicons name={icon} color={iconColor} size={80} />
          )}
          <MainText style={styles('messageText')}>{message}</MainText>
          {!useIconsOnly && (
            <View
              style={[styles('spacer'), { borderTopColor: borderColor }]}
            ></View>
          )}
          {!useIconsOnly && !useInternalButtons && children && (
            <View
              style={[
                children.length > 1
                  ? styles('moreActions')
                  : styles('singleAction'),
                actionsStyle
              ]}
            >
              {children}
            </View>
          )}
          {!useIconsOnly && useInternalButtons && onOkayPress && (
            <View
              style={[
                styles('moreActions'),
                hideCancel ? { justifyContent: 'center' } : {}
              ]}
            >
              {!hideCancel && (
                <Button
                  testID="cancelTrigger"
                  title={t('titleCancel')}
                  onPress={() => updateState(() => setVisible(false))}
                />
              )}
              <Button
                testID="okayTrigger"
                title={t('okay')}
                onPress={() => {
                  onOkayPress();
                  updateState(() => setVisible(false));
                }}
              />
            </View>
          )}
          {!useIconsOnly && useInternalButtons && !onOkayPress && (
            <View
              style={[
                styles('moreActions'),
                {
                  justifyContent: 'center'
                }
              ]}
            >
              <Button
                testID="okayTrigger"
                title={t('okay')}
                onPress={() => updateState(() => setVisible(false))}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },
    modal: {
      // minHeight: 150,
      borderWidth: 1,
      // boxShadow: '1px 1px 1px black',
      padding: 16,
      justifyContent: 'space-between',
      alignItems: 'center',
      // boxSizing: 'border-box'
      borderRadius: 10,
      overflow: 'hidden'
    },
    children: {
      width: 500
    },
    messageText: {
      textAlign: 'center',
      paddingTop: 15
    },
    spacer: {
      width: '100%',
      borderTopWidth: 1,
      paddingTop: 10
    },
    singleAction: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    moreActions: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '90%',
      paddingHorizontal: 20
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      children: {
        width: '70%'
      },
      messageText: {
        paddingTop: 8
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      children: {
        width: '98%'
      },
      messageText: {
        paddingTop: 8
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      children: {
        width: '98%'
      },
      messageText: {
        paddingTop: 5
      }
    }
  }
);

export default AlertModal;
