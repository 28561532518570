/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { useColorScheme as _useColorScheme } from 'react-native-appearance';

// useColorScheme from react-native does not support web currently. So we replace
// this with react-native-appearance to have theme support on web.
const useColorScheme = (): string => {
  // return _useColorScheme();
  return 'light'; // force light theme for now
};

export default useColorScheme;
