/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { t } from '../helpers/localized';
import { View, useThemeColor, ThemeProps } from '../components/UI/Themed';
import ContainerView from '../components/UI/ContainerView';
import { MainText } from '../components/UI/StyledText';
import { RootStackParamList } from '../types';

const NotFoundScreen = (
  { navigation }: StackScreenProps<RootStackParamList, 'NotFound'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'button');
  /* #endregion */

  return (
    <ContainerView
      scrollable
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <View style={styles.container}>
        <MainText strong>{t('screenNotFound')}</MainText>
        <TouchableOpacity
          onPress={() => navigation.replace('Shop')}
          style={styles.link}
        >
          <MainText style={{ color }}>{t('goToHome')}</MainText>
        </TouchableOpacity>
      </View>
    </ContainerView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  link: {
    marginTop: 15,
    paddingVertical: 15
  }
});

export default NotFoundScreen;
