/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';

import useIsMounted from '../../hooks/useIsMounted';
import Colors from '../../constants/Colors';
import { Ionicons, View, ThemeProps, useThemeColor } from '../UI/Themed';
import { MainText } from '../UI/StyledText';
import { RootState } from '../../store';

type BigHeaderTileProps = ThemeProps & {
  position: string;
};

type HeaderTileProps = ThemeProps & {
  backgroundColor: string;
  position?: string;
  param?: string;
  manualColorTheme?: 'light' | 'dark';
  textAlign: 'left' | 'right';
  icon?: string;
  text?: string;
  subText?: string;
};

type ScreenSettingsProps = {
  name: string;
  array: Array<HeaderTileProps>;
};

type ScreenProps = {
  name: string;
  settings: Array<ScreenSettingsProps>;
};

// global vars
let timer: ReturnType<typeof setInterval>;
let index = 0;

const SMALL_MAINTEXT: Record<DEVICE_SIZES, boolean> = {
  extra_large: false,
  large: false,
  medium: true,
  small: true,
  extra_small: true
};

const BigHeaderTile = React.memo((props: BigHeaderTileProps) => {
  /* #region Fields */
  const { position, lightColor, darkColor } = props;
  const isMounted = useIsMounted();
  const { styles, deviceSize } = useStyles();
  const [isReady, setIsReady] = useState<boolean>(false);
  const collapsedHeader: boolean = useSelector(
    (state: RootState) => state.settings.collapsedHeader
  );
  const screenSettings: Array<ScreenSettingsProps> = useSelector(
    (state: RootState) =>
      state.settings.screens?.find(
        (screens: ScreenProps) => screens.name === 'AllScreens'
      )?.settings
  );
  const headerTiles = screenSettings
    ?.find((config: ScreenSettingsProps) => config.name === 'header tiles')
    ?.array.filter((tile: HeaderTileProps) => {
      return tile.position === position;
    });
  const [tileItem, setTileItem] = useState<HeaderTileProps>();
  const transitions = useTransition(tileItem, {
    from: animatedStyles.tileAnimationFrom,
    enter: animatedStyles.tileAnimationEnter,
    leave: animatedStyles.tileAnimationLeave,
    config: { duration: 1000 }
  });
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    if (headerTiles && !tileItem && !isReady) {
      setTileItem(headerTiles[0]);
      setIsReady(true);
    }
  }, [isMounted, headerTiles, tileItem, isReady]);

  useEffect(() => {
    if (isReady && headerTiles?.length > 1) {
      if (collapsedHeader) {
        if (timer) {
          clearInterval(timer);
          timer = undefined;
        }
      } else {
        if (headerTiles && !timer) {
          timer = setInterval(() => {
            index++;

            // reset index to restart the loop
            if (index >= headerTiles.length) {
              index = 0;
            }

            headerTiles.forEach((item, idx) => {
              if (index === idx) {
                // TODO: isn't working reliably after navigation
                updateState(() => setTileItem(item));
              }
            });
          }, 5000);
        }
      }
    }
  }, [collapsedHeader, headerTiles, isReady]);
  /* #endregion */

  /* #region Renderers */
  return (
    <>
      {transitions((props, item, key) => {
        const textColor = item?.manualColorTheme
          ? Colors[item.manualColorTheme].text
          : useThemeColor({ light: lightColor, dark: darkColor }, 'text');

        return (
          <animated.div
            style={StyleSheet.flatten([
              styles('animatedContainer'),
              { ...props, backgroundColor: item?.backgroundColor }
            ])}
            key={key}
          >
            <View
              style={[
                styles('tileContainer'),
                item?.textAlign === 'left'
                  ? { justifyContent: 'flex-start' }
                  : { justifyContent: 'flex-end' }
              ]}
            >
              <View
                style={[
                  styles('tile'),
                  {
                    backgroundColor: item?.backgroundColor
                  }
                ]}
              >
                {item?.icon && <Ionicons name={item?.icon} color={textColor} />}
              </View>
              <MainText
                style={[styles('mainText'), { color: textColor }]}
                small={SMALL_MAINTEXT[deviceSize]}
              >
                {item?.text}
              </MainText>
            </View>
            <View
              style={[
                styles('tileContainer'),
                styles('spacer'),
                item?.textAlign === 'left'
                  ? { justifyContent: 'flex-start' }
                  : { justifyContent: 'flex-end' }
              ]}
            >
              <View
                style={[
                  styles('tile'),
                  {
                    backgroundColor: item?.backgroundColor
                  }
                ]}
              />
              <MainText style={[styles('subText'), { color: textColor }]} small>
                {item?.subText}
              </MainText>
            </View>
          </animated.div>
        );
      })}
    </>
  );
  /* #endregion */
});

const animatedStyles = StyleSheet.flatten({
  tileAnimationFrom: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transform: 'translate3d(0,-10px,0)'
  },
  tileAnimationEnter: {
    opacity: 1,
    transform: 'translate3d(0,0px,0)'
  },
  tileAnimationLeave: {
    opacity: 0,
    transform: 'translate3d(0,-10px,0)'
  }
});

const useStyles = CreateResponsiveStyle(
  {
    animatedContainer: {
      marginLeft: 15,
      marginRight: 15
    },
    tileContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    tile: {
      width: 30,
      paddingLeft: 5
    },
    spacer: {
      marginTop: 5
    },
    mainText: {
      marginLeft: 5
    },
    subText: {
      marginLeft: 5
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      animatedContainer: {
        marginLeft: 0,
        marginRight: 0
      },
      spacer: {
        marginTop: 0
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      animatedContainer: {
        marginLeft: 0,
        marginRight: 0
      },
      spacer: {
        marginTop: 0
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      animatedContainer: {
        marginLeft: 0,
        marginRight: 0
      },
      spacer: {
        marginTop: 0
      }
    }
  }
);

export default BigHeaderTile;
