/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import _ from 'lodash';

import { numberToLocalString, parseLocaleNumber, t } from './localized';
import {
  IProductAttribute,
  ProductAttributeJsonValueType,
  ProductAttributeValueTypes
} from '../types';

export const getAllHideAttributeKeysByAttributeName = (
  productAttributeValueTypes?: Array<ProductAttributeValueTypes>,
  productAttributes?: Array<IProductAttribute>
) => {
  // add available attributeKeys from productAttributes
  const hideAttributeKeys = Array<Object>();
  productAttributes?.forEach((attr: IProductAttribute) => {
    const json_field = attr.json;
    const value_type = productAttributeValueTypes?.find(
      (valuetype) => valuetype.id === json_field.type
    );

    if (
      // handle productAttributes, which have a single value (the selected ones!)
      value_type?.type_name === t('productAttributeValueTypeDictionary') &&
      !Array.isArray(json_field.value) &&
      json_field.value.hasOwnProperty('hidesAttributeKey')
    ) {
      hideAttributeKeys.push({
        key: (json_field.value as ProductAttributeJsonValueType).label,
        value: (json_field.value as ProductAttributeJsonValueType)
          .hidesAttributeKey
      });
    } else if (
      // handle productAttributes, which have a multiple value
      value_type?.type_name === t('productAttributeValueTypeDictionary') &&
      Array.isArray(json_field.value)
    ) {
      json_field.value.forEach((item) => {
        if (item.hidesAttributeKey) {
          hideAttributeKeys.push({
            key: item.label,
            value: item.hidesAttributeKey
          });
        }
      });
    }
  });

  // console.log('getAllHideAttributeKeysByAttributeName', hideAttributeKeys);
  return hideAttributeKeys;
};

const createUniqueKeyList = (
  productAttributeValueTypes?: Array<ProductAttributeValueTypes>,
  selectedProductAttributes?: Array<IProductAttribute>
) => {
  const hideAttributeKeys = getAllHideAttributeKeysByAttributeName(
    productAttributeValueTypes,
    selectedProductAttributes
  );
  // remove duplicates from key list and return
  const uniqueKeyList = [
    ...new Set(_.flatMap(_.map(hideAttributeKeys, 'value')))
  ];

  // console.log('createUniqueKeyList', uniqueKeyList);
  return uniqueKeyList;
};

export const productAttributeFilter = (
  productAttributeValueTypes?: Array<ProductAttributeValueTypes>,
  selectedProductAttributes?: Array<IProductAttribute>,
  configItems?: Array<IProductAttribute>
) => {
  const uniqueHideAttributeKeys = createUniqueKeyList(
    productAttributeValueTypes,
    selectedProductAttributes
  );

  let refiltered: Array<IProductAttribute> | undefined = [];
  // filter attribute groups with matching attributeKey
  if (configItems) {
    refiltered = configItems.filter((attr: IProductAttribute) => {
      const json_field = attr.json;
      const value_type = productAttributeValueTypes?.find(
        (valuetype) => valuetype.id === json_field.type
      );

      if (value_type?.type_name === t('productAttributeValueTypeDictionary')) {
        // remove complete group of attributes
        const attributeGroupKey = json_field.attributeKey;
        if (attributeGroupKey) {
          return !uniqueHideAttributeKeys?.includes(attributeGroupKey);
        }
      }

      return true;
    });
  } else {
    refiltered = selectedProductAttributes?.filter(
      (attr: IProductAttribute) => {
        // remove complete group of attributes
        const attributeGroupKey = attr.attributeKey;
        if (attributeGroupKey) {
          return !uniqueHideAttributeKeys?.includes(attributeGroupKey);
        }

        return true;
      }
    );
  }

  return refiltered;
};

export const productAttributeValueFilter = (
  productAttributeValueTypes?: Array<ProductAttributeValueTypes>,
  selectedProductAttributes?: Array<IProductAttribute>,
  configItemValue?:
    | string
    | Array<ProductAttributeJsonValueType>
    | ProductAttributeJsonValueType
    | number
) => {
  const uniqueHideAttributeKeys = createUniqueKeyList(
    productAttributeValueTypes,
    selectedProductAttributes
  );

  if (Array.isArray(configItemValue)) {
    const refiltered = configItemValue.filter(
      (value: ProductAttributeJsonValueType) => {
        // remove only single attributes of group
        const attributeItemKey = value.attributeKey;

        // return every item, which has no set attributeKey
        if (!attributeItemKey) {
          return true;
        }

        // return every value, which has attributeKey, which is not in the filter list
        if (!uniqueHideAttributeKeys?.includes(attributeItemKey)) {
          return true;
        }

        return false;
      }
    );

    // console.log('productAttributeValueFilter', refiltered);
    return refiltered;
  } else {
    // console.log('productAttributeValueFilter', configItemValue);
    return configItemValue;
  }
};

const createAddsAttributePriceList = (
  productAttributeValueTypes?: Array<ProductAttributeValueTypes>,
  selectedProductAttributes?: Array<IProductAttribute>
) => {
  // add available addsAttributePrices from selectedProductAttributes
  let addsAttributePriceList = {};
  selectedProductAttributes?.forEach((attr: IProductAttribute) => {
    const json_field = attr.json;
    const value_type = productAttributeValueTypes?.find(
      (valuetype) => valuetype.id === json_field.type
    );

    // handle selectedProductAttributes, which have a single value (the selected ones!)
    if (
      value_type?.type_name === t('productAttributeValueTypeDictionary') &&
      !Array.isArray(json_field.value) &&
      json_field.value.hasOwnProperty('addsAttributePrice')
    ) {
      addsAttributePriceList = {
        ...addsAttributePriceList,
        ...(json_field.value as ProductAttributeJsonValueType)
          .addsAttributePrice
      };
    }
  });

  // omit attributes with key 'self'
  addsAttributePriceList = Object.fromEntries(
    Object.entries(addsAttributePriceList).filter((e) => e[0] != 'self')
  );

  // console.log('addsAttributePriceList', addsAttributePriceList);
  return addsAttributePriceList;
};

export const productAttributePriceFilter = (
  productAttributeValueTypes?: Array<ProductAttributeValueTypes>,
  selectedProductAttributes?: Array<IProductAttribute>,
  configItems?: Array<IProductAttribute>,
  originKey?: string
) => {
  const addsAttributePriceList: { [key: string]: number | string } =
    createAddsAttributePriceList(
      productAttributeValueTypes,
      selectedProductAttributes
    );

  let refiltered: Array<IProductAttribute> | undefined = [];

  if (configItems) {
    refiltered = configItems.map((attr: IProductAttribute) => {
      const json_field = attr.json;
      const value_type = productAttributeValueTypes?.find(
        (valuetype) => valuetype.id === json_field.type
      );

      if (value_type?.type_name === t('productAttributeValueTypeDictionary')) {
        const addsConfigItemPriceList: { [key: string]: number | string } = {};
        if (originKey) {
          (json_field.value as ProductAttributeJsonValueType[]).forEach(
            (configAttr: ProductAttributeJsonValueType) => {
              if (
                configAttr.hasOwnProperty('addsAttributePrice') &&
                Object.keys(configAttr.addsAttributePrice!).includes(originKey)
              ) {
                addsConfigItemPriceList[configAttr.label] =
                  configAttr.addsAttributePrice![originKey];
                // console.log(
                //   'Handle originKey',
                //   originKey,
                //   configAttr.label,
                //   configAttr.addsAttributePrice![originKey]
                // );
              }
            }
          );
        }

        let groupKey = '';
        // handle complete group of attributes
        if (
          json_field.hasOwnProperty('attributeKey') &&
          Object.keys(addsAttributePriceList).includes(json_field.attributeKey!)
        ) {
          groupKey = json_field.attributeKey!;
          // console.log('Handle group by key', groupKey);
        }

        // handle attribute items
        if (Array.isArray(json_field.value)) {
          json_field.value.forEach((item) => {
            if (
              (item.hasOwnProperty('attributeKey') &&
                Object.keys(addsAttributePriceList).includes(
                  item.attributeKey!
                )) ||
              groupKey.length > 0
            ) {
              if (item.hasOwnProperty('price')) {
                const additionalValue =
                  groupKey.length > 0
                    ? parseLocaleNumber(addsAttributePriceList[groupKey])
                    : parseLocaleNumber(
                        addsAttributePriceList[item.attributeKey!]
                      );
                item.finalPrice =
                  parseLocaleNumber(item.price) + additionalValue;
                // console.log(
                //   'Handle availableProductAttribute item',
                //   item.label,
                //   item.finalPrice
                // );
              }
            } else {
              if (item.hasOwnProperty('price')) {
                // reset price tag to original
                item.finalPrice = item.price;
                // console.log(
                //   'Reset availableProductAttribute item',
                //   item.label,
                //   item.finalPrice
                // );
              }
            }

            // update price tags for originKey
            if (
              originKey &&
              Object.keys(addsConfigItemPriceList).includes(item.label)
            ) {
              if (
                item.hasOwnProperty('input') &&
                item.input!.hasOwnProperty('stepPrice')
              ) {
                item.input!.finalStepPrice =
                  parseLocaleNumber(item.input!.stepPrice!) +
                  parseLocaleNumber(addsConfigItemPriceList[item.label]);
                // console.log(
                //   'Update stepPrice for originKey',
                //   item.label,
                //   addsConfigItemPriceList[item.label],
                //   item.input!.stepPrice
                // );
              } else {
                item.finalPrice =
                  parseLocaleNumber(item.finalPrice!) +
                  parseLocaleNumber(addsConfigItemPriceList[item.label]);
                // console.log(
                //   'Update finalPrice for originKey',
                //   item.label,
                //   addsConfigItemPriceList[item.label],
                //   item.finalPrice
                // );
              }
            }
          });
        }
      }

      return attr;
    });
  } else {
    refiltered = selectedProductAttributes?.map((attr: IProductAttribute) => {
      const json_field = attr.json;
      const value_type = productAttributeValueTypes?.find(
        (valuetype) => valuetype.id === json_field.type
      );

      let groupKey = '';
      // handle complete group of attributes
      if (
        attr.hasOwnProperty('attributeKey') &&
        Object.keys(addsAttributePriceList).includes(attr.attributeKey)
      ) {
        groupKey = attr.attributeKey;
        // console.log('Handle group by key', groupKey);
      }

      if (value_type?.type_name === t('productAttributeValueTypeDictionary')) {
        const addsAttributeItemPriceList: { [key: string]: number | string } =
          {};
        let json_field_value =
          json_field.value as ProductAttributeJsonValueType;

        if (
          originKey &&
          json_field_value.hasOwnProperty('addsAttributePrice') &&
          Object.keys(json_field_value.addsAttributePrice!).includes(originKey)
        ) {
          addsAttributeItemPriceList[json_field_value.label] =
            json_field_value.addsAttributePrice![originKey];
          // console.log(
          //   'Handle originKey',
          //   originKey,
          //   json_field_value.label,
          //   json_field_value.addsAttributePrice![originKey]
          // );
        }

        if (
          (json_field_value.hasOwnProperty('attributeKey') &&
            Object.keys(addsAttributePriceList).includes(
              json_field_value.attributeKey!
            )) ||
          groupKey.length > 0
        ) {
          if (json_field_value.hasOwnProperty('price')) {
            const additionalValue =
              groupKey.length > 0
                ? parseLocaleNumber(addsAttributePriceList[groupKey])
                : parseLocaleNumber(
                    addsAttributePriceList[json_field_value.attributeKey!]
                  );
            json_field_value.finalPrice =
              parseLocaleNumber(json_field_value.price) + additionalValue;
            // console.log(
            //   'Handle selectProductAttribute item',
            //   json_field_value.label,
            //   json_field_value.finalPrice
            // );
          }
        } else {
          if (json_field_value.hasOwnProperty('price')) {
            // reset price tag to original
            json_field_value.finalPrice = json_field_value.price;
            // console.log(
            //   'Reset selectProductAttribute item',
            //   json_field_value.label,
            //   json_field_value.finalPrice
            // );
          }
        }

        // update price tags for originKey
        if (
          originKey &&
          Object.keys(addsAttributeItemPriceList).includes(
            json_field_value.label
          )
        ) {
          if (
            json_field_value.hasOwnProperty('input') &&
            json_field_value.input!.hasOwnProperty('stepPrice')
          ) {
            json_field_value.input!.finalStepPrice =
              parseLocaleNumber(json_field_value.input!.stepPrice!) +
              parseLocaleNumber(
                addsAttributeItemPriceList[json_field_value.label]
              );
            // console.log(
            //   'Update stepPrice for originKey',
            //   json_field_value.label,
            //   addsAttributeItemPriceList[json_field_value.label],
            //   json_field_value.input!.stepPrice
            // );
          } else {
            json_field_value.finalPrice =
              parseLocaleNumber(json_field_value.finalPrice!) +
              parseLocaleNumber(
                addsAttributeItemPriceList[json_field_value.label]
              );
            // console.log(
            //   'Update finalPrice for originKey',
            //   json_field_value.label,
            //   addsAttributeItemPriceList[json_field_value.label],
            //   json_field_value.finalPrice
            // );
          }
        }
      }

      return attr;
    });
  }

  // console.log('refiltered', refiltered);
  return refiltered;
};

export const productAttributeInputFilter = (
  productAttributeValueTypes?: Array<ProductAttributeValueTypes>,
  selectedProductAttributes?: Array<IProductAttribute>,
  configItems?: Array<IProductAttribute>
) => {
  let refiltered: Array<IProductAttribute> | undefined = [];

  if (configItems) {
    refiltered = configItems.map((attr: IProductAttribute) => {
      const json_field = attr.json;
      const value_type = productAttributeValueTypes?.find(
        (valuetype) => valuetype.id === json_field.type
      );

      if (value_type?.type_name === t('productAttributeValueTypeDictionary')) {
        const selectedProductAttribute = selectedProductAttributes?.find(
          (item) => item.attributeId === attr.id
        );

        // handle attribute items
        if (selectedProductAttribute && Array.isArray(json_field.value)) {
          (json_field.value as ProductAttributeJsonValueType[]).forEach(
            (item) => {
              let selected_attr_value = selectedProductAttribute.json
                .value as ProductAttributeJsonValueType;
              if (item.label === selected_attr_value.label) {
                // copy modified input values from selectedAttribute to configItem
                if (selected_attr_value.hasOwnProperty('input')) {
                  item.input = selected_attr_value.input;
                }
              }
            }
          );
        }
      }

      return attr;
    });
  } else {
    refiltered = selectedProductAttributes;
  }
  return refiltered;
};

export const truncateString = (input: string, maxLength: number) => {
  if (input && input.length > maxLength) {
    return `${input.substr(0, maxLength)}...`;
  } else {
    return input;
  }
};

export const calculateLastInputPrice = (
  value: ProductAttributeJsonValueType,
  apiCompliant: boolean = false
) => {
  // make deep copy, so that changes won't affect original items
  let tmpValue = _.cloneDeep(value);
  const lastInput = tmpValue.input?.hasOwnProperty('lastInput')
    ? tmpValue.input.lastInput!
    : 1;
  // calculate price * lastInput
  let finalPrice = 0;
  if (tmpValue.hasOwnProperty('finalPrice')) {
    finalPrice = parseLocaleNumber(tmpValue.finalPrice!);

    // if lastInput is used to calculate new price, than store old one as basePrice
    if (apiCompliant && tmpValue.input?.lastInput) {
      tmpValue.input.basePrice = numberToLocalString(finalPrice);
    }

    if (tmpValue.input?.hasOwnProperty('finalStepPrice')) {
      let finalStepPrice = parseLocaleNumber(tmpValue.input.finalStepPrice!);
      finalStepPrice = _.round(finalStepPrice * lastInput, 2);
      finalPrice = finalPrice + finalStepPrice;

      if (apiCompliant) {
        delete tmpValue.input['finalStepPrice'];
      }
    }

    // update finalPrice
    tmpValue.finalPrice = finalPrice;

    if (apiCompliant) {
      tmpValue.price = numberToLocalString(finalPrice);
      delete tmpValue['finalPrice'];
    }
  }

  return tmpValue;
};
