/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import AsyncStorage from '@react-native-async-storage/async-storage';

import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { formReducer, getFormState } from '../../helpers/input-form';
import { Ionicons, ThemeProps, useThemeColor, View } from './Themed';
import { LabelText } from './StyledText';
import AlertModal from './AlertModal';
import Input from './Input';
import TouchableComponent from './TouchableComponent';

export const FILTER_PERSISTENCE_KEY = 'FILTER_PERSISTENCE_KEY';

export type InputFilterProps = ThemeProps & {
  containerStyle?: StyleSheet['props'];
  onChangeFilter: (items: Array<string>) => void;
  useStorage: boolean;
};

const InputFilter = (props: InputFilterProps) => {
  /* #region Fields */
  const { containerStyle, onChangeFilter, useStorage, lightColor, darkColor } =
    props;
  const refInput = useRef();
  const isMounted = useIsMounted();
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const [filterItems, setFilterItems] = useState<Array<string>>([]);

  // form validation model
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      search: ''
    },
    inputValidities: {
      search: false
    },
    formIsValid: false
  });

  const tagBackgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    const getStoredFilters = async () => {
      const loadedData = await AsyncStorage.getItem(FILTER_PERSISTENCE_KEY);

      if (loadedData) {
        const { filters } = JSON.parse(loadedData);
        if (filters?.length > 0) {
          updateState(() => setFilterItems(filters));
        }
      }
    };

    if (useStorage) getStoredFilters();

    return () => {};
  }, []);

  useEffect(() => {
    // passing filterItems list to outside property
    onChangeFilter(filterItems);
  }, [filterItems]);

  const inputChangeHandler = useCallback(
    (inputIdentifier: string, inputValue: unknown, inputValidity: boolean) => {
      dispatchFormState(
        getFormState(inputIdentifier, inputValue, inputValidity)
      );
    },
    [dispatchFormState]
  );

  const submitHandler = useCallback(async () => {
    try {
      const value = formState.inputValues.search.trim();
      if (value.length > 0 && !filterItems.includes(value)) {
        // add item to filter list
        updateState(() =>
          setFilterItems((prevItems) => {
            const updatedFilters = [...prevItems, value];

            if (useStorage) {
              AsyncStorage.setItem(
                FILTER_PERSISTENCE_KEY,
                JSON.stringify({ filters: updatedFilters })
              );
            }

            formState.inputValues.search = '';
            return updatedFilters;
          })
        );
      }

      // clear text input
      refInput.current?.value('');
      // refInput.current?.focus();
    } catch (err) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('errorOccurred')}
            message={err.message}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    }
  }, [dispatch, formState, filterItems, useStorage]);

  const clearHandler = useCallback(
    async (item) => {
      // remove item from filter list
      updateState(() =>
        setFilterItems((prevItems) => {
          const updatedFilters = prevItems.filter((obj) => obj !== item);

          if (useStorage) {
            AsyncStorage.setItem(
              FILTER_PERSISTENCE_KEY,
              JSON.stringify({ filters: updatedFilters })
            );
          }

          return updatedFilters;
        })
      );
      // return the filter list
      onChangeFilter(filterItems);
    },
    [filterItems]
  );
  /* #endregion */

  /* #region Renderers */
  return (
    <View style={containerStyle}>
      {alertModalContent}
      <Input
        ref={refInput}
        id="search"
        placeholder={t('titleSearchProduct')}
        keyboardType="default"
        autoCapitalize="none"
        errorText={t('messageSearchMatchNotFound')}
        onInputChange={inputChangeHandler}
        onSubmitEditing={submitHandler}
        initialValue={''}
        initiallyValid={false}
      />
      <View style={styles.tagContainer}>
        {filterItems.map((item, index) => {
          return (
            <View
              key={index}
              style={[styles.tag, { backgroundColor: tagBackgroundColor }]}
            >
              <LabelText small>{item}</LabelText>
              <View style={styles.clear}>
                <TouchableComponent
                  noFeedback
                  onPress={() => clearHandler(item)}
                >
                  <Ionicons name="close" size={15} />
                </TouchableComponent>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
  /* #endregion */
};

const styles = StyleSheet.create({
  tagContainer: {
    flexDirection: 'row',
    paddingBottom: 5
  },
  tag: {
    flexDirection: 'row',
    marginHorizontal: 5,
    paddingLeft: 10
  },
  clear: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: 'transparent'
  }
});

export default InputFilter;
