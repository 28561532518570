/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { truncateString } from '../../helpers/filter';
import { View, ScrollView } from '../../components/UI/Themed';
import ContainerView from '../../components/UI/ContainerView';
import Loading from '../../components/UI/Loading';
import Card from '../../components/UI/Card';
import ArticleItem from '../../components/blog/ArticleItem';
import * as blogActions from '../../store/actions/blog';
import { selectedFeedArticle } from '../../store/reselect';
import { BlogStackParamList } from '../../types';

const ArticleScreen = ({
  route,
  navigation
}: StackScreenProps<BlogStackParamList, 'Article'>): JSX.Element => {
  /* #region Fields */
  const { styles } = useStyles();
  const isWeb = Platform.OS === 'web';
  const isMounted = useIsMounted();
  const [error, setError] = useState<string>('');
  const articleSlug = route.params?.articleSlug;
  const selectedArticle = useSelector(selectedFeedArticle(articleSlug));
  const dispatch = useDispatch();
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  const loadArticle = useCallback(async () => {
    updateState(() => setError(''));
    if (selectedArticle) {
      try {
        dispatch(blogActions.hitArticle(selectedArticle.id));
      } catch (err) {
        updateState(() => setError(err.message));
      }
    }
  }, [dispatch, setError, selectedArticle]);

  useFocusEffect(() => {
    // set hitcount for article in database
    loadArticle();
  });

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${selectedArticle?.title} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({
        title: truncateString(selectedArticle?.title, 22)
      });
    }
  }, [navigation, selectedArticle]);
  /* #endregion */

  /* #region Renderers */
  if (!selectedArticle) {
    return <Loading />;
  }

  return (
    <ContainerView
      scrollable
      seoTitle={`${selectedArticle?.title} | ${t('appTitle')}`}
      seoDescription={selectedArticle?.teaser?.replace(/(<([^>]+)>)/gi, '')}
      seoMeta={[
        { name: 'robots', content: 'index,nofollow' },
        { name: 'keywords', content: selectedArticle?.meta_keywords }
      ]}
    >
      <View style={styles('screen')}>
        <ScrollView style={styles('container')}>
          <Card style={styles('article')}>
            <ArticleItem
              date={selectedArticle?.date}
              title={selectedArticle?.title}
              body={selectedArticle?.body}
            ></ArticleItem>
          </Card>
        </ScrollView>
      </View>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    screen: {
      flex: 1,
      backgroundColor: 'transparent'
    },
    container: {
      backgroundColor: 'transparent',
      marginVertical: 20
    },
    article: {
      backgroundColor: 'transparent',
      marginHorizontal: '8%'
    }
  },
  {
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      article: {
        marginHorizontal: '4%'
      }
    },
    [DEVICE_SIZES.SMALL_DEVICE]: {
      container: {
        marginVertical: 0
      },
      article: {
        marginHorizontal: 0
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      container: {
        marginVertical: 0
      },
      article: {
        marginHorizontal: 0
      }
    }
  }
);

export default ArticleScreen;
