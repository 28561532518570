/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *
 *  Special thanks goes to https://blog.bitsrc.io/improving-seo-of-react-apps-with-react-helmet-7b79fb8774f4
 *-------------------------------------------------------------*/

import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';

export type SEOProps = {
  seoTitle?: string;
  seoDescription?: string;
  seoAuthor?: string;
  seoLang?: string;
  seoMeta?: Array<Object>;
};

const SEO = React.memo((props: SEOProps) => {
  /* #region Fields */
  const { seoTitle, seoDescription, seoAuthor, seoLang, seoMeta } = props;
  const language = useSelector((state: RootState) => state.settings.language);
  const title = seoTitle ? seoTitle : '';
  const description = seoDescription ? seoDescription : '';
  const author = seoAuthor ? seoAuthor : '';
  const lang = seoLang ? seoLang : language;
  const meta = seoMeta ? seoMeta : [];
  /* #endregion */

  /* #region Renderers */
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      // The title is set by navigation.setOptions()
      // title={title}
      // titleTemplate={`%s | ${t('appTitle')}`}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: description
        }
      ].concat(meta)}
    />
  );
  /* #endregion */
});

export default SEO;
