/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef
} from 'react';
import {
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform
} from 'react-native';
import { CreateResponsiveStyle } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { HeaderBackButton } from '@react-navigation/elements';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { formReducer, getFormState } from '../../helpers/input-form';
import {
  View,
  Button,
  useThemeColor,
  ThemeProps
} from '../../components/UI/Themed';
import AlertModal from '../../components/UI/AlertModal';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import Input from '../../components/UI/Input';
import Card from '../../components/UI/Card';
import store, { RootState } from '../../store';
import * as authActions from '../../store/actions/auth';
import { AuthStackParamList } from '../../types';

const AuthScreen = (
  { navigation }: StackScreenProps<AuthStackParamList, 'UserAuth'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const { styles } = useStyles();
  const isWeb = Platform.OS === 'web';
  const containerRef = useRef<ContainerViewProps>();
  const isMounted = useIsMounted();
  const isAuth = useSelector((state: RootState) => !!state.auth.accessToken);
  const authEmail = useSelector((state: RootState) => state.auth.email);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const dispatch = useDispatch();

  // form validation model
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      email: authEmail ? authEmail : '',
      password: ''
    },
    inputValidities: {
      email: authEmail ? true : false,
      password: false
    },
    formIsValid: false
  });
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (isAuth) {
      // TODO: Can be a screen for user profile
      navigation.navigate('Shop');
    }
  }, [isAuth]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleUserAuthScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleUserAuthScreen') });
    }

    navigation.setOptions({
      headerLeft: (props) => {
        return (
          <HeaderBackButton
            {...props}
            onPress={() => {
              store.dispatch(authActions.logout()); // we reference screenOptions in our navigation wrapper, so we cannot use hooks
              navigation.navigate('Shop');
            }}
          />
        );
      }
    });
  }, [navigation]);

  const authHandler = useCallback(async () => {
    if (!formState.formIsValid) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('wrongInput')}
            message={t('checkFormErrors')}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
      return;
    }

    updateState(() => setIsLoading(true));
    dispatch(authActions.setDidTryAL(true));

    try {
      if (isSignUp) {
        dispatch(
          authActions.signUp(
            formState.inputValues.email,
            formState.inputValues.password
          )
        );
      } else {
        dispatch(
          authActions.login(
            formState.inputValues.email,
            formState.inputValues.password
          )
        );
      }
    } catch (err) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('errorOccurred')}
            message={err.message}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, formState, setIsLoading]);

  const inputChangeHandler = useCallback(
    (inputIdentifier: string, inputValue: unknown, inputValidity: boolean) => {
      dispatchFormState(
        getFormState(inputIdentifier, inputValue, inputValidity)
      );
    },
    [dispatchFormState]
  );
  /* #endregion */

  /* #region Renderers */
  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      contentContainerStyle={{ flex: 1 }}
      seoTitle={`${t('titleUserAuthScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <KeyboardAvoidingView
        behavior="padding"
        keyboardVerticalOffset={50}
        style={styles('screen')}
      >
        {alertModalContent}
        <View style={styles('centered')}>
          <Card style={styles('authContainer')}>
            <Input
              id="email"
              label={t('inputEMailLabel')}
              keyboardType="email-address"
              required
              email
              autoCapitalize="none"
              errorText={t('inputEMailError')}
              onInputChange={inputChangeHandler}
              initialValue={authEmail ? authEmail : ''}
            />
            <Input
              id="password"
              label={t('inputPasswordLabel')}
              keyboardType="default"
              secureTextEntry
              required
              minLength={8}
              autoCapitalize="none"
              errorText={t('inputPasswordError')}
              onInputChange={inputChangeHandler}
              initialValue=""
            />
            <View style={styles('buttonContainer')}>
              {isLoading ? (
                <ActivityIndicator size="small" color={primaryColor} />
              ) : (
                <Button
                  title={isSignUp ? t('titleSignUp') : t('titleLogin')}
                  color={buttonColor}
                  onPress={authHandler}
                />
              )}
            </View>
            <View style={styles('buttonContainer')}>
              <Button
                title={t('titleSignUp')}
                color={buttonAccentColor}
                onPress={() => {
                  navigation.push('Auth', { screen: 'UserProfile' });
                }}
              />
            </View>
          </Card>
        </View>
      </KeyboardAvoidingView>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    screen: {
      flex: 1,
      margin: 20
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    authContainer: {
      width: '80%',
      maxWidth: 400,
      maxHeight: 400,
      padding: 20
    },
    buttonContainer: {
      marginTop: 10
    }
  },
  {}
);

export default AuthScreen;
