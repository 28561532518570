/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { AnyAction } from 'redux';

import ShopCategory from '../../models/ShopCategory';
import ProductCategory from '../../models/ProductCategory';
import Product from '../../models/Product';
import PartnerSite from '../../models/PartnerSite';
import {
  SET_FETCHED_FROM_API,
  SET_PRODUCT_CATEGORIES,
  SET_PRODUCTS,
  SET_PRODUCT_SHOP_CATEGORIES,
  SET_PARTNER_SITES,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT
} from '../actions/products';

interface ProductState {
  availableProductCategories: Array<ProductCategory>;
  availableProducts: Array<Product>;
  availableShopCategories: Array<ShopCategory>;
  availablePartnerSites: Array<PartnerSite>;
  userProducts: Array<Product>;
  fetchedFromAPI: boolean;
}

const initialState: ProductState = {
  availableProductCategories: [],
  availableProducts: [],
  availableShopCategories: [],
  availablePartnerSites: [],
  userProducts: [],
  fetchedFromAPI: false
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        availableProductCategories: action.categories
      };

    case SET_PRODUCTS:
      return {
        ...state,
        availableProducts: action.products,
        userProducts: action.userProducts,
        fetchedFromAPI: true
      };

    case SET_PRODUCT_SHOP_CATEGORIES:
      return {
        ...state,
        availableShopCategories: action.categories
      };

    case SET_PARTNER_SITES:
      return {
        ...state,
        availablePartnerSites: action.partnersites
      };

    case CREATE_PRODUCT:
      const newProduct = new Product(
        action.productData.id,
        action.productData.ownerId,
        action.productData.categoryId,
        action.productData.title,
        action.productData.slug,
        action.productData.images,
        action.productData.linkedURL,
        action.productData.is_pricedByAttribute,
        action.productData.pricedByAttributeId,
        action.productData.description,
        action.productData.price,
        action.productData.currency
      );
      return {
        ...state,
        availableProducts: state.availableProducts.concat(newProduct),
        userProducts: state.userProducts.concat(newProduct)
      };

    case UPDATE_PRODUCT:
      const userProductIndex = state.userProducts.findIndex(
        (prod) => prod.id === action.pid
      );
      const availableProductIndex = state.availableProducts.findIndex(
        (prod) => prod.id === action.pid
      );
      const updatedProduct = new Product(
        action.pid,
        state.userProducts[userProductIndex].ownerId,
        action.productData.categoryId,
        action.productData.title,
        action.productData.slug,
        action.productData.images,
        action.productData.linkedURL,
        action.productData.is_pricedByAttribute,
        action.productData.pricedByAttributeId,
        action.productData.description,
        action.productData.price,
        action.productData.currency
      );
      const updatedUserProducts = [...state.userProducts];
      updatedUserProducts[userProductIndex] = updatedProduct;
      const updatedAvailableProducts = [...state.availableProducts];
      updatedAvailableProducts[availableProductIndex] = updatedProduct;
      return {
        ...state,
        availableProducts: updatedAvailableProducts,
        userProducts: updatedUserProducts
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        availableProducts: state.availableProducts.filter(
          (product) => product.id !== action.pid
        ),
        userProducts: state.userProducts.filter(
          (product) => product.id !== action.pid
        )
      };

    case SET_FETCHED_FROM_API:
      return {
        ...state,
        fetchedFromAPI: action.value
      };
  }
  return state;
};
