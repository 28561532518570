/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef
} from 'react';
import {
  KeyboardAvoidingView,
  ActivityIndicator,
  Platform
} from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StackScreenProps } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { t } from '../../helpers/localized';
import { formReducer, getFormState } from '../../helpers/input-form';
import {
  View,
  Button,
  useThemeColor,
  Ionicons,
  ThemeProps
} from '../../components/UI/Themed';
import AlertModal from '../../components/UI/AlertModal';
import { LabelText, MainText } from '../../components/UI/StyledText';
import TouchableComponent from '../../components/UI/TouchableComponent';
import ContainerView, {
  ContainerViewProps
} from '../../components/UI/ContainerView';
import Card from '../../components/UI/Card';
import Input from '../../components/UI/Input';
import * as authActions from '../../store/actions/auth';
import * as notificationActions from '../../store/actions/notification';
import { RootState } from '../../store';
import { ShopStackParamList } from '../../types';

const AuthScreen = (
  { navigation }: StackScreenProps<ShopStackParamList, 'ShopAuth'>,
  { lightColor, darkColor }: ThemeProps
): JSX.Element => {
  /* #region Fields */
  const isWeb = Platform.OS === 'web';
  const containerRef = useRef<ContainerViewProps>();
  const isMounted = useIsMounted();
  const { styles, deviceSize } = useStyles();
  const isAuth = useSelector((state: RootState) => !!state.auth.accessToken);
  const authEmail = useSelector((state: RootState) => state.auth.email);
  const cartItemCount = useSelector(
    (state: RootState) => state.cart.totalCount
  );
  const [alertModalContent, setAlertModalContent] = useState<JSX.Element>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const primaryColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'primary'
  );
  const buttonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'button'
  );
  const buttonAccentColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'buttonAccent'
  );
  const headerButtonColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'headerButtons'
  );
  const borderColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    'border'
  );
  const dispatch = useDispatch();

  // form validation model
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      email: authEmail ? authEmail : '',
      password: ''
    },
    inputValidities: {
      email: authEmail ? true : false,
      password: false
    },
    formIsValid: false
  });
  /* #endregion */

  /* #region Methods */
  const updateState = (callback: () => void) => {
    if (isMounted.current) {
      callback();
    }
  };
  /* #endregion */

  /* #region Events */
  useEffect(() => {
    if (isAuth) {
      navigation.push('Shop', {
        screen: 'Products',
        params: {
          screen: 'FinishOrder'
        }
      });
    }
  }, [isAuth]);

  const loginHandler = useCallback(async () => {
    if (!formState.formIsValid) {
      // set up modal
      updateState(() =>
        setAlertModalContent(
          <AlertModal
            title={t('wrongInput')}
            message={t('checkFormErrors')}
            onDismiss={() => updateState(() => setAlertModalContent(<></>))}
          />
        )
      );
      return;
    }

    updateState(() => setIsLoading(true));
    dispatch(authActions.setDidTryAL(true));

    try {
      dispatch(
        authActions.login(
          formState.inputValues.email,
          formState.inputValues.password
        )
      );
    } catch (err) {
      dispatch(notificationActions.setMessage(t('errorOccurred'), err.message));
    } finally {
      updateState(() => setIsLoading(false));
    }
  }, [dispatch, formState, setIsLoading]);

  useEffect(() => {
    // Inject title string into Toobar's header
    if (isWeb) {
      navigation.setOptions({
        title: `${t('titleShopAuthScreen')} | ${t('appTitle')}`
      });
    } else {
      navigation.setOptions({ title: t('titleShopAuthScreen') });
    }

    // Always show HeaderButtons for shopping cart
    navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableComponent
            onPress={() => {
              navigation.push('Shop', {
                screen: 'Products',
                params: {
                  screen: 'Cart'
                }
              });
            }}
            style={[styles('headerButton'), styles('cartButton')]}
          >
            <View style={styles('touchableContainer')}>
              <LabelText style={{ color: headerButtonColor }}>
                {cartItemCount}
              </LabelText>
              <Ionicons
                name="cart"
                color={headerButtonColor}
                style={styles('touchable')}
              />
            </View>
          </TouchableComponent>
        );
      }
    });
  }, [navigation, cartItemCount]);

  const inputChangeHandler = useCallback(
    (inputIdentifier: string, inputValue: unknown, inputValidity: boolean) => {
      dispatchFormState(
        getFormState(inputIdentifier, inputValue, inputValidity)
      );
    },
    [dispatchFormState]
  );
  /* #endregion */

  /* #region Renderers */
  return (
    <ContainerView
      ref={containerRef}
      scrollable={isWeb}
      seoTitle={`${t('titleShopAuthScreen')} | ${t('appTitle')}`}
      seoMeta={[{ name: 'robots', content: 'noindex,nofollow' }]}
    >
      <KeyboardAvoidingView
        behavior="padding"
        keyboardVerticalOffset={50}
        style={styles('screen')}
      >
        {alertModalContent}
        <View style={styles('centered')}>
          <Card style={styles('container')}>
            <View style={styles('form')}>
              <View style={styles('centered')}>
                <View style={styles('authContainer')}>
                  <Input
                    id="email"
                    label={t('inputEMailLabel')}
                    keyboardType="email-address"
                    required
                    email
                    autoCapitalize="none"
                    errorText={t('inputEMailError')}
                    onInputChange={inputChangeHandler}
                    initialValue={authEmail ? authEmail : ''}
                  />
                  <Input
                    id="password"
                    label={t('inputPasswordLabel')}
                    keyboardType="default"
                    secureTextEntry
                    required
                    minLength={8}
                    autoCapitalize="none"
                    errorText={t('inputPasswordError')}
                    onInputChange={inputChangeHandler}
                    initialValue=""
                  />
                  <View style={styles('buttonContainer')}>
                    {isLoading ? (
                      <ActivityIndicator size="small" color={primaryColor} />
                    ) : (
                      <Button
                        title={t('titleLogin')}
                        color={buttonColor}
                        onPress={loginHandler}
                      />
                    )}
                  </View>
                  <View style={styles('buttonContainer')}>
                    <Button
                      title={t('titleSignUp')}
                      color={buttonAccentColor}
                      onPress={() => {
                        navigation.push('Auth', { screen: 'UserProfile' });
                      }}
                    />
                  </View>
                </View>
              </View>
              <View
                style={
                  [
                    DEVICE_SIZES.EXTRA_SMALL_DEVICE,
                    DEVICE_SIZES.SMALL_DEVICE
                  ].includes(deviceSize)
                    ? [
                        styles('horizontalLine'),
                        { borderBottomColor: borderColor }
                      ]
                    : [styles('verticalLine'), { borderLeftColor: borderColor }]
                }
              />
              <View style={styles('registerContainer')}>
                <MainText>{t('messageWithoutRegistration')}</MainText>
                <View style={styles('buttonContainer')}>
                  <Button
                    title={t('titleContinueAsGuest')}
                    color={buttonAccentColor}
                    onPress={() => {
                      navigation.push('Shop', {
                        screen: 'Products',
                        params: {
                          screen: 'GuestProfile'
                        }
                      });
                    }}
                  />
                </View>
              </View>
            </View>
          </Card>
        </View>
      </KeyboardAvoidingView>
    </ContainerView>
  );
  /* #endregion */
};

const useStyles = CreateResponsiveStyle(
  {
    screen: {
      flex: 1,
      margin: 20
    },
    centered: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    container: {
      height: '80%',
      width: '90%',
      padding: 20,
      marginHorizontal: 20
    },
    form: {
      flex: 1,
      flexDirection: 'row',
      padding: 10,
      backgroundColor: 'transparent'
    },
    authContainer: {
      width: '100%',
      height: '100%',
      maxWidth: 400,
      maxHeight: 400,
      padding: 20
    },
    registerContainer: {
      flex: 1,
      padding: 20,
      justifyContent: 'center',
      alignItems: 'center'
    },
    horizontalLine: {
      width: '90%',
      alignSelf: 'center',
      marginVertical: 20,
      borderBottomWidth: 1
    },
    verticalLine: {
      marginHorizontal: 5,
      borderLeftWidth: 1
    },
    buttonContainer: {
      marginTop: 10
    },
    headerButton: {
      marginHorizontal: Platform.select({ web: 11, default: 0 })
    },
    cartButton: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    touchableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    touchable: {
      marginHorizontal: 8
    }
  },
  {
    [DEVICE_SIZES.SMALL_DEVICE]: {
      container: {
        flexGrow: 1
      },
      form: {
        flexGrow: 1,
        flexDirection: 'column'
      },
      authContainer: {
        height: 270
      },
      registerContainer: {
        flexGrow: 1
      }
    },
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      container: {
        flexGrow: 1
      },
      form: {
        flexGrow: 1,
        flexDirection: 'column'
      },
      authContainer: {
        height: 270
      },
      registerContainer: {
        flexGrow: 1
      }
    }
  }
);

export default AuthScreen;
