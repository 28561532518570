/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2021 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { ScreenParamList } from '../../types';

export const SET_ACTIVE_SCREEN = 'SET_ACTIVE_SCREEN';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_PRODUCT_CONFIGRUATOR_DETAILS =
  'SET_PRODUCT_CONFIGRUATOR_DETAILS';
export const CLEAR_PRODUCT_CONFIGRUATOR_DETAILS =
  'CLEAR_PRODUCT_CONFIGRUATOR_DETAILS';

export const setActiveScreen = (screen: ScreenParamList) => {
  return { type: SET_ACTIVE_SCREEN, screen };
};

export const setMessage = (title: string, message: string) => {
  return { type: SET_MESSAGE, title, message };
};

export const clearMessage = () => {
  return { type: CLEAR_MESSAGE };
};

export const setProductConfiguratorDetails = (details) => {
  return { type: SET_PRODUCT_CONFIGRUATOR_DETAILS, details };
};

export const clearProductConfiguratorDetails = () => {
  return { type: CLEAR_PRODUCT_CONFIGRUATOR_DETAILS };
};
