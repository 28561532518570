/*--------------------------------------------------------------
 *  Copyright (C) 2018 - 2022 dsoft-app-dev.de and friends.
 *
 *  This Program may be used by anyone in accordance with the terms of the
 *  German Free Software License
 *
 *  The License may be obtained under http://www.d-fsl.org.
 *-------------------------------------------------------------*/

import { AnyAction } from 'redux';
import _ from 'lodash';

import CartItem from '../../models/CartItem';
import { t, parseLocaleNumber } from '../../helpers/localized';
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_DID_VERIFY_ORDER,
  SET_DID_VERIFY_ADDRESS
} from '../actions/cart';
import { ADD_ORDER } from '../actions/orders';
import { DELETE_PRODUCT } from '../actions/products';
import { LOGOUT } from '../actions/auth';

interface CartState {
  items: any;
  totalCount: number;
  totalAmount: number;
  didVerifyOrder: boolean;
  didVerifyAddress: boolean;
  didFinishOrder: boolean;
}

const initialState: CartState = {
  items: {},
  totalCount: 0,
  totalAmount: 0,
  didVerifyOrder: false,
  didVerifyAddress: false,
  didFinishOrder: false
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ADD_TO_CART:
      const addedProduct = action.product;
      // make deep copy, so that changes won't affect original items
      const addedProductAttributes = _.cloneDeep(
        action.selectedProductAttributes
      );
      const prodTitle = addedProduct.title;

      // calculate additional pricing and build up unique cartItem key
      let additionalPricing = 0;
      let cartItemKey = `${addedProduct.id}`;
      addedProductAttributes.forEach((element) => {
        if (element.json.value.hasOwnProperty('finalPrice')) {
          additionalPricing += parseLocaleNumber(element.json.value.finalPrice);
          cartItemKey += `-${element.json.value.label}`;
        }
        // calculate additional price for lastInput
        if (element.json.value.input) {
          let finalStepPrice = parseLocaleNumber(
            element.json.value.input.finalStepPrice
          );
          finalStepPrice = finalStepPrice * element.json.value.input.lastInput;
          additionalPricing += finalStepPrice;
          cartItemKey += `-${element.json.value.input.lastInput}`;
        }
      });
      const prodPrice =
        parseLocaleNumber(addedProduct.price) + additionalPricing;

      // add basic product price to attributes
      if (addedProductAttributes.length > 0) {
        addedProductAttributes.unshift({
          id: 0,
          productId: addedProduct.id,
          name: t('priceBase'),
          sortOrder: -1,
          json: {
            value: `${t('priceFrom')} ${parseLocaleNumber(
              addedProduct.price
            ).toLocaleString(action.language, {
              style: 'currency',
              currency: action.currency
            })}`,
            type: 1
          }
        });
      }

      let updatedOrNewCartItem;
      if (
        state.items[cartItemKey] &&
        _.isEqual(
          state.items[cartItemKey].productAttributes,
          addedProductAttributes
        )
      ) {
        // already have the item in the list and manage the quantity
        updatedOrNewCartItem = new CartItem(
          state.items[cartItemKey].quantity + 1,
          prodTitle,
          addedProductAttributes,
          prodPrice,
          state.items[cartItemKey].sum + prodPrice
        );
      } else {
        // create a new item and add it to items
        updatedOrNewCartItem = new CartItem(
          1,
          prodTitle,
          addedProductAttributes,
          prodPrice,
          prodPrice
        );
      }
      return {
        ...state,
        items: { ...state.items, [cartItemKey]: updatedOrNewCartItem },
        totalCount: state.totalCount + 1,
        totalAmount: state.totalAmount + prodPrice,
        didVerifyOrder: action.orderVerified,
        didVerifyAddress: action.addressVerified,
        didFinishOrder: false
      };

    case REMOVE_FROM_CART:
      const selectedCartItem = state.items[action.itemKey];
      const currentQty = selectedCartItem.quantity;

      let updatedCartItems;

      if (currentQty > 1) {
        // need to reduce the quantity of the item, not to erase the entire line
        const updatedCartItem = new CartItem(
          selectedCartItem.quantity - 1,
          selectedCartItem.productTitle,
          selectedCartItem.productAttributes,
          selectedCartItem.productPrice,
          selectedCartItem.sum - selectedCartItem.productPrice
        );

        updatedCartItems = {
          ...state.items,
          [action.itemKey]: updatedCartItem
        };
      } else {
        updatedCartItems = { ...state.items };
        delete updatedCartItems[action.itemKey];
      }
      return {
        ...state,
        items: updatedCartItems,
        totalCount: state.totalCount - 1,
        totalAmount: state.totalAmount - selectedCartItem.productPrice,
        didVerifyOrder: false,
        didVerifyAddress: false,
        didFinishOrder: false
      };

    case ADD_ORDER:
      return {
        ...initialState,
        didVerifyOrder: true,
        didVerifyAddress: true,
        didFinishOrder: true
      };

    case DELETE_PRODUCT:
      let itemKey;
      Object.keys(state.items).forEach((item) => {
        // the itemKey of CartItems has the pattern 'pid-xxxx-xxxx-...', hence we have to extract the pid part to match
        if (item.substring(0, item.indexOf('-')) === String(action.pid)) {
          itemKey = item;
        }
      });

      if (!itemKey || !state.items[itemKey]) {
        return state;
      }

      const updatedItems = { ...state.items };
      const itemTotal = state.items[itemKey].sum;
      const itemCount = state.items[itemKey].quantity;
      delete updatedItems[itemKey];

      return {
        ...state,
        items: updatedItems,
        totalCount: state.totalCount - itemCount,
        totalAmount: state.totalAmount - itemTotal,
        didVerifyOrder: false,
        didVerifyAddress: false,
        didFinishOrder: false
      };

    case SET_DID_VERIFY_ORDER:
      return {
        ...state,
        didVerifyOrder: action.value
      };

    case SET_DID_VERIFY_ADDRESS:
      return {
        ...state,
        didVerifyAddress: action.value
      };

    case LOGOUT:
      return {
        ...state,
        didVerifyOrder: false,
        didVerifyAddress: false,
        didFinishOrder: false
      };
  }
  return state;
};
